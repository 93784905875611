import {
  ESendUserInvitationStatus,
  IUserInvitation,
} from "../interfaces/IUserInvitation";

export const API_PATH_apiUsersInvitationsGet = '/services/users-invitations';

export interface IApiUsersInvitationsGetQuery {
  search?: string;
  skip: string;
  limit: string;
  lastSendStatus?: ESendUserInvitationStatus[];
  sortFieldName?: string;
  sortDirection?: '0' | '1' | '-1';
  deleted?: "true" | "false";
}

export interface IApiUsersInvitationsGetResponse {
  usersInvitations: IUserInvitation[];
}

