import {
  IDBEntityBase,
  getDefaultDBEntityDocument,
} from "utils-library/dist/commonJs/db-entity-interfaces";

import {EAlertType} from "./EAlertType";

export interface IAlertConfig extends IDBEntityBase {
  id: EAlertType;
  severity: EAlertSeverity;
  postponable: boolean;
  resolveOptions: IAlertResolveOption[];
  alertTypeLabel: string;
  notes: string;
}

export enum EAlertSeverity {
  INFO = "INFO",
  WARNING = "WARNING",
  DANGER = "DANGER",
}

export interface IAlertResolveOption {
  id: string;   // Guid
  label: string;
}

export const getDefaultAlertConfig = (
  {
    id,
    severity,
    postponable = true,
  }: {
    id: EAlertType;
    severity: EAlertSeverity;
    postponable?: boolean;
    resolveOptions?: IAlertResolveOption[];
  },
): IAlertConfig => {
  const alertConfig = getDefaultDBEntityDocument<IAlertConfig>({
    severity,
    postponable,
    resolveOptions: [],
    alertTypeLabel: id.toLocaleLowerCase().split('_')
      .filter(Boolean)
      .join(' '),
    notes: '',
  });
  alertConfig.id = id;
  return alertConfig;
};
