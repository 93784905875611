import * as React from "react";

import {BreakpointDeviceContainer} from "../BreakpointDeviceContainer";

import {EBreakpointDevice} from "../ui-interfaces";

export interface IBreakpointDeviceRenderProps {
  render: (breakpoint: EBreakpointDevice) => any;
}

export const BreakpointDeviceRender = (props: IBreakpointDeviceRenderProps): JSX.Element => {
  const {render} = props;

  return (
    <>
      <BreakpointDeviceContainer mobile>
        {render(EBreakpointDevice.MOBILE)}
      </BreakpointDeviceContainer>
      <BreakpointDeviceContainer tablet>
        {render(EBreakpointDevice.TABLET)}
      </BreakpointDeviceContainer>
      <BreakpointDeviceContainer laptop>
        {render(EBreakpointDevice.LAPTOP)}
      </BreakpointDeviceContainer>
      <BreakpointDeviceContainer desktop>
        {render(EBreakpointDevice.DESKTOP)}
      </BreakpointDeviceContainer>
      <BreakpointDeviceContainer wide>
        {render(EBreakpointDevice.WIDE)}
      </BreakpointDeviceContainer>
    </>
  );
};
