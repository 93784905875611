export const API_PATH_ApiDeviceMgmtLocationChangeId = '/services/device-mgtm/apiDeviceMgmtLocationChangeId';

export interface IApiDeviceMgmtLocationChangeIdRequest {
  id: string;
  newId: string;
}

export interface IApiDeviceMgmtLocationChangeIdResponse {
  ok: true;
}
