export const API_PATH_ApiAlertMgtmCreateTestAlert = '/services/alert-mgtm/apiAlertMgtmCreateTestAlert';

export interface IApiAlertMgtmCreateTestAlertRequest {
  createTestAlert: ECreateTestAlert;
  regionId: string;
  locationId: string;
  deviceId: string;
  title: string;
  description: string;
}

export enum ECreateTestAlert {
  DEMO_HEAVY_SNOWFALL= "DEMO_HEAVY_SNOWFALL",
}

export interface IApiAlertMgtmCreateTestAlertResponse {
  ok: true;
}
