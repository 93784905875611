import {ILocation} from "../../interfaces/ILocation";

export const API_PATH_ApiDeviceMgmtLocationChangeRegion = '/services/devices-mgtm/apiDeviceMgmtLocationChangeRegion';

export interface IApiDeviceMgmtLocationChangeRegionRequest {
  locationId: string;
  regionId: string;
}

export interface IApiDeviceMgmtLocationChangeRegionResponse {
  location: ILocation;
}
