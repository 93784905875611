export const API_PATH_ApiAlertMgtmAlertHandlingResolve = '/services/alerts-mgtm/apiAlertMgtmAlertHandlingResolve';

export interface IApiAlertMgtmAlertHandlingResolveRequest {
  handlingId: string;
  resolveId: string;
}

export interface IApiAlertMgtmAlertHandlingResolveResponse {
  ok: true;
}
