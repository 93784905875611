import * as React from "react";
import {
  ReactElement,
  useState,
  useEffect,
  ForwardedRef,
  useImperativeHandle,
} from "react";

import {useDebouncedCallback} from "use-debounce";

import {Box} from "../Box";

import {Condition} from "../Condition";
import {
  Table,
  IColumn,
  ITableSort,
} from "../Table";
import {ITableFilter} from "./interfaces";
import {
  LabelOverlayDataProgress,
  EProgressType,
} from "../LabelOverlayDataProgress";
import {
  GridContainer,
  GridItem,
} from "../Grid";
import {ErrorBanner} from "../ErrorBanner";
import {FilterInput} from "./components/FilterInput";
import {IsLoadingBox} from "../IsLoadingBox";
import {
  Button,
  EButtonSize,
} from "../Button";
import {ContainerSection} from "../ContainerSection";
import {CenterContainer} from "../CenterContainer";

import {
  useLoadMoreData,
  IUseLoadMoreArgs,
} from "../useLoadMoreData";
import {useOnChange} from "../useOnChange";

import {
  useTheme,
  SxProps,
  Theme,
} from "../ThemeProvider";
import {createIcon} from "../IconComponent";
import LoadingIcon from "@mui/icons-material/Cached";
import LoadMoreIcon from "@mui/icons-material/GetApp";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export interface ITableLoadMoreProps<TData> {
  sx?: SxProps<Theme>;
  ref?: React.RefObject<ITableLoadMoreRef<TData>>;

  title?: string;
  ariaLabel: string;

  columns: IColumn<TData>[];

  filters?: ITableFilter[];   // Initial filters, read once only
  sort?: ITableSort<TData>;   // Initial sort, read once only

  rowHover?: boolean;

  loadSize?: number;
  debounceTimeout?: number;   // Default is 300 (ms)

  onRowClick?: (rowData: TData) => void;

  renderAboveTable?: TTableLoadMoreRender<TData>;
  renderAfterFilters?: TTableLoadMoreRender<TData>;
  renderBelowTable?: TTableLoadMoreRender<TData>;

  onLoad: TTableLoadMoreHandlerLoad<TData>;
}

export type TTableLoadMoreHandlerLoad<TData> = (args: {
  filters: ITableFilter[];
  sort: ITableSort<TData>;
  pagination: {
    skip: number;
    limit: number;
  };
}) => Promise<TData[]>;

export type TTableLoadMoreRender<TData> = (args: {
  isLoading: boolean;
  filters: ITableFilter[];
  sort: ITableSort<TData>;
  rows: TData[];
}) => ReactElement;

export interface ITableLoadMoreRef<TData> {
  reload: () => void;
  getItems: () => TData[];
  setItems: (items: TData[]) => void;
}

/**
 * @deprecated Use the `ViewController` with a combination of `Table` instead.
 */
const TableLoadMoreCore =
  React.forwardRef<ITableLoadMoreRef<any>, ITableLoadMoreProps<any>>(
    <TData, >(props: ITableLoadMoreProps<TData>, ref: ForwardedRef<ITableLoadMoreRef<TData>>): ReactElement => {
      const {
        title = "",
        ariaLabel,
        columns,
        filters: userFilters,
        sort: userSort = {
          fieldName: undefined,
          direction: 0,
        },
        rowHover = true,
        loadSize = 20,
        debounceTimeout,
        onRowClick,
        renderAboveTable,
        renderAfterFilters,
        renderBelowTable,
        onLoad,
      } = props;
      const theme = useTheme();

      const [filters, setFilters] = useState<ITableFilter[]>((userFilters || []).map(f => ({...f})));
      const [sort, setSort] = useState<ITableSort<TData>>(userSort);

      useEffect(() => {
        if (!userFilters) return;

        const areFiltersSame =
          JSON.stringify(userFilters.map(f => f.options)) ===
          JSON.stringify(filters.map(f => f.options));

        if (!areFiltersSame) setFilters(userFilters);
      }, [userFilters]);

      const handleLoad = (
        {
          skip,
          limit,
        }: IUseLoadMoreArgs,
      ): Promise<TData[]> => {
        return onLoad({
          filters,
          sort,
          pagination: {
            skip,
            limit,
          },
        });
      };

      const {
        isLoading,
        error,
        hasMore,
        reload,
        loadMore: loadMoreCore,
        items: rows,
        setItems,
      } = useLoadMoreData<TData>({
        loadSize,
        onLoad: handleLoad,
      });

      const loadMore = useDebouncedCallback(
        loadMoreCore,
        debounceTimeout,
      );

      useImperativeHandle(ref, () => ({
        reload,
        getItems: () => rows,
        setItems,
      }));

      useOnChange({
        dep: [filters, sort],
        onChange: reload,
      });

      const handleChangeFilter = (filter: ITableFilter): void => {
        setFilters(filters.map(scanFilter =>
          scanFilter.filterName === filter.filterName
            ? filter
            : scanFilter,
        ));
      };

      const handleSortChange = (sort: ITableSort<TData>): void => {
        setSort(sort);
      };

      return (
        <Box
          dataComponentName="TableLoadMore"
          sx={{paddingTop: theme.spacing(2)}}
        >

          {renderAboveTable
            && renderAboveTable({
              isLoading,
              filters,
              sort,
              rows,
            })
          }

          <Condition if={!!filters.length}>
            <ContainerSection
              Icon={createIcon.byMuiIcon(FilterAltIcon)}
              title={title}
              subtitle="Filters"
            >
              <GridContainer spacing={2}>
                {filters
                  .filter(filter => !filter.hidden)
                  .map((filter, index) => (
                    <GridItem
                      key={index}
                      mobile={12}
                      tablet={6}
                      desktop={3}
                    >
                      <FilterInput
                        filter={filter}
                        onChange={handleChangeFilter}
                      />
                    </GridItem>
                  ))
                }
              </GridContainer>
            </ContainerSection>
          </Condition>

          {renderAfterFilters
            && renderAfterFilters({
              isLoading,
              filters,
              sort,
              rows,
            })
          }

          <CenterContainer>
            <IsLoadingBox
              spacing={2}
              collapsible={false}
              isLoading={isLoading}
            >
              Loading...
            </IsLoadingBox>
          </CenterContainer>

          <ErrorBanner error={error}/>

          {!!rows.length && (
            <Table
              ariaLabel={ariaLabel}
              columns={columns}
              sort={sort}
              rows={rows}
              rowHover={rowHover}
              onRowClick={onRowClick}
              onChangeSort={handleSortChange}
            />
          )}

          <LabelOverlayDataProgress
            show={!rows.length && !isLoading}
            type={EProgressType.NO_DATA_FOUND}
          />

          <CenterContainer
            textAlign="center"
            sx={{
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(3),
              display: hasMore ? undefined : "none",
            }}
            fullHeight={false}
          >
            <Button
              icon={
                isLoading
                  ? <LoadingIcon/>
                  : <LoadMoreIcon/>
              }
              size={EButtonSize.LARGE}
              disabled={isLoading}
              onClick={loadMore}
            >
              {isLoading
                ? 'loading'
                : 'Load more'
              }
            </Button>
          </CenterContainer>

          {renderBelowTable
            && renderBelowTable({
              isLoading,
              filters,
              sort,
              rows,
            })
          }

        </Box>
      );
    },
  );

export const TableLoadMore: <TData,>(props: ITableLoadMoreProps<TData>) => ReactElement | null = TableLoadMoreCore;
