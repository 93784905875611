export {
  IPost,
  IDTOPost,
  IPostsLoadMorePayload,
  getDefaultDTOPost,
} from "../interfaces/IPost";

export {
  ILoadPostsPagination,
  validateLoadPostsPagination,
} from "../interfaces/ILoadPostsPagination";

export {
  ESortPostsBy,
} from "../interfaces/ESortPostsBy";

export {
  EPostInteractions,
  IVote,
  IClap,
  IReact,
} from "../interfaces/EPostInteractions";
