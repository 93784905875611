import * as React from "react";
import {useState} from "react";

import {dynaSwitchEnum} from "dyna-switch";

import {IGeoPosition} from "utils-library/dist/commonJs/geo";
import {
  textEllipsis,
  convertLengthMetersToFeet,
  convertLengthFeetToMeters,
} from "utils-library/dist/commonJs/utils";

import {
  FlexContainerParent,
  FlexContainer,
  FlexItemMax,
  FlexItemMin,
} from "../../FlexContainer";
import {
  IconButton,
  EIconButtonSize,
} from "../../IconButton";
import {Condition} from "../../Condition";
import {Tooltip} from "../../Tooltip";
import {InputDualUnit} from "../../InputDualUnit";
import {useClipboardPaste} from "../../useClipboardPaste";
import {useSetTimeout} from "../../useSetTimeout";
import {useIsMounted} from "../../useIsMounted";
import {TBoxSpacing} from "../../ui-interfaces";

import {EGeoCoordinatesEditorSize} from "../interfaces";
import {getGeoPositionByString} from "../utils/getGeoPositionByString";

import {CoordinateInput} from "./components/CoordinateInput";

import {SxProps} from "../../ThemeProvider";
import {createIcon} from "../../IconComponent";
import {
  sxTransition,
  ECSSDuration,
} from "../../sxTransition";
import ResolveAltIcon from '@mui/icons-material/AutoMode';
import PasteIcon from '@mui/icons-material/ContentPaste';
import InsertIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

export interface IInputCoordinateProps {
  size?: EGeoCoordinatesEditorSize;
  readOnly?: boolean;
  disableAdd?: boolean;
  focused?: boolean;
  showAlt?: boolean;
  coordinate: IGeoPosition;
  onElevationResolve?: (coordinate: IGeoPosition) => Promise<number>; // The elevation is expected to be in meters (not in feet)
  onFocus?: () => void;
  onInsert?: () => void;
  onDelete?: () => void;
  onChange: (position: IGeoPosition) => void;
}

export const InputCoordinate: React.FC<IInputCoordinateProps> = (
  {
    size = EGeoCoordinatesEditorSize.MEDIUM,
    readOnly = false,
    disableAdd = true,
    focused = false,
    showAlt = true,
    coordinate,
    coordinate: {
      lat,
      lng,
      alt = 0,
    },
    onElevationResolve,
    onFocus,
    onInsert,
    onDelete,
    onChange,
  },
) => {
  const getIsMounted = useIsMounted();
  const [disableTheElevInput, setDisableTheElevInput] = useState<boolean>(false);
  const {clipboardText} = useClipboardPaste();
  const [pasteError, setPasteError] = useState<string | null>(null);
  const {
    start: pasteErrorStart,
    clear: pasteErrorTimerClear,
  } = useSetTimeout({cb: () => setPasteError(null)});

  const updateAlt = async (forPosition: IGeoPosition = coordinate): Promise<void> => {
    if (!onElevationResolve) return;
    try {
      if (!getIsMounted()) return;
      setDisableTheElevInput(true);
      const alt = await onElevationResolve(forPosition);
      onChange({
        lat: forPosition.lat,
        lng: forPosition.lng,
        alt,
      });
    }
    catch (error) {
      console.error(
        `Cannot resolve the elevation`,
        {
          forPosition,
          error,
        },
      );
    }
    finally {
      if (getIsMounted()) setDisableTheElevInput(false);
    }
  };

  const handleContainerClick = (event: any): void => {
    if (event.target.nodeName === 'INPUT') return;
    onFocus && onFocus();
  };
  const handleLatChange = (newLat: number): void => {
    if (newLat === lat) return;
    onChange({
      lat: newLat,
      lng,
      alt,
    });
  };
  const handleLngChange = (newLng: number): void => {
    if (newLng === lng) return;
    onChange({
      lat,
      lng: newLng,
      alt,
    });
  };
  const handleElevChange = (newAlt: number): void => {
    if (newAlt === alt) return;
    onChange({
      lat,
      lng,
      alt: newAlt,
    });
  };
  const handleResolveAlt = () => updateAlt();
  const handlePasteClick = (): void => {
    const {
      coordinate: newPosition,
      error: parseError,
    } = getGeoPositionByString(clipboardText);
    pasteErrorTimerClear();
    if (newPosition) {
      setPasteError(null);
      onChange(newPosition);
      if (newPosition.alt === undefined) updateAlt(newPosition);
    }
    else {
      setPasteError(parseError);
      pasteErrorStart(3000);
    }
  };

  const buttonScale = dynaSwitchEnum<EGeoCoordinatesEditorSize, number>(
    size,
    {
      [EGeoCoordinatesEditorSize.SMALL]: 0.5,
      [EGeoCoordinatesEditorSize.MEDIUM]: 0.6,
    },
  );
  const sxIconContainer: SxProps = {
    position: 'relative',
    top: dynaSwitchEnum<EGeoCoordinatesEditorSize, string>(
      size,
      {
        [EGeoCoordinatesEditorSize.SMALL]: '-6px',
        [EGeoCoordinatesEditorSize.MEDIUM]: '-7px',
      },
    ),
    mb: dynaSwitchEnum<EGeoCoordinatesEditorSize, string>(
      size,
      {
        [EGeoCoordinatesEditorSize.SMALL]: '-14px',
        [EGeoCoordinatesEditorSize.MEDIUM]: '-16px',
      },
    ),
  };
  const sxIcon: SxProps = {transform: `scale(${buttonScale})`};

  return (
    <FlexContainerParent
      sx={{
        py: theme =>
          theme.spacing(
            dynaSwitchEnum<EGeoCoordinatesEditorSize, number>(
              size,
              {
                [EGeoCoordinatesEditorSize.SMALL]: 0.5,
                [EGeoCoordinatesEditorSize.MEDIUM]: 0.75,
              },
            ),
          ),
        transition: theme => sxTransition(theme, 'background', ECSSDuration.SHORT),
        background: theme => focused ? theme.palette.grayShades.gray1 : undefined,
      }}
      dataComponentName="CoordinateEditor"
      spacing={
        dynaSwitchEnum<EGeoCoordinatesEditorSize, TBoxSpacing>(
          size,
          {
            [EGeoCoordinatesEditorSize.SMALL]: 0,
            [EGeoCoordinatesEditorSize.MEDIUM]: 0.5,
          },
        )
      }
      alignVertical="top"
      verticalMaxWidth={460}
      onClick={handleContainerClick}
    >

      <FlexItemMax flex={3}>
        <CoordinateInput
          size={size}
          title="Latitude"
          label="LAT"
          readOnly={readOnly}
          validation={{
            min: -90,
            max: 90,
          }}
          value={lat}
          onFocus={onFocus}
          onBlur={handleLatChange}
        />
      </FlexItemMax>

      <FlexItemMin>
        <div style={{width: '6px'}}/>
      </FlexItemMin>

      <FlexItemMax flex={3}>
        <CoordinateInput
          size={size}
          title="Longitude"
          label="LNG"
          readOnly={readOnly}
          validation={{
            min: -180,
            max: 180,
          }}
          value={lng}
          onFocus={onFocus}
          onBlur={handleLngChange}
        />
      </FlexItemMax>

      <Condition if={showAlt}>
        <FlexItemMin>
          <div style={{width: '6px'}}/>
        </FlexItemMin>

        <FlexItemMax flex={2}>
          <InputDualUnit
            convertActualToUserValue={convertLengthMetersToFeet}
            convertUserValueToActual={convertLengthFeetToMeters}
            value={alt}
            onBlur={handleElevChange}
          >
            {(
              {
                value,
                validationError,
                onBlur,
              },
            ) => (
              <FlexContainer>
                <FlexItemMax>
                  <CoordinateInput
                    size={size}
                    title="Elevation"
                    label="ELEV"
                    readOnly={readOnly}
                    validation={{
                      min: -5000,
                      max: 5000,
                    }}
                    disabled={disableTheElevInput}
                    helperLabel="feet"
                    value={Number(value)}
                    validationError={validationError}
                    onFocus={onFocus}
                    onBlur={n => onBlur(n.toString())}
                  />
                </FlexItemMax>
                <FlexItemMin
                  show={!!onElevationResolve}
                  sx={sxIconContainer}
                >
                  <IconButton
                    sx={sxIcon}
                    show={!readOnly}
                    size={EIconButtonSize.SMALL}
                    ariaLabel="Retrieve the elevation"
                    title="Retrieve the elevation from the internet"
                    noPadding
                    Icon={createIcon.byMuiIcon(ResolveAltIcon)}
                    onClick={handleResolveAlt}
                  />
                </FlexItemMin>
              </FlexContainer>
            )}
          </InputDualUnit>
        </FlexItemMax>
      </Condition>

      <FlexItemMin
        sx={{
          textAlign: 'right',
          whiteSpace: 'nowrap',
          paddingLeft: theme => theme.spacing(1),
          ...sxIconContainer,
        }}
      >
        <Tooltip
          title={
            pasteError
              ? textEllipsis('Clipboard is not a coordinate, error: ' + pasteError, 120)
              : ''}
          showDelay={0}
        >
          <IconButton
            sx={{
              ...sxIcon,
              color: theme => pasteError ? theme.palette.error.main : undefined,
            }}
            show={!readOnly}
            size={EIconButtonSize.SMALL}
            ariaLabel="Paste coordinates"
            title="Paste coordinates from clipboard"
            noPadding
            disabled={!clipboardText}
            Icon={createIcon.byMuiIcon(PasteIcon)}
            onClick={handlePasteClick}
          />
        </Tooltip>
        <IconButton
          show={!!onInsert && !disableAdd && !readOnly}
          sx={sxIcon}
          size={EIconButtonSize.SMALL}
          ariaLabel="Insert coordinate"
          title="Insert new coordinate next of it"
          noPadding
          Icon={createIcon.byMuiIcon(InsertIcon)}
          onClick={onInsert}
        />
        <IconButton
          show={!!onDelete && !readOnly}
          sx={sxIcon}
          size={EIconButtonSize.SMALL}
          ariaLabel="Delete"
          title="Delete this coordinate"
          noPadding
          Icon={createIcon.byMuiIcon(DeleteIcon)}
          onClick={onDelete}
        />
      </FlexItemMin>
    </FlexContainerParent>
  );
};
