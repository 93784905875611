import {IDeviceDriver} from "./IDeviceDriver";
import {validateDataMethods} from "utils-library/dist/commonJs/validation-engine";
import {EDeviceType} from "../interfaces/EDeviceType";

export interface ITrafficVehiclesCounterConfig {
    activeStudy: boolean;
}

export interface ITrafficVehiclesCounterStatus {
    acPower: boolean;
    dcVoltage: number;
    studyStatus: boolean;
    batteryChargePercentage: number;
}

export const TrafficVehiclesCounterMHCDriver: IDeviceDriver<ITrafficVehiclesCounterConfig, ITrafficVehiclesCounterStatus> = {
  deviceType: EDeviceType.MHC_TRAFFIC_VEHICLES_COUNTER,
  label: 'NC400 Traffic Counter',
  validateConfig: {activeStudy: validateDataMethods.isBoolean},
  validateStatus: {
    acPower: validateDataMethods.isBoolean,
    dcVoltage: validateDataMethods.isNumber,
    studyStatus: validateDataMethods.isBoolean,
    batteryChargePercentage: value => validateDataMethods.isNumberInRange(value, 0, 100),
  },
};

export const getDefaultTrafficVehiclesCounterConfig = (): ITrafficVehiclesCounterConfig => ({activeStudy: false});
