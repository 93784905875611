import muiColors from "@mui/material/colors";
import {
  alpha,
  darken,
  lighten,
} from "@mui/material/styles";
import "./paletteExtension.type";

const colors = {...muiColors};

export {
  colors,
  alpha,
  darken,
  lighten,
};

export {
  SxProps,
} from "@mui/system";

export {
  ThemeProvider,
  IThemeProviderProps,
  Theme,
  EThemeName,
  EThemeSize,
} from "./ThemeProvider";

export {
  useTheme,
} from "./useTheme";

export {
  useMediaQuery,
} from "@mui/material";
