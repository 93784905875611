import {EDeviceBrokerType} from "./IDevice";
import {EDeviceFamily} from "./EDeviceType";
import {EDeviceType} from "./EDeviceType";

export interface IDeviceDriverInfo {
  deviceBrokerId: string;
  deviceBrokerType: EDeviceBrokerType;
  family: EDeviceFamily;    // Family (group) of the device
  deviceType: EDeviceType;
  driverStatus: EDeviceDriverStatus;
  label: string;            // How is shown
  description: string;      // More info about the device
  manufacturer: string;     // Informative
  canReadConfig: boolean;
  canWriteConfig: boolean;
  canBeLocationMaster: boolean;
}

export enum EDeviceDriverStatus {
  // The EDriverState enum determines whether a driver is eligible to be added to the system.
  // However, once a driver has been added, it can be used in the system regardless of its state.
  //    In other words, the driver state does not interfere with the functionality of added drivers in the system.
  //    Its purpose is to control whether a device should be added or not.
  //    Any non-RELEASE driver may cause problems and should be replaced if issues arise.
  ALPHA = "ALPHA",                      // Developer test. Can be added only with Alpha Drivers user right.
  BETA = "BETA",                        // Testers and users test. Can be added only with Beta Drivers user right.
  RELEASE = "RELEASE",                  // Production ready driver. Can be added by any user.
  DEPRECATED_SOON = "DEPRECATED_SOON",  // Still can be used by anyone but there is an indication.
  DEPRECATED = "DEPRECATED",            // The driver cannot be added by anyone.
}
