import * as React from "react";
import {
  useState,
  useEffect,
} from "react";

import {dynaSwitchEnum} from "dyna-switch";

import {
  FlexContainerHorizontal,
  FlexItemMax,
  FlexItemMin,
} from "../../../FlexContainer";
import {Box} from "../../../Box";
import {
  HelperText,
  EHelperTextType,
} from "../../../HelperText";
import {useTheme} from "../../../ThemeProvider";

import {EGeoCoordinatesEditorSize} from "../../interfaces";

import * as styles from './CoordinateInput.module.less';
import {useStateWithPropValue} from "../../../useStateWithPropValue";
import {
  sxTransition,
  ECSSDuration,
} from "../../../sxTransition";

export interface ICoordinateInputProps {
  size: EGeoCoordinatesEditorSize;
  label: string;
  title: string;
  readOnly: boolean;
  validation: {
    min: number;
    max: number;
  };
  helperLabel?: string;
  disabled?: boolean;
  value: number;
  validationError?: string;
  onFocus?: () => void;
  onBlur: (value: number) => void;
}

export const CoordinateInput: React.FC<ICoordinateInputProps> = (
  {
    size,
    label,
    title,
    readOnly,
    validation: {
      min,
      max,
    },
    helperLabel,
    disabled = false,
    value: propValue,
    validationError: userValidationError,
    onFocus,
    onBlur,
  },
) => {
  const theme = useTheme();
  const [value, setValue] = useStateWithPropValue<number>({
    readOnly: readOnly,
    defaultValue: 0,
    propValue,
  });
  const [validationError, setValidationError] = useState<string>("");

  const validate = (): void => {
    if (value < min) setValidationError(`Should be bigger or equal than ${min}`);
    else if (value > max) setValidationError(`Should be less or equal than ${max}`);
    else setValidationError("");
  };
  useEffect(validate, []);

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }
  };
  const handleWheel = (event: any) => {
    event.target.blur();
  };
  const handleFocus = (): void => {
    onFocus && onFocus();
  };
  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (readOnly) return;
    validate();
    onBlur(Number(event.target.value));
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (readOnly) return;
    setValue(Number(event.target.value));
  };

  return (
    <FlexContainerHorizontal
      sx={{background: theme => theme.palette.background.default}}
      title={title}
      alignVertical="middle"
    >
      <FlexItemMin
        sx={{
          fontFamily: 'monospace',
          fontSize: '12px',
          marginLeft: '2px',
          marginRight: '4px',
          opacity: 0.7,
        }}
      >
        {label}
      </FlexItemMin>
      <FlexItemMax
        sx={{
          position: 'relative',
          'input': {
            opacity: disabled ? 0.5 : undefined,
            transition: theme => sxTransition(theme, 'opacity', ECSSDuration.SHORT),
          },
        }}
      >
        <input
          className={styles.hideSpinButtons}
          style={{
            width: '100%',
            outline: 'none',
            borderColor: theme.palette.grayShades.gray3,
            borderStyle: 'groove',
            borderWidth: '1px',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            fontSize: dynaSwitchEnum<EGeoCoordinatesEditorSize, string>(
              size,
              {
                [EGeoCoordinatesEditorSize.SMALL]: '11px',
                [EGeoCoordinatesEditorSize.MEDIUM]: '13px',
              },
            ),
          }}
          disabled={disabled}
          readOnly={readOnly}
          type="number"
          value={value}
          onKeyDown={handleKeyDown}
          onWheel={handleWheel}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <Box
          sx={{
            position: 'absolute',
            fontSize: '8px',
            right: '2px',
            bottom: '1px',
            opacity: 0.7,
          }}
        >
          {helperLabel}
        </Box>
        <HelperText type={EHelperTextType.ERROR}>
          {
            [
              userValidationError,
              validationError,
            ]
              .filter(Boolean)
              .join(' - ')
          }
        </HelperText>
      </FlexItemMax>
    </FlexContainerHorizontal>
  );
};
