import {BrowserDbCollection} from "./BrowserDbCollection";
import {ICRUDDoc} from "./ICRUDDoc";

interface IBrowserDbConfig {
  dbName: string;
  collectChanges?: boolean;
}

interface IDbCollectionChange<TSchema> extends ICRUDDoc {
  collectionChangeId: string;
  collectionName: string;
  offlineAction: EOfflineAction;
  key: string;
  doc?: TSchema;
}

export interface ICollectionConfig<TSchema> {
  collectionName: string;
  version?: number;             // No support for migration/upgrade scripts (like Dyna Mongo DB)
  keyFieldName: string;
  buildSearchContent?: (data: TSchema) => string[];
  indexFieldNames?: string[];
}

export enum EOfflineAction {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  UNDELETE = "UNDELETE",
  PERMANENT_DELETE = "PERMANENT_DELETE",
}

export class BrowserDb {
  private readonly dbChangesCollection: BrowserDbCollection<IDbCollectionChange<any>>;

  constructor(private readonly config: IBrowserDbConfig) {
    this.dbChangesCollection = new BrowserDbCollection({
      dbName: this.config.dbName,
      version: 2,
      collectionName: 'browserDbChangesCollection',
      keyFieldName: 'collectionChangeId',
      indexFieldNames: 'updatedAt'.split(','),
      onCreate: async (doc) => {
        doc;
        if (localStorage.getItem('_debug_offlineMessages') === 'true') console.log('DEBUG-OFFLINE: dbChangesCollection NEW_DOC', doc);
      },
    });
  }

  public collection<TSchema extends ICRUDDoc>(
    {
      collectionName,
      version,
      keyFieldName,
      buildSearchContent,
      indexFieldNames = [],
    }: ICollectionConfig<TSchema>,
  ): BrowserDbCollection<TSchema> {
    return new BrowserDbCollection<TSchema>({
      dbName: this.config.dbName,
      collectionName,
      version,
      keyFieldName,
      indexFieldNames,
      buildSearchContent,
      onCreate: async (doc) => {
        if (!this.config.collectChanges) return;
        await this.dbChangesCollection.update({
          collectionChangeId: '',
          key: doc[keyFieldName],
          offlineAction: EOfflineAction.CREATE,
          collectionName,
          doc,
        });
      },
      onUpdate: async (key, doc) => {
        if (!this.config.collectChanges) return;
        await this.dbChangesCollection.update({
          collectionChangeId: '',
          key,
          offlineAction: EOfflineAction.UPDATE,
          collectionName,
          doc,
        });
      },
      onDelete: async (key, doc) => {
        if (!this.config.collectChanges) return;
        await this.dbChangesCollection.update({
          collectionChangeId: '',
          key,
          offlineAction: EOfflineAction.DELETE,
          collectionName,
          doc,
        });
      },
      onUndelete: async (key, doc) => {
        if (!this.config.collectChanges) return;
        await this.dbChangesCollection.update({
          collectionChangeId: '',
          key,
          offlineAction: EOfflineAction.UNDELETE,
          collectionName,
          doc,
        });
      },
      onPermanentDelete: async (key) => {
        if (!this.config.collectChanges) return;
        await this.dbChangesCollection.update({
          collectionChangeId: '',
          key,
          offlineAction: EOfflineAction.PERMANENT_DELETE,
          collectionName,
        });
      },
    });
  }

  public getDbChangesByCount(count = 1): Promise<IDbCollectionChange<any>[]> {
    return this.dbChangesCollection.find({
      pagination: {
        skip: 0,
        limit: count,
      },
      sort: {
        fieldName: 'updatedAt',
        direction: 1,
      },
    });
  }

  public async getDbChangesAll(): Promise<IDbCollectionChange<any>[]> {
    const count = await this.getDbChangesCount();
    return this.getDbChangesByCount(count);
  }

  public async getDbChangesCount(): Promise<number> {
    return this.dbChangesCollection.count();
  }

  public async deleteDbChanges(collectionChangeIds: string[]): Promise<void> {
    await Promise.all(
      collectionChangeIds
        .map(collectionChangeId => this.dbChangesCollection.deletePermanent(collectionChangeId)),
    );
  }
}
