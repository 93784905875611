import {IDeviceDriver} from "./IDeviceDriver";
import {validateDataMethods} from "utils-library/dist/commonJs/validation-engine";
import {EDeviceType} from "../interfaces/EDeviceType";

export interface IConnectITSLocationHubConfig {
  maintenanceMode: boolean;
}

export interface IConnectITSLocationHubStatus {
  acPower: boolean;
  dcVoltage: number;
  flasherStatus: boolean;
  batteryChargePercentage: number;
}

export const ConnectITSLocationHub: IDeviceDriver<IConnectITSLocationHubConfig, IConnectITSLocationHubStatus> = {
  deviceType: EDeviceType.CONNECT_ITS_LOCATION_HUB,
  label: 'Connect ITS location hub',
  validateConfig: {maintenanceMode: validateDataMethods.isBoolean},
  validateStatus: {
    acPower: validateDataMethods.isBoolean,
    dcVoltage: validateDataMethods.isNumber,
    flasherStatus: validateDataMethods.isBoolean,
    batteryChargePercentage: value => validateDataMethods.isNumberInRange(value, 0, 100),
  },
};

export const getDefaultConnectITSLocationHubConfig = (): IConnectITSLocationHubConfig=>({maintenanceMode: false});
