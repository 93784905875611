export const asyncCalls = (...functions: Array<((done?: any) => void) | undefined | null>): void => {
  const fs = functions.concat().filter(Boolean);
  const next = (): void => {
    const f = fs.shift();
    if (!f) return;
    if (f.length) {
      f(next);
    }
    else {
      f();
      next();
    }
  };
  next();
};
