export const isInViewPort = (element: Element): Promise<boolean> => {
  return new Promise((resolve) => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      resolve(entry.isIntersecting);
      observer.disconnect();
    });
    observer.observe(element);
  });
};
