import * as L from "leaflet";
import {DivIcon} from "leaflet";

import {renderToStaticMarkup} from "react-dom/server";

import "./customIconMarker.less";

export const customIconMarker = (element: JSX.Element): DivIcon => {
  const iconMarkup = renderToStaticMarkup(element);
  return L.divIcon({
    className: 'leaflet-marker-div-icon',
    html: iconMarkup,
    iconAnchor: [0, 0], // Allow elements to customize top/bottom/left/right for custom positioning
    popupAnchor: [-3, -24],
  });
};
