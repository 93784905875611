/* eslint-disable no-useless-escape */
import sanitizeHtml from "sanitize-html";

import {decodeText} from "../../decode-text";

export const isSanitizedHtml = (html: string): boolean => {
  // Todo: Imor: When the html is invalid (tags not closed), return false! It should return true!
  const decoded: string = decodeText(
    html
      .replace(/&/g, ' ')
      .replace(/&amp;/g, ' ')
      .replace(/&nbsp;/g, ' ')
      .replace(/<br>/g, '<br/>')
      .replace(/<br >/g, '<br/>'),
  );
  const sanitized = sanitizeHtml(
    decoded,
    {
      allowedTags:
        [
          'h1', 'h2', 'h3', 'h4', 'h5',
          'p', 'a', 'u', 'ul', 'ol', 'nl', 'li', 'b', 'i', 'br',
          'strong', 'em', 'strike', 'hr', 'br', 'img', 'blockquote', 'span',
        ],
      allowedAttributes: {
        'a': ['href', 'title', 'alt', 'target'],
        'img': ['src', 'style'],
        'p': ['style'],
        'u': ['style'],
        'b': ['style'],
        'span': ['style'],
        'ul': ['style'],
        'li': ['style'],
        'br': [],
      },
    },
  );
  const clean = (text: string): string =>
    text
      .replace(/ /g, '')
      .replace(/;/g, '');

  return clean(decoded) === clean(sanitized);
};
