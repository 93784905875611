import {ITrafficVehiclesCountData} from "../../interfaces/ITrafficVehiclesCountData";

export const API_PATH_ApiTrafficStudiesVehiclesCounterDataSearchGet = '/services/traffic-studies/apiTrafficStudiesVehiclesCounterDataSearchGet';

export interface IApiTrafficStudiesVehiclesCounterDataSearchGetRequest {
  studyIds?: string[];
  start?: number;
  end?: number;
  skip: number;
  limit: number;
}

export type IApiTrafficStudiesVehiclesCounterDataSearchGetResponse = ITrafficVehiclesCountData[];
