export interface IUserInvitation {
  email: string;        // PK
  fullCompanyName: string;
  title: string;
  firstName: string;
  lastName: string;
  rights: string[];
  lastSendDate: number;
  lastSendStatus: ESendUserInvitationStatus;
  log: IUserInvitationLog[];
  createdAt: number;
  updatedAt: number;
  deletedAt: number;
}

export interface IDBUserInvitation extends IUserInvitation {
  searchContent: string;
}

export interface IUsersInvitationsSort {
  fieldName: EUsersInvitationsSortField;
  direction?:  0 | 1 | -1;
}

export enum EUsersInvitationsSortField {
  EMAIL = "email",
  TITLE = "title",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  LAST_SEND_DATE = "lastSendDate",
  LAST_SEND_STATUS = "lastSendStatus",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  DELETED_AT = "deletedAt",
}

export enum ESendUserInvitationStatus {
  NOT_CREATED = "NOT_CREATED",
  NOT_SENT = "NOT_SENT",
  SENDING = "SENDING",
  SENT = "SENT",
  SEND_FAILED = "SEND_FAILED",
  ACCEPTED = "ACCEPTED",
  DELETED = "DELETED",
  UNDELETED = "UNDELETED",
}

export interface IUserInvitationLog {
  date: number;
  status: ESendUserInvitationStatus;
  user: string;
  info?: string;
}
