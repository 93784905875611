export class ConsoleOnce {
  private loggedMessages = new Set();

  constructor(private global: any) {
  }

  public log(title: string, ...messages: any[]) {
    this.logMessage(this.global.console.log, title, ...messages);
  }

  public warn(title: string, ...messages: any[]) {
    this.logMessage(this.global.console.warn, title, ...messages);
  }

  public error(title: string, ...messages: any[]) {
    this.logMessage(this.global.console.error, title,...messages);
  }

  public debug(title: string, ...messages: any[]) {
    this.logMessage(this.global.console.debug, title,...messages);
  }

  public info(title: string, ...messages: any[]) {
    this.logMessage(this.global.console.info, title,...messages);
  }

  private logMessage(consoleMethod: (...args: any[]) => void, ...messages: any[]) {
    const methodKey = consoleMethod.name; // Get the name of the console method
    const uniqueMessage = `${methodKey}:${messages[0]}`; // Create a unique key for the message and method

    if (!this.loggedMessages.has(uniqueMessage)) {
      consoleMethod("[consoled once]", ...messages);
      this.loggedMessages.add(uniqueMessage);
    }
  }
}
