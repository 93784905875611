import {IRegion} from "../../interfaces/IRegion";
import {ILocation} from "../../interfaces/ILocation";
import {IDevice} from "../../interfaces/IDevice";

export const API_PATH_ApiDeviceMgmtVTreeSearchAll = '/services/devices-management/apiDeviceMgmtVTreeSearchAll';

export interface IApiDeviceMgmtVTreeSearchAllRequest {
  searchText: string;
  searchTextInRegions: 'true' | 'false';
  searchTextInLocations: 'true' | 'false';
  searchTextInDevices: 'true' | 'false';
  showItems: 'actual' | 'deleted' | 'archived';
}

export interface IApiDeviceMgmtVTreeSearchAllResponse {
  regions: IRegion[];
  locations: ILocation[];
  devices: IDevice[];
}
