import {ESendUserInvitationStatus} from "../../../users-invitation/interfaces/IUserInvitation";

export const API_PATH_ApiCreateCompanyGet = '/services/auth-create-company';

export interface IApiCreateCompanyGetQueryRequest {
  companyId: string;
  email: string;
}

export interface IApiCreateCompanyGetResponse {
  fullCompanyName: string;
  title: string;
  firstName: string;
  lastName: string;
  rights: string[];
  lastSendStatus: ESendUserInvitationStatus;
}

