export const API_PATH_apiUsersInvitationsSendPost = '/services/users-invitations/send';

export interface IApiUsersInvitationsSendPostBodyRequest {
  email: string;
}

export interface IApiUsersInvitationsSendPostResponse {
  ok: true;
}

