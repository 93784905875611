import * as React from "react";
import {
  useMemo,
  useState,
} from "react";
import {DynaLocalStorageData} from "dyna-local-storage";

import {ModalDialog} from "../ModalDialog";

import {createIcon} from "../IconComponent";
import AgreeIcon from '@mui/icons-material/CheckCircleOutline';
import DisagreeIcon from '@mui/icons-material/HighlightOff';

export interface IDisclaimerModalProps {
  rememberId?: string;
  title: string;
  content: any;
  onAgree?: () => void;
  onDisagree: () => void;
}

export const DisclaimerModal = (props: IDisclaimerModalProps): JSX.Element => {
  const {
    rememberId = '',
    title,
    content,
    onAgree,
    onDisagree,
  } = props;

  const ls = useMemo(() =>
    new DynaLocalStorageData(
      'DisclaimerModalLSKey',
      {},
    ),
  [],
  );

  const [show, setShow] = useState(rememberId ? !ls.data[rememberId] : true);

  const handleAgreeClick = (): void=>{
    if (rememberId) {
      ls.data[rememberId] = true;
      ls.save();
    }
    onAgree && onAgree();
    setShow(false);
  };
  const handleDisagreeClick = (): void=>{
    onDisagree();
    setShow(false);
  };

  return (
    <ModalDialog
      show={show}
      title={title}
      buttons={[
        {
          Icon: createIcon.byMuiIcon(AgreeIcon),
          label: 'Agree',
          onClick: handleAgreeClick,
        },
        {
          Icon: createIcon.byMuiIcon(DisagreeIcon),
          label: 'Disagree',
          onClick: handleDisagreeClick,
        },
      ]}
    >
      {content}
    </ModalDialog>
  );
};

