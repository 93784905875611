import {IGeoPosition} from "utils-library/dist/commonJs/geo";

export const getRadiusByPositionAndScreenSize = (
  {
    zoom,
    position: {lat},
    mapComponentSize: {
      width,
      height,
    },
  }: {
    zoom: number;
    position: IGeoPosition;
    mapComponentSize: {
      width: number;
      height: number;
    };
  },
): number => {
  const metersPerPixel =
    156543.04
    * Math.cos(radians(lat))
    / Math.pow(2, zoom);

  const radiusInMeters =
    metersPerPixel
    * (width / 2 + height / 2);

  return radiusInMeters;
};

const radians = (degrees: number) => degrees * Math.PI / 180;
