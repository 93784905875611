import * as React from "react";
import {
  useState,
  useRef,
} from "react";

import {Confirm} from "../Confirm";
import {IIconComponent} from "../IconComponent";

export interface IConfirmArgs {
  title: string;
  message?: string;     // Same as the children
  children?: any;       // Same as message
  helperMessage?: string;

  labelConfirmButton?: string;
  labelConfirmIcon?: IIconComponent;
  labelCancelButton?: string | null;  // Null to hide it
  labelCancelIcon?: IIconComponent;

  forceUserConfirmation?: boolean; // Set it true to force users to click a button to confirm/cancel
}

/**
 * The `useConfirm` hook returns an object that contains the `confirm` function, which returns a `Promise<boolean>`.
 * Additionally, it returns the `confirmViewer` JSX.Element, which must be rendered **always**!
 * Internally, this hook manages the actual visibility of the confirmation dialog.
 */
export const useConfirm = (): {
  confirm: (confirmArgs: IConfirmArgs) => Promise<boolean>;
  confirmViewer: JSX.Element;
} => {
  const [show, setShow] = useState<boolean>(false);
  const [args, setArgs] = useState<IConfirmArgs>({title: ''});

  const refPromise = useRef<{ resolve: (confirm: boolean) => void } | null>(null);

  const handleOk = () => {
    setShow(false);
    refPromise.current?.resolve(true);
    refPromise.current = null;
  };

  const handleCancel = () => {
    setShow(false);
    refPromise.current?.resolve(false);
    refPromise.current = null;
  };

  const handleClose = (confirm: boolean) => {
    setShow(false);
    refPromise.current?.resolve(confirm);
    refPromise.current = null;
  };

  return {
    confirm: async confirmArgs => {
      setArgs(confirmArgs);
      setShow(true);
      return new Promise<boolean>(resolve => {
        refPromise.current = {resolve};
      });
    },
    confirmViewer: (
      <Confirm
        {...args}
        show={show}
        onOk={handleOk}
        onCancel={handleCancel}
        onClose={handleClose}
      />
    ),
  };
};
