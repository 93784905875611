import {validateDataMethods} from "utils-library/dist/commonJs/validation-engine";

import {IDeviceDriver} from "./IDeviceDriver";
import {EDeviceType} from "../interfaces/EDeviceType";

import {EAlertType} from "../../alerts-management/interfaces/EAlertType";
import {apiAlertMgtmAlertHandlingPost} from "../../alerts-management/client/for-services";

export interface IDemoHeavySnowFailAlert {
  snow: number;
}

export interface IDemoHeavySnowFailAlertHandle {
  authoritiesInformed: boolean;
  closedLanes: number;
}

export interface IDemoSnowSensorConfig {
  scanDistanceInMeters: number;
}

export interface IDemoSnowSensorStatus {
  voltage: number;
  snow: number;
}

export const DemoSnowSensor: IDeviceDriver<IDemoSnowSensorConfig, IDemoSnowSensorStatus> = {
  deviceType: EDeviceType.DEMO_SNOW_SENSOR,
  label: '(demo) Snow sensor',
  validateConfig: {
    scanDistanceInMeters: (value) => {
      if (!validateDataMethods.isNumber(value)) return "Should be a number";
      if (value < 5) return "Should be number bigger or equal to 5";
      return "";
    },
  },
  validateStatus: {
    voltage: validateDataMethods.isNumber,
    snow: validateDataMethods.isNumber,
  },
  monitorIncomingDeviceStatus:
    async (
      {deviceStatus},
    ) => {
      if (!deviceStatus.status) return; // 4TS, is not possible to be null

      const snow = deviceStatus.status.snow;
      // Todo: Alert creation is disabled on purpose.
      // Activate it only for dev purposes and never deploy this anywhere (staging or production)
      if ((() => true)()) return;

      await apiAlertMgtmAlertHandlingPost<IDemoHeavySnowFailAlert, IDemoHeavySnowFailAlertHandle>({
        deviceStatus,
        alertType: EAlertType.DEMO_HEAVY_SNOWFALL,
        title: `High snowfall ${snow}%`,
        description: 'Snowfall above 80%',
        alert: {snow},
        alertHandle: {
          authoritiesInformed: false,
          closedLanes: -1,
        },
      });
    },
};

export const getDefaultDemoSnowSensorConfig = (): IDemoSnowSensorConfig => ({scanDistanceInMeters: 5});
