import * as React from "react";
import {ReactElement} from "react";

import {dynaSwitchEnum} from "dyna-switch";

import {LabelOverlay} from "../LabelOverlay";
import {Ellipsis} from "../Ellipsis";

import {createIcon} from "../IconComponent";
import {ProgressIcon} from "../ProgressIcon";
import InitializeIcon from '@mui/icons-material/HourglassEmpty';
import IsLoadingStaticIcon from '@mui/icons-material/Cloud';
import NoSearchIcon from '@mui/icons-material/SearchOff';
import NoDataIcon from '@mui/icons-material/Storage';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';

export interface ILabelOverlayDataProgressProps {
  show?: boolean;
  fullHeight?: boolean; // Default is true
  iconSize?: number;
  type: EProgressType;
  title?: string | ReactElement;
  description?: string | ReactElement;
  children?: any;
}

export enum EProgressType {
  HIDDEN = "HIDDEN",
  INITIALIZING = "INITIALIZING",
  IS_LOADING_ANIMATION = "IS_LOADING_ANIMATION",
  IS_LOADING_STATIC = "IS_LOADING_STATIC",
  NO_DATA_FOUND = "NO_DATA_FOUND",
  NO_DATA_AVAILABLE = "NO_DATA_AVAILABLE",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  WARNING = "WARNING",
}

export const LabelOverlayDataProgress = (
  {
    show = true,
    fullHeight = true,
    iconSize = 128,
    type,
    title,
    description,
    children,
  }: ILabelOverlayDataProgressProps,
): ReactElement | null => {
  if (!show) return null;
  return dynaSwitchEnum<EProgressType, ReactElement | null>(
    type,
    {
      [EProgressType.HIDDEN]: null,
      [EProgressType.INITIALIZING]:
        <LabelOverlay
          Icon={createIcon.byMuiIcon(InitializeIcon)}
          fullHeight={fullHeight}
          title={title || "Initializing..."}
          description={description || "Please wait."}
          children={children}
        />,
      [EProgressType.IS_LOADING_ANIMATION]:
        <LabelOverlay
          iconElement={<ProgressIcon size={iconSize}/>}
          fullHeight={fullHeight}
          title={title || "Loading..."}
          description={description || "Please wait."}
          children={children}
        />,
      [EProgressType.IS_LOADING_STATIC]:
        <LabelOverlay
          Icon={createIcon.byMuiIcon(IsLoadingStaticIcon)}
          size={iconSize}
          fullHeight={fullHeight}
          title={title || <>Loading<Ellipsis/></>}
          description={description || "Please wait."}
          children={children}
        />,
      [EProgressType.NO_DATA_FOUND]:
        <LabelOverlay
          Icon={createIcon.byMuiIcon(NoSearchIcon)}
          size={iconSize}
          fullHeight={fullHeight}
          title={title || "No results found"}
          description={description || "Your search return no data"}
          children={children}
        />,
      [EProgressType.NO_DATA_AVAILABLE]:
        <LabelOverlay
          Icon={createIcon.byMuiIcon(NoDataIcon)}
          size={iconSize}
          fullHeight={fullHeight}
          title={title || "Empty"}
          description={description || "Data not available"}
          children={children}
        />,
      [EProgressType.SUCCESS]:
        <LabelOverlay
          Icon={createIcon.byMuiIcon(SuccessIcon)}
          iconColor={theme => theme.palette.success.main}
          size={iconSize}
          fullHeight={fullHeight}
          title={title || "Success"}
          description={description || "Operation successfully completed"}
          children={children}
        />,
      [EProgressType.ERROR]:
        <LabelOverlay
          Icon={createIcon.byMuiIcon(ErrorIcon)}
          iconColor={theme => theme.palette.error.main}
          size={iconSize}
          fullHeight={fullHeight}
          title={title || "Error"}
          description={description || "Operation failed"}
          children={children}
        />,
      [EProgressType.WARNING]:
        <LabelOverlay
          Icon={createIcon.byMuiIcon(WarningIcon)}
          iconColor={theme => theme.palette.warning.main}
          size={iconSize}
          fullHeight={fullHeight}
          title={title || "Warning"}
          description={description}
          children={children}
        />,
    },
  );
};
