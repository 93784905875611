import * as React from "react";

import {Box} from "../Box";

import {
  SxProps,
  Theme,
} from '../ThemeProvider';

export interface IContainerFullscreenProps {
  sx?: SxProps<Theme>;
  dataComponentName?: string;
  fullHeight?: boolean;
  fullScreen: boolean;
  children: any;
}

export const ContainerFullscreen = (props: IContainerFullscreenProps): JSX.Element => {
  const {
    sx = {},
    dataComponentName,
    fullScreen,
    fullHeight,
    children,
  } = props;

  return (
    <Box
      dataComponentName={[dataComponentName, "ContainerFullscreen"]}
      sx={{
        ...(
          fullScreen
            ? {
              position: "fixed",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 500,
            }
            : {}
        ),
        ...(
          fullHeight && !fullScreen
            ? {height: '100%'}
            : {}
        ),
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
