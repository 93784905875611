import L from 'leaflet';
import * as React from 'react';
import {
  useEffect,
  useState,
} from 'react';
import * as ReactDOM from 'react-dom';

import {useMap} from 'react-leaflet';

import {IGeoPosition} from "utils-library/dist/commonJs/geo";

export interface IMapComponentProps {
  componentId: string;
  position: IGeoPosition;
  interactive?: boolean;    // If set false the component won't be accessible at all, no drag events, just on the map as part of it.
  draggable?: boolean;
  zIndex?: number;
  children: any;
  onDragStart?: (position: IGeoPosition) => void;
  onDrag?: (position: IGeoPosition) => void;
  onDragEnd?: (position: IGeoPosition) => void;
}

export const MapComponent: React.FC<IMapComponentProps> = (
  {
    componentId,
    position,
    interactive = true,
    draggable = false,
    zIndex,
    children,
    onDragStart,
    onDrag,
    onDragEnd,
  },
) => {
  const map = useMap();
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!map) return;

    const divIcon = L.divIcon({
      className: `element-id--${componentId}`,
      html: '',
    });

    const marker = L.marker(position, {
      icon: divIcon,
      interactive,
      draggable,
      zIndexOffset: zIndex,
    });

    marker.addTo(map);

    if (onDragStart) {
      marker.on('dragstart', () => {
        onDragStart(marker.getLatLng());
      });
    }

    if (onDrag) {
      marker.on('drag', () => {
        onDrag(marker.getLatLng());
      });
    }

    if (onDragEnd) {
      marker.on('dragend', () => {
        onDragEnd(marker.getLatLng());
      });
    }

    const containerElement = marker.getElement();
    if (containerElement) {
      setContainer(containerElement as HTMLDivElement);
    }

    return () => {
      marker.removeFrom(map);
      setContainer(null);
    };
  }, [
    componentId,
    map,
    position,
    draggable,
    !!onDragStart,
    !!onDrag,
    !!onDragEnd,
  ]);

  const handleDoubleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  if (!container) return null;

  return (
    <div onDoubleClick={handleDoubleClick}>
      {ReactDOM.createPortal(<>{children}</>, container)}
    </div>
  );
};
