import {EDeviceType} from "../../interfaces/EDeviceType";

export const API_PATH_ApiDeviceMgmtDevicesConfigGet = '/services/devices-mgtm/apiDeviceMgmtDevicesConfigGet';

export interface IApiDeviceMgmtDevicesConfigGetQueryRequest {
  deviceId: string;
  // Dev note:
  //    DeviceBrokerId and deviceType are required because the Device Item in the database might not have been updated yet.
  //    This occurs when there is a change in the device type or broker ID.  deviceBrokerId: string;
  deviceBrokerId: string;
  deviceType: EDeviceType;
}

export interface IApiDeviceMgmtDevicesConfigGetResponse {
  config: any;
}
