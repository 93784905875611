import {ICommentContent} from "../../../../../features/db-comments";

export const API_PATH_ApiTrafficStudyCommentPut = '/services/traffic-studies/apiTrafficStudyCommentPut';

export interface IApiTrafficStudyCommentPutRequest {
  trafficStudyId: string;
  postId: string;
  comment: Partial<ICommentContent>;
}

export interface IApiTrafficStudyCommentPutResponse {
  ok: boolean;
}
