import {useState} from "react";

import {connect} from "react-dynadux";
import {IDynaError} from "dyna-error";

import {IValidationResult} from "utils-library/dist/commonJs/validation-engine";

import {IAppStore} from "../../../../state/IAppStore";

import {
  Alert,
  EAlertType,
} from "mhc-ui-components/dist/Alert";
import {Box} from "mhc-ui-components/dist/Box";
import {ErrorBanner} from "mhc-ui-components/dist/ErrorBanner";
import {ContainerEnumConditional} from "mhc-ui-components/dist/ContainerEnumConditional";
import {IsLoading} from "mhc-ui-components/dist/IsLoading";
import {
  GridContainer,
  GridItem,
} from "mhc-ui-components/dist/Grid";
import {Input} from "mhc-ui-components/dist/Input";
import {ButtonBar} from "mhc-ui-components/dist/ButtonBar";
import {
  Button,
  EButtonColor,
  EButtonSize,
} from "mhc-ui-components/dist/Button";
import {
  InputMultiSelect,
  EInputMultiSelectItemType,
} from "mhc-ui-components/dist/InputMultiSelect";
import {
  useForm,
  EFormType,
} from "mhc-ui-components/dist/useForm";

import {
  IApiCreateCompanyPostBodyRequest,
  ESendUserInvitationStatus,
  EUserAuthenticationRights,
} from "mhc-server";

import {InvitationStatus} from "../../../users-invitations/components/InvitationStatus";

import {getUserRightsOptions} from "../../../../utils/user-rights/getUserRightsOptions";
import {apiCreateCompanyGet} from "../../api/apiCreateCompanyGet";
import {apiCreateCompanyPost} from "../../api/apiCreateCompanyPost";
import {apiCreateCompanyDelete} from "../../api/apiCreateCompanyDelete";

import SearchIcon from "@mui/icons-material/Search";
import CreateIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import ResetIcon from "@mui/icons-material/RotateLeft";
import DeleteIcon from "@mui/icons-material/Delete";

export interface ICreateCompanyPageProps {
  store: IAppStore;
}

enum EFormStep {
  STEP_1_USER_FILL_COMPANY_ID_AND_EMAIL,
  STEP_2_API_CHECK_FOR_EXISTING_COMPANY_INVITATION,
  STEP_3_USER_FILL_INVITATION_INFO,
  STEP_4_API_CREATE_COMPANY,
  STEP_5_USER_COMPLETED,
}

export const CreateCompanyPage = connect((props: ICreateCompanyPageProps): JSX.Element => {
  const {store: {userAuth: {utils: {userHasRight}}}} = props;

  const [currentInvitationIsLoading, setCurrentInvitationIsLoading] = useState(false);
  const [currentInvitationStatus, setCurrentInvitationStatus] = useState<ESendUserInvitationStatus | null>(null);
  const [currentInvitationNetworkError, setCurrentInvitationNetworkError] = useState<IDynaError | null>(null);
  const [currentInvitationValidationErrors, setCurrentInvitationValidationErrors] =
    useState<IValidationResult<{ companyId: string; email: string }> | null>
    (null);

  const [successInfo, setSuccessInfo] = useState<string[]>([]);
  const [formStep, setFormStep] = useState<EFormStep>(EFormStep.STEP_1_USER_FILL_COMPANY_ID_AND_EMAIL);

  const isSysAdmin = userHasRight(EUserAuthenticationRights.SYSTEM_ADMINISTRATOR);

  const createUpdateNewCompany = async (data: IApiCreateCompanyPostBodyRequest): Promise<void> => {
    try {
      setFormStep(EFormStep.STEP_4_API_CREATE_COMPANY);
      setSuccessInfo([]);
      const response = await apiCreateCompanyPost(data);
      setCurrentInvitationStatus(ESendUserInvitationStatus.SENT);
      setSuccessInfo(response.info);
      resetForm({companyId});  // Reset but keep the current companyId
      setFormStep(EFormStep.STEP_5_USER_COMPLETED);
    }
    catch (e) {
      setFormStep(EFormStep.STEP_3_USER_FILL_INVITATION_INFO);
      throw e;
    }
  };

  const emptyFormData: IApiCreateCompanyPostBodyRequest = {
    companyId: '',
    fullCompanyName: '',
    email: '',
    title: '',
    lastName: '',
    firstName: '',
    rights: getUserRightsOptions({isSysAdmin}).map(right => right.value),
  };

  const {
    data: {
      companyId,
      fullCompanyName,
      email,
      title,
      firstName,
      lastName,
      rights,
    },
    isLoading: createUpdateIsLoading,
    formProps,
    alertViewer,
    confirmViewer,
    validationResult: {dataValidation},
    networkError: newInvitationNetworkError,
    change,
    delete: deleteInvitation,
    resetForm,
  } = useForm<IApiCreateCompanyPostBodyRequest, string>({
    formType: EFormType.EDIT,
    loadDataId: '',
    emptyFormData,
    onApiGet: async () => emptyFormData,
    onApiPost: async (data): Promise<{ dataId: string; data?: IApiCreateCompanyPostBodyRequest }> => {
      await createUpdateNewCompany(data);
      return {dataId: companyId};
    },
    onApiPut: createUpdateNewCompany,
    onApiDelete: async (): Promise<IApiCreateCompanyPostBodyRequest> => {
      await apiCreateCompanyDelete({
        companyId,
        email,
      });
      setSuccessInfo([`Invitation to companyId: ${companyId} do admin: ${email} successfully deleted`]);
      handleResetClick();
      return {  // Reset, but keep the same companyId
        ...emptyFormData,
        companyId,
      };
    },
  });

  const handleCheckForExistingInvitation = async (): Promise<void> => {
    try {
      setSuccessInfo([]);
      setFormStep(EFormStep.STEP_2_API_CHECK_FOR_EXISTING_COMPANY_INVITATION);
      setCurrentInvitationNetworkError(null);
      setCurrentInvitationIsLoading(true);
      setCurrentInvitationValidationErrors(null);
      setCurrentInvitationStatus(null);

      const currentInvitation = await apiCreateCompanyGet({
        companyId,
        email,
      });

      if (currentInvitation) {
        const {
          fullCompanyName,
          title,
          firstName,
          lastName,
          rights,
          lastSendStatus,
        } = currentInvitation;
        change({
          fullCompanyName,
          title,
          firstName,
          lastName,
          rights,
        });
        setCurrentInvitationStatus(lastSendStatus);
      }

      setFormStep(EFormStep.STEP_3_USER_FILL_INVITATION_INFO);
    }
    catch (e) {
      const error: IDynaError = e as any;
      switch (error.status) {
        case 400:
          if (error.validationErrors) setCurrentInvitationValidationErrors(error.validationErrors.query);
          setCurrentInvitationNetworkError(error);
          setFormStep(EFormStep.STEP_1_USER_FILL_COMPANY_ID_AND_EMAIL);
          break;
        case 404:
          // That's expected! The invitation doesn't exist
          setFormStep(EFormStep.STEP_3_USER_FILL_INVITATION_INFO);
          break;
        default:
          setCurrentInvitationNetworkError(error);
          setFormStep(EFormStep.STEP_1_USER_FILL_COMPANY_ID_AND_EMAIL);
      }
    }
    finally {
      setCurrentInvitationIsLoading(false);
    }
  };

  const handleRightsChange = (rights: string[]): void => change({rights});

  const handleResetClick = (): void => {
    resetForm({companyId});  // Reset but keep the current companyId
    setFormStep(EFormStep.STEP_1_USER_FILL_COMPANY_ID_AND_EMAIL);
    setSuccessInfo([]);
    setCurrentInvitationNetworkError(null);
    setCurrentInvitationValidationErrors(null);
  };

  const isLoading: boolean = currentInvitationIsLoading || createUpdateIsLoading;
  const updateMode: 'create' | 'update' =
    currentInvitationStatus === ESendUserInvitationStatus.SENT
      ? "update"
      : "create";

  return (
    <Box dataComponentName="CreateCompanyPage">
      <IsLoading isLoading={isLoading}>
        <form {...formProps}>
          {confirmViewer}
          {alertViewer}
          <ErrorBanner
            error={
              currentInvitationNetworkError
              || newInvitationNetworkError
            }
          />
          <Alert
            type={EAlertType.SUCCESS}
            show={!!successInfo.length}
            title="Success - Invitation sent for the new company"
          >
            {successInfo.map((info, index) => (<div key={index}>{info}</div>))}
          </Alert>
          <GridContainer spacing={5}>

            <GridItem mobile={12} tablet={4}>
              <Input
                name="companyId"
                label="Company ID"
                ariaLabel="Company ID"
                autoFocus
                helperLabel="Only lowercase characters with single hyphens"
                disabled={
                  formStep !== EFormStep.STEP_1_USER_FILL_COMPANY_ID_AND_EMAIL
                  && formStep !== EFormStep.STEP_5_USER_COMPLETED
                }
                value={companyId}
                validationError={
                  currentInvitationValidationErrors?.dataValidation.companyId
                  || dataValidation.companyId
                }
              />
            </GridItem>
            <GridItem mobile={12} tablet={8}>
              <Input
                name="email"
                label="Administrator email"
                ariaLabel="Administrator email"
                helperLabel="The system will send invitation to the administrator"
                disabled={
                  formStep !== EFormStep.STEP_1_USER_FILL_COMPANY_ID_AND_EMAIL
                  && formStep !== EFormStep.STEP_5_USER_COMPLETED
                }
                value={email}
                validationError={
                  currentInvitationValidationErrors?.dataValidation.email
                  || dataValidation.email
                }
              />
            </GridItem>

            <ContainerEnumConditional
              enum={formStep}
              showIn={[
                EFormStep.STEP_1_USER_FILL_COMPANY_ID_AND_EMAIL,
                EFormStep.STEP_2_API_CHECK_FOR_EXISTING_COMPANY_INVITATION,
                EFormStep.STEP_5_USER_COMPLETED,
              ]}
            >
              <GridItem mobile={12}>
                <Button
                  size={EButtonSize.LARGE}
                  disabled={isLoading}
                  icon={<SearchIcon/>}
                  onClick={handleCheckForExistingInvitation}
                >
                  Check for existing Company / Invitation
                </Button>
              </GridItem>
            </ContainerEnumConditional>

            <ContainerEnumConditional
              enum={formStep}
              showIn={[
                EFormStep.STEP_3_USER_FILL_INVITATION_INFO,
                EFormStep.STEP_4_API_CREATE_COMPANY,
              ]}
            >

              <GridItem mobile={12}>
                <InvitationStatus
                  status={
                    currentInvitationStatus
                    || ESendUserInvitationStatus.NOT_CREATED
                  }
                />
              </GridItem>

              <GridItem mobile={12}>
                <Input
                  name="fullCompanyName"
                  label="Full company name"
                  ariaLabel="Full company name"
                  value={fullCompanyName}
                  validationError={dataValidation.fullCompanyName}
                />
              </GridItem>

              <GridItem mobile={4} tablet={3}>
                <Input
                  name="title"
                  label="Title"
                  ariaLabel="Title"
                  value={title}
                  validationError={dataValidation.title}
                />
              </GridItem>
              <GridItem mobile={8} tablet={4}>
                <Input
                  name="firstName"
                  label="First Name"
                  ariaLabel="First Name"
                  value={firstName}
                  validationError={dataValidation.firstName}
                />
              </GridItem>
              <GridItem mobile={12} tablet={5}>
                <Input
                  name="lastName"
                  label="Last name"
                  ariaLabel="Last name"
                  value={lastName}
                  validationError={dataValidation.lastName}
                />
              </GridItem>

              <GridItem>
                <InputMultiSelect
                  name="rights"
                  label="User rights"
                  itemType={EInputMultiSelectItemType.CHIP}
                  options={getUserRightsOptions({isSysAdmin})}
                  pickerOptions={10.5}
                  value={rights}
                  validationError={dataValidation.rights}
                  onChange={handleRightsChange}
                />
              </GridItem>

              <GridItem>
                <ButtonBar>
                  {updateMode === "create" && (
                    <Button
                      size={EButtonSize.XLARGE}
                      icon={<CreateIcon/>}
                    >
                      Create company and invite
                    </Button>
                  )}
                  {updateMode === "update" && (
                    <Button
                      size={EButtonSize.XLARGE}
                      icon={<EditIcon/>}
                    >
                      Update invitation
                    </Button>
                  )}
                  <Button
                    type="reset"
                    icon={<ResetIcon/>}
                    onClick={handleResetClick}
                  >
                    Reset
                  </Button>
                  <Button
                    color={EButtonColor.ERROR}
                    disabled={currentInvitationStatus !== ESendUserInvitationStatus.SENT}
                    icon={<DeleteIcon/>}
                    onClick={deleteInvitation}
                  >
                    Delete invitation
                  </Button>
                </ButtonBar>
              </GridItem>

            </ContainerEnumConditional>

          </GridContainer>
        </form>
      </IsLoading>
    </Box>
  );
});
