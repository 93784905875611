import * as React from "react";

import {
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "../FlexContainer";
import {IconViewer} from "../IconViewer";
import {IIconComponent} from "../IconComponent";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";

export interface IIconTextProps {
  sx?: SxProps<Theme>;
  dataComponentName?: string;
  show?: boolean;
  Icon?: IIconComponent;    // The Icon size is obtained automatically by the current font size
  children: any;
}

export const IconText = (props: IIconTextProps): JSX.Element | null => {
  const {
    sx = {},
    dataComponentName,
    show = true,
    Icon,
    children,
  } = props;

  if (!show) return null;

  return (
    <FlexContainerHorizontal
      sx={sx}
      dataComponentName={[dataComponentName, "IconText"]}
      alignVertical="top"
    >
      <FlexItemMin show={!!Icon}>
        <IconViewer
          sx={{
            marginTop: '0.1em',
            marginRight: '0.2em',
          }}
          Icon={Icon}
        />
      </FlexItemMin>
      <FlexItemMax sx={{whiteSpace: 'normal'}}>
        {children}
      </FlexItemMax>
    </FlexContainerHorizontal>
  );
};
