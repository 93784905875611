import * as React from "react";
import {
  useState,
  useEffect,
} from "react";

import {IGeoPosition} from "utils-library/dist/commonJs/geo";

import {IIconComponent} from "../../../../IconComponent";
import {Window} from "../../../../Windows";

import {MapComponent} from "../MapComponent";
import {MapPolyline} from "../MapPolyline";


export interface IMapToolWindowProps {
  show: boolean;
  windowId: string;

  ownerPosition: IGeoPosition;

  Icon?: IIconComponent;
  title: string;
  minimizable: boolean;

  minWidth?: number;    // If there is no width or height specified, the size will be determined by the content of the children.

  children: any;
  onClose: () => void;
}

export const MapToolWindow: React.FC<IMapToolWindowProps> = (
  {
    show,
    windowId,
    minimizable,
    minWidth,
    ownerPosition,
    Icon,
    title,
    children,
    onClose,
  },
) => {
  const [initialWindowPosition, setInitialWindowPosition] = useState<IGeoPosition>({
    lat: ownerPosition.lat - 0.0001,
    lng: ownerPosition.lng + 0.0001,
  });
  useEffect(()=>{
    if (!show) setInitialWindowPosition(currentWindowPosition); // Save the initialWindowPosition for the next show.
  }, [show]);

  const [currentWindowPosition, setCurrentWindowPosition] = useState<IGeoPosition>(initialWindowPosition);

  const handleDragEng = (position: IGeoPosition) => setCurrentWindowPosition(position);

  if (!show) return null;

  return (
    <MapComponent
      componentId={windowId}
      position={initialWindowPosition} // Dev note: Do not use the currentWindowPosition here as it cannot handle massive updates
      interactive
      draggable
      zIndex={1000}
      onDrag={handleDragEng}
    >
      <Window
        Icon={Icon}
        title={title}
        minimizable={minimizable}
        minWidth={minWidth}
        onClose={onClose}
      >
        {children}
      </Window>
      <MapPolyline
        points={[
          ownerPosition,
          currentWindowPosition,
        ]}
        pathOptions={{
          weight: 3,
          color: '#cfff00',
          dashArray: '8, 4',
        }}
      />
    </MapComponent>
  );
};
