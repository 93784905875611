import {ITranslationPayload} from "../intl";

export interface IEnumInfo<TEnum extends string | number | symbol> {
  enum: TEnum;
  label: ITranslationPayload;
  description: ITranslationPayload;
}

export interface IEnumInfoItemSetup {
  label: ITranslationPayload;
  description: ITranslationPayload;
}

export interface IEnumInfos<TEnum extends string | number | symbol> {
  dic: Record<TEnum, IEnumInfo<TEnum>>;
  array: IEnumInfo<TEnum>[];
}

export const enumInfo = <TEnum extends string | number | symbol>(setupDic: Record<TEnum, IEnumInfoItemSetup>): IEnumInfos<TEnum> => ({
  dic:
    Object.entries<IEnumInfoItemSetup>(setupDic)
      .reduce((acc: Record<TEnum, IEnumInfo<TEnum>>, [enumKey, {
        label, description,
      }]) => {
        acc[enumKey] = {
          enum: enumKey,
          label,
          description,
        };
        return acc;
      }, {} as any),
  array:
    Object.entries<IEnumInfoItemSetup>(setupDic)
      .reduce(
        (
          acc: IEnumInfo<TEnum>[],
          [
            enumKey,
            {
              label,
              description,
            },
          ],
        ) => {
          return acc.concat({
            enum: enumKey as any,
            label,
            description,
          });
        },
        [],
      ),
});
