import * as React from "react";

import {Box} from "../Box";
import {
  ProgressIcon,
  EProgressIconType,
} from "../ProgressIcon";

import {
  SxProps,
  useTheme,
  Theme,
} from "../ThemeProvider";

export interface IIsLoadingProps {
  sx?: SxProps<Theme>;
  dataComponentName?: string;
  iconType?: EProgressIconType;
  fullHeight?: boolean;       // Default is false
  isLoading?: boolean;
  width?: number;             // Default is 40
  label?: string | JSX.Element;
  showCircularIcon?: boolean; // Default is true
  fadeBackground?: boolean;   // Default is true
  children?: any;
}

export const IsLoading = (props: IIsLoadingProps) => {
  const {
    sx = {},
    dataComponentName,
    iconType,
    fullHeight = false,
    isLoading = true,
    width = 40,
    label,
    showCircularIcon = true,
    fadeBackground = true,
    children = null,
  } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: (sx as any).height
          ? (sx as any).height
          : fullHeight ? '100%' : undefined,
        ...sx,
      }}
      dataComponentName={[dataComponentName, "IsLoading"]}
    >
      {children}
      <Box
        show={isLoading}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: fadeBackground ? 'rgba(155, 155, 155, 0.5)' : undefined,
        }}
      >
        <Box show={showCircularIcon}>
          <ProgressIcon
            iconType={iconType}
            size={width}
          />
        </Box>
        <Box
          show={!!label}
          sx={{
            marginTop: theme.spacing(3),
            fontSize: theme.typography.fontSize,
            padding: '4px 8px',
            borderRadius: 13,
            color: theme.palette.background.default,
            backgroundColor: theme.palette.action.active,
          }}
          children={label}
        />
      </Box>
    </Box>
  );
};
