import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";

import {EUserAuthenticationRights} from "mhc-server";

import {IAppRoute} from "../../application/config/IAppRoute";
import UsersIcon from "@mui/icons-material/PeopleAlt";

const UserManagementListPage = lazy(() => import( "../pages/UserManagementListPage/UserManagementListPage"));

export const routeListUsers: IAppRoute<{
  search?: string;
}> = {
  menuId: 'users-management',
  icon: <UsersIcon/>,
  title: 'User Management',
  routePath: '/users/list/:search?/',
  getRoutePath: ({search = ""} = {}) =>
    routeListUsers.routePath
      .replace(':search?', search),
  userHasAllRights: [],
  userHasAnyOfRights: [
    EUserAuthenticationRights.ADMINISTRATOR,
    EUserAuthenticationRights.SYSTEM_ADMINISTRATOR,
  ],
  render: ({pathParams}) => (
    <LazyLoadComponent>
      <UserManagementListPage
        search={pathParams.search || ''}
      />
    </LazyLoadComponent>
  ),
};
