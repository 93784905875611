export const API_PATH_ApiAlertMgtmRegionLocationDeviceInfoGet = '/services/alert-mgtm/apiAlertMgtmRegionLocationDeviceInfoGet';

export interface IApiAlertMgtmRegionLocationDeviceInfoGetRequest {
  regionId: string;
  locationId: string;
  deviceId: string;
}

export interface IApiAlertMgtmRegionLocationDeviceInfoGetResponse {
  region: {
    label: string;
    description: string;
  };
  location: {
    label: string;
    description: string;
  };
  device: {
    label: string;
    description: string;
  };
}
