export const API_PATH_ApiTrafficStudyCommentDelete = '/services/trafic-studies/apiTrafficStudyCommentDelete';

export interface IApiTrafficStudyCommentDeleteRequest {
  trafficStudyId: string;
  postId: string;
  deleteIt: boolean;
}

export interface IApiTrafficStudyCommentDeleteResponse {
  ok: true;
}

