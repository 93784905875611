import {IGeoPosition} from "utils-library/dist/commonJs/geo";
import {getGeoPositionsByStrings} from "./getGeoPositionsByStrings";

export const getGeoPositionByString = (text: string): {
  coordinate: IGeoPosition | null;
  error: string | null;
} => {
  const {
    coordinates,
    errors,
  } = getGeoPositionsByStrings([text]);
  return {
    coordinate: coordinates.pop() || null,
    error: errors.pop() || null,
  };
};
