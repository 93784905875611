import * as React from "react";
import {
  useState,
  useEffect,
} from "react";

import {Box} from "../Box";
import {useResizeDetector} from 'react-resize-detector';

import {
  SxProps,
  Theme,
} from "../ThemeProvider";
import {
  TDataComponentName,
  getDataComponentName,
} from "../ui-interfaces";

export interface IContainerDetectResizeProps {
  sx?: SxProps<Theme>;
  dataComponentName?: TDataComponentName;
  debounce?: boolean;    // Debounce changes, helpful to wait other animations to complete before render().
  fullHeight?: boolean;
  children: (args: { width: number; height: number }) => any;
  onScroll?: (event: React.MouseEvent) => void;
  onClick?: (event: React.MouseEvent) => void;
}

export const ContainerDetectResize = (props: IContainerDetectResizeProps): JSX.Element => {
  const {
    sx,
    dataComponentName,
    children,
    debounce,
    fullHeight = false,
    onClick,
    onScroll,
  } = props;

  const {
    width = 0,
    height = 0,
    ref,
  } = useResizeDetector({
    refreshMode: debounce ? 'debounce' : undefined,
    skipOnMount: false,
  });

  const [initialDimension, setInitialDimension] = // We use the initial dimension in the first place because the useResizeDetector is delaying producing blank areas for a short time!
    useState<{ width: number; height: number }>({
      width: 0,
      height: 0,
    });

  useEffect(() => {
    if (!ref.current) return;
    setInitialDimension({
      width: ref.current.clientWidth,
      height: ref.current.clientHeight,
    });
  }, [ref.current]);

  return (
    <Box
      dataComponentName={getDataComponentName(dataComponentName, "ContainerDetectResize")}
      sx={sx}
      fullHeight={fullHeight}
      ref={ref}
      onClick={onClick}
      onScroll={onScroll}
    >
      {children({
        width: width || initialDimension.width,
        height: height || initialDimension.height,
      })}
    </Box>
  );
};
