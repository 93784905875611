import * as React from "react";
import {ReactElement} from "react";

import Grid from "@mui/material/Grid";

import {
  TDataComponentName,
  getDataComponentName,
} from "../ui-interfaces";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";
import {
  sxTransition,
  ECSSDuration,
} from "../sxTransition";

export interface IGridItemProps {
  sx?: SxProps<Theme>;
  dataComponentName?: TDataComponentName;
  show?: boolean;           // Render or not
  hidden?: boolean;         // In the DOM but not visible
  noSpacing?: boolean;      // Default is false. Remove the space for this specific grid item. Useful when the content is shown with animation.

  mobile?: TGridColumn;     // Default is 12
  tablet?: TGridColumn;     // Default is mobile
  laptop?: TGridColumn;     // Default is tablet
  desktop?: TGridColumn;    // Default is tablet
  wide?: TGridColumn;       // Default is tablet

  children?: any;
}

export type TGridColumn = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const GridItem = (props: IGridItemProps): ReactElement | null => {
  const {
    sx = {},
    dataComponentName,
    show = true,
    hidden = false,
    noSpacing = false,
    mobile = 12,
    tablet = mobile || 12,
    laptop = tablet || 12,
    desktop = laptop || 12,
    wide = desktop || 12,
    children = null,
  } = props;

  if (!show) return null;

  return (
    <Grid
      sx={{
        ...(
          hidden
            ? {
              position: 'absolute',
              left: -100000,
            }
            : {}
        ),
        padding:
          noSpacing
            ? 0
            : undefined,
        transition: theme => sxTransition(theme, "padding", ECSSDuration.SHORT),
        ...sx,
      }}
      data-component-name={getDataComponentName(dataComponentName, "GridItem")}
      item
      xs={mobile}
      sm={tablet}
      md={laptop}
      lg={desktop}
      xl={wide}
    >
      {children}
    </Grid>
  );
};
