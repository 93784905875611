import {isNumber} from "./isNumber";
import {pluralize} from "./pluralize";
import {roundToString} from "dyna-loops";

export const pluralizedCount = (
  {
    this: _this = false,
    isAtFront = false,
    unit,
    items,
    precision = 0,
  }: {
    this?: boolean;
    isAtFront?: boolean;
    unit: string;
    items: any[] | number;
    precision?: number;
  },
): string => {
  const count: number =
    isNumber(items)
      ? items
      : (items as any).length;
  const countLabel = (() => {
    if (count === 1) return "one";
    if (count === 2) return "two";
    if (count === 3) return "three";
    if (count === 10) return "ten";
    return roundToString(count, precision);
  })();

  const wordPluralized = pluralize(unit, items);

  return [
    _this && pluralize('this', items),
    isAtFront && pluralize('is', items),
    countLabel,
    wordPluralized,
  ]
    .filter(Boolean)
    .join(' ');
};
