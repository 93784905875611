import {IDBEntityHistoryDocument} from "utils-library/dist/commonJs/db-entity";
import {ITrafficStudy} from "../../../interfaces/ITrafficStudy";

export const API_PATH_ApiTrafficStudyHistorySearchGet = '/services/traffic-studies/apiTrafficStudyHistorySearchGet';

export interface IApiTrafficStudyHistorySearchGetRequest {
  trafficStudyId: string;
  searchText?: string;
  tags?: string[];
  skip: number;
  limit: number;
}

export type IApiTrafficStudyHistorySearchGetResponse = IDBEntityHistoryDocument<ITrafficStudy>[];
