import {
  validateDataMethods,
  IValidationResult,
  validationEngine,
} from "utils-library/dist/commonJs/validation-engine";

import {ICommentContent} from "./ECommentPostType";

export const  validateCommentContent = (logContent: ICommentContent): IValidationResult<ICommentContent> =>
  validationEngine<ICommentContent>({
    validationRules: {body: value => validateDataMethods.isValidText(value, 1, 2000)},
    data: logContent,
  });
