import * as React from "react";
import {useMemo} from "react";
import {guid} from "dyna-guid";
import {dynaSwitch} from "dyna-switch";

import Input from "@mui/material/Input";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

import {Box} from "../Box";
import {Chips} from "../Chips";
import {
  HelperText,
  EHelperTextType,
} from "../HelperText";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";

export interface IInputMultiSelectProps {
  sx?: SxProps<Theme>;
  dataComponentName?: string;
  variant?: EIInputMultiSelectVariant;  // Default is EIInputMultiSelectVariant.STANDARD
  itemType?: EInputMultiSelectItemType; // Default is EInputMultiSelectItemType.STANDARD
  spacing?: EInputMultiSelectSpacing;   // Default is EInputMultiSelectSpacing.NONE

  disabled?: boolean;                   // Default is false
  readOnly?: boolean;                   // Default is false
  hidden?: boolean;                     // Default is false

  label?: string;
  ariaLabel?: string;
  helperLabel?: string;
  icon?: JSX.Element;

  name?: string;

  noItemLabel?: JSX.Element | string;   // Default is "---"

  options: IInputMultiSelectOption[];
  pickerOptions?: number;               // Default is 4.5

  validationError?: string;

  value: string[];  // The value of the selected option

  onChange?: (value: string[]) => void;
  onClose?: (value: string[]) => void;
}

export enum EIInputMultiSelectVariant {
  FILLED = "filled",
  OUTLINED = "outlined",
  STANDARD = "standard",
}

export interface IInputMultiSelectOption {
  value: string;
  label: string;
}

export enum EInputMultiSelectSpacing {
  NONE = "none",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export enum EInputMultiSelectItemType {
  MENU = "MENU",
  CHIP = "CHIP",
}

export const InputMultiSelect = (props: IInputMultiSelectProps): JSX.Element => {
  const {
    dataComponentName,
    sx = {},
    variant = EIInputMultiSelectVariant.STANDARD,
    itemType = EInputMultiSelectItemType.MENU,
    spacing = EInputMultiSelectSpacing.NONE,

    disabled,
    readOnly,
    hidden,
    icon,

    name,

    noItemLabel = "---",

    label,
    ariaLabel,
    helperLabel = '',

    options,
    pickerOptions = 4.5,

    validationError,

    value,

    onChange,
    onClose,
  } = props;

  const id: string = useMemo<string>(() => guid(), []);
  const labelId = id + '---label';

  const handleChange = (e: any): void => onChange && onChange(e.target.value);
  const handleClose = (e: any): void => onClose && onClose(e.target.value);

  const MENU_ITEM_HEIGHT = 48;
  const MENU_ITEM_PADDING_TOP = 8;

  return (
    <Box
      sx={{
        ...(hidden
          ? {
            position: 'absolute',
            overflow: 'hidden',
            height: 0,

          }
          : {}
        ),
        margin: dynaSwitch(
          spacing,
          undefined,
          {
            [EInputMultiSelectSpacing.NONE]: 0,
            [EInputMultiSelectSpacing.SMALL]: '0 4px 4px 0',
            [EInputMultiSelectSpacing.MEDIUM]: '0 8px 8px 0',
            [EInputMultiSelectSpacing.LARGE]: '0 16px 16px 0',
          },
        ),
        ...sx,
      }}
      dataComponentName={[dataComponentName, 'InputMultiSelect']}
    >
      <InputLabel
        id={labelId}
        htmlFor={id}
      >
        {label}
      </InputLabel>
      <Select
        sx={{width: '100%'}}
        id={id}
        aria-label={ariaLabel}
        variant={variant}
        labelId={labelId}
        name={name}
        IconComponent={icon ? () => icon : undefined}

        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          PaperProps: {style: {maxHeight: MENU_ITEM_HEIGHT * pickerOptions + MENU_ITEM_PADDING_TOP}},
        }}

        disabled={disabled}
        readOnly={readOnly}

        multiple

        input={<Input/>}

        value={value}
        onChange={handleChange}
        onClose={handleClose}

        displayEmpty
        renderValue={(selectedValues: string []) => {
          if (!selectedValues.length) return noItemLabel;
          switch (itemType) {
            case EInputMultiSelectItemType.MENU:
              return (
                selectedValues.map((selectedValue, index, array) => (
                  <span
                    key={index}
                  >
                    {options.find(scanOption => scanOption.value === selectedValue)?.label || 'err!'}
                    {index + 1 < array.length ? <span>, </span> : null}
                  </span>
                ))
              );
            case EInputMultiSelectItemType.CHIP:
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  <Chips
                    chips={
                      selectedValues
                        .map(selectedValue => options.find(scanOption => scanOption.value === selectedValue))
                        .filter(Boolean)
                        .map((option: IInputMultiSelectOption) => ({label: option.label}))
                    }
                  />
                </Box>
              );
          }
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
          >
            <Checkbox checked={value.indexOf(option.value) > -1}/>
            <ListItemText primary={option.label}/>
          </MenuItem>
        ))}
      </Select>
      <HelperText>{helperLabel}</HelperText>
      <HelperText type={EHelperTextType.ERROR}>{validationError}</HelperText>
    </Box>
  );
};
