import * as React from "react";

import {
  InputMultiSelect,
  IInputMultiSelectOption,
} from "../../../../InputMultiSelect";

export interface ISingleOptionFilterProps {
  label: string;
  helperLabel?: string;
  options: IInputMultiSelectOption[];
  value: string[];
  onChange: (value: string[]) => void;
}

export const MultiOptionFilter = (props: ISingleOptionFilterProps): JSX.Element => {
  const {
    label,
    helperLabel,
    options,
    value,
    onChange,
  } = props;

  return (
    <InputMultiSelect
      label={label}
      ariaLabel={label}
      helperLabel={helperLabel}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};
