/**
 * Searches for a specified set of words within a given source text.
 *
 * To enable case-insensitive searches, it is more efficient to profile the values in lowercase.
 * Because this method is used extensively for filtering, it's better to change the values to lowercase and cache them instead of making this method do this every time.
 *
 * @param {Object} options - The options object.
 * @param {string} options.searchText - The words to search for.
 * @param {string} options.sourceText - The source text to search within.
 *
 * @returns {boolean} Returns true if all search words are found in the source text, otherwise false.
 */
export const searchTextEngine = (
  {
    searchText,
    sourceText,
  }: {
    searchText: string;
    sourceText: string;
  },
): boolean => {
  const searchWords = getWords(searchText || "");
  const dataWords = getWords(sourceText || "");

  // Filter search words that are found in data words
  const found = searchWords
    .filter(searchWord => dataWords.find(dataWord => dataWord.indexOf(searchWord) > -1));
  return found.length === searchWords.length;
};

const getWords = (text: string): string[] => text.toLowerCase().split(' '); // Intentionally, do not lowercase here!
