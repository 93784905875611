import {
  useState,
  useEffect,
} from 'react';

import {useHistory} from 'react-router-dom';
import * as History from "history";

import {useConfirm} from "../useConfirm";

export interface IPromptRouteChangeProps {
  active?: boolean;                                             // Default is true. Set this prompt active or not
  getPrompt: (location: History.Location) => IPrompt | null;    // Return text to prompt with it or empty string to do not prompt
  onConfirm?: () => Promise<void>;
  onCancel?: () => void;
}

export interface IPrompt {
  title: string;
  message: string;
}

export const PromptRouteChange = (props: IPromptRouteChangeProps): JSX.Element => {
  const {
    active = true,
    getPrompt,
  } = props;

  const {
    confirm,
    confirmViewer,
  } = useConfirm();

  const {
    lastBlockedLocation,
    clearLastBlockedLocation,
  } = useBlockNavigation(history => {
    if (!active) return false;
    return !!getPrompt(history);
  });

  useEffect(() => {
    if (!lastBlockedLocation) return;

    const prompt = getPrompt(lastBlockedLocation);
    if (!prompt) return; // 4TS

    confirm({
      title: prompt.title,
      message: prompt.message,
      labelConfirmButton: 'Leave',
      labelCancelButton: 'No, stay',
    })
      .then(leave => {
        if (leave) window.location.pathname = lastBlockedLocation.pathname; // Dev info: history.push(lastBlockedLocation.pathname); doesn't work for some reason!
        clearLastBlockedLocation();
      });
  }, [lastBlockedLocation]);

  return confirmViewer;
};

const useBlockNavigation = (shouldBlock: (location: History.Location) => boolean): {
  lastBlockedLocation: History.Location | null;
  clearLastBlockedLocation: () => void;
} => {
  const history = useHistory();
  const [lastBlockedLocation, setLastBlockedLocation] = useState<History.Location | null>(null);

  useEffect(() => {
    const unblock = history.block((location) => {
      const block = shouldBlock(location);
      block
        ? setLastBlockedLocation(location)
        : setLastBlockedLocation(null);
      return block
        ? false
        : undefined;
    });

    return () => {
      unblock();
    };
  }, [history, shouldBlock]);

  return {
    lastBlockedLocation,
    clearLastBlockedLocation: () => setLastBlockedLocation(null),
  };
};
