export interface IContainerEnumConditionalProps {
  enum: any;
  showIn: any[];
  children: any;
}

export const ContainerEnumConditional = (props: IContainerEnumConditionalProps): JSX.Element | null => {
  const {
    children,
    enum: enum_,
    showIn = [],
  } = props;

  return showIn.includes(enum_)
    ? children
    : null;
};
