const alphabet =
  Array(26)
    .fill(null)
    .map((v, index) => {
      v; // 4TS
      return String.fromCharCode(65 + index);
    });

export const camelToHuman = (text: string): string => {
  return text
    .split('')
    .reduce((acc: string[], t, index) => {
      if (alphabet.includes(t) && index > 0) acc.push(' ');
      acc.push(
        index === 0
          ? t.toUpperCase()
          : t.toLowerCase(),
      );
      return acc;
    }, [])
    .join('');
};
