exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CoordinateInput-module-hideSpinButtons--2cI7NVk8aA2e::-webkit-inner-spin-button,\n.CoordinateInput-module-hideSpinButtons--2cI7NVk8aA2e::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n", ""]);

// exports
exports.locals = {
	"hideSpinButtons": "CoordinateInput-module-hideSpinButtons--2cI7NVk8aA2e"
};