export const irregularPlurals: { [key: string]: string } = {
  has: 'have',
  is: 'are',
  this: 'these',
  does: 'do',
  "doesn't": "don't",
  species: 'species',
  person: 'people',
  mouse: 'mice',
  child: 'children',
  man: 'men',
  woman: 'women',
  tooth: 'teeth',
  foot: 'feet',
  goose: 'geese',
  louse: 'lice',
  ox: 'oxen',
  cactus: 'cacti',
  sheep: 'sheep',
  deer: 'deer',
  fish: 'fish',
  moose: 'moose',
  focus: 'foci',
  fungus: 'fungi',
  nucleus: 'nuclei',
  radius: 'radii',
  stimulus: 'stimuli',
  syllabus: 'syllabi',
  thesis: 'theses',
  phenomenon: 'phenomena',
  index: 'indices',
  matrix: 'matrices',
  criterion: 'criteria',
  bacterium: 'bacteria',
  curriculum: 'curricula',
  datum: 'data',
  schema: 'schemas',
  software: 'software',
  hardware: 'hardware',
  network: 'networks',
  server: 'servers',
  database: 'databases',
  program: 'programs',
  algorithm: 'algorithms',
  code: 'code',
  byte: 'bytes',
  bit: 'bits',
  bug: 'bugs',
  debug: 'debug',
  syntax: 'syntax',
  loop: 'loops',
  variable: 'variables',
  constant: 'constants',
  function: 'functions',
  class: 'classes',
  object: 'objects',
  array: 'arrays',
  "string": 'strings',
  number: 'numbers',
  "boolean": 'booleans',
  null: 'null',
  "undefined": 'undefined',
  interface: 'interfaces',
  module: 'modules',
  package: 'packages',
  import: 'imports',
  export: 'exports',
  async: 'async',
  await: 'await',
  promise: 'promises',
  callback: 'callbacks',
  error: 'errors',
  exception: 'exceptions',
  log: 'logs',
  file: 'files',
  directory: 'directories',
  path: 'paths',
  permission: 'permissions',
  authentication: 'authentication',
  encryption: 'encryption',
  decryption: 'decryption',
  compression: 'compression',
  decompression: 'decompression',
  request: 'requests',
  response: 'responses',
  route: 'routes',
  middleware: 'middlewares',
  framework: 'frameworks',
  library: 'libraries',
  version: 'versions',
  release: 'releases',
  build: 'builds',
  deploy: 'deploys',
  client: 'clients',
  browser: 'browsers',
  cache: 'caches',
  session: 'sessions',
  cookie: 'cookies',
  authorization: 'authorization',
  role: 'roles',
  user: 'users',
  group: 'groups',
  home: 'homes',
  profile: 'profiles',
  settings: 'settings',
  preferences: 'preferences',
  account: 'accounts',
  login: 'logins',
  logout: 'logouts',
  register: 'registers',
  password: 'passwords',
  email: 'emails',
  notification: 'notifications',
  message: 'messages',
  inbox: 'inboxes',
  outbox: 'outboxes',
  compose: 'composes',
  send: 'sends',
  receive: 'receives',
  delete: 'deletes',
  edit: 'edits',
  save: 'saves',
  cancel: 'cancels',
  submit: 'submits',
  add: 'adds',
  remove: 'removes',
  create: 'creates',
  update: 'updates',
  select: 'selects',
  deselect: 'deselects',
  search: 'searches',
  filter: 'filters',
  sort: 'sorts',
};
