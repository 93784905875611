import {
  useState,
  useEffect,
} from 'react';

export interface IKeyStatus {
  shiftKeyDown: boolean;
  ctrlKeyDown: boolean;
  cmdKeyDown: boolean;
  altKeyDown: boolean;
}

export const useKeyStatus = (): IKeyStatus => {
  const [keyStatus, setKeyStatus] = useState<IKeyStatus>({
    shiftKeyDown: false,
    ctrlKeyDown: false,
    cmdKeyDown: false,
    altKeyDown: false,
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      setKeyStatus({
        shiftKeyDown: event.shiftKey,
        ctrlKeyDown: event.ctrlKey,
        cmdKeyDown: event.metaKey,
        altKeyDown: event.altKey,
      });
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      setKeyStatus({
        shiftKeyDown: event.shiftKey,
        ctrlKeyDown: event.ctrlKey,
        cmdKeyDown: event.metaKey,
        altKeyDown: event.altKey,
      });
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return keyStatus;
};
