export const pickRandomItems = <T>(array: T[], count: number): T[] => {
  const shuffled = array.concat();
  const result: T[] = [];

  let currentIndex = shuffled.length;
  let randomIndex;

  // While there are elements to pick and count is not zero
  while (count > 0 && currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // Swap current element with the randomly selected element
    [shuffled[currentIndex], shuffled[randomIndex]] = [
      shuffled[randomIndex],
      shuffled[currentIndex],
    ];

    // Add the selected element to the result if not already present
    if (!result.includes(shuffled[currentIndex])) {
      result.push(shuffled[currentIndex]);
      count--;
    }
  }

  return result;
};
