export const API_PATH_apiUserInvitationsTemplateDefaultPost = '/services/users-invitations/template-default';

export interface IApiUserInvitationsTemplateDefaultPostBodyRequest{
  fromTitle: string;
  fromEmail: string;
  subject: string;
  html: string;
  text: string;
}

export interface IApiUserInvitationsTemplateDefaultPostResponse {
  ok: true;
}
