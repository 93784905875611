import {EAlertType} from "../../interfaces/EAlertType";

export const API_PATH_ApiAlertMgtmAlertHandlingPost = '/services/alerts-mgtm/apiAlertMgtmAlertHandlingPost';

export interface IApiAlertMgtmAlertHandlingPostRequest<TAlert, TAlertHandle> {
  accessKey: string;
  type: EAlertType;
  title: string;
  description: string;
  regionId: string;
  locationId: string;
  deviceId: string;
  alert: TAlert;
  alertHandle: TAlertHandle;
  userId: string;
}

export interface IApiAlertMgtmAlertHandlingPostResponse {
  ok: true;
}
