export const arraySortMethod = (a: string | number, b: string | number, reverse = false): 0 | 1 | -1 => {
  if (typeof a === 'number' && typeof b === 'number') {
    // Compare numbers
    if (a < b) {
      return reverse ? 1 : -1;
    }
    else if (a > b) {
      return reverse ? -1 : 1;
    }
    else {
      return 0;
    }
  }
  else if (typeof a === 'string' && typeof b === 'string') {
    // Compare strings
    const comparison = a.localeCompare(b) as any;
    return reverse ? -comparison : comparison;
  }
  else {
    // If types are different, consider numbers to come before strings
    if (typeof a === 'number') {
      return reverse ? 1 : -1;
    }
    else {
      return reverse ? -1 : 1;
    }
  }
};
