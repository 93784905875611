import {
  useState,
  useEffect,
} from "react";
import {guid} from "dyna-guid";
import {IDynaError} from "dyna-error";

import {TSetMethod} from "../usePerformanceState";
import {useIsMounted} from "../useIsMounted";
import {useOnChange} from "../useOnChange";

export interface IUseLoadDataArgs<TData> {
  defaultData: TData;
  loadOnStartup?: boolean;      // Default is true
  intervalReload?: number;      // Default is undefined. Interval in ms for auto reload
  errorHandling?: {
    consoleIt?: boolean;        // Default is false
    consoleMessage?: string;    // Default is 'useLoadData: Load error'
    userMessage?: string;       // Default is undefined, the userMessage of the Error will be used
  };
  reloadDep?: any;              // Will reload if this is changed (it uses the useOnChange)
  load: () => Promise<TData>;
  /**
   * Call back on data load from load()
   */
  onLoad?: (data: TData) => void;
  /**
   * Call back for any change of data, from load() or from changeData
   */
  onChange?: (data: TData) => void;
  onError?: (e: IDynaError) => void;
}

export interface IUseLoadDataApi<TData> {
  isInitialLoading: boolean;
  isLoading: boolean;
  isReloading: boolean;
  loaded: boolean;
  data: TData;
  error?: IDynaError;
  changeId: string;
  load: () => Promise<TData>;
  reload: () => Promise<TData>;       // Load again, this turns on the isReloading and not the isLoading
  reset: () => void;                  // Reset the hook it it's initial state
  changeData: TSetMethod<TData>;
}

export const useLoadData = <TData>(
  {
    defaultData,
    loadOnStartup = true,
    intervalReload,
    errorHandling = {},
    reloadDep,
    load: loadMethod,
    onLoad,
    onChange,
    onError,
  }: IUseLoadDataArgs<TData>,
): IUseLoadDataApi<TData> => {
  const {
    consoleIt = false,
    consoleMessage = 'useLoadData: Load error',
    userMessage,
  } = errorHandling;

  const getIsMounted = useIsMounted();
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(loadOnStartup);
  const [isLoading, setIsLoading] = useState<boolean>(loadOnStartup);
  const [isReloading, setIsReloading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [data, setData] = useState<TData>(defaultData);
  const [error, setError] = useState<IDynaError | undefined>(undefined);
  const [changeId, setChangeId] = useState<string>("");

  useEffect(() => {
    if (loadOnStartup) load().catch(() => undefined);  // Swallow the error, nobody can catch it.
  }, []);

  useEffect(() => {
    if (!intervalReload) return undefined;
    const intervalHandler = setInterval(() => load(true), intervalReload);
    return () => clearInterval(intervalHandler);
  }, [intervalReload]);

  useOnChange({
    dep: reloadDep,
    onChange: () => {
      setData(defaultData);
      onChange?.(defaultData);
      load().catch(() => undefined);  // Swallow the error, nobody can catch it.
    },
  });

  const load = async (isReloading: boolean = false): Promise<TData> => {
    try {
      setError(undefined);
      if (isReloading) setIsReloading(true);
      setIsLoading(true);
      const data = await loadMethod();
      if (!getIsMounted()) return data;
      setData(data);
      onChange?.(data);
      if (isReloading) setIsReloading(false);
      setIsLoading(false);
      setIsInitialLoading(false);
      setLoaded(true);
      setChangeId(guid());
      onLoad?.(data);
      return data;
    }
    catch (e) {
      if (userMessage) e.userMessage = userMessage;
      if (consoleIt) console.error(consoleMessage, e);
      if (getIsMounted()) {
        isReloading
          ? setIsReloading(false)
          : setIsLoading(false);
        if (isInitialLoading) setIsInitialLoading(false);
        setError(e);
      }
      onError?.(e);
      throw e;
    }
  };

  const reset = (): void => {
    setIsInitialLoading(false);
    setIsLoading(false);
    setIsReloading(false);
    setLoaded(false);
    setData(defaultData);
    onChange?.(defaultData);
    setError(undefined);
    setChangeId("");
  };

  return {
    isInitialLoading,
    isLoading,
    isReloading,
    loaded,
    data,
    error,
    changeId,
    load,
    reload: () => load(true),
    reset,
    changeData: payload => {
      setData(data => {
        const newData =
          typeof payload === "function"
            ? payload(data)
            : payload;
        const output = {
          ...data,
          ...newData,
        };
        onChange?.(output);
        return output;
      });
      setChangeId(guid());
    },
  };
};
