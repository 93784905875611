import * as React from "react";

import {ITableFilter} from "../../interfaces";
import {ETableFilterComparison} from "../../interfaces";
import {ETableFilterValueType} from "../../interfaces";

import {TextFilter} from "./components/TextFilter";
import {BooleanFilter} from "./components/BooleanFilter";
import {SingleOptionFilter} from "./components/SingleOptionFilter";
import {MultiOptionFilter} from "./components/MultiOptionFilter";

export interface IFilterInputProps<TUserFilterValue> {
  filter: ITableFilter<TUserFilterValue>;
  onChange: (filter: ITableFilter<TUserFilterValue>) => void;
}

export const FilterInput = <TUserFilterValue, >(props: IFilterInputProps<TUserFilterValue>): JSX.Element => {
  const {
    filter,
    filter: {
      type,
      label,
      options = [],
      value: _value,
    },
    onChange,
  } = props;

  const value: any = _value; // Cast it as any

  const helperText = ((): string => {
    const {filter: {comparison}} = props;
    switch (comparison) {
      case ETableFilterComparison.CONTAINS: return '(*) Containing';
      case ETableFilterComparison.EQUAL: return '(=) Exact';
      case ETableFilterComparison.BIGGER: return '(>) Bigger than';
      case ETableFilterComparison.EQUAL_BIGGER: return '(=>) Equal of bigger than';
      case ETableFilterComparison.LESS: return '(<) Less than';
      case ETableFilterComparison.EQUAL_LESS: return '(=<) Equal or less than';
    }
  })();

  const handleInputChange = (value: any): void => {
    onChange({
      ...filter,
      value,
    });
  };

  switch (type) {
    case ETableFilterValueType.TEXT:
      return (
        <TextFilter
          label={label}
          helperLabel={helperText}
          value={value || ''}
          onChange={handleInputChange}
        />
      );
    case ETableFilterValueType.BOOLEAN:
      return (
        <BooleanFilter
          label={label}
          value={value as any}
          onChange={handleInputChange}
        />
      );
    case ETableFilterValueType.OPTION:
      return (
        <SingleOptionFilter
          label={label}
          options={options}
          value={value}
          onChange={handleInputChange}
        />
      );
    case ETableFilterValueType.OPTIONS:
      return (
        <MultiOptionFilter
          label={label}
          options={options}
          value={value}
          onChange={handleInputChange}
        />
      );

    case ETableFilterValueType.DATE:
    case ETableFilterValueType.NUMBER:
      return <div>Err: Filter type [{type}] is not implemented</div>;
  }
};
