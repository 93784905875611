import {
  useState,
  useEffect,
  RefObject,
} from 'react';

import {IDynaError} from "dyna-error";

import {useIsMounted} from "../useIsMounted";

export interface IUseClipboardCopyArgs {
  refButton: RefObject<HTMLDivElement | HTMLButtonElement>; // The button is getting hidden if the browser doesn't support the navigator.clipboard!
  refContent: RefObject<HTMLDivElement>;
  resetCopyTimeout?: number;
}

export interface IUseClipboardCopyApi {
  isLoading: boolean;
  isClipboardSupported: boolean;
  isCopied: boolean;
  error: IDynaError | null;
}

export const useClipboardCopy = (
  {
    refContent,
    refButton,
    resetCopyTimeout = 3000,
  }: IUseClipboardCopyArgs,
): IUseClipboardCopyApi => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const getIsMounted = useIsMounted();

  useEffect(
    () => {
      if (!refButton.current) return;

      let timeout: ReturnType<typeof setTimeout> | null;

      const handleClick = async () => {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }

        try {
          setIsLoading(true);

          setError(null);
          if (!refContent.current) throw new Error('Error: 202304281947: refCurrent.current is invalid');
          if (!refContent.current.innerText) throw new Error('Error: 202304281948: Content to copy is empty');
          await navigator.clipboard.writeText(refContent.current.innerText);
          if (!getIsMounted()) return;
          setIsCopied(true);
          timeout = setTimeout(() => {
            timeout = null;
            if (!getIsMounted()) return;
            setIsCopied(false);
          }, resetCopyTimeout);
        }
        catch (error) {
          setError(error);
          console.error('Failed to copy: ', error);
        }
        finally {
          setIsLoading(false);
        }
      };

      refButton.current.addEventListener('click', handleClick);

      return () => {
        if (!refButton.current) return;
        if (!isClipboardSupported) return;
        refButton.current.removeEventListener('click', handleClick);
        timeout && clearTimeout(timeout);
      };
    },
    [
      refContent,
      refContent?.current,
      refButton,
      refButton.current,
    ]);

  return {
    isLoading,
    isClipboardSupported,
    isCopied,
    error,
  };
};

export const isClipboardSupported: boolean =
  !!navigator.clipboard && typeof navigator.clipboard.writeText === 'function';
