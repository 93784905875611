import {EUserAuthenticationRights} from "mhc-server";

import {IAppRoute} from "../../application/config/IAppRoute";
import {CreateCompanyPage} from "../pages/CreateCompanyPage";
import CreateCompanyIcon from "@mui/icons-material/Business";

export const routeCreateCompanyPage: IAppRoute = {
  menuId: 'create-company',
  title: 'Create Company',
  icon: <CreateCompanyIcon/>,
  routePath: '/create-company',
  getRoutePath: () => routeCreateCompanyPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [
    EUserAuthenticationRights.ADMINISTRATOR,
    EUserAuthenticationRights.SYSTEM_ADMINISTRATOR,
  ],
  exact: true,
  render: () => {
    return <CreateCompanyPage/>;
  },
};

