import localforage from "localforage";
import {DynaJobQueue} from "dyna-job-queue";

import {dynaError} from "dyna-error";
import {dynaTry} from "dyna-try";

export interface ITileKeyUrl {
  key: string;
  url: string;
}

export interface IDbTilesConfig {
  onSaveOfflineMaps: THandleSaveMaps;
}

export type THandleSaveMaps = (args: THandleSaveMapsArgs) => void;

export type THandleSaveMapsArgs = {
  saveMapsCount: number;
  saveMapsLabel: string;
  savedMapsCount: number;
  savedMapsLabel: string;
  saveMaps: (args: {
    onProgress: (args: {
      progress: number;
      failedLoadCount: number;
      failedSaveCount: number;
    }) => void;
    onComplete: (canceled: boolean) => void;
  }) => {
    cancel: () => void;
  };
};

export class DbTiles {
  private readonly store: typeof localforage;

  constructor(private readonly config: IDbTilesConfig) {
    this.store = localforage.createInstance({name: `GeoMapLeaflet-dbTiles--for-offline`});
  }

  // This function is called by setupOffline's leaflet-offline
  public clear(): Promise<void> {
    return this.store.clear();
  }

  public getSavedMapsCount(): Promise<number> {
    return this.store.length();
  }

  public async getOccupiedSizeInMb(): Promise<number> {
    const count = await this.getSavedMapsCount();
    return count * 90;
  }

  public async getSavedMapsLabel(): Promise<string> {
    const count = await this.getSavedMapsCount();
    return this.getMapsCountLabel(count);
  }

  public getMapsCountLabel(maps: number): string {
    return `${maps} maps (~${(maps * 90 / 1024).toFixed(1)}mb)`;
  }

  // This function is called by setupOffline's leaflet-offline
  public async getItem(key: string): Promise<any> {
    if (navigator.onLine) return undefined; // Ignore that cached to get the most updated version of the tile.
    return this.store.getItem(key);
  }

  // This function is called by setupOffline's leaflet-offline
  public async saveTiles(tiles: ITileKeyUrl[]): Promise<void> {
    const currentTilesCount = await this.getSavedMapsCount();
    return new Promise(resolve => {
      this.config.onSaveOfflineMaps({
        saveMapsCount: tiles.length,
        saveMapsLabel: this.getMapsCountLabel(tiles.length),
        savedMapsCount: currentTilesCount,
        savedMapsLabel: this.getMapsCountLabel(currentTilesCount),
        saveMaps: ({
          onProgress, onComplete,
        }) => {
          const loadQueue = new DynaJobQueue({parallels: 10});
          let tilesLoadFailed = 0;
          let tilesSaveFailed = 0;
          let canceled = false;

          const saveNextTile = async (tile: ITileKeyUrl, no: number): Promise<void> => {
            if (canceled) return;

            const saveResult = await this.saveTile(tile);
            if (!saveResult.loaded) tilesLoadFailed++;
            if (saveResult.loaded && !saveResult.saved) tilesSaveFailed++;

            onProgress({
              progress: Number((no * 100 / tiles.length).toFixed(1)),
              failedLoadCount: tilesLoadFailed,
              failedSaveCount: tilesSaveFailed,
            });
          };

          tiles.forEach((tile, index) => {
            loadQueue.addJobPromised(() => saveNextTile(tile, index));
          });
          loadQueue.addJobPromised(async () => {
            onComplete(canceled);
            resolve();
          });

          return {cancel: () => canceled = true};
        },
      });
    });
  }

  private saveTile = async (tile: ITileKeyUrl): Promise<{ loaded: boolean; saved: boolean }> => {
    const key = tile.key;
    const url = tile.url.replace('http://', 'https://');
    let loaded = false;
    let saved = false;
    await dynaTry({
      timeout: 10000,
      try: async () => {
        const start = Date.now();
        const response = await fetch(url);
        const imageBlob = await response.blob();
        if (response.status < 200 && response.status >= 300) {
          throw dynaError({
            code: 202102251336,
            message: `Fetch error status: ${response.status}`,
          });
        }
        loaded = true;
        await this.store.removeItem(key);
        await this.store.setItem(key, imageBlob);
        saved = true;
        console.log(`Offline: Saved tile: ${(imageBlob.size / 1024).toFixed(1)}kb in ${Date.now() - start}ms url: ${url}`);
      },
    })
      .catch(error => console.error(`Offline: DbTiles: saveTile: Cannot process tile url [${url}]`, error));
    return {
      loaded,
      saved,
    };
  };
}
