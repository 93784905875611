import * as React from "react";
import {
  ReactElement,
  useState,
} from "react";

import {IGeoPosition} from "utils-library/dist/commonJs/geo";
import {pluralizedCount} from "utils-library/dist/commonJs/utils";
import {
  arrayRemove,
  arrayInsert,
} from "utils-library/dist/commonJs/array";

import {ButtonBar} from "../ButtonBar";
import {
  Button,
  EButtonSize,
  EButtonColor,
} from "../Button";

import {
  LabelContainer,
  ELabelContainerVariant,
} from "../LabelContainer";
import {Box} from "../Box";
import {
  InputSwitch,
  EInputSwitchPosition,
} from "../InputSwitch";
import {Condition} from "../Condition";
import {HelperText} from "../HelperText";
import {ModalDialog} from "../ModalDialog";
import {
  IconStatus,
  EIconStatus,
} from "../IconStatus";
import {useConfirm} from "../useConfirm";
import {useLocalStorageState} from "../useLocalStorageState";
import {useClipboardPaste} from "../useClipboardPaste";

import {EGeoCoordinatesEditorSize} from "./interfaces";
import {InputCoordinate} from "./components/InputCoordinate";
import {getGeoPositionsByStrings} from "./utils/getGeoPositionsByStrings";

import {createIcon} from "../IconComponent";
import CoordinatesEditorIcon from "@mui/icons-material/EditLocation";
import MuiIconElevation from '@mui/icons-material/FilterHdr';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import PasteCoordinatesIcon from '@mui/icons-material/ContentPasteGo';
import OkIcon from '@mui/icons-material/Done';

const ElevationIcon = createIcon.byMuiIcon(MuiIconElevation);

export interface IInputCoordinatesProps {
  label?: string;
  variant?: ELabelContainerVariant;
  size?: EGeoCoordinatesEditorSize;
  readOnly?: boolean;
  coordinates: IGeoPosition[];
  maxCoordinates?: number;
  footer?: ReactElement;
  onElevationResolve?: (position: IGeoPosition) => Promise<number>;   // The elevation is expected to be in meters (not in feet)
  onFocus?: (coordinateIndex: number) => void;
  onChange: (positions: IGeoPosition[]) => void;
}

/**
 * Input Coordinates. Needs height from the parent.
 */
export const InputCoordinates: React.FC<IInputCoordinatesProps> = (
  {
    label = "Coordinates editor",
    variant = ELabelContainerVariant.OUTLINED,
    size = EGeoCoordinatesEditorSize.MEDIUM,
    readOnly = false,
    coordinates,
    maxCoordinates,
    footer = null,
    onElevationResolve,
    onFocus,
    onChange,
  },
) => {
  const {
    confirm,
    confirmViewer,
  } = useConfirm();
  const [showAlt, setShowAlt] = useLocalStorageState<boolean>({
    key: 'CoordinatesEditor-component-showAlt',
    default: false,
  });
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);
  const {
    hasClipboard,
    clipboardText,
  } = useClipboardPaste();
  const [
    showPasteCoordinatesReport,
    setShowPasteCoordinatesReport,
  ] =
    useState<
      | {
      iconStatus: EIconStatus;
      title: string;
      message: string | ReactElement;
      errors: ReactElement | null;
    }
      | null
    >
    (null);

  const handleFocus = (index: number): void => {
    if (focusedIndex === index) return;
    setFocusedIndex(index);
    onFocus && onFocus(index);
  };
  const handleAddFirstCoordinate = (): void => {
    onChange([{
      lat: 0,
      lng: 0,
    }]);
  };

  const handleCleanAllCoordinates = async (): Promise<void> => {
    const deleteConfirmation = await confirm({
      title: 'Delete confirmation',
      message: `Are you sure you want to delete ${pluralizedCount({
        this: true,
        unit: 'coordinate',
        items: coordinates,
      })}?`,
    });
    if (deleteConfirmation) onChange([]);
  };

  const handlePasteCoordinatesClick = (): void => {
    const parseCoordinates = getGeoPositionsByStrings(clipboardText.split('\n'));

    if (!!maxCoordinates && coordinates.length + parseCoordinates.coordinates.length > maxCoordinates) {
      setShowPasteCoordinatesReport({
        iconStatus: EIconStatus.ERROR,
        title: 'Paste coordinates',
        message: `The coordinates detected in the clipboard (${parseCoordinates.coordinates.length}) along with the existing ones (${coordinates.length}) exceed the maximum coordinates limitation of ${maxCoordinates}. Please remove some and try again.`,
        errors: null,
      });
      return;
    }

    onChange([
      ...coordinates,
      ...parseCoordinates.coordinates,
    ]);
    setShowPasteCoordinatesReport({
      iconStatus: (() => {
        if (parseCoordinates.coordinates.length) {
          return parseCoordinates.errors.length
            ? EIconStatus.WARNING
            : EIconStatus.SUCCESS;
        }
        else {
          return EIconStatus.ERROR;
        }
      })(),
      title: 'Paste coordinates',
      message:
        parseCoordinates.errors.length
          ? (
            <>
              <Condition
                if={!!parseCoordinates.coordinates.length}
                then={
                  <>
                    {pluralizedCount({
                      unit: 'coordinate',
                      items: parseCoordinates.coordinates,
                    })}
                    {" "}
                    added
                  </>}
                else={"No coordinated added"}
              />
              <Condition if={!!parseCoordinates.errors.length}>
                , and there
                {" "}
                {pluralizedCount({
                  isAtFront: true,
                  unit: 'error',
                  items: parseCoordinates.errors,
                })}!
              </Condition>
            </>
          )
          : `Success, ${pluralizedCount({
            unit: 'coordinate',
            items: parseCoordinates.coordinates,
          })} added`,
      errors: (
        <Box show={!!parseCoordinates.errors.length}>
          <b>Errors:</b>
          <ul>
            {parseCoordinates.errors.map((error, index) => (
              <li key={index}>
                {error}
              </li>
            ))}
          </ul>
        </Box>
      ),
    });
  };
  const handleModalOkClick = (): void => setShowPasteCoordinatesReport(null);

  const handleDelete = async (index: number): Promise<void> => {
    const deleteConfirmation = await confirm({
      title: 'Delete confirmation',
      message: 'Are you sure you want to delete this coordinate?',
      children: (
        <>
          <br/>
          <br/>
          <b>Coordinate no: {index + 1}</b>
          <br/>
          <br/>
          <span><b>LAT:</b> {coordinates[index].lat}</span><br/>
          <span><b>LNG:</b> {coordinates[index].lng}</span><br/>
          <span><b>ELEV:</b> {coordinates[index].alt}</span><br/>
        </>
      ),
      labelConfirmIcon: createIcon.byMuiIcon(DeleteIcon),
      labelConfirmButton: 'Delete it',
    });
    if (deleteConfirmation) onChange(arrayRemove(coordinates, index));
  };
  const handleInsert = (index: number): void => {
    onChange(
      arrayInsert(
        coordinates,
        index,
        {...coordinates[index]},
      ),
    );
  };
  const handleChange = (index: number, position: IGeoPosition): void => {
    const newPositions = [...coordinates];
    newPositions[index] = position;
    onChange(newPositions);
  };

  const disableAdd = !!maxCoordinates && coordinates.length === maxCoordinates;

  return (
    <LabelContainer
      sx={{background: theme => theme.palette.background.default}}
      dataComponentName="InputCoordinates"
      Icon={createIcon.byMuiIcon(CoordinatesEditorIcon)}
      label={label}
      variant={variant}
      fullHeight
      overFlowY
      labelRightContent={
        <Box show={!!coordinates.length}>
          <InputSwitch
            ariaLabel="Show elevation field"
            switchPosition={EInputSwitchPosition.RIGHT}
            Icon={ElevationIcon}
            label="Elevation"
            value={showAlt}
            onChange={setShowAlt}
          />
        </Box>
      }
      helperContent={
        <>
          <HelperText
            sx={{py: theme => theme.spacing(0.5)}}
            Icon={createIcon.byMuiIcon(InfoIcon)}
          >
            The LAT/LNG decimal places can be up to 14, with all others being disregarded.
          </HelperText>
          {footer}
        </>
      }
    >
      <Box
        sx={{overflowY: 'auto'}}
        fullHeight
        dataComponentName="InputCoordinates-scrollableContent"
      >
        {coordinates.map((position, index) => (
          <InputCoordinate
            key={index}
            size={size}
            readOnly={readOnly}
            disableAdd={disableAdd}
            showAlt={showAlt}
            focused={focusedIndex === index}
            coordinate={position}
            onElevationResolve={onElevationResolve}
            onFocus={() => handleFocus(index)}
            onInsert={() => handleInsert(index)}
            onDelete={() => handleDelete(index)}
            onChange={position => handleChange(index, position)}
          />
        ))}
        <ButtonBar
          show={!readOnly}
          noHorizontalSpace
        >
          <Button
            Icon={createIcon.byMuiIcon(AddCircleIcon)}
            show={!disableAdd && !coordinates.length}
            size={EButtonSize.SMALL}
            onClick={handleAddFirstCoordinate}
          >
            Add the 1st coordinate
          </Button>
          <Button
            Icon={createIcon.byMuiIcon(PasteCoordinatesIcon)}
            disabled={!hasClipboard || disableAdd}
            size={EButtonSize.SMALL}
            onClick={handlePasteCoordinatesClick}
          >
            Paste coordinates
          </Button>
          <Button
            Icon={createIcon.byMuiIcon(DeleteIcon)}
            show={!!coordinates.length}
            size={EButtonSize.SMALL}
            color={EButtonColor.ERROR}
            onClick={handleCleanAllCoordinates}
          >
            Delete all
          </Button>
        </ButtonBar>
      </Box>
      <Condition if={!!showPasteCoordinatesReport}>
        <ModalDialog
          show
          title={showPasteCoordinatesReport?.title || ""}
          buttons={[
            {
              Icon: createIcon.byMuiIcon(OkIcon),
              label: 'Ok',
              onClick: handleModalOkClick,
            },
          ]}
        >
          <IconStatus
            status={showPasteCoordinatesReport?.iconStatus || EIconStatus.HELP}
            sizePx={64}
            delayAnimationStartMs={150}
            message={showPasteCoordinatesReport?.message || "Internal error 20230920100524"}
          />
          {showPasteCoordinatesReport?.errors}
        </ModalDialog>
      </Condition>
      {confirmViewer}
    </LabelContainer>
  );
};
