// Help for leaflet general: https://leafletjs.com/
// Help for react-leaflet general: https://react-leaflet.js.org/
// Help for react-leaflet Child components (map elements): https://react-leaflet.js.org/docs/api-components/
// Help for offline mode: https://walkingtree.tech/play-with-maps-in-react-online-and-offline-using-leaflet-libraries/
// API of the offline library: https://www.npmjs.com/package/leaflet-offline
// Wanna group markers? Not implemented. https://www.npmjs.com/package/react-leaflet-markercluster

import * as React from "react";
import {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
} from "react";

import {dynaTry} from "dyna-try";

import {
  useResizeEvent,
  IResizeEventArgs,
} from "../useResizeEvent";

import {dynaSwitchEnum} from "dyna-switch";
import {DynaLocalStorageData} from "dyna-local-storage";

import {
  Map,
  latLngBounds,
} from "leaflet";
import 'leaflet/dist/leaflet.css';

import {
  MapContainer,
  TileLayer,
  Marker,
  ZoomControl,
  useMapEvents,
} from "react-leaflet";

// Google maps layer
import ReactLeafletGoogleLayer from "react-leaflet-google-layer"; // Dev note: Upgrading to v2.2.0 has broken types (in combination of react-leaflet's version)
import {
  leafletOffline,
  ILeafletOfflineApi,
  THandleSaveMaps,
  THandleDeleteMaps,
} from "./offline";

import {IGeoPosition} from "utils-library/dist/commonJs/geo";
import {BrowserGeolocation} from "utils-library/dist/commonJs/web";

import {EMapType} from "../geoInterfaces";

import {Box} from "../Box";
import {IsLoading} from "../IsLoading";
import {
  LoadExternalDependencies,
  EDependencyType,
} from "../LoadExternalDependencies";
import {useIsMounted} from "../useIsMounted";

import {MapButton} from "./components/ui-components/MapButton";
import {MapTypeButton} from "./components/ui-components/MapTypeButton";
import {FullScreenButton} from "./components/ui-components/FullScreenButton";

import {IMapPopUpProps} from "./components/map-components/MapMarker/components/MapPopUp";
import {
  MapMarker,
  IMapMarkerProps,
  IMapMarkerRef,
} from "./components/map-components/MapMarker";
import {
  MapCircle,
  IMapCircleProps,
} from "./components/map-components/MapCircle";
import {
  MapPolyline,
  IMapPolylineProps,
} from "./components/map-components/MapPolyline";
import {
  MapComponent,
  IMapComponentProps,
} from "./components/map-components/MapComponent";
import {
  MapToolWindow,
  IMapToolWindowProps,
} from "./components/map-components/MapToolWindow";
import {
  MapDirectionLine,
  IMapDirectionLineProps,
} from "./components/map-components/MapDirectionLine";
import {
  colorMarker,
  ELeafletMarkerColor,
} from "./markers/colorMarker";
import {
  HeadingSelector,
  IHeadingSelectorProps,
} from "./components/map-components/MapHeadingSelector";

import {getRadiusByPositionAndScreenSize} from "./utils/getRadiusByPositionAndScreenSize";

import "./GeoMapLeafletCore.less";
import {
  SxProps,
  useTheme,
  Theme,
} from "../ThemeProvider";
import CenterCurrentLocationIcon from "@mui/icons-material/FilterCenterFocus";
import CenterMarkersIcon from '@mui/icons-material/CenterFocusWeak';
import CenterItemsIcon from '@mui/icons-material/CenterFocusStrong';

export type IPopUp = IMapPopUpProps;
export type IGeoMapLeafletMarker = IMapMarkerProps;
export type IGeoMapLeafletPolyline = IMapPolylineProps;
export type IGeoMapDirectionLine = IMapDirectionLineProps;
export type IGeoMapLeafletCircle = IMapCircleProps;
export type IGeoMapComponent = IMapComponentProps;
export type IGeoMapToolWindow = IMapToolWindowProps;
export type IGeoMapLeafletHeadingSelector = Omit<IHeadingSelectorProps, "readOnly">; // Readonly is needed but is derived from core props

export interface IGeoMapLeafletCoreProps {
  sx?: SxProps<Theme>;
  dataComponentName?: string;
  id: string;
  dataAttributes?: Record<string, string>;

  ref?: React.RefObject<IGeoMapLeafletCoreRef>;

  darkThemeActive?: boolean;            // Default is false. Set it to true to for MUI's dark theme
  defaultTravelDuration?: number;       // Default is 0 (no animation). Animation duration for traveling (changing the map's center geo position).

  delayStart?: number;                  // Temp fix for the MHC-00425 Frozen map on parent's render

  readOnly?: boolean;
  disabled?: boolean;
  rememberLastUserPosition?: boolean;   // Default is true

  googleMapApiKey?: string;       // Needed for all maps except the roadmap

  defaultMapType?: EMapType;      // Default is EMapType.ROADMAP
  availableMapTypes?: EMapType[]; // Default is [EMapType.ROADMAP]

  defaultZoom?: number;
  minZoom?: number;               // Default is 1
  maxZoom?: number;               // Default is 18. Max is 21, limited by Google Maps (Satellite & Hybrid)
  gestureZoom?: boolean;          // Default is false. This is also for the wheel mouse zoom

  defaultCenter?: IGeoPosition;

  centerButton?: boolean;         // Default is true, center to current location (geo got from browser).
  centerMarkersButton?: boolean;  // Default is false, centers to markers only.
  centerAllItemsButton?: boolean; // Default is false, centers to all items including markers.
  fullScreenButton?: boolean;     // Default is false. Shows a fullscreen button. (Does not actually make it fullscreen, but adjusts to the browser window)

  // Geo Shapes
  /**
   * Markers are the fundamental method of rendering elements on the map, whether they are colored markers or custom icons represented as JSX.Elements.
   * However, it's important to note that while custom icons, being JSX.Elements, can be updated at runtime, they **cannot** receive DOM events.
   * For interactive marker icons that require event handling, you can utilize the mapElements instead.
   * Note: If a marker is replaced in the same position with a different one, the markerId SHOULD BE different. Otherwise, we can get the React warning: Warning: React has detected a change in the order of Hooks called by ForwardRef.
   */
  markers?: IGeoMapLeafletMarker[];
  polylines?: IGeoMapLeafletPolyline[];
  directionLines?: IGeoMapDirectionLine[];
  circles?: IGeoMapLeafletCircle[];
  /**
   * Map components are similar to markers, but their components have interactivity. Regular markers, on the other hand, can have custom icons as JSX.Elements but cannot receive DOM events.
   * Since the interactive elements are movable, you need to utilize the ContainerActualClick container/wrapper to capture actual click events.
   */
  mapComponents?: IGeoMapComponent[];
  toolWindows?: IGeoMapToolWindow[];
  headingSelector?: IGeoMapLeafletHeadingSelector;
  // Why not just `children` here?
  //   In the future, we plan to implement virtualization and clustering of geo shapes in the map.
  //   When working with objects, it is much more performance-wise efficient to work with JSX.Elements! _Much less renders_.
  //   The connection between the children can be established in a cleaner and faster manner.

  showOfflineSaveDeleteButtons?: boolean;   // Default is false

  rightToolbar?: JSX.Element;     // To add buttons. Use the MapButton or a variation of it
  mapOverlay?: JSX.Element;       // Render anything above the map (with absolute position)
  alerts?: JSX.Element;           // Render anything as Alerts (usually <Alert>)

  onOfflineSaveMaps?: THandleSaveMaps;
  onOfflineDeleteMaps?: THandleDeleteMaps;

  onClick?: (geoPosition: IGeoPosition) => void;
  onFullScreenClick?: (
    event: IMapFullScreenEvent,
  ) => void;
  onCenterRadiusDistanceChange?: (radiusInMeters: IMapViewState) => void; // Called when the center radius in meters changes
  onMapViewStateChange?: (mapViewState: IMapViewState) => void;           // Called when anything changes except the radius in meters
}

export interface IGeoMapLeafletCoreRef {
  refresh: () => void;
  zoom: number;
  mapViewState: IMapViewState;
  center: (zoom: number, travelDuration?: number) => Promise<void>;
  centerAllMarkers: (travelDuration?: number) => Promise<void>;
  centerAllItems: (travelDuration?: number) => Promise<void>;   // Including markers
  centerToPosition: (position: IGeoPosition, zoom: number, travelDuration?: number) => Promise<void>;
  openMarkerPopUp: (markerId: string, travelDuration?: number) => Promise<void>;
  closeMarkerPopUp: () => void;
}

export interface IMapFullScreenEvent {
  fullScreen: boolean;
  preventDefault: () => void;
  refreshMap: () => void;
}

export interface IMapViewState {
  position: IGeoPosition;
  zoom: number;
  type: EMapType;
  centerRadiusInMeters: number; // The distance from the current map's center to the edges of the user's viewport in meters.
  // Are you going to change this interface? You have to change the LS_KEY as well!
  // Otherwise, the loaded outdated from localStorage map view state would break the app!
}

const DEFAULT_MAP_VIEW_STATE: IMapViewState = {
  type: EMapType.ROADMAP,
  position: {
    lng: 16.315104299999998,
    lat: 48.197407,
    alt: 0,
  },
  centerRadiusInMeters: -1,
  zoom: 13,
};

const LS_KEY = "GeoMapLeaflet-id-v202206081021";

const OFFLINE_MIN_ZOOM_TO_SAVE = 14;

export const GeoMapLeafletCore = memo(React.forwardRef<IGeoMapLeafletCoreRef, IGeoMapLeafletCoreProps>((props, ref): JSX.Element => {
  const {
    sx = {},
    dataComponentName,
    id,
    dataAttributes,
    darkThemeActive = false,
    defaultTravelDuration = 0,

    delayStart = 0,

    readOnly = false,
    disabled = false,
    rememberLastUserPosition = true,

    googleMapApiKey,

    defaultMapType,
    availableMapTypes = [EMapType.ROADMAP],

    defaultZoom,
    minZoom = 1,
    maxZoom = 21,

    defaultCenter,

    markers = [],
    polylines = [],
    directionLines = [],
    circles = [],
    mapComponents = [],
    toolWindows = [],
    headingSelector,

    centerButton = true,
    centerMarkersButton = false,
    centerAllItemsButton = false,
    gestureZoom = false,

    fullScreenButton = false,

    showOfflineSaveDeleteButtons = false,

    rightToolbar = null,
    mapOverlay,
    alerts,

    onOfflineSaveMaps = () => console.error('Dev error: onOfflineSaveMaps is not provided'),
    onOfflineDeleteMaps = () => console.error('Dev error: onOfflineDeleteMaps is not provided'),

    onClick,
    onFullScreenClick,
    onCenterRadiusDistanceChange,
    onMapViewStateChange,
  } = props;
  const theme = useTheme();

  const getIsMounted = useIsMounted();

  const refMarkers = useRef<{ [markerId: string]: IMapMarkerRef }>({});

  const [leafletMap, setLeafletMap] = useState<Map | undefined>();
  const [leafletOfflineApi, setLeafletOfflineApi] = useState<ILeafletOfflineApi | undefined>();

  const closeMarkerPopUp = (): void =>
    Object.values(refMarkers.current)
      .forEach(marker => {
        if (!marker) return;
        if (marker.isPopUpOpen) marker.closePopUp();
      });

  useImperativeHandle(ref, () => ({
    refresh,
    center: centerMap,
    get zoom(): number {
      return ls.data.zoom;
    },
    get mapViewState() {
      return ls.data;
    },
    centerAllMarkers,
    centerAllItems,
    centerToPosition: async (geoPosition, zoom, travelDuration) => {
      closeMarkerPopUp();
      await setCenter({
        geoPosition,
        zoom,
        travelDuration,
      });
    },
    openMarkerPopUp: async (markerId, travelDuration = defaultTravelDuration) => {
      const openMarkerId = Object.keys(refMarkers.current).find(markerId => refMarkers.current[markerId]?.isPopUpOpen);
      if (markerId === openMarkerId) return; // Exit, the marker is already opened

      const marker = markers.find(marker => marker.markerId === markerId);
      const refMarker = refMarkers.current[markerId];
      if (!marker) return;
      if (!refMarker) return;

      closeMarkerPopUp();
      await setCenter({
        geoPosition: marker.position,
        zoom: 15,
        travelDuration,
      });
      refMarker.openPopUp();
    },
    closeMarkerPopUp: () => {
      closeMarkerPopUp();
    },
  }));

  const getCenterRadiusInMeters =
    (_ls: typeof ls): number =>
      getRadiusByPositionAndScreenSize({
        position: _ls.data.position,
        zoom: _ls.data.zoom,
        mapComponentSize: {
          width: leafletMap?.getSize().x || -1,
          height: leafletMap?.getSize().y || -1,
        },
      });

  const updateTriggerRadiusInMeters = (): void => {
    ls.data.centerRadiusInMeters = getCenterRadiusInMeters(ls);
    onCenterRadiusDistanceChange?.(ls.data);
  };

  // Initialize local storage
  const ls = useMemo(() => {
    const ls = new DynaLocalStorageData<IMapViewState>(`${LS_KEY}---${id}`);
    ls.data = {
      ...DEFAULT_MAP_VIEW_STATE,
      ...ls.data,
      centerRadiusInMeters: -1,
    };
    ls.data.centerRadiusInMeters = getCenterRadiusInMeters(ls);
    if (rememberLastUserPosition) ls.save();
    return ls;
  }, [leafletMap]);


  const initialCenter = useMemo(() => ls.data.position, [leafletMap]);
  const initialZoom = useMemo(() => ls.data.zoom, [leafletMap]);

  // Todo: Once the MHC-00277 is fixed, delete the following line and uncomment the very next one.
  const [mapType, setMapType] = useState<EMapType>(defaultMapType || EMapType.ROADMAP);
  const [isCentering, setIsCentering] = useState(false);
  const [centerPosition, setCenterPosition] = useState<IGeoPosition | null>(null);
  const [fullScreen, setFullScreen] = useState(false);

  const handleFullScreenClick = (fullScreen: boolean): void => {
    let prevent = false;
    onFullScreenClick?.({
      fullScreen,
      preventDefault: () => prevent = true,
      refreshMap: () => leafletMap?.invalidateSize(),
    });
    if (!prevent) setFullScreen(fullScreen);
  };

  // Check for duplicate marketIds and console.error them
  useEffect(() => {
    const duplicatedDic: { [markerId: string]: number } = {};
    markers.forEach(marker => {
      if (!duplicatedDic[marker.markerId]) duplicatedDic[marker.markerId] = 0;
      duplicatedDic[marker.markerId]++;
    });
    Object.entries(duplicatedDic)
      .map(([markerId, count]) => ({
        markerId,
        count,
      }))
      .filter(item => item.count > 1)
      .forEach(item => console.error(`GeoMapLeaflet: The markerId: [${item.markerId}] is duplicated ${item.count} times`));
  }, [markers.map(marker => marker.markerId).join()]);

  // MHC-00308 Frozen map after Validate Broadcast
  // MHC-00250 Bugfix: Show the map in a tick later
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (getIsMounted()) setShow(true);
    }, delayStart);
  }, []);

  // Validate the google map key
  useEffect(() => {
    if (mapType !== EMapType.ROADMAP && !googleMapApiKey) {
      console.error(`Map type: ${mapType} requires googleMapApiKey!`);
    }
  }, [mapType, googleMapApiKey]);

  // Start - resolve the center & zoom
  useEffect(() => {
    if (!leafletMap) return;

    const zoom = defaultZoom || ls.data.zoom;
    if (
      defaultCenter
      && defaultCenter.lat !== 0
      && defaultCenter.lng !== 0
      && defaultCenter.lat !== -1
      && defaultCenter.lng !== -1
    ) {
      setCenter({
        geoPosition: defaultCenter,
        zoom,
        travelDuration: 0,
        savePosition: false,
      });
      return;
    }

    const elementsPositions = getAllItemPositions();

    const middleMarkerPosition: false | IGeoPosition = (
      !!elementsPositions.length
      && elementsPositions
        .concat()
        .sort((pA, pB) => pA.lng - pB.lng)[Math.floor(elementsPositions.length / 2)]
    );
    if (middleMarkerPosition) {
      setCenter({
        geoPosition: middleMarkerPosition,
        zoom,
        savePosition: false,
      });
      return;
    }

    if (
      initialCenter.lat !== DEFAULT_MAP_VIEW_STATE.position.lat &&
      initialCenter.lng !== DEFAULT_MAP_VIEW_STATE.position.lng
    ) {
      setCenter({
        geoPosition: initialCenter,
        zoom,
        savePosition: false,
      });
      return;
    }

    const browserGeolocation = new BrowserGeolocation();
    browserGeolocation.getGeoPosition()
      .then(geoPosition => {
        setCenter({
          geoPosition,
          zoom,
        });
      })
      .catch(error => {
        console.error('GeoMapLeaflet cannot get the geolocation from browser', error);
      });

  }, [leafletMap]);

  // Refresh the tiles on start. They might be missing if, on the initial render, the size of it was smaller.
  // MHC-00613 https://trello.com/c/9rjwaUIJ
  useEffect(() => {
    if (!leafletMap) return;
    setTimeout(() => {
      if (getIsMounted()) leafletMap.invalidateSize();
    }, 100);
    setTimeout(() => {
      if (getIsMounted()) leafletMap.invalidateSize();
    }, 200);
  }, [leafletMap]);

  // Load the Offline layer/feature
  useEffect(() => {
    if (!leafletMap) return;
    setLeafletOfflineApi(
      leafletOffline({
        leafletMap,
        showOfflineSaveDeleteButtons,
        onSaveOfflineMaps: onOfflineSaveMaps,
        onDeleteOfflineMaps: onOfflineDeleteMaps,
      }),
    );
  }, [leafletMap]);

  // Trigger initially some events
  useEffect(() => {
    onCenterRadiusDistanceChange?.(ls.data);
    onMapViewStateChange?.(ls.data);
  }, []);

  // On parent's resize, invalidateSize to add missing tiles
  const handleContainerResize = useCallback(({diffPercentage}: IResizeEventArgs) => {
    updateTriggerRadiusInMeters();
    if (diffPercentage < 10) return;
    leafletMap?.invalidateSize();
  }, [leafletMap]);

  const {ref: refContainer} = useResizeEvent({
    refreshRate: 500,
    leading: false,
    onResize: handleContainerResize,
  });

  // Render useMapEvents under <MapContainer>, to get the map reference and to handle it's events.
  const MapEvents = (): null => {
    const _leafletMap = useMapEvents({
      click(event) {
        if (readOnly) return;
        onClick?.({
          lat: event.latlng.lat,
          lng: event.latlng.lng,
          alt: event.latlng.alt || 0,
        });
      },
      dragend() {
        ls.data.position = _leafletMap.getBounds().getCenter();
        updateTriggerRadiusInMeters();
        onMapViewStateChange?.(ls.data);
        if (rememberLastUserPosition) ls.save();
      },
      zoomend() {
        ls.data.zoom = _leafletMap.getZoom();
        updateTriggerRadiusInMeters();
        onMapViewStateChange?.(ls.data);
        if (!readOnly) leafletOfflineApi?.enableSaveButton(ls.data.zoom >= OFFLINE_MIN_ZOOM_TO_SAVE);
        if (rememberLastUserPosition) ls.save();
      },
    });
    leafletOfflineApi?.enableSaveButton(_leafletMap.getZoom() >= OFFLINE_MIN_ZOOM_TO_SAVE);
    setTimeout(() => {
      // In timeout since at this point we render.
      if (getIsMounted()) setLeafletMap(_leafletMap);
    }, 10);
    return null;
  };

  const refresh = (timeout = 1000): Promise<void> => {
    return new Promise(resolve => {
      setShow(false);
      setTimeout(() => {
        if (!getIsMounted()) return;
        setShow(true);
        resolve();
      }, timeout);
    });
  };

  const setCenter = async (
    {
      geoPosition,
      zoom,
      travelDuration = defaultTravelDuration,
      easeLinearity,
      savePosition = true,
    }: {
      geoPosition: IGeoPosition;
      zoom: number;
      travelDuration?: number;
      easeLinearity?: number;
      savePosition?: boolean;
    },
  ): Promise<void> => {
    if (leafletMap) {
      leafletMap.setView(
        geoPosition,
        zoom,
        {
          animate: !!travelDuration,
          duration: travelDuration / 1000,
          easeLinearity,
        },
      );
    }
    else {
      console.error('Internal dev error: GeoMapLeaflet: setCenter(): leafletMap reference is undefined. You should use the setCenter() after the 1st render.');
    }
    if (savePosition) {
      ls.data.position = geoPosition;
      ls.data.zoom = zoom;
      updateTriggerRadiusInMeters();
      onMapViewStateChange?.(ls.data);
      ls.save();
    }
    await new Promise(r => setTimeout(r, travelDuration));
  };

  const centerMap = async (zoom: number = 17, travelDuration: number = defaultTravelDuration): Promise<void> => {
    return dynaTry({
      timeout: 5000,
      try: async () => {
        setIsCentering(true);
        const browserGeolocation = new BrowserGeolocation({enableHighAccuracy: true});
        const geoPosition = await browserGeolocation.getGeoPosition();
        setCenter({
          geoPosition,
          zoom,
          travelDuration,
        });
        setCenterPosition(geoPosition);
      },
    })
      .catch((e: any) => {
        alert('Cannot center. Check if the location access is turned off for this app.');
        console.error('Cannot center', e);
      })
      .finally(() => {
        setIsCentering(false);
      });
  };

  const centerAllMarkers = async (travelDuration: number = defaultTravelDuration): Promise<void> => {
    return centerToPositions(travelDuration, markers.map(marker => marker.position));
  };

  const centerAllItems = async (travelDuration: number = defaultTravelDuration): Promise<void> => {
    return centerToPositions(travelDuration, getAllItemPositions());
  };

  const centerToPositions = async (travelDuration: number = defaultTravelDuration, positions: IGeoPosition[]): Promise<void> => {
    if (!positions.length) return;  // Exit, nothing to center
    if (!leafletMap) return;        // 4TS
    try {
      setIsCentering(true);
      const bounds = latLngBounds([]);
      positions.forEach(position => {
        bounds.extend([position.lat, position.lng]);
      });
      if (!bounds.isValid()) {
        console.error('Internal error: GeoMapLeafletCore: centerToPositions: Built bounds are invalid', {bounds});
        return;
      }
      travelDuration
        ? leafletMap.flyToBounds(
          bounds,
          {
            animate: true,
            duration: travelDuration / 1000,
          },
        )
        : leafletMap.fitBounds(bounds);
      ls.data = {
        ...ls.data,
        position: {
          ...ls.data.position,
          lat: bounds.getCenter().lat,
          lng: bounds.getCenter().lng,
        },
      };
      if (rememberLastUserPosition) ls.save();
      await new Promise(r => setTimeout(r, travelDuration));
      updateTriggerRadiusInMeters();
      onMapViewStateChange?.(ls.data);
    }
    catch (e) {
      console.error('Cannot center around markers', e);
    }
    finally {
      setIsCentering(false);
    }
  };

  const getAllItemPositions = (): IGeoPosition[] => [
    ...markers.map(marker => marker.position),
    ...polylines.reduce((acc: IGeoPosition[], polyline) => acc.concat(polyline.points), []),
    ...directionLines.reduce((acc: IGeoPosition[], polyline) => acc.concat(polyline.points), []),
    ...circles.reduce((acc: IGeoPosition[], circle) => acc.concat(circle.center), []),
    ...mapComponents.reduce((acc: IGeoPosition[], mapComponent) => acc.concat(mapComponent.position), []),
    ...toolWindows.reduce((acc: IGeoPosition[], toolWindow) => acc.concat(toolWindow.ownerPosition), []),
  ];

  const handleMapTypeChange = (mapType: EMapType): void => {
    if (!navigator.onLine && mapType !== EMapType.ROADMAP) {
      setMapType(EMapType.ROADMAP);
      alert('No internet connection, only the Roadmap view is available.');
    }
    else {
      setMapType(mapType);
      ls.data.type = mapType;
      ls.save();
      updateTriggerRadiusInMeters();
      onMapViewStateChange?.(ls.data);

      // Bugfix for Google map, doesn't refresh when zoom in is high.
      if (
        (mapType === EMapType.SATELLITE || mapType === EMapType.HYBRID)   // If the new map type is one of these
        && leafletMap && leafletMap.getZoom() > 20                        // And if the zoom was above this
      ) {
        leafletMap.setZoom(20);                                     // Zoom out to refresh the Google map
      }
    }
  };

  const isDarkTheme: boolean = darkThemeActive && theme.palette.mode === 'dark';

  const sxRoot: SxProps = {
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    zIndex: 0,
    '& .leaflet-container': {
      height: '100%',
      backgroundColor: isDarkTheme ? '#222222' : undefined,
    },
    ...(fullScreen
      ? {
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 200,
      }
      : {}),
    ...(disabled
      ? {
        pointerEvents: "none",
        opacity: 0.8,
      }
      : {}),
    ...sx,
  };

  if (!show) {
    return (
      <IsLoading
        sx={sxRoot}
        isLoading
      />
    );
  }

  const handleCenterMap = () => centerMap();

  const handleCenterToAllItems = () => centerAllItems();

  return (
    <LoadExternalDependencies
      fullHeightProgress
      dependencies={[
        {
          // Dev note: This LoadExternalDependencies is trying to avoid the console warning:
          // "Google Maps JavaScript API has been loaded directly without loading=async. This can result in suboptimal performance. For best-practice loading patterns please see https://goo.gle/js-api-loading"
          // But we still get this for some reason.
          // Ideally, this LoadExternalDependencies would be removed completely along with the useGoogMapsLoader props below too.
          type: EDependencyType.SCRIPT,
          src: `https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}`,
        },
      ]}
    >
      <Box
        key={`map-dark-theme--${isDarkTheme.toString()}`}
        sx={sxRoot}
        dataComponentName={["GeoMapLeafletCore", dataComponentName]}
        dataAttributes={dataAttributes}
        ref={refContainer as any}
      >
        <MapContainer
          center={initialCenter}
          zoomControl={false}
          zoom={initialZoom}
          scrollWheelZoom={gestureZoom}
        >

          <ZoomControl
            position="bottomright"
          />

          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url={
              isDarkTheme
                ? 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png'
                : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            }
            minZoom={minZoom}
            maxZoom={maxZoom}
            maxNativeZoom={19}  // Max zoom supported by openstreetmap.org
          />

          {mapType !== EMapType.ROADMAP && (
            <ReactLeafletGoogleLayer
              key={mapType}
              apiKey={googleMapApiKey}
              useGoogMapsLoader={false} // Important: set to false
              type={
                dynaSwitchEnum<EMapType, 'roadmap' | 'satellite' | 'terrain' | 'hybrid'>(
                  mapType,
                  {
                    [EMapType.ROADMAP]: "roadmap",
                    [EMapType.SATELLITE]: "satellite",
                    [EMapType.TERRAIN]: "terrain",
                    [EMapType.HYBRID]: "hybrid",
                  },
                )
              }
              googleMapsAddLayers={[
                {name: 'TrafficLayer'},
                {name: 'BicyclingLayer'},
                {name: 'TransitLayer'},
              ]}
            />
          )}

          {markers.map(marker => (
            <MapMarker
              // @ts-ignore
              ref={(m: IMapMarkerRef) => refMarkers.current[marker.markerId] = m}
              key={marker.markerId}
              {...marker}
            />
          ))}
          {polylines.map((polyline, index) => (
            <MapPolyline
              key={index}
              {...polyline}
            />
          ))}
          {directionLines.map(directionLine => (
            <MapDirectionLine
              key={directionLine.directionLineId}
              {...directionLine}
            />
          ))}
          {circles.map((circle, index) => (
            <MapCircle
              key={index}
              {...circle}
            />
          ))}
          {mapComponents.map((mapComponent) => (
            <MapComponent
              key={mapComponent.componentId}
              {...mapComponent}
            />
          ))}
          {toolWindows.map((toolWindow) => (
            <MapToolWindow
              key={toolWindow.windowId}
              {...toolWindow}
            />
          ))}

          {!!centerPosition && (
            <Marker
              position={centerPosition}
              icon={colorMarker(ELeafletMarkerColor.GREY)}
            />
          )}

          {!!headingSelector && (
            <HeadingSelector
              position={headingSelector.position}
              headings={headingSelector.headings}
              circleRadius={headingSelector.circleRadius}
              readOnly={readOnly}
              onSliceClick={headingSelector.onSliceClick}
              onSelectDeselectClick={headingSelector.onSelectDeselectClick}
            />
          )}
          <MapEvents/>

        </MapContainer>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: 12,
            right: 12,
            zIndex: 1100,
            '& > *': {
              display: 'block',
              marginBottom: theme.spacing(1),
            },
          }}
        >
          {fullScreenButton && (
            <FullScreenButton
              fullScreen={fullScreen}
              onChange={handleFullScreenClick}
            />
          )}
          {centerButton && (
            <MapButton
              title="Go to current location"
              icon={<CenterCurrentLocationIcon/>}
              isLoading={isCentering}
              onClick={handleCenterMap}
            />
          )}
          {centerMarkersButton && (
            <MapButton
              title="Center to markers"
              icon={<CenterMarkersIcon/>}
              isLoading={isCentering}
              onClick={handleCenterToAllItems}
            />
          )}
          {centerAllItemsButton && (
            <MapButton
              title="Center to items"
              icon={<CenterItemsIcon/>}
              isLoading={isCentering}
              onClick={handleCenterToAllItems}
            />
          )}
          {(availableMapTypes.length > 1 && (
            <MapTypeButton
              availableMapTypes={availableMapTypes}
              mapType={mapType}
              onClick={handleMapTypeChange}
            />
          ))}
          {rightToolbar}
        </Box>

        {mapOverlay}
        <Box
          dataComponentName="MapAlertsContainer"
          sx={{
            position: "absolute",
            left: '8px',
            right: '64px',
            bottom: '20px',
            zIndex: 2000,
          }}
        >
          {alerts}
        </Box>
      </Box>
    </LoadExternalDependencies>
  );
}));
