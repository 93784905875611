import {convertDictionaryToViewLabelArray} from "utils-library/dist/commonJs/utils";

export enum ETrafficStudiesRights {
  READ = "TS_R",
  WRITE = "TS_W",
  COMMENT = "TS_C",
  CREATE_MOCK_TRAFFIC_DATA = "TS_CMTD",
}

export const ETrafficStudiesRightsArray = convertDictionaryToViewLabelArray<ETrafficStudiesRights>({
  [ETrafficStudiesRights.READ]: "Traffic studies / Read",
  [ETrafficStudiesRights.WRITE]: "Traffic studies / Write",
  [ETrafficStudiesRights.COMMENT]: "Traffic studies / Comment",
  [ETrafficStudiesRights.CREATE_MOCK_TRAFFIC_DATA]: "Mock / Create Mock Traffic Data",
});
