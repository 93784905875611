/**
 * Wait until something by condition
 * @returns If the condition became true
 */
export const waitUntil = async (
  {
    checkCondition,
    timeout,
    intervalTimeout,
  }: {
    /**
     * Promised method that returns if the conditions is true.
     */
    checkCondition: () => Promise<boolean>;
    /**
     * Maximum time to wait
     *
     * @example 3000 for 3 seconds
     */
    timeout: number;
    /**
     * Interval timeout for checking
     *
     * @example 200
     */
    intervalTimeout: number;
  },
): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    let conditionMet = false;
    let elapsedTime = 0;

    // Start the timer for timeout
    const timeoutId = setTimeout(() => {
      conditionMet = false;
      resolve(false);
    }, timeout);

    // Check the condition at regular intervals
    const intervalId = setInterval(() => {
      checkCondition()
        .then((result) => {
          conditionMet = result;
        })
        .catch(error => {
          clearTimeout(timeoutId);
          clearInterval(intervalId);
          reject(error);
        })
        .finally(() => {
          elapsedTime += intervalTimeout;

          // Resolve with the result only if the condition is met or the timeout is reached
          if (conditionMet || elapsedTime >= timeout) {
            clearTimeout(timeoutId);
            clearInterval(intervalId);

            if (conditionMet) {
              resolve(true);
            }
            else {
              resolve(false);
            }
          }
        });
    }, intervalTimeout);
  });
};
