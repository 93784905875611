import * as React from "react";

import {EOpenMode} from "../../interfaces";
import {INotificationIcon} from "../../interfaces";
import {IProfileOption} from "../../interfaces";

import {Box} from "../../../Box";

import {
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "../../../FlexContainer";

import {Condition} from "../../../Condition";
import {CenterContainer} from "../../../CenterContainer";
import {BreakpointDeviceContainer} from "../../../BreakpointDeviceContainer";
import {AppNotificationIcons} from "./components/AppNotificationIcons";
import {IconButton} from "../../../IconButton";

import {useTheme} from "../../../ThemeProvider";
import {sxTransitionShowHide} from "../../../sxTransition";
import MenuIcon from '@mui/icons-material/Menu';

export interface IAppHeaderProps {
  appTitle?: string;
  appTitleHelper?: string;
  appTitleContent?: JSX.Element;        // Override the above, to render special content

  openMode: EOpenMode;

  notificationIcons?: INotificationIcon[];
  profileUserName?: string;
  profileIcon?: JSX.Element;
  profileOptions?: IProfileOption[];

  onAsideMenuOpenChange: (openMode: EOpenMode) => void;
}

const MENU_ICON_SIZE = 24;

export const AppHeader = (props: IAppHeaderProps): JSX.Element => {
  const theme = useTheme();
  const {
    appTitle,
    appTitleHelper,
    appTitleContent,

    openMode,

    notificationIcons,
    profileUserName,
    profileIcon,
    profileOptions,

    onAsideMenuOpenChange,
  } = props;

  const showMenuIcon = openMode === EOpenMode.COLLAPSED;

  const handleMenuClick = (): void => onAsideMenuOpenChange(EOpenMode.EXPANDED);

  return (
    <FlexContainerHorizontal
      dataComponentName="AppHeader"
      alignVertical="middle"
      sx={{
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <FlexItemMin
        sx={{
          marginLeft: showMenuIcon ? undefined : '0 !important',
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          transition: sxTransitionShowHide(theme, 'margin-left', showMenuIcon),
        }}
      >
        <Box
          sx={{
            transition: sxTransitionShowHide(theme, 'width', showMenuIcon),
            width: showMenuIcon ? MENU_ICON_SIZE + parseInt(theme.spacing(2)) : 0,
            overflow: 'hidden',
          }}
        >
          <IconButton
            ariaLabel="Application main menu button"
            icon={
              <MenuIcon
                sx={{color: theme.palette.primary.contrastText}}
                width={MENU_ICON_SIZE}
              />}
            onClick={handleMenuClick}
          />

        </Box>
      </FlexItemMin>

      <Condition if={!!appTitleContent}>
        <FlexItemMax
          sx={{
            margin: 0,
            paddingLeft: theme.spacing(1),
          }}
        >
          {appTitleContent}
        </FlexItemMax>
      </Condition>

      <Condition not if={!!appTitleContent}>
        <FlexItemMax>
          <CenterContainer horizontally="left">
            <Box
              sx={{
                fontSize: theme.typography.fontSize * 1.2,
                fontWeight: 'bold',
                color: theme.palette.primary.contrastText,
              }}
            >
              {appTitle}
            </Box>
            <BreakpointDeviceContainer allExcept mobile>
              <Box
                sx={{
                  fontSize: theme.typography.fontSize * 1,
                  color: theme.palette.primary.contrastText,
                }}
              >
                {appTitleHelper}
              </Box>
            </BreakpointDeviceContainer>
          </CenterContainer>
        </FlexItemMax>
      </Condition>

      <FlexItemMin>
        <AppNotificationIcons
          notificationIcons={notificationIcons}
          profileUserName={profileUserName}
          profileIcon={profileIcon}
          profileOptions={profileOptions}
        />
      </FlexItemMin>
    </FlexContainerHorizontal>
  );
};
