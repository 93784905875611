import * as React from "react";
import {dynaSwitch} from "dyna-switch";

import {EMapType} from "../../../../geoInterfaces";

import {SVGIcon} from "../../../../SVGIcon";
import {MapButton} from "../MapButton";

import RoadMapIcon from '@mui/icons-material/Map';
import TerrainIcon from '@mui/icons-material/Terrain';
import HybridIcon from '@mui/icons-material/Satellite';

// eslint-disable-next-line
const satelliteImage = require('../../../assets/satelllite.svg');

const SatelliteIcon = () => <SVGIcon src={satelliteImage}/>;

export interface IMapTypeButtonProps {
  availableMapTypes: EMapType[]; // Default is [EMapType.ROADMAP]
  mapType: EMapType;
  onClick: (mapType: EMapType) => void;
}

export const MapTypeButton = (props: IMapTypeButtonProps): JSX.Element => {
  const {
    availableMapTypes,
    mapType,
    onClick,
  } = props;

  const handleClick = () => {
    const options = (new Array<EMapType>()).concat(availableMapTypes, availableMapTypes);
    onClick(options[options.indexOf(mapType) + 1]);
  };

  return (
    <MapButton
      title="Map type"
      icon={dynaSwitch(
        mapType,
        <RoadMapIcon/>,
        {
          [EMapType.ROADMAP]: <RoadMapIcon />,
          [EMapType.SATELLITE]: <SatelliteIcon/>,
          [EMapType.TERRAIN]: <TerrainIcon/>,
          [EMapType.HYBRID]: <HybridIcon/>,
        },
      )}
      onClick={handleClick}
    />
  );
};
