import * as React from "react";
import {ReactElement} from "react";

import MuiTypography from "@mui/material/Typography";

import {
  TDataComponentName,
  getDataComponentName,
} from "../ui-interfaces";

import {useBreakpointDevice} from "../useBreakpointDevice";

import {
  SxProps,
  useTheme,
  Theme,
} from "../ThemeProvider";

export type TTypographyVariant =
  | 'body1'
  | 'body2'
  | 'button'
  | 'caption'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'inherit'
  | 'overline'
  | 'subtitle1'
  | 'subtitle2';


export interface ITypographyProps {
  sx?: SxProps<Theme>;
  show?: boolean;
  dataComponentName?: TDataComponentName;

  variant?: TTypographyVariant;     // Default is body1

  v?: TTypographyVariant;          // Variant abbreviation

  align?:
    | 'center'
    | 'left'
    | 'right';

  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strikethrough?: boolean;

  gutterBottom?: boolean; // Default is true

  noWrap?: boolean;       // Default is false
  noSpace?: boolean;      // Default is false, set it to true for no margins

  ariaLabel?: string;
  children: any;
}

export const Typography = (props: ITypographyProps): ReactElement | null => {
  const theme = useTheme();
  const {
    sx = {},
    show = true,
    dataComponentName,
    variant: userVariant,
    v,
    align,
    bold = false,
    italic = false,
    underline = false,
    strikethrough = false,
    gutterBottom = true,
    noWrap = false,
    noSpace = false,
    ariaLabel,
    children,
  } = props;

  const {
    isMobile,
    isTablet,
    isLaptop,
  } = useBreakpointDevice();

  const style: React.CSSProperties = {};
  if (bold) style.fontWeight = "bold";
  if (italic) style.fontStyle = "italic";
  if (underline) style.textDecoration = "underline";
  if (strikethrough) style.textDecoration = "line-through";
  if (strikethrough && underline) style.textDecoration = "line-through underline";

  const variant: TTypographyVariant = userVariant || v || "body1";
  const component: any = variant[0] === "h" ? "" : "div";
  const paragraph = variant.startsWith('body');

  // Check if it's a size-reducing variant and apply the scale
  if (['body1', 'body2', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(variant)) {
    if (isMobile) {
      style.fontSize = `calc(${theme.typography[variant].fontSize} * 0.8)`;
    }
    else if (isTablet) {
      style.fontSize = `calc(${theme.typography[variant].fontSize} * 0.85)`;
    }
    else if (isLaptop) {
      style.fontSize = `calc(${theme.typography[variant].fontSize} * 0.9)`;
    }
  }

  if (!show) return null;

  return (
    <MuiTypography
      data-component-name={getDataComponentName(dataComponentName, "Typography")}
      component={component}
      sx={{
        ...theme.typography[variant],
        ...sx,
        ...(noSpace ? {margin: 0} : {}),
      }}
      style={style}
      variant={variant}
      paragraph={paragraph}
      align={align}
      noWrap={noWrap}
      gutterBottom={gutterBottom}
      aria-label={ariaLabel}
    >
      {children}
    </MuiTypography>
  );
};
