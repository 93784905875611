import * as React from "react";

import {
  TDataComponentName,
  getDataComponentName,
} from "../ui-interfaces";

import {Box} from "../Box";
import {
  Theme,
  SxProps,
} from "../ThemeProvider";

export interface IContainerAbsolutProps {
  sx?: SxProps<Theme>;
  dataComponentName?: TDataComponentName;
  show?: boolean;
  children?: any;
  zIndex?: number;
}

// It needs a parent with position relative to fit to the parent
export const ContainerAbsolut: React.FC<IContainerAbsolutProps> = (
  {
    sx,
    dataComponentName,
    show = true,
    children = null,
    zIndex,
  },
) => {
  if (!show) return null;
  return (
    <Box
      dataComponentName={getDataComponentName(dataComponentName, "ContainerAbsolut")}
      sx={{
        ...sx,
        position: "absolute",
        zIndex,
      }}
    >
      {children}
    </Box>
  );
};
