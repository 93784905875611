import {round} from "./round";

export const getRatio = (width: number, height: number, precision = 0): {
  a: number;
  b: number;
} => {
  const ratio = width > height ? width / height : height / width;

  if (width > height) {
    return {
      a: round(ratio, precision),
      b: 1,
    };
  }
  else {
    return {
      a: 1,
      b: round(ratio, precision),
    };
  }
};
