import {
  IDynaError,
  dynaError,
} from "dyna-error";

import {hashMd5} from "../hash-md5";

export interface ICallsHandlerConfig<TArgs, TResponse> {
  onCall: (args: TArgs) => Promise<TResponse>;
}

/**
 * PromisesAggregator is a class that manages asynchronous function calls, ensuring that redundant calls
 * with the same arguments share the same Promise.
 */
export class PromisesAggregator<TArgs, TResponse> {
  constructor(private readonly config: ICallsHandlerConfig<TArgs, TResponse>) {
  }

  private readonly calls: Record<
    string,
    Array<{
      resolve: (response: TResponse) => void;
      reject: (error: IDynaError) => void;
    }>
  > = {};
  private readonly loads: Record<
    string,
    {
      isLoading: boolean;
      response: TResponse;
      error: IDynaError | null;
    }
  > = {};

  public exec(args: TArgs): Promise<TResponse> {
    return new Promise<TResponse>((resolve, reject) => {
      const callId = this.getIdByArgs(args);

      if (!this.calls[callId]) this.calls[callId] = [];
      this.calls[callId].push({
        resolve,
        reject,
      });

      if (!this.loads[callId]) {
        this.loads[callId] = {
          isLoading: true,
          response: null as any,
          error: null,
        };
        this.config
          .onCall(args)
          .then((response: TResponse) => {
            if (!this.loads[callId]) return; // It is cleared
            this.loads[callId].isLoading = false;
            this.loads[callId].response = response;
            this.fulfill(callId);
          })
          .catch((error: IDynaError) => {
            if (!this.loads[callId]) return; // It is cleared
            this.loads[callId].isLoading = false;
            this.loads[callId].error = error;
            this.fulfill(callId);
          });
      }

      this.fulfill(callId);
    });
  }

  public clear(args: TArgs): void {
    const callId = this.getIdByArgs(args);
    const load = this.loads[callId];
    if (!load) return;
    load.error = dynaError({
      code: 20240104093329,
      message: "PromisesAggregator: Promise cleared",
    });
    load.isLoading = false;
    this.fulfill(callId);
    delete this.loads[callId];
  }

  private fulfill(callId: string): void {
    const load = this.loads[callId];
    if (!load) return;
    if (load.isLoading) return;

    const calls = this.calls[callId];
    if (!calls) return;

    while (calls.length) {
      const call = calls.shift();
      if (!call) return;
      if (load.error) call.reject(load.error); else call.resolve(load.response);
    }
  }

  private getIdByArgs(args: TArgs): string {
    return hashMd5(JSON.stringify(args));
  }
}
