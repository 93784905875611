export const API_PATH_apiUsersInvitationsPut = '/services/users-invitations';

export interface IApiUsersInvitationsPutBodyRequest {
  email: string; // The PK to find an invitation to update it (email cannot be changed,
  fullCompanyName?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  rights?: string[];
}

export interface IApiUsersInvitationsPutResponse {
  ok: true;
}
