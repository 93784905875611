import {useState} from "react";

import {isObject} from "utils-library/dist/commonJs/utils";

import {useOnChange} from "../useOnChange";

/**
 * UseStateWithPropValue combines updates made to a component's prop value with updates made to its state value
 * The usage is the same with this of React's useState.
 * This hook makes the components to work as managed and unmanaged on the same time.
 * @param name The name of the property of the value
 * @param defaultValue Default value (required). This can be from the props or hardcoded.
 * @param propValue Value from the prop (optional), changes to it will change the value!
 * @param disabled When true, changes are ignored and not applied
 * @param readOnly When true, changes are ignored and not applied
 * @param onChange Called only when setValue() is called
 */
export const useStateWithPropValue =
  <TValue, TData = any>(
    {
      name,
      defaultValue,
      propValue,
      disabled,
      readOnly,
      onChange,
    }: {
      name?: keyof TData;
      defaultValue: TValue;
      propValue?: TValue;
      disabled?: boolean;
      readOnly?: boolean;
      onChange?: (value: TValue, name?: keyof TData) => void;
    },
  ): [TValue, (value: Partial<TValue> | ((currentValue: TValue) => Partial<TValue> | undefined)) => void] => {
    const [value, setValue] = useState<TValue>(propValue ?? defaultValue);

    useOnChange<TValue | undefined>({
      dep: propValue,
      onChange: v => setValue(v as any),
    });

    const handleChange = (userValue: Partial<TValue> | ((currentValue: TValue | undefined) => Partial<TValue> | undefined)): void => {
      if (disabled || readOnly) return;
      let newValue: TValue = null as any;
      setValue(currentValue => {
        const resolvedUserValue =
          typeof userValue === "function"
            ? (userValue as any)(currentValue)
            : userValue;
        if (resolvedUserValue === undefined) return currentValue; // No changes requested
        newValue =
          isObject(currentValue)
            ? {
              ...currentValue,
              ...resolvedUserValue,
            }
            : resolvedUserValue;
        return newValue;
      });
      onChange && onChange(newValue, name);
    };

    return [
      value,
      handleChange,
    ];
  };
