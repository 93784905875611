import * as React from "react";

import {
  INotificationIcon,
  IProfileOption,
} from "../../../interfaces";

import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";

import {
  FlexContainerHorizontal,
  FlexItemMin,
} from "../../../../FlexContainer";
import {Box} from "../../../../Box";

import {Condition} from "../../../../Condition";
import {BreakpointDeviceContainer} from "../../../../BreakpointDeviceContainer";
import {
  IconButton,
  EIconButtonSize,
} from "../../../../IconButton";

import {useTheme} from "../../../../ThemeProvider";
import MenuItem from "@mui/material/MenuItem";
import MoreIcon from "@mui/icons-material/MoreVert";

export interface IAppNotificationIconsProps {
  notificationIcons?: INotificationIcon[];
  profileUserName?: string;
  profileIcon?: JSX.Element;
  profileOptions?: IProfileOption[];
}

export const AppNotificationIcons = (props: IAppNotificationIconsProps): JSX.Element => {
  const theme = useTheme();
  const {
    notificationIcons = [],
    profileUserName,
    profileIcon,
    profileOptions = [],
  } = props;

  const menuId = 'app-menu--desktop';
  const mobileMenuId = 'app-menu--mobile';

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = !!anchorEl;
  const isMobileMenuOpen = !!mobileMoreAnchorEl;

  const handleNotificationIconClick = (disabled: boolean | undefined, onClick?: () => void): void => {
    handleMenuClose();
    if (disabled) return;
    onClick && onClick();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileOptionClick = (onClick?: () => void): void => {
    onClick && onClick();
    handleMenuClose();
  };

  return (
    <>
      <FlexContainerHorizontal
        alignVertical="middle"
        fullHeight
        spacing={0}
      >
        <BreakpointDeviceContainer allExcept mobile>
          {notificationIcons.map(({
            icon, label, counter, disabled, onClick,
          }, index) => (
            <FlexItemMin key={index}>
              <IconButton
                sx={{color: theme.palette.primary.contrastText}}
                icon={
                  <Badge badgeContent={counter} color="secondary">
                    {icon}
                  </Badge>
                }
                ariaLabel={label}
                size={EIconButtonSize.LARGE}
                disabled={disabled}
                onClick={() => handleNotificationIconClick(disabled, onClick)}
              />
            </FlexItemMin>
          ))}
        </BreakpointDeviceContainer>

        <Condition if={!!profileIcon}>
          <FlexItemMin>
            <FlexContainerHorizontal alignVertical="middle">
              <FlexItemMin show={!!profileUserName}>
                <BreakpointDeviceContainer tablet>
                  <Box sx={{textAlign: 'right'}}>
                    {profileUserName}
                  </Box>
                </BreakpointDeviceContainer>
                <BreakpointDeviceContainer allExcept mobile tablet>
                  <Box sx={{whiteSpace: 'pre'}}>
                    {profileUserName}
                  </Box>
                </BreakpointDeviceContainer>
              </FlexItemMin>
              <FlexItemMin>
                <IconButton
                  sx={{
                    color: theme.palette.primary.contrastText,
                    '& img': {
                      height: 40,
                      borderRadius: 20,
                    },
                  }}
                  icon={profileIcon}
                  ariaLabel="User profile"
                  size={EIconButtonSize.LARGE}
                  ariaControls={menuId}
                  ariaHasPopUp
                  onClick={handleProfileMenuOpen}
                />
              </FlexItemMin>
            </FlexContainerHorizontal>
          </FlexItemMin>
        </Condition>

        <BreakpointDeviceContainer mobile>
          <IconButton
            sx={{color: theme.palette.primary.contrastText}}
            icon={<MoreIcon/>}
            ariaLabel={`Show notifications${profileIcon ? '& profile info' : ''}`}
            ariaControls={mobileMenuId}
            ariaHasPopUp
            size={EIconButtonSize.LARGE}
            onClick={handleMobileMenuOpen}
          />
        </BreakpointDeviceContainer>

      </FlexContainerHorizontal>

      {/* Pop up menus, should be always rendered by this order. */}

      <Menu
        id={mobileMenuId}
        anchorEl={mobileMoreAnchorEl}
        keepMounted
        open={isMobileMenuOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleMobileMenuClose}
      >

        {notificationIcons.map(({
          icon, label, counter, disabled, onClick,
        }, index) => (
          <MenuItem
            key={index}
            onClick={() => handleNotificationIconClick(disabled, onClick)}
          >
            <IconButton
              icon={
                <Badge
                  badgeContent={counter}
                  color="secondary"
                >
                  {icon}
                </Badge>
              }
              ariaLabel={label}
              size={EIconButtonSize.LARGE}
              disabled={disabled}
            />
            <p>{label}</p>
          </MenuItem>
        ))}
      </Menu>

      <Menu
        id={menuId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {profileOptions
          .filter(option => !option.hidden)
          .map((
            {
              disabled,
              icon,
              label,
              onClick,
            },
            index,
          ) => (
            <MenuItem
              key={index}
              disabled={disabled}
              onClick={() => handleProfileOptionClick(onClick)}
            >
              <IconButton
                ariaLabel={label}
                icon={icon}
              />
              {label}
            </MenuItem>
          ))}
      </Menu>

    </>
  );
};
