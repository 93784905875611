import {IAppRoute} from "../../application/config/IAppRoute";
import {EAlertsManagementRights} from "mhc-server";
import {AlertMgtmSettingsPage} from "../pages/AlertMgtmSettingsPage";
import SettingsIcon from "@mui/icons-material/Tune";

export const routeAlertMgtmSettingsPage: IAppRoute = {
  menuId: '###-alert-mgtm-settings-page',
  title: 'Alerts Management Settings',
  icon: <SettingsIcon/>,
  routePath: '/alerts-management-settings',
  getRoutePath: () => routeAlertMgtmSettingsPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [
    EAlertsManagementRights.ALERT_CONFIG_VIEW,
    EAlertsManagementRights.ALERT_CONFIG_EDIT,
  ],
  render: () => <AlertMgtmSettingsPage />,
};
