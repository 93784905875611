import {IMockCreateTrafficDataResponse} from "../../../device-management/providers/deviceManagementBroker/brokers/MQTTDeviceBroker/device-drivers/TrafficVehiclesCounterMHCMQTTDriver/commands";

export const API_PATH_ApiTrafficStudyGenerateMockTrafficData = '/services/traffic-studies/apiTrafficStudyGenerateMockTrafficData';

export interface IApiTrafficStudyGenerateMockTrafficDataRequest {
  deviceId: string;
  trafficStudyId: string;
}

export type IApiTrafficStudyGenerateMockTrafficDataResponse = IMockCreateTrafficDataResponse;
