import {ETrafficStudiesRights} from "mhc-server";

import {IAppRoute} from "../../application/config/IAppRoute";

import {
  TrafficStudyManage,
  ETrafficStudyManageTab,
} from "../pages/TrafficStudyManage";

import {buildUrlByRoutePath} from "utils-library/dist/commonJs/utils";

import {TrafficStudiesIcon} from "../icons";

export const routeTrafficStudyManage: IAppRoute<{
  trafficStudyId: string;
  tab?: ETrafficStudyManageTab;
  tabArgument?: string;
}> = {
  menuId: "menu--trafficStudy-manage",
  title: 'Manage Traffic Study',
  icon: <TrafficStudiesIcon/>,
  routePath: '/traffic-studies/manage/:trafficStudyId/:tab?/:tabArgument?',
  getRoutePath: args =>
    buildUrlByRoutePath({
      routePath: routeTrafficStudyManage.routePath,
      pathParams: args,
    }),
  userHasAllRights: [],
  userHasAnyOfRights: [
    ETrafficStudiesRights.READ,
    ETrafficStudiesRights.WRITE,
  ],
  exact: true,
  render: (
    {
      pathParams: {
        trafficStudyId,
        tab,
        tabArgument,
      },
    }) =>
    <TrafficStudyManage
      trafficStudyId={trafficStudyId}
      tab={tab}
      tabArgument={tabArgument}
    />,
};
