export const API_PATH_ApiWWRStartZipFileCreationV1 = '/services/wrong-way-resolution/apiWWRStartZipFileCreation-v1';

export interface IApiWWRStartZipFileCreationV1Request {
}

export interface IApiWWRStartZipFileCreationV1Response {
  maxSizeInMb: number;
  compressJobId: string;
  wsEventName: string;
}
