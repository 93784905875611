import {TObject} from "../typescript";
import {dynaObjectScan} from "dyna-object-scan";

export const getValuesFromObjectAsStrings = (
  {
    object,
    lowerCase = false,
    convertNumberToString = value => String(value),
    convertDateToString = date => date.toString(),
  }: {
    object: TObject;
    lowerCase?: boolean;
    convertNumberToString?: (value: number) => string;
    convertDateToString?: (date: Date) => string;
  },
): string[] => {
  const stringValues: string[] = [];
  dynaObjectScan(
    object,
    ({value}) => {
      if (typeof value === "string") {
        stringValues.push(value);
      }
      else if (typeof value === "number") {
        stringValues.push(convertNumberToString(value));
      }
      else if (value instanceof Date) {
        stringValues.push(convertDateToString(value));
      }
      // All other types (object, array, null, undefined) are not string able.
      // The object scan will resolve the nested values.
    },
  );
  return lowerCase
    ? stringValues.map(t => t.toLowerCase())
    : stringValues;
};

