export const API_PATH_ApiAlertMgtmUserInfoGet = '/services/alert-mgtm/apiAlertMgtmUserInfoGet';

export interface IApiAlertMgtmUserInfoGetRequest {
  userId: string;
}

export interface IApiAlertMgtmUserInfoGetResponse {
  avatarUrl: string;
  displayName: string;
}
