import {
  IDBEntityBase,
  getDefaultDBEntityDocument,
} from "utils-library/dist/commonJs/db-entity-interfaces";

export interface IUser extends IDBEntityBase {
  id: string;                 // PK
  authUserId: string;         // PK Example: 'OAUTH_GOOGLE/l24j6nl2j462lj46543'
  avatarUrl?: string;
  displayName: string;
  title?: string;
  firstName: string;
  lastName: string;
  loginName?: string;         // PK
  phoneNumber?: string;
  email: string;
  rights: string[];
  pswHash?: string;
}

export const getDefaultUser = () => getDefaultDBEntityDocument<IUser>({
  authUserId: '',
  displayName: '',
  title: '',
  email: '',
  firstName: '',
  lastName: '',
  rights: [],
});
