export const setDeepValue = <TData = any>(
  {
    data: userData,
    propName,
    value: newValue,
  }: {
    data: TData;
    propName: string;
    value: any;
  },
): {
  data: TData;
  changed: boolean;
  propFound: boolean;
} => {
  const data: TData = {...userData};
  const paths = propName.split('.');
  const lastPath = paths[paths.length - 1];

  const pathObjects: any[] = [data];
  paths
    .forEach(path => {
      const lastObjectPath = pathObjects[pathObjects.length - 1];
      if (lastObjectPath === undefined) {
        pathObjects.push(undefined);
        return;
      }
      pathObjects.push(lastObjectPath[path]);
    });
  const targetObject = pathObjects[pathObjects.length - 2];

  const propFound = targetObject !== undefined && targetObject[lastPath] !== undefined;
  let changed = false;

  if (propFound) {
    const currentValue = targetObject[lastPath];
    const diff = currentValue !== newValue;
    if (diff) {
      targetObject[lastPath] = newValue;
      changed = true;
    }
  }

  return {
    data,
    changed,
    propFound,
  };
};
