import {getHost} from "utils-library/dist/commonJs/utils";
import {IApiAppVersionGetResponse} from "mhc-server";

import {useLoadData} from "mhc-ui-components/dist/useLoadData";
import {Confirm} from "mhc-ui-components/dist/Confirm";

import {appConfig} from "../../config/appConfig";
import {apiAppVersionGet} from "../../api/version/apiAppVersionGet";

export const CheckAppVersion = (): JSX.Element | null => {
  const {
    isLoading,
    error: loadError,
    data: {version},
  } = useLoadData<IApiAppVersionGetResponse>({
    defaultData: {
      version: '',
      codeName: '',
      builtAt: '',
    },
    load: apiAppVersionGet,
  });

  if (isLoading) return null;
  if (loadError) return null;

  if (version === appConfig.appVersion) return null; // Is the same

  const handleCloseToUpdateClick = () => {
    const host =
      getHost(appConfig.webBaseUrl)
        .split('.')
        .reverse()
        .slice(0, 2)
        .reverse()
        .join('.');
    window.location.href = `https://${host}`;
  };

  return (
    <Confirm
      show
      title="Metiri - New version is available"
      message="Close and Open again the App to Update."
      helperMessage={
        [
          ' ',
          `Current version: ${appConfig.appVersion}`,
          `New version: ${version}`,
          ' ',
          "If the App runs on a Browser,",
          "ensure all tabs with this App are closed.",
        ]
          .filter(Boolean)
          .join('\n')
      }
      forceUserConfirmation
      labelConfirmButton="Close Application"
      labelCancelButton={null}
      onOk={handleCloseToUpdateClick}
    />
  );
};
