import {IRegion} from "../../../device-management/interfaces/IRegion";
import {ILocation} from "../../../device-management/interfaces/ILocation";

export const API_PATH_ApiAlertMgtmRegionsLocationsGet = '/services/alerts-mgtm/apiAlertMgtmRegionsLocationsGet';

export interface IApiAlertMgtmRegionsLocationsGetRequest {
  archived?: boolean | "both";
  deleted?: boolean | "both";
}

export interface IApiAlertMgtmRegionsLocationsGetResponse {
  regions: IRegion[];
  locations: ILocation[];
}
