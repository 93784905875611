// Function to calculate the matching percentage between the document title and search query
export const calculateMatchingPercentage = (text: string, query: string): number => {
  const wordRegex = /[^\W_]+/g; // Regular expression to match words

  // Extract words from text and query
  const titleWords = text.toLocaleLowerCase().match(wordRegex) || [];
  const queryWords = query.toLocaleLowerCase().match(wordRegex) || [];

  const matchedWords = titleWords.filter((word) => queryWords.includes(word));
  const matchingPercentage = (matchedWords.length / queryWords.length) * 100;

  return matchingPercentage;
};
