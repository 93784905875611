import * as React from "react";
import {dynaSwitchEnum} from "dyna-switch";

import {Box} from "../Box";

import MuiModal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import {
  Theme,
  SxProps,
} from "../ThemeProvider";

export interface IModalProps {
  sx?: SxProps<Theme>;          // Style of the window
  dataComponentName?: string;
  show: boolean;

  fullMode?: boolean;           // Width & Height will be "90%"
  fullModeMargin?: EFullModeMargin;

  fullWidth?: boolean;          // It gets always the maxWidth, otherwise, the width depends on the content of the modal

  minWidth?: number | string;   // Default is 320
  maxWidth?: number | string;   // Default is 90%

  dimBackground?: boolean;

  children: any;

  onBackdropClick?: (reason: 'backdropClick' | 'escapeKeyDown') => void;
}

export enum EFullModeMargin {
  XSMALL = "XSMALL",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export const Modal = (props: IModalProps): JSX.Element => {
  const {
    sx = {},
    dataComponentName,
    show,
    fullMode = false,
    fullWidth = false,
    fullModeMargin = EFullModeMargin.MEDIUM,
    minWidth = 320,
    maxWidth = "90%",
    dimBackground = true,
    children,
    onBackdropClick,
  } = props;

  const fullWidthMarginValue = dynaSwitchEnum<EFullModeMargin, string>(
    fullModeMargin,
    {
      [EFullModeMargin.XSMALL]: '98%',
      [EFullModeMargin.SMALL]: '95%',
      [EFullModeMargin.MEDIUM]: '90%',
      [EFullModeMargin.LARGE]: '80%',
    },
  );

  return (
    <MuiModal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 3200,
      }}
      open={show}
      closeAfterTransition
      components={{Backdrop: dimBackground ? Backdrop : undefined}}
      onClose={onBackdropClick}
    >
      <Box
        sx={{
          backgroundColor: theme => theme.palette.background.paper,
          border: '2px solid #000',
          boxShadow: theme => theme.shadows[5],
          zIndex: 1,
          minWidth:
            typeof minWidth === "number"
              ? minWidth + 'px'
              : minWidth,
          maxWidth:
            typeof maxWidth === "number"
              ? maxWidth + 'px'
              : maxWidth,
          maxHeight: fullWidthMarginValue,
          overflowY: 'auto',
          width:
            fullWidth
              ? '100%'
              : fullMode
                ? fullWidthMarginValue
                : undefined,
          height: fullMode
            ? fullWidthMarginValue
            : undefined,
          position: 'relative',
          ...sx,
        }}
        dataComponentName={dataComponentName}
      >
        {children}
      </Box>
    </MuiModal>
  );
};
