import * as React from "react";
import {InputSelect} from "../../../../InputSelect";

export interface ISingleOptionFilterProps {
  label: string;
  helperLabel?: string;
  options: { label: string; value: string }[];
  value: string;
  onChange: (value: string) => void;
}

export const SingleOptionFilter = (props: ISingleOptionFilterProps): JSX.Element => {
  const {
    label,
    helperLabel,
    options,
    value,
    onChange,
  } = props;

  return (
    <InputSelect
      label={label}
      ariaLabel={label}
      helperLabel={helperLabel}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};
