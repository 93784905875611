import {getFilenameExtension} from "./getFilenameExtension";

export const getFilenameWithDotSuffix = (filename: string, suffix: string): string => {
  const fullFileParts = filename.split('/');
  const fullFileName = fullFileParts.pop() || '';
  const fn = fullFileName.split('.');
  fn.pop();
  fn.push(suffix);
  fn.push(getFilenameExtension(filename));
  const newFileName = fn.join('.');
  fullFileParts.push(newFileName);
  return fullFileParts.join('/');
};
