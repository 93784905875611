import * as React from "react";
import {ReactElement} from "react";

import {
  FlexContainerResponsive,
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "../FlexContainer";
import {Box} from "../Box";
import {IconViewer} from "../IconViewer";
import {IIconComponent} from "../IconComponent";

export interface IContainerSectionProps {
  show?: boolean;
  h?: 1 | 2 | 3 | 4 | 5;
  fullHeight?: boolean;
  Icon?: IIconComponent;
  SubIcon?: IIconComponent;
  title: string;
  subtitle?: string;
  topRightToolbar?: ReactElement;
  children?: any;
}

export const ContainerSection = (props: IContainerSectionProps): ReactElement | null => {
  const {
    show = true,
    h = 1,
    fullHeight,
    Icon,
    SubIcon,
    title,
    subtitle,
    topRightToolbar,
    children,
  } = props;

  const factor = 5 + 1 - h;

  if (!show) return null;

  return (
    <FlexContainerResponsive
      verticalOnMobile
      fullHeight={fullHeight}
      spacing={1}
    >
      <FlexItemMin
        sx={{position: 'relative'}}
        show={!!Icon}
      >
        <IconViewer
          Icon={Icon}
          width={factor * 24}
        />
        <Box
          show={!!SubIcon}
          sx={{
            position: 'absolute',
            right: theme => theme.spacing(-1),
            bottom: 0,
            padding: theme => theme.spacing(factor / 5),
            backgroundColor: theme => theme.palette.background.paper,
            borderRadius: (factor * 12) + 'px',
            border: '1px solid',
            borderColor: theme => theme.palette.text.secondary,
            fontSize: 0,
          }}
        >
          <IconViewer
            Icon={SubIcon}
            width={factor * 5}
          />
        </Box>
      </FlexItemMin>

      <FlexItemMax sx={{paddingTop: theme => theme.spacing(1)}}>
        <FlexContainerHorizontal alignVertical="top">
          <FlexItemMax>
            <Box
              sx={{
                fontWeight: 'bold',
                fontSize: factor * 5,
              }}
            >
              {title}
            </Box>
            <Box
              sx={{
                fontWeight: 'bold',
                fontSize: factor * 3,
              }}
            >
              {subtitle}
            </Box>
          </FlexItemMax>
          <FlexItemMin>
            {topRightToolbar}
          </FlexItemMin>
        </FlexContainerHorizontal>
        <Box sx={{paddingLeft: factor / 4}}>
          {children}
        </Box>
      </FlexItemMax>
    </FlexContainerResponsive>
  );
};
