import * as React from "react";

import {
  EButtonBarAlign,
  ButtonBar,
} from "../ButtonBar";
import {
  Button,
  EButtonColor,
} from "../Button";
import {
  IUseFormApi,
  EFormMode,
} from "../useForm";

import {useBreakpointDevice} from "../useBreakpointDevice";

import CreateIcon from '@mui/icons-material/AddCircle';
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from '@mui/icons-material/HighlightOff';

export interface IFormToolbarProps<TData> {
  useFormApi: IUseFormApi<TData>;

  // Override useForm's disable property
  disableEditButton?: boolean;
  disableSaveButton?: boolean;
  disableCancelButton?: boolean;

  // Override useForm's hidden property
  hideEditButton?: boolean;
  hideSaveButton?: boolean;
  hideCancelButton?: boolean;

  showCancelAsClose?: boolean;            // If true, the Cancel button is shown as Close. Use true for dialog forms.

  additionalButtons?: JSX.Element;        // Additional buttons inside the ButtonBar.

  labels?: IFormToolbarLabels;
}

export interface IFormToolbarLabels {
  save: string;
  cancel: string;
  close: string;
}

export const FormToolbar = <TData, >(props: IFormToolbarProps<TData>): JSX.Element => {
  const {
    useFormApi: {
      formMode,
      isChanged,
      buttons,
    },
    disableEditButton,
    disableSaveButton,
    disableCancelButton,
    hideEditButton,
    hideSaveButton,
    hideCancelButton,
    showCancelAsClose = false,
    additionalButtons,
    labels = {
      save: 'Save',
      cancel: 'Cancel',
      close: 'Close',
    },
  } = props;

  const {
    isMobile,
    isTablet,
  } = useBreakpointDevice();

  const handleSaveSubmit = (e: React.MouseEvent): any => {
    e.preventDefault();
    buttons.save.onClick();
  };

  return (
    <ButtonBar
      spacing={1}
      nowrap
      reverseOnMac
      align={EButtonBarAlign.RIGHT}
      noHorizontalSpace
    >
      {additionalButtons}
      <Button
        icon={<EditIcon/>}
        show={buttons.edit.show && !hideEditButton}
        color={
          buttons.edit.active
            ? EButtonColor.SECONDARY
            : EButtonColor.PRIMARY
        }
        disabled={disableEditButton === true || buttons.edit.disabled}
        hideLabelOnMobile
        hideLabelOnTablet
        onClick={buttons.edit.onClick}
      >
        Edit
      </Button>

      <Button
        sx={{width: isMobile || isTablet ? 64 : undefined}}
        icon={
          formMode === EFormMode.CREATE
            ? <CreateIcon/>
            : <SaveIcon/>
        }
        show={buttons.save.show && !hideSaveButton}
        disabled={disableSaveButton === true || buttons.save.disabled}
        children={isMobile || isTablet ? undefined : labels.save}
        onClick={handleSaveSubmit}
      />
      <Button
        sx={{width: isMobile || isTablet ? 64 : undefined}}
        icon={isChanged ? <CancelIcon/> : <CloseIcon/>}
        color={EButtonColor.SECONDARY}
        show={buttons.cancel.show && !hideCancelButton}
        disabled={disableCancelButton === true || buttons.cancel.disabled}
        children={(() => {
          if (isMobile || isTablet) return undefined; // No room in mobile devices
          if (isChanged) return labels.cancel;
          if (showCancelAsClose) return labels.close;
          return labels.cancel;
        })()}
        onClick={buttons.cancel.onClick}
      />

    </ButtonBar>
  );
};
