import {TObject} from "../typescript";

export const getDeepValue = (obj: TObject, path: string): any => {
  const keys = path.split('.');

  let currentObj = obj;

  for (const key of keys) {
    if (currentObj && currentObj[key] !== undefined) {
      currentObj = currentObj[key];
    }
    else {
      return undefined;
    }
  }

  return currentObj;
};
