import * as React from "react";

export interface IErrorBoundaryProps {
  children: any;
  consoleError?: boolean; // Default is false
  onErrorRender?: (error: any) => JSX.Element;
  onError?: (error: any, errorInfo: any) => void;
}

interface IErrorBoundaryState {
  hasError: boolean;
  error: any | null;
  errorInfo: any | null;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  // Resource: https://reactjs.org/docs/error-boundaries.html

  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  public componentDidCatch(error: any, errorInfo: any) {
    const {
      consoleError = false,
      onError,
    } = this.props;
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
    if (onError) onError(error, errorInfo);
    if (consoleError) {
      console.error('ErrorBoundary error:', error.message, {
        error,
        errorInfo,
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        this.props.onErrorRender!(this.state.error) ||
        <h1>Something went wrong. Try refreshing the page.</h1>
      );
    }

    return this.props.children;
  }
}
