import {IUser} from "../../user-authentication/interfaces/IUser";

export const API_PATH_apiUserMngmtUserUndelete = '/services/users-management/user/undelete';

export interface IApiUserMngmtUserUndeleteBodyRequest {
  userId: string;
}

export interface IApiUserMngmtUserUndeleteResponse {
  user: IUser;
}
