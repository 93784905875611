export const watchElementRemoval = (
  {
    element,
    onRemove,
  }: {
    element: HTMLElement;
    onRemove: () => void;
  },
) => {
  let cbCalled = false;
  const observer = new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      if (mutation.type === 'childList') {
        const isAncestorRemoved = !document.body.contains(element);
        if (isAncestorRemoved) {
          observer.disconnect();
          if (!cbCalled) {
            onRemove();
            cbCalled = true;
          }
          break;
        }
      }
    }
  });

  observer.observe(document, {
    childList: true,
    subtree: true,
  });
};
