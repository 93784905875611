export const API_PATH_apiUserMngmtUserPut = '/services/users-management/user';

export interface IApiUserMngmtUserPutBodyRequest {
  userId: string;
  displayName?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  avatarUrl?: string;
  rights?: string[];
}

export interface IApiUserMngmtUserPutResponse {
  ok: true;
}
