import {IRegion} from "../../interfaces/IRegion";

export const API_PATH_ApiDeviceMgmtRegionCreate = '/services/devices-mgtm/apiDeviceMgmtRegionCreate';

export interface IApiDeviceMgmtRegionCreateBodyRequest {
  regionId: string;
  label: string;
  description: string;
  beforeRegionId: string;
  notes: string;
}

export interface IApiDeviceMgmtRegionCreateResponse {
  region: IRegion;
}
