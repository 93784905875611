import {IDeviceDriver} from "./IDeviceDriver";
import {validateDataMethods} from "utils-library/dist/commonJs/validation-engine";
import {EDeviceType} from "../interfaces/EDeviceType";

export interface IDemoConnectITSLocationHubConfig {
  maintenanceMode: boolean;
}

export interface IDemoConnectITSLocationHubStatus {
  acPower: boolean;
  dcVoltage: number;
  flasherStatus: boolean;
  batteryChargePercentage: number;
}

export const DemoConnectITSLocationHub: IDeviceDriver<IDemoConnectITSLocationHubConfig, IDemoConnectITSLocationHubStatus> = {
  deviceType: EDeviceType.CONNECT_ITS_LOCATION_HUB,
  label: '(demo) Connect ITS location hub',
  validateConfig: {maintenanceMode: validateDataMethods.isBoolean},
  validateStatus: {
    acPower: validateDataMethods.isBoolean,
    dcVoltage: validateDataMethods.isNumber,
    flasherStatus: validateDataMethods.isBoolean,
    batteryChargePercentage: value => validateDataMethods.isNumberInRange(value, 0, 100),
  },
};

export const getDefaultDemoConnectITSLocationHubConfig = (): IDemoConnectITSLocationHubConfig => ({maintenanceMode: false});
