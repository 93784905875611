import {ITrafficVehiclesCountEnvironmentalData} from "../../interfaces/ITrafficVehiclesCountEnvironmentalData";

export const API_PATH_ApiTrafficStudiesVehiclesCountEnvironmentalDataSearchGet = '/services/traffic-studies/apiTrafficStudiesVehiclesCountEnvironmentalDataSearchGet';

export interface IApiTrafficStudiesVehiclesCountEnvironmentalDataSearchGetRequest {
  studyIds?: string[];
  start?: number;
  end?: number;
  skip: number;
  limit: number;
}

export type IApiTrafficStudiesVehiclesCountEnvironmentalDataSearchGetResponse = ITrafficVehiclesCountEnvironmentalData[];
