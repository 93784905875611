import {MutableRefObject} from "react";

import {useResizeDetector} from "react-resize-detector";

import {useIsMounted} from "../useIsMounted";
import {usePerformanceState} from "../usePerformanceState";

export interface IUseResizeEventArgs {
  refreshRate?: number;     // Default is 500
  skipOnMount?: boolean;    // Default is true
  leading?: boolean;        // Default is true
  trailing?: boolean;       // Default is true
  onResize?: (args: IResizeEventArgs) => void;
}

export interface IResizeEventArgs {
  width: number;
  height: number;
  diffPercentage: number;
}

export interface IUseResizeEventAPI<TElement> {
  ref: MutableRefObject<TElement | null>;
  width: number;
  height: number;
}

interface IDimension {
  width: number;
  height: number;
}

export const useResizeEvent = <TElement>(
  {
    refreshRate = 500,
    skipOnMount = true,
    leading = true,
    trailing = true,
    onResize = () => undefined,
  }: IUseResizeEventArgs = {},
): IUseResizeEventAPI<TElement> => {
  const [dimension, setDimension] = usePerformanceState<IDimension>({
    width: 0,
    height: 0,
  });

  const getIsMounted = useIsMounted();

  const handleContainerResize = (width: number, height: number) => {
    if (!getIsMounted()) return;
    if (width === 0 && height === 0) return; // Ignore this useDebouncedCallback's call

    const widthDiffPercentage = Math.abs(100 * (width - dimension.width) / dimension.width);
    const heightDiffPercentage = Math.abs(100 * (height - dimension.height) / dimension.height);
    const diffPercentage = (widthDiffPercentage + heightDiffPercentage) / 2;

    setDimension({
      width,
      height,
    });

    onResize({
      width,
      height,
      diffPercentage,
    });
  };

  const {ref} = useResizeDetector({
    skipOnMount,
    refreshRate,
    refreshMode: "debounce",
    refreshOptions: {
      leading,
      trailing,
    },
    onResize: handleContainerResize,
  });

  return {
    ref: ref as any,
    ...dimension,
  };
};
