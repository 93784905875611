export const API_PATH_apiUsersInvitationsDelete = '/services/users-invitations';

export interface IApiUsersInvitationsDeleteBodyRequest {
  email: string;      // PK for the invitations
  deleteInfo: string;
}

export interface IApiUsersInvitationsDeleteResponse {
  ok: true;
}

