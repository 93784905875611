export const arrayUnique =
  <T>(
    array: T[],
    keyGenerator?: (item: T) => string,
  ): T[] => (
    array
      .reduce((acc: T[], item) => {
        if (keyGenerator) {
          const itemKey = keyGenerator(item);
          if (!acc.find(scanItem => keyGenerator(scanItem) === itemKey)) acc.push(item);
        }
        else {
          if (!acc.includes(item)) acc.push(item);
        }
        return acc;
      }, [])
  );

