export const API_PATH_ApiDeviceMgmtLocationChangeOrder = '/services/devices-mgtm/apiDeviceMgmtLocationChangeOrder';

export interface IApiDeviceMgmtLocationChangeOrderBodyRequest {
  regionId: string;
  locationId: string;
  beforeLocationId: string;
}

export interface IApiDeviceMgmtLocationChangeOrderResponse {
  ok: true;
}
