import {IValidateDataEngineRules} from "utils-library/dist/commonJs/validation-engine";
import {validateDataMethods} from "utils-library/dist/commonJs/validation-engine";
import {validationEngine} from "utils-library/dist/commonJs/validation-engine";

import {IBinPoint} from "utils-library/dist/commonJs/data-process";

export interface ITrafficVehiclesCountConfig {
  speedBinsInMph: IBinPoint[];
  lengthBinsInFeet: IBinPoint[];
}

export const getDefaultTrafficVehiclesCountConfig = (): ITrafficVehiclesCountConfig => ({
  speedBinsInMph: [],
  lengthBinsInFeet: [],
});

const validateDataBinTypes = (dataBins: IBinPoint[]): string => {
  if (!Array.isArray(dataBins)) return "Should be array";

  const validationMessages: string[] =
    dataBins
      .map(dataBin => validationEngine<IBinPoint>({
        data: dataBin,
        validationRules: dataBinValidationRules,
      }))
      .reduce((acc: string[], validation) => acc.concat(validation.messages), []);

  return validationMessages.join(' ');
};

const dataBinValidationRules: IValidateDataEngineRules<IBinPoint> = {
  label: value => validateDataMethods.isValidText(value, 0, 30),
  startingFrom: value => {
    if (value === "*") return "";
    return validateDataMethods.isNumberInRange(value, 0, 99999);
  },
};

const validateDataBinValues = (dataBins: IBinPoint[]): string => {
  if (!dataBins.length) return "At least one bin is required";
  if (dataBins.length > 16) return "Bins would be up to 16";

  const validationMessages: string[] = [];
  dataBins.forEach((dataBin, index, array) => {
    if (index === 0) return;
    const prevDataBin = array[index - 1];
    if (!prevDataBin) return; // 4TS
    if (prevDataBin.startingFrom > dataBin.startingFrom) {
      validationMessages.push(`Value ${dataBin.startingFrom} at position ${index + 1} should be bigger than the prev ${prevDataBin.startingFrom}`);
    }
  });
  return validationMessages.join(', ');
};

export const trafficVehiclesCountConfigTypesValidationRules: IValidateDataEngineRules<ITrafficVehiclesCountConfig> = {
  speedBinsInMph: validateDataBinTypes,
  lengthBinsInFeet: validateDataBinTypes,
};
export const trafficVehiclesCountConfigValuesValidationRules: IValidateDataEngineRules<ITrafficVehiclesCountConfig> = {
  speedBinsInMph: validateDataBinValues,
  lengthBinsInFeet: validateDataBinValues,
};
