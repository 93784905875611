import * as React from "react";

import {
  Input,
  EInputVariant,
} from "../../../../Input";

import {
  SxProps,
  Theme,
} from "../../../../ThemeProvider";

export interface ITextFilterProps {
  sx?: SxProps<Theme>;
  dataComponentName?: string;
  label: string;
  helperLabel: string;
  value: string;
  onChange: (value: string) => void;
}


export const TextFilter = (props: ITextFilterProps): JSX.Element => {
  const {
    sx = {},
    dataComponentName,
    label,
    helperLabel,
    value,

    onChange,
  } = props;

  return (
    <Input
      sx={sx}
      dataComponentName={[dataComponentName, "TextFilter"].filter(Boolean).join(' ')}
      label={label}
      variant={EInputVariant.OUTLINED}
      placeholder="Type..."
      helperLabel={helperLabel}
      value={value}
      onChange={onChange}
    />
  );
};
