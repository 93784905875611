import {IDBEntitySortOption} from "utils-library/dist/commonJs/db-entity";

import {ITrafficStudy} from "../../../interfaces/ITrafficStudy";

export const API_PATH_ApiTrafficStudiesSearchGet = '/services/traffic-studies/apiTrafficStudiesSearchGet';

export interface IApiTrafficStudiesSearchGetRequest {
  searchText: string;
  start?: number;
  end?: number;
  archived?: boolean | 'both';
  deleted?: boolean | 'both';
  sort?: {
    start?: IDBEntitySortOption;
    label?: IDBEntitySortOption;
  };
  skip: number;
  limit: number;
}

export interface IApiTrafficStudiesSearchGetResponse {
  trafficStudies: ITrafficStudy[];
}
