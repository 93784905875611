import {
  IDBEntityBase,
} from "utils-library/dist/commonJs/db-entity-interfaces";
import {TObject} from "utils-library/dist/commonJs/typescript";

import {EAlertType} from "./EAlertType";
import {EDeviceType} from "../../device-management/interfaces/EDeviceType";

export interface IAlertHandling<TAlert extends TObject, TAlertHandle extends TObject> extends IDBEntityBase {
  // Basic info
  type: EAlertType;
  title: string;              // Set by the device driver
  description: string;        // Set by the device driver
  regionId: string;
  locationId: string;
  deviceId: string;
  deviceType: EDeviceType;
  assignedToUserId: string;   // Which user is handling this. Initially none.
  // Hardware data
  alert: TAlert;              // The alert incident as created by the device driver
  // Software data
  alertHandle: TAlertHandle;  // Handle object updated by the user (only this is changed by the user)
  // User's data
  userTitle?: string;         // Override device driver's title
  userLabelText?: string;     // User's label shown in the list
  userLabelColor?: string;
  userFlag?: EAlertHandlingUserFlag;
  // Internal
  resolveId: string;          // Initially empty string, on resolve an id of the IAlertResolveOption
  showAt: number;     // Timestamp, when should be popped up again
  postponedCount: number;
  // Info: CRUD timestamps are provided here by the IDBEntityBase
  // Activity and User logs, supported by comments.
}

export enum EAlertHandlingUserFlag {
  TOMATO = "TOMATO",
  FLAMINGO = "FLAMINGO",
  ORANGE = "ORANGE",
  BANANA = "BANANA",
  SAGE = "SAGE",
  BASIL = "BASIL",
  CYAN = "CYAN",
  BLUEBERRY = "BLUEBERRY",
  GRAPE = "GRAPE",
  GRAPHITE = "GRAPHITE",
}
