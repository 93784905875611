import {
  IDBEntityBase,
  getDefaultDBEntityDocument,
} from "utils-library/dist/commonJs/db-entity-interfaces";

export interface ILocation extends IDBEntityBase {
  order: number;

  showOnMap: boolean;
  maintenanceMode: boolean;
  regionId: string;   // Parent

  masterDeviceId: string;

  label: string;
  description: string;

  geoPosition: {
    lat: number;
    lng: number;
    alt?: number;
  };

  notes: string;
}

export const getDefaultLocation = () => getDefaultDBEntityDocument<ILocation>({
  order: -1,
  showOnMap: true,
  maintenanceMode: false,
  regionId: '',
  masterDeviceId: '',
  label: '',
  description: '',
  geoPosition: {
    lat: -1,
    lng: -1,
    alt: 0,
  },
  notes: '',
});
