export const API_PATH_ApiTrafficStudyArchive = '/services/traffic-studies/apiTrafficStudyArchive';

export interface IApiTrafficStudyArchiveRequest {
  trafficStudyId: string;
  archiveIt: boolean;
}

export interface IApiTrafficStudyArchiveResponse {
  ok: true;
}
