import {ITrafficStudy} from "../../../interfaces/ITrafficStudy";

export const API_PATH_ApiTrafficStudyCopy = '/services/traffic-studies/apiTrafficStudyCopy';

export interface IApiTrafficStudyCopyRequest {
  sourceTrafficStudyId: string;
  deviceId: string;
  label: string;
}

export type IApiTrafficStudyCopyResponse = ITrafficStudy;
