import {IRegion} from "../../interfaces/IRegion";
import {ILocation} from "../../interfaces/ILocation";
import {IDevice} from "../../interfaces/IDevice";
import {IDeviceStatus} from "../../interfaces/IDeviceStatus";

export const API_PATH_ApiDeviceMgmtRegionDeviceStatusGet = '/services/devices-mgtm/apiDeviceMgmtRegionDeviceStatusGet';

export interface IApiDeviceMgmtRegionDeviceStatusGetBodyRequest {
  regionIds: string[];
}

export interface IApiDeviceMgmtRegionDeviceStatusGetResponse {
  regions: IRegion[];
  locations: ILocation[];
  devices: IDevice[];

  devicesStatusDic: {[deviceId: string]: IDeviceStatus<any> | null};
}
