import {IPreset} from "../../db/presets/PresetEntity.interfaces";

export const API_PATH_ApiAppPresetGet = '/services/app/presets/apiAppPresetGet';

export interface IApiAppPresetGetRequest {
  id: string;
}

export interface IApiAppPresetGetResponse<TPreset> {
  preset: IPreset<TPreset>;
}
