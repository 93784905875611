export const API_PATH_ApiDeviceMgmtLocationArchive = '/services/devices-mgtm/apiDeviceMgmtLocationArchive';

export interface IApiDeviceMgmtLocationArchiveBodyRequest {
  locationId: string;
  archiveIt: boolean;
}

export interface IApiDeviceMgmtLocationArchiveResponse {
  ok: true;
}
