/**
 * Decode encoded text
 * @param text
 * @returns string
 */

export const decodeText = (text: string): string => {
  let result: string = text.toLowerCase();

  [
    {
      encoded: `%20`,
      decoded: ` `,
    },
    {
      encoded: `%0A`,
      decoded: `\n`,
    },
    {
      encoded: `%3C`,
      decoded: `<`,
    },
    {
      encoded: `%3E`,
      decoded: `>`,
    },
    {
      encoded: `%2E`,
      decoded: `.`,
    },
    {
      encoded: `%3D`,
      decoded: `=`,
    },
    {
      encoded: `%22`,
      decoded: `"`,
    },
    {
      encoded: `%7B`,
      decoded: `{`,
    },
    {
      encoded: `%7D`,
      decoded: `}`,
    },
    {
      encoded: `%28`,
      decoded: `(`,
    },
    {
      encoded: `%29`,
      decoded: `)`,
    },
    {
      encoded: `%3E`,
      decoded: `>`,
    },
    {
      encoded: `&nbsp;`,
      decoded: ` `,
    },
    {
      encoded: `&lt;`,
      decoded: `<`,
    },
    {
      encoded: `&gt;`,
      decoded: `>`,
    },
    {
      encoded: `&amp;`,
      decoded: `&`,
    },
    {
      encoded: `&quot;`,
      decoded: `"`,
    },
    {
      encoded: `&apos;`,
      decoded: `'`,
    },
  ].forEach(set => {
    if (result.includes(set.encoded.toLowerCase())) {
      result = result
        .toLowerCase()
        .replace(new RegExp(set.encoded.toLowerCase(), 'g'), set.decoded)
        .toLowerCase();
    }
    else if (result.includes(set.encoded.toUpperCase())) {
      result = result
        .toUpperCase()
        .replace(new RegExp(set.encoded.toUpperCase(), 'g'), set.decoded)
        .toLowerCase();
    }
  });

  return result;
};
