export const textArrayEllipsis = (_array: (string | number)[], showCount: number): string => {
  const array = _array.concat();

  if (array.length === 1) return String(array[0]);
  if (showCount > array.length) return `${array.splice(0, array.length - 1).join(', ')} and ${array.pop()}`;

  const part1Content = array.slice(0, showCount);
  const part2Content = array.slice(showCount);
  let part2Text = "";
  if (part2Content.length === 1) part2Text = `${part2Content[0]}`;
  if (part2Content.length > 1) part2Text = `${part2Content.length} more`;
  if (part2Content.length === 0) part2Text = part1Content.pop() as any;

  return `${part1Content.join(', ')} and ${part2Text}`;
};
