import * as React from "react";
import {Box} from "../../../Box";

export interface IAppLogoProps {
  squareLogoImage?: string;
  landscapeLogoImage?: string;
}

export const AppLogo = (props: IAppLogoProps): JSX.Element => {
  const {
    squareLogoImage,
    landscapeLogoImage,
  } = props;

  return (
    <Box sx={{width: 256}}>
      <img
        style={{
          width: 56,
          height: 56,
          margin: "4px",
        }}
        src={squareLogoImage}
        alt="Application logo"
      />
      <img
        style={{
          maxWidth: 200,
          height: 56,
          margin: "4px",
        }}
        src={landscapeLogoImage}
        alt="Application logo"
      />
    </Box>
  );
};
