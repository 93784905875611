import {IUser} from "../../interfaces/IUser";

export const API_PATH_apiAuthCurrentUserInfoGet = '/services/authentication/apiAuthCurrentUserInfoGet';

export interface IApiAuthCurrentUserInfoGetRequestQuery {
  customTokenExpirationInDays?: number;
}

export interface IApiAuthCurrentUserInfoGetResponse {
  companyId: string;
  signedIn: boolean;
  user: IUser | null;
  authErrorMessage: string;   // Empty when is signedIn
  tokenExpiresAt: number;
}
