import * as React from "react";

import MuiIconButton from '@mui/material/IconButton';

import {IIconComponent} from "../IconComponent";

import {
  Theme,
  SxProps,
} from "../ThemeProvider";

export interface IIconButtonProps {
  ariaLabel: string;
  ariaHasPopUp?: boolean | 'false' | 'true' | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog';
  ariaControls?: string;
  size?: EIconButtonSize;   // Default is EIconButtonSize.LARGE
  sx?: SxProps<Theme>;
  show?: boolean;
  noPadding?: boolean;
  icon?: JSX.Element;
  Icon?: IIconComponent;
  disabled?: boolean;
  disableRipple?: boolean;
  title?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export enum EIconButtonSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export const IconButton = (props: IIconButtonProps): JSX.Element | null => {
  const {
    ariaLabel,
    ariaHasPopUp = false,
    ariaControls,
    sx = {},
    show = true,
    noPadding,
    icon,
    Icon,
    title,
    size,
    disabled,
    disableRipple,
    onClick,
  } = props;

  if (!show) return null;
  if (!icon && !Icon) return null;

  return (
    <MuiIconButton
      aria-label={ariaLabel}
      aria-haspopup={ariaHasPopUp}
      aria-controls={ariaControls}
      size={size}
      sx={sx}
      style={{padding: noPadding ? 0 : undefined}}
      title={title}
      disabled={disabled}
      disableRipple={disableRipple}
      onClick={onClick}
    >
      {icon}
      {Icon && (
        <span
          style={{
            borderRadius: '50%',
            overflow: 'hidden',
            fontSize: 0,
          }}
        >
          <Icon width={32}/>
        </span>
      )}
    </MuiIconButton>
  );
};
