import {useState} from "react";

export interface IUseLocalStorageConfig<TState> {
  key: string;
  default: TState;
  doNotSave?: boolean;    // If this is true, then changes won't be saved and this eventually will work a regular useState.
}

export interface IUseLocalStorageApi<TState> {
  state: TState;
  setState: (state: TState) => void;
}

export const useLocalStorageState = <TState, >(
  {
    key: _key,
    "default": defaultState,
    doNotSave = false,
  }: IUseLocalStorageConfig<TState>,
): [TState, (state: TState) => void] => {
  const key = `useLocalStorageState--${_key}`;

  const initialState: TState = (() => {
    const lsStateRaw = localStorage.getItem(key);
    if (lsStateRaw === undefined) return defaultState;
    if (lsStateRaw === null) return defaultState;
    return JSON.parse(lsStateRaw);
  })();

  const [state, _setState] = useState(initialState);

  const setState = (state: TState): void => {
    _setState(state);
    if (!doNotSave) localStorage.setItem(key, JSON.stringify(state));
  };

  return [
    state,
    setState,
  ];
};
