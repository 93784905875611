export interface IDBWrongWayResolution{
  date: number;
  roadEventId: string;
  resolutionId: string;
  notes: string;
  user: {
    id: string;
    avatarUrl?: string;
    firstName: string;
    lastName: string;
    loginName: string;
    phoneNumber?: string;
    email?: string;
    createdAt?: number;
    updatedAt?: number;
  };
}

export enum ECollectionNames {
  WRONG_WAY_RESOLUTIONS = 'wrong-way-resolutions',
}
