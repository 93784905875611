import {ITrafficStudy} from "../../../interfaces/ITrafficStudy";

export const API_PATH_ApiTrafficStudyPut = '/services/traffic-studies/apiTrafficStudyPut';

export interface IApiTrafficStudyPutRequest {
  id: string;

  fieldName:
    Exclude<
      keyof ITrafficStudy,
      | 'copiedFromStudyId'
      | 'trafficDataCollectedAt'
      | 'lastPublishedAt'
    >;
  value: any;

  historyReason?: string;
  historyTags?: string[];
}

export interface IApiTrafficStudyPutResponse {
  validationErrors: Partial<Record<keyof ITrafficStudy, string>>;
}
