import {IDynaError} from "dyna-error";

export enum ELogPostType {
  LOGS_CONNECTION = "LOGS_CONNECTION",  // Content is ILogsConnection
  LOG = "LOG",                          // Content is ILogContent
  COMMENT = "COMMENT",                  // Content is ICommentPost
}

export interface ILogsConnection {
}

export interface ILogContent {
  level: ELogLevel;
  title: string;
  timezone?: string;
  details?: string;
  error?: IDynaError;
  origin?: ELogOrigin;
  tags?: string[];
}

export enum ELogLevel {
  INFO = "INFO",
  LOG = "LOG",
  WARNING = "WARNING",
  ERROR = "ERROR",
  DEBUG = "DEBUG",
}

export enum ELogOrigin {
  SERVER_EXTERNAL = "SERVER_EXTERNAL",
  SERVER_APP = "SERVER_APP",
  WEB_APP_EXTERNAL = "WEB_APP_EXTERNAL",
  WEB_APP_APP = "WEB_APP_APP",
  USER = "USER",
}

export const getDefaultLogContent = (): ILogContent => ({
  level: ELogLevel.LOG,
  title: '',
});
