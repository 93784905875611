import * as React from "react";

import {
  TDataComponentName,
  getDataComponentName,
} from "../ui-interfaces";
import {ContainerRelative} from "./ContainerRelative";
import {ContainerAbsolut} from "./ContainerAbsolut";

import {
  Theme,
  SxProps,
} from "../ThemeProvider";

export interface IContainerRelativeAbsolutProps {
  dataComponentName?: TDataComponentName;
  sx?: SxProps<Theme>;
  show?: boolean;
  fullHeight?: boolean;
  children?: any;
}

/**
 * Create an Absolute div inside a Relative div
 *
 * Visually, there is no difference, but due to the Absolute div, this container decouples the children from parents' DOM element behaviors. Most noticeably, this container fixes broken nested scroll containers (CSS overflow) in Flex items.
 *
 * Flex containers are designed to be just flexible according to the available space, and scrolled content is sometimes still buggy. This is because of the Browser's engine! A ContainerRelativeAbsolut as flex item vanishes, this flex parentship and scroll bars are applied as expected.
 */
export const ContainerRelativeAbsolut: React.FC<IContainerRelativeAbsolutProps> = (
  {
    dataComponentName,
    sx,
    show = true,
    fullHeight = false,
    children = null,
  },
) => {
  return (
    <ContainerRelative
      dataComponentName={getDataComponentName(dataComponentName, "ContainerRelativeAbsolut")}
      sx={sx}
      show={show}
      fullHeight={fullHeight}
    >
      <ContainerAbsolut
        sx={{
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        {children}
      </ContainerAbsolut>
    </ContainerRelative>
  );
};
