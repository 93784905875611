import {
  ILoadPostsPagination,
  IDTOPost,
} from "../../../../../features/db-posts";

import {
  ELogPostType,
  ILogsConnection,
} from "../../../../../features/db-logs";

export const API_PATH_ApiTrafficStudyLogsLoadGet = '/services/traffic-studies/apiTrafficStudyLogsLoadGet';

export interface IApiTrafficStudyLogsLoadGetRequest {
  trafficStudyId: string;
  /**
   * Pagination for root and nested children
   */
  paginations: ILoadPostsPagination[];
}

export interface IApiTrafficStudyLogsLoadGetResponse {
  commentsConnection: IDTOPost<ELogPostType.LOGS_CONNECTION, ILogsConnection>;
}
