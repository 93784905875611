import {IAppRoute} from "../../application/config/IAppRoute";
import {EAlertsManagementRights} from "mhc-server";
import {AlertMgtmListPage} from "../pages/AlertMgtmListPage";
import {AlertIcon} from "../icons/AlertIcon";

export const routeAlertMgtmListPage: IAppRoute<{ presetId: string }> = {
  menuId: '###-alert-mgtm-list-page',
  title: 'Alerts Management',
  icon: <AlertIcon/>,
  routePath: '/alerts-management/list/:presetId?',
  getRoutePath: ({presetId = ""} = {presetId: ''}) =>
    routeAlertMgtmListPage
      .routePath
      .replace(':presetId?', presetId),
  userHasAllRights: [],
  userHasAnyOfRights: [EAlertsManagementRights.ALERT_HANDLING_VIEW],
  render: ({pathParams: {presetId}}) => <AlertMgtmListPage presetId={presetId}/>,
};
