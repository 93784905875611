import {round} from "./round";

export const roundToString = (value: number, precision: number): string => {
  const minValue: number = 1 / (Math.pow(10, precision));
  let text: string;

  if (value === 0 || value >= minValue) {
    text = round(value, precision).toLocaleString();
  }
  else {
    text = '<' + (minValue.toLocaleString());
  }

  if (precision > 0) {
    if (text.indexOf('.') === -1) text += '.';
    while (text.length - text.indexOf('.') - 1 < precision) text += '0';
  }

  return text;
};
