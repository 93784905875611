import {EDeviceType} from "../../interfaces/EDeviceType";
import {IDeviceStatus} from "../../interfaces/IDeviceStatus";

export const API_PATH_ApiDeviceMgmtDevicesStatusGetByIds = '/services/devices-mgtm/apiDeviceMgmtDevicesStatusGetByIds';

export interface IApiDeviceMgmtDevicesStatusGetByIdsRequest {
  regionId: string;
  locationId: string;
  deviceId: string;
  // Dev note:
  //    DeviceBrokerId and deviceType are required because the Device Item in the database might not have been updated yet.
  //    This occurs when there is a change in the device type or broker ID.  deviceBrokerId: string;
  deviceBrokerId: string;
  deviceType: EDeviceType;
}

export type IApiDeviceMgmtDevicesStatusGetByIdsResponse = IDeviceStatus<any>;
