import * as React from "react";
import {
  useState,
  useRef,
} from "react";

import {IDynaError} from "dyna-error";

import {
  FlexContainerVertical,
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "../FlexContainer";
import {Box} from "../Box";
import {
  Link,
  ELinkColor,
} from "../Link";
import {Modal} from "../Modal";
import {FormatDateTime} from "../FormatDateTime";
import {
  IconButton,
  EIconButtonSize,
} from "../IconButton";
import {
  ButtonBar,
  EButtonBarAlign,
} from "../ButtonBar";
import {
  Button,
  EButtonColor,
} from "../Button";
import {ButtonCopyToClipboard} from "../ButtonCopyToClipboard";

import {createIcon} from "../IconComponent";
import CloseIcon from '@mui/icons-material/Cancel';

export interface IErrorLinkModalViewerProps {
  error?: IDynaError | any;
  show?: boolean;           // Is a case you need to hide it anyway
  color?: ELinkColor;       // Default is ELinkColor.ERROR
  children?: string;        // Default is "Show error details" The error label
}

/**
 * Use this component to display the "Show error details" link.
 * Upon clicking, it will show a modal containing the error details.
 * @constructor
 */
export const ErrorLinkModalViewer = (props: IErrorLinkModalViewerProps): JSX.Element | null => {
  const {
    show: showLink = true,
    color = ELinkColor.ERROR,
    error,
    children = "Show error details",
  } = props;

  const refClipboardContent = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  if (!error) return null;

  const handleLinkClick = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const stringifiedError = JSON.stringify(error, null, 2);
  const errorReport = (
    <Box
      dataComponentName="ErrorReport"
      ref={refClipboardContent}
      sx={{
        fontFamily: 'monospace',
        whiteSpace: 'pre',
      }}
    >
      <p><b>Date:</b> <FormatDateTime/></p>
      <p><b>Website address:</b> <code>{window.location.href}</code></p>
      {
        stringifiedError === "{}"
          ? `Native Error toString(): ${error.toString()}`
          : stringifiedError
      }
    </Box>
  );

  return (
    <>
      <Link
        show={showLink && !!error}
        href=""
        color={color}
        onClick={handleLinkClick}
      >
        {children}
      </Link>
      <Modal
        sx={{padding: theme => theme.spacing(2)}}
        show={showModal}
        fullMode
        onBackdropClick={handleCloseModal}
      >
        <FlexContainerVertical
          fullHeight
          spacing={2}
        >
          <FlexItemMin>
            <FlexContainerHorizontal>
              <FlexItemMax>
                <h1>Error details</h1>
              </FlexItemMax>
              <FlexItemMin
                sx={{color: theme => theme.palette.error.main}}
              >
                <IconButton
                  Icon={createIcon.byMuiIcon(CloseIcon)}
                  size={EIconButtonSize.MEDIUM}
                  ariaLabel="Close"
                  onClick={handleCloseModal}
                />
              </FlexItemMin>
            </FlexContainerHorizontal>
          </FlexItemMin>
          <FlexItemMax
            overFlowX
            overFlowY
          >
            {errorReport}
          </FlexItemMax>
          <FlexItemMin>
            <ButtonBar
              reverseOnMac
              align={EButtonBarAlign.RIGHT}
            >
              <ButtonCopyToClipboard>
                {errorReport}
              </ButtonCopyToClipboard>
              <Button
                Icon={createIcon.byMuiIcon(CloseIcon)}
                color={EButtonColor.SECONDARY}
                onClick={handleCloseModal}
              >
                Close
              </Button>
            </ButtonBar>
          </FlexItemMin>
        </FlexContainerVertical>
      </Modal>
    </>
  );
};
