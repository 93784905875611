import * as React from "react";
import {ReactElement} from "react";

import {Box} from "../Box";
import TextField from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {
  DateTimePicker,
  TimePicker,
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import {TObject} from "utils-library/dist/commonJs/typescript";

import {
  HelperText,
  EHelperTextType,
} from "../HelperText";

export interface IInputDateTimePickerProps<TData> {
  mode?: EInputDateTimeMode;
  inputVariant?: EInputDateTimePickerVariant;
  timeMode?: EInputTimeMode;
  formatDate?: string;
  formatTime?: string;
  name?: keyof TData;
  label?: string;
  ariaLabel?: string;
  helperLabel?: string;
  validationError?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  showTodayButton?: boolean;
  openPickerToYear?: boolean;
  minutesStep?:
    | 1 // Default
    | 5
    | 15
    | 30;
  value: Date | null;
  minDate?: Date;
  maxDate?: Date;
  messages?: {
    today: string;
    now: string;
    todayAndNow: string;
  };
  onChange: (value: Date, name?: keyof TData) => void;  // MUI5 data time pickers at only controlled, only with v6 would be uncontrolled
}

export enum EInputDateTimeMode {
  DATE = "DATE",
  TIME = "TIME",
  DATE_TIME = "DATE_TIME",
}

export enum EInputTimeMode {
  AM_PM = "AM_PM",
  H24 = "H24",
}

export enum EInputDateTimePickerVariant {
  FILLED = "filled",
  OUTLINED = "outlined",
  STANDARD = "standard",
}

export const InputDateTimePicker = <TData extends TObject, >(props: IInputDateTimePickerProps<TData>): ReactElement => {
  const {
    mode = EInputDateTimeMode.DATE_TIME,
    inputVariant = EInputDateTimePickerVariant.STANDARD,
    timeMode = EInputTimeMode.H24,
    name,
    label,
    ariaLabel = label || "Date time picker",
    helperLabel,
    validationError,
    required = false,
    readOnly = false,
    disabled = false,
    openPickerToYear = false,
    minutesStep = 1,
    value,
    minDate,
    maxDate,
    onChange,
  } = props;

  const amPm = timeMode === EInputTimeMode.AM_PM;

  const handleValueChange = (value?: Date | string | null): void => {
    // Should not be undefined or null or "Invalid date" at any time during editing (especially with keyboard)
    if (!(value instanceof Date)) return;   // Would be string, null
    if (isNaN(value as any)) return; // Is "Invalid date"
    onChange(value, name);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        aria-label={ariaLabel}
        sx={{
          '& .MuiInputAdornment-root button': {
            position: 'relative',
            left: -4, // Fix the position of the icon
          },
        }}
        dataComponentName="InputDateTimePicker"
      >
        {(() => {
          switch (mode) {
            case EInputDateTimeMode.DATE_TIME:
              return (
                <DateTimePicker
                  componentsProps={{actionBar: {actions: ['today']}}}
                  ampm={amPm}
                  label={label}
                  readOnly={readOnly}
                  disabled={disabled}
                  hideTabs={false}
                  openTo={openPickerToYear ? "year" : "day"}
                  minDate={minDate}
                  maxDate={maxDate}
                  value={value}
                  minutesStep={minutesStep}
                  renderInput={(props: any) => (
                    <TextField
                      {...props}
                      sx={{
                        ...(props.sx || {}),
                        width: '100%',
                      }}
                      variant={inputVariant}
                      required={required}
                      error={!!validationError}
                    />)
                  }
                  onChange={handleValueChange}
                />
              );
            case EInputDateTimeMode.DATE:
              return (
                <DatePicker
                  componentsProps={{actionBar: {actions: ['today']}}}
                  label={label}
                  readOnly={readOnly}
                  disabled={disabled}
                  openTo={openPickerToYear ? "year" : "day"}
                  minDate={minDate}
                  maxDate={maxDate}
                  value={value}
                  renderInput={(props: any) => (
                    <TextField
                      {...props}
                      sx={{
                        ...(props.sx || {}),
                        width: '100%',
                      }}
                      variant={inputVariant}
                      error={!!validationError}
                      required={required}
                    />
                  )}
                  onChange={handleValueChange}
                />
              );
            case EInputDateTimeMode.TIME:
              return (
                <TimePicker
                  ampm={amPm}
                  label={label}
                  readOnly={readOnly}
                  disabled={disabled}
                  minutesStep={minutesStep}
                  value={value}
                  renderInput={(props: any) => (
                    <TextField
                      {...props}
                      sx={{
                        ...(props.sx || {}),
                        width: '100%',
                      }}
                      variant={inputVariant}
                      error={!!validationError}
                      required={required}
                    />
                  )}
                  onChange={handleValueChange}
                />
              );
          }
        })()}
      </Box>
      <HelperText>{helperLabel}</HelperText>
      <HelperText type={validationError ? EHelperTextType.ERROR : undefined}>{validationError}</HelperText>
    </LocalizationProvider>
  );
};
