export const API_PATH_apiUsersInvitationsUndeletePut = '/services/users-invitations/undelete';

export interface IApiUsersInvitationsUndeletePutBodyRequest {
  email: string;
  undeleteInfo: string;
}

export interface IApiUsersInvitationsUndeletePutResponse {
  ok: true;
}

