import * as React from "react";
import {dynaSwitchEnum} from "dyna-switch";
import {Link as RouterLink_} from "react-router-dom";

import MuiLink from "@mui/material/Link";

import {
  TDataComponentName,
  getDataComponentName,
} from "../ui-interfaces";

import {Box} from "../Box";
import {IconViewer} from "../IconViewer";

import {useKeyStatus} from "../useKeyStatus";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";
import {
  sxTransition,
  ECSSDuration,
} from "../sxTransition";
import {createIcon} from "../IconComponent";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export interface ILinkProps {
  sx?: SxProps<Theme>;
  show?: boolean;
  display?: ELinkDisplay;         // Default is INLINE
  dataComponentName?: TDataComponentName;
  title?: string;
  href?: string;                  // Hrefs starting with "/" are considered Local Links
  target?: "_blank" | "_self" | "_parent" | "_top";
  showOpenInNewIcon?: boolean;
  rel?: string;
  hidden?: boolean;
  color?: ELinkColor;             // Default is ELinkColor
  disabled?: boolean;
  underline?: ELinkUnderline;     // Default is ELinkUnderline.HOVER
  tabIndex?: number;
  routerLinkForLocals?: boolean;      // Set this to false, to navigate local links without using the react-router-link as "component".
  forLocalLinksScrollToTop?: boolean; // Default is true
  children: any;
  onClick?: (event: React.MouseEvent) => void;
}

export enum ELinkDisplay {
  INLINE = "INLINE",
  BLOCK = "BLOCK",
}

export enum ELinkColor {
  INHERIT = "INHERIT",
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  INFO = "INFO",
  WARNING = "WARNING",
  WHITE = "WHITE",
}

export enum ELinkUnderline {
  ALWAYS = "always",
  HOVER = "hover",
  NONE = "none",
}

export const Link = (props: ILinkProps): JSX.Element | null => {
  const {
    sx = {},
    show = true,
    display = ELinkDisplay.INLINE,
    dataComponentName,
    color = ELinkColor.PRIMARY,
    disabled = false,
    underline = ELinkUnderline.HOVER,
    title,
    href = "",
    target,
    showOpenInNewIcon,
    rel,
    hidden = false,
    tabIndex,
    routerLinkForLocals = true,
    forLocalLinksScrollToTop = true,
    children,
    onClick,
  } = props;

  const {
    shiftKeyDown,
    ctrlKeyDown,
    cmdKeyDown,
  } = useKeyStatus();

  if (!show) return null;

  const muiButtonColor =
    dynaSwitchEnum<
      ELinkColor,
      | 'inherit'
      | 'primary'
      | 'secondary'
      | 'success'
      | 'error'
      | 'info'
      | 'warning'
      | 'white'
    >(
      color,
      {
        [ELinkColor.INHERIT]: 'inherit',
        [ELinkColor.PRIMARY]: 'primary',
        [ELinkColor.SECONDARY]: 'secondary',
        [ELinkColor.SUCCESS]: 'success',
        [ELinkColor.ERROR]: 'error',
        [ELinkColor.INFO]: 'info',
        [ELinkColor.WARNING]: 'warning',
        [ELinkColor.WHITE]: 'white',
      },
    );

  const handleClick = (event: React.MouseEvent): void => {
    if (disabled) return;

    if (
      href
      && forLocalLinksScrollToTop
      && href.startsWith('/')
      && target !== "_blank"
      && !shiftKeyDown
      && !ctrlKeyDown
      && !cmdKeyDown
    ) {
      window.scrollTo(0, 0);
    }

    onClick && onClick(event);
  };

  if (hidden) return null;

  const isLocalLink = href.startsWith('/') && routerLinkForLocals;
  const sxLink: SxProps<Theme> = {
    ...sx,
    opacity: disabled ? 0.6 : undefined,
    cursor: !disabled && (!!href || !!onClick) ? 'pointer' : undefined,
  };

  return (
    <Box
      data-component-name={getDataComponentName(dataComponentName, "Link")}
      inline={display === ELinkDisplay.INLINE}
    >
      <MuiLink
        sx={sxLink}
        component={
          isLocalLink
            ? RouterLink_ as any
            : undefined
        }
        title={title}
        color={muiButtonColor}
        underline={underline}
        href={href ? isLocalLink ? undefined : href : undefined}
        to={href ? isLocalLink ? href : undefined : undefined}
        rel={rel}
        target={target}
        tabIndex={tabIndex}
        onClick={handleClick}
      >
        {children}
      </MuiLink>
      {showOpenInNewIcon && (
        <MuiLink
          sx={{
            ...sxLink,
            position: 'relative',
            top: 2,
            p: 1,
            transition: theme => sxTransition(theme, 'background', ECSSDuration.SHORT),
            ':hover': {background: theme => theme.palette.grayShades.gray1},
          }}
          underline={underline}
          rel={rel}
          href={href}
          target="_blank"
          tabIndex={tabIndex}
          title="Open in a new tab"
        >
          <IconViewer Icon={createIcon.byMuiIcon(OpenInNewIcon)}/>
        </MuiLink>
      )}
    </Box>
  );
};
