// Types & data

export {
  IUser,
  getDefaultUser,
} from "../../interfaces/IUser";

export {
  EUserAuthenticationRights,
  EUserAuthenticationRightsArray,
} from "../../interfaces/EUserAuthenticationRights";

export {
  EAuthStrategy,
} from "../../interfaces/EAuthStrategy";

// Endpoints

export {
  API_PATH_apiAuthCurrentUserInfoGet,
  IApiAuthCurrentUserInfoGetRequestQuery,
  IApiAuthCurrentUserInfoGetResponse,
} from "../../endpoints/userInfo/apiAuthCurrentUserInfoGet.interfaces";

export {
  API_PATH_apiAuthLogoutGet,
  IApiAuthLogoutGet,
} from "../../endpoints/logout/apiAuthLogoutGet.interfaces";

export {
  API_PATH_ApiCreateCompanyGet,
  IApiCreateCompanyGetQueryRequest,
  IApiCreateCompanyGetResponse,
} from "../../endpoints/authCreateCompany/apiCreateCompanyGet.interfaces";

export {
  API_PATH_ApiCreateCompanyPost,
  IApiCreateCompanyPostBodyRequest,
  IApiCreateCompanyPostResponse,
} from "../../endpoints/authCreateCompany/apiCreateCompanyPost.interfaces";

export {
  API_PATH_ApiCreateCompanyDelete,
  IApiCreateCompanyDeleteBodyRequest,
  IApiCreateCompanyDeleteResponse,
} from "../../endpoints/authCreateCompany/apiCreateCompanyDelete.interfaces";

export {
  API_PATH_apiAuthBasicSignInV2Get,
  IApiAuthBasicSignInV2GetQuery,
} from "../../endpoints/authBasicSignIn/apiAuthBasicSignInV2Get.interfaces";

// OAuth API paths

export {
  API_PATH_apiAuthGoogleOpenIdMethodRedirect,
} from "../../endpoints/authOpenIdGoogle/apiOAuthOpenIDGoogle.interfaces";

export {
  API_PATH_apiAuthMicrosoftOpenIdMethodRedirect,
} from "../../endpoints/authOpenIdMicrosoft/apiOpenIdMicrosoft.interfaces";

export {
  API_PATH_apiAuthOhioOpenIdMethodRedirect,
} from "../../endpoints/authOpenIdOhio/apiOAuthOpenIDOhio.interfaces";
