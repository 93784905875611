export const isCurrentAddressSame = (pathOrUrl: string): boolean => {
  if (!sameQueries(window.location.href, pathOrUrl)) return false;

  const isFullUrl = pathOrUrl.startsWith('http');
  if (isFullUrl) return sameSlugs(window.location.href, pathOrUrl);

  return sameSlugs(window.location.pathname, pathOrUrl);
};

const sameSlugs = (aLink: string, bLink: string): boolean => {
  return (
    aLink
      .split('/')
      .filter(Boolean)
      .join('/')
    === bLink.split('/')
      .filter(Boolean)
      .join('/')
  );
};

const sameQueries = (aLink: string, bLink: string): boolean => {
  return (
    aLink
      .split('?')
      .slice(1)
      .join('?')
    === bLink.split('?')
      .slice(1)
      .join('?')
  );
};
