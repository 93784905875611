import * as React from "react";
import {dynaSwitchEnum} from "dyna-switch";

import {
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "../FlexContainer";
import {Box} from "../Box";
import {CenterContainer} from "../CenterContainer";
import {Collapse} from "../Collapse";
import {
  Button,
  EButtonVariant,
  EButtonSize,
} from "../Button";

import {IconViewer} from "../IconViewer";
import {
  useTheme,
  SxProps,
  Theme,
} from "../ThemeProvider";
import {alpha} from "@mui/material/styles";

import {sxTransitionShowHide} from "../sxTransition";
import {
  createIcon,
  IIconComponent,
} from "../IconComponent";
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Report';
import CloseIcon from "@mui/icons-material/Close";
import NeutralIcon from '@mui/icons-material/PlayArrow';
import BugIcon from '@mui/icons-material/BugReport';

export interface IAlertProps {
  sx?: SxProps<Theme>;
  dataComponentName?: string;
  type?: EAlertType;                // Default is EAlertType.INFO
  variant?: EAlertVariant;          // Default is EAlertVariant.FILLED
  color?: string;                   // Override the default color of the type
  Icon?: IIconComponent;            // Override the default icon of the type
  show?: boolean;                   // Render or not
  title?: string;                   // Alert will open on first render and when the title or children has value.
  children?: any | null;            //   The alert can close by the Close Button or if the title and children have no value.
  rightPlace?: any;                 // Right place between the children and the close button
  marginBottom?: number;            // Default is 2, Theme spacing. Applied only when it is shown (with animation)
  closeButton?: {
    Icon?: IIconComponent;          // Default is 'x' icon
    label?: string;
    onClick: () => void;
  };
}

export enum EAlertVariant {
  FILLED = "filled",
  OUTLINED = "outlined",
  STANDARD = "standard",
}

export enum EAlertType {
  SUCCESS = "SUCCESS",
  INFO = "INFO",
  WARNING = "WARNING",
  ERROR = "ERROR",
  NEUTRAL = "NEUTRAL",
  DEBUG = "DEBUG",
}

type TAlertColors =
  Record<
    "dark" | "light",
    Record<
      EAlertType,
      {
        baseColor: string;
        contrastColor: string;
      }
    >
  >;

const alertColors = (theme: Theme): TAlertColors => ({
  light: {
    [EAlertType.INFO]: {
      baseColor: theme.palette.info.dark,
      contrastColor: theme.palette.background.default,
    },
    [EAlertType.WARNING]: {
      baseColor: theme.palette.warning.dark,
      contrastColor: theme.palette.background.default,
    },
    [EAlertType.SUCCESS]: {
      baseColor: theme.palette.success.dark,
      contrastColor: theme.palette.background.default,
    },
    [EAlertType.ERROR]: {
      baseColor: theme.palette.error.dark,
      contrastColor: theme.palette.background.default,
    },
    [EAlertType.NEUTRAL]: {
      baseColor: theme.palette.grayShades.gray8,
      contrastColor: theme.palette.grayShades.gray2,
    },
    [EAlertType.DEBUG]: {
      baseColor: theme.palette.warning.dark,
      contrastColor: theme.palette.background.default,
    },
  },
  dark: {
    [EAlertType.INFO]: {
      baseColor: theme.palette.info.light,
      contrastColor: theme.palette.background.default,
    },
    [EAlertType.WARNING]: {
      baseColor: theme.palette.warning.light,
      contrastColor: theme.palette.background.default,
    },
    [EAlertType.SUCCESS]: {
      baseColor: theme.palette.success.light,
      contrastColor: theme.palette.background.default,
    },
    [EAlertType.ERROR]: {
      baseColor: theme.palette.error.light,
      contrastColor: theme.palette.background.default,
    },
    [EAlertType.NEUTRAL]: {
      baseColor: theme.palette.grayShades.gray8,
      contrastColor: theme.palette.grayShades.gray2,
    },
    [EAlertType.DEBUG]: {
      baseColor: theme.palette.warning.light,
      contrastColor: theme.palette.background.default,
    },
  },
});


export const Alert: React.FC<IAlertProps> = (
  {
    sx = {},
    dataComponentName,
    type = EAlertType.INFO,
    variant = EAlertVariant.FILLED,
    Icon,
    title = '',
    show = true,
    children = null,
    rightPlace,
    closeButton,
    marginBottom = 1,
  },
) => {
  const theme = useTheme();

  const colors = alertColors(theme);
  const baseColor = colors[theme.palette.mode][type].baseColor;
  const contrastColor = colors[theme.palette.mode][type].contrastColor;

  return (
    <Box
      sx={{
        transition: sxTransitionShowHide(theme, 'margin-bottom', show),
        marginBottom: show ? marginBottom : 0,
        ...sx,
      }}
      dataComponentName={[dataComponentName, "Alert"]}
    >
      <Collapse expanded={show}>
        <FlexContainerHorizontal
          sx={{
            overflowWrap: 'anywhere',
            borderRadius: 2,
            borderWidth: 1,
            borderStyle: 'solid',
            ...dynaSwitchEnum<EAlertVariant, SxProps<Theme>>(
              variant,
              {
                [EAlertVariant.FILLED]: {
                  color: contrastColor,
                  background: baseColor,
                  borderColor: baseColor,
                },
                [EAlertVariant.OUTLINED]: {
                  color: baseColor,
                  borderColor: baseColor,
                },
                [EAlertVariant.STANDARD]: {
                  color: baseColor,
                  background: alpha(baseColor, 0.1),
                  borderColor: alpha(baseColor, 0.3),
                },
              },
            ),
          }}
          spacing={1}
        >
          <FlexItemMin sx={{pt: '2px'}}>
            <IconViewer
              width={32}
              Icon={
                Icon
                  ? Icon
                  : dynaSwitchEnum<EAlertType, IIconComponent | undefined>(
                    type,
                    {
                      [EAlertType.INFO]: createIcon.byMuiIcon(InfoIcon),
                      [EAlertType.WARNING]: createIcon.byMuiIcon(WarningIcon),
                      [EAlertType.SUCCESS]: createIcon.byMuiIcon(SuccessIcon),
                      [EAlertType.ERROR]: createIcon.byMuiIcon(ErrorIcon),
                      [EAlertType.NEUTRAL]: createIcon.byMuiIcon(NeutralIcon),
                      [EAlertType.DEBUG]: createIcon.byMuiIcon(BugIcon),
                    },
                  )
              }
            />
          </FlexItemMin>

          <FlexItemMax>
            <CenterContainer
              horizontally="left"
              sx={{'>div': {width: "100%"}}}
            >
              <Box
                sx={{
                  fontSize: theme => theme.typography.fontSize * 1.1,
                  fontWeight: 'bold',
                }}
              >
                {title}
              </Box>
              <Box sx={{fontSize: theme => theme.typography.fontSize * 0.9}}>
                {children}
              </Box>
            </CenterContainer>
          </FlexItemMax>

          <FlexItemMin show={!!rightPlace}>
            {rightPlace}
          </FlexItemMin>

          {!!closeButton && (
            <FlexItemMin sx={{pt: '6px'}}>
              <Button
                show={!!closeButton}
                variant={EButtonVariant.TRANSPARENT}
                sx={{
                  color: dynaSwitchEnum<EAlertVariant, string>(
                    variant,
                    {
                      [EAlertVariant.FILLED]: contrastColor,
                      [EAlertVariant.OUTLINED]: baseColor,
                      [EAlertVariant.STANDARD]: baseColor,
                    },
                  ),
                }}
                size={EButtonSize.XXSMALL}
                Icon={closeButton.Icon || createIcon.byMuiIcon(CloseIcon)}
                onClick={closeButton.onClick}
              >
                {closeButton.label}
              </Button>
            </FlexItemMin>
          )}
        </FlexContainerHorizontal>
      </Collapse>
    </Box>
  );
};
