import {PaletteOptions} from "@mui/material/styles/createPalette";
import {grayShades} from "./grayShades";

export const MUIV4_PALETTE_LIGHT: PaletteOptions = {
  mode: 'light',
  appMainColor: {
    light: '#7986cb',
    main: '#3f51b5',
    dark: '#303f9f',
    contrastText: '#fff',
  },
  primary: {
    light: '#7986cb',
    main: '#3f51b5',
    dark: '#303f9f',
    contrastText: '#fff',
  },
  secondary: {
    light: '#df82ec',
    main: '#c25bd3',
    dark: '#a823be',
    contrastText: '#fff',
  },
  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  warning: {
    light: '#ffb74d',
    main: '#ff9800',
    dark: '#f57c00',
    contrastText: '#fff',
  },
  info: {
    light: '#64b5f6',
    main: '#2196f3',
    dark: '#303f9f',
    contrastText: '#fff',
  },
  success: {
    light: '#81c784',
    main: '#4caf50',
    dark: '#388e3c',
    contrastText: '#fff',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
  background: {
    default: '#fafafa',
    paper: '#f4f4f4',
  },
  grayShades: {
    gray0: grayShades[0],
    gray1: grayShades[1],
    gray2: grayShades[2],
    gray3: grayShades[3],
    gray4: grayShades[4],
    gray5: grayShades[5],
    gray6: grayShades[6],
    gray7: grayShades[7],
    gray8: grayShades[8],
    gray9: grayShades[9],
  },
};
