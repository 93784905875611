import {TObject} from "utils-library/dist/commonJs/typescript";

export type TEventHandlerClient<TPayload> = (args: {
  type: string;
  payload: TPayload;
}) => void;

/**
 * Service worker communication channel, client version
 * Communicate with the service worker easily.
 * Changes of this should be converted to JS with https://www.typescriptlang.org/play and pasted in the index.html of the app.
 */
export class SWChannelClient {
  //#region "Init"

  constructor() {
    this.init();
  }

  private init(): void {
    this.setupMessageListener();
  }

  //#endregion "Init"

  //#region "Send"

  public sendToActiveWorker = async (
    {
      type,
      payload,
      throwErrorOnNoWorker = true,
    }: {
      type: string;
      payload?: any;
      throwErrorOnNoWorker?: boolean;
    },
  ): Promise<void> => {
    return this.sendToWorker({
      type,
      payload,
      throwErrorOnNoWorker,
      serviceWorkerType: 'active',
    });
  };

  public sendToWaitingWorker = async (
    {
      type,
      payload,
      throwErrorOnNoWorker = true,
    }: {
      type: string;
      payload?: any;
      throwErrorOnNoWorker?: boolean;
    },
  ): Promise<void> => {
    return this.sendToWorker({
      type,
      payload,
      throwErrorOnNoWorker,
      serviceWorkerType: 'waiting',
    });
  };

  private sendToWorker = async (
    {
      serviceWorkerType = "active",
      throwErrorOnNoWorker = true,
      type,
      payload,
    }: {
      serviceWorkerType?:
        | 'active'
        | 'waiting';
      throwErrorOnNoWorker?: boolean;
      type: string;
      payload?: any;
    },
  ): Promise<void> => {
    const serviceWorker: ServiceWorker | null = await this.getServiceWorker({serviceWorkerType});

    if (!serviceWorker) {
      if (throwErrorOnNoWorker) {
        throw new Error(`Internal error 20231219110127: Could not resolve service worker of type [${serviceWorkerType}]`);
      }
      else {
        return; // Exit silently
      }
    }

    serviceWorker.postMessage({
      type,
      payload,
    });
  };

  private getServiceWorker = async (
    {serviceWorkerType}: {
      serviceWorkerType?:
        | 'active'
        | 'waiting';
    },
  ): Promise<ServiceWorker | null> => {
    const registrations = await window.navigator?.serviceWorker?.getRegistrations() || [];
    const registration =
      registrations
        .find(reg => {
          if (serviceWorkerType === "active") return !!reg.active;
          if (serviceWorkerType === "waiting") return !!reg.waiting;
          return false;
        });
    if (!registration) return null;
    if (serviceWorkerType === "active") return registration.active;
    if (serviceWorkerType === "waiting") return registration.waiting;
    return null;
  };

  public get browserSupportServiceWorkers(): boolean {
    return !!window.navigator?.serviceWorker;
  }

  public async hasWaitingServiceWorker(): Promise<boolean> {
    const sw = await this.getServiceWorker({serviceWorkerType: 'waiting'});
    return !!sw;
  }

  //#endregion "Send"

  //#region "Add / Remove / Trigger listeners"

  private eventListeners: Record<string, Array<TEventHandlerClient<any>>> = {};

  public addEventListener = <TPayload, >(type: string, cb: TEventHandlerClient<TPayload>): void => {
    if (!this.eventListeners[type]) this.eventListeners[type] = [];
    this.eventListeners[type].push(cb);
  };

  public removeEventListener = (type: string, cb: TEventHandlerClient<any>): void => {
    if (!this.eventListeners[type]) this.eventListeners[type] = [];
    this.eventListeners[type] = this.eventListeners[type].filter(scanCb => scanCb !== cb);
    if (!this.eventListeners[type].length) delete this.eventListeners[type];
  };

  private setupMessageListener(): void {
    window.navigator?.serviceWorker?.addEventListener('message', event => {
      const {
        type,
        payload,
      } = event.data;

      this.handleIncomingServiceWorkerMessage({
        type,
        payload,
      });
    });
  }

  private handleIncomingServiceWorkerMessage = (
    {
      type,
      payload,
    }: {
      type: string;
      payload?: TObject;
    },
  ): void => {
    (this.eventListeners[type] || [])
      .forEach(cb => cb({
        type,
        payload,
      }));
  };

  //#endregion "Add / Remove / Trigger listeners"

  //#region "Request / Response"

  public requestFromActiveWorker<TRequestPayload, TResponsePayload>(
    {
      type,
      payload: requestPayload,
    }: {
      type: string;
      payload?: TRequestPayload;
    },
  ): Promise<TResponsePayload> {
    return new Promise<TResponsePayload>((resolve, reject) => {
      (async () => {
        const requestId =
          Date.now()
          + '-'
          + Math.random()
            .toFixed(10)
            .slice(2);
        const responseType = `@@sw-channel@@/${type}/response/${requestId}`;

        const handleResponse: TEventHandlerClient<TResponsePayload> = ({payload}) => {
          resolve(payload);
          this.removeEventListener(responseType, handleResponse);
        };

        try {
          this.addEventListener(responseType, handleResponse);
          await this.sendToActiveWorker({
            type: `@@sw-channel@@/${type}/request`,
            payload: {
              payload: requestPayload,
              requestId,
            },
          });
        }
        catch (e) {
          reject(e);
          this.removeEventListener(responseType, handleResponse);
        }
      })();
    });
  }

  //#endregion "Request / Response"
}

(window as any).SWChannelClient = SWChannelClient;
