import {
  IDBEntityBase,
  getDefaultDBEntityDocument,
} from "utils-library/dist/commonJs/db-entity-interfaces";

export interface IDeviceMgtmSettings extends IDBEntityBase {
  defaultDevicesOutdatedMsThreshold: number;
  defaultDevicesDeadMsThreshold: number;
}

export const getDefaultIDeviceMgtmSettings = (): IDeviceMgtmSettings => getDefaultDBEntityDocument<IDeviceMgtmSettings>({
  defaultDevicesOutdatedMsThreshold: 5000,
  defaultDevicesDeadMsThreshold: 60000,
});

