import * as React from "react";
import {useMemo} from "react";
import {guid} from "dyna-guid";

import MuiSwitch from '@mui/material/Switch';

import {Box} from "../Box";

import {
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "../FlexContainer";

import {
  HelperText,
  EHelperTextType,
} from "../HelperText";

import {
  useTheme,
  SxProps,
  Theme,
} from "../ThemeProvider";
import {CenterContainer} from "../CenterContainer";
import {IIconComponent} from "../IconComponent";

export interface IInputSwitchProps<TData> {
  sx?: SxProps<Theme>;
  show?: boolean;
  dataComponentName?: string;
  name?: keyof TData;
  label?: string | JSX.Element;
  title?: string;
  Icon?: IIconComponent;
  helperLabel?: string;
  hidden?: boolean;
  ariaLabel: string;
  switchPosition?: EInputSwitchPosition;
  color?: EInputSwitchColor;
  disabled?: boolean;
  readOnly?: boolean;
  nowrap?: boolean;
  validationError?: string;
  value: boolean;
  onChange?: (value: boolean, name?: keyof TData) => void;
}

export enum EInputSwitchColor {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum EInputSwitchPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export const InputSwitch = <TData, >(props: IInputSwitchProps<TData>): JSX.Element => {
  const theme = useTheme();
  const id = useMemo(guid, []);
  const {
    sx = {},
    show = true,
    dataComponentName,
    name,
    hidden,
    label = null,
    title,
    Icon,
    helperLabel,
    ariaLabel,
    switchPosition = EInputSwitchPosition,
    disabled = false,
    readOnly = false,
    nowrap = false,
    color = EInputSwitchColor.PRIMARY,
    value,
    validationError,
    onChange,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    event; // 4TS
    if (!onChange) return;
    if (disabled) return;
    if (readOnly) return;
    onChange(checked, name);
  };

  const onlyLabel = !helperLabel && !validationError;

  return (
    <Box
      hidden={hidden}
      show={show}
      title={ariaLabel}
    >
      <FlexContainerHorizontal
        sx={sx}
        dataComponentName={[dataComponentName, "InputSwitch"].filter(Boolean).join(' ')}
        reverseOrder={switchPosition === EInputSwitchPosition.RIGHT}
      >

        <FlexItemMin>
          <MuiSwitch
            sx={{pointerEvents: readOnly ? "none" : undefined}}
            id={id}
            name={name as any}
            aria-label={ariaLabel}
            title={title}
            readOnly={readOnly}     // Dev info: readonly is not respected by MUI. We still have the `change` event triggered. Maybe this is not a fault of MUI, since the `readonly` attribute is applied on the DOM.
            color={color}
            checked={value}
            disabled={disabled}
            onChange={handleChange}
          />
        </FlexItemMin>

        {!!Icon && (
          <FlexItemMin
            sx={{
              position: 'relative',
              top: theme => theme.spacing(1),
              marginLeft: theme => switchPosition === EInputSwitchPosition.RIGHT ? theme.spacing(1) : undefined,
            }}
          >
            <Icon width={24}/>
          </FlexItemMin>
        )}

        <FlexItemMax
          sx={{marginLeft: theme.spacing(1)}}
        >
          <CenterContainer horizontally="left">
            <Box
              sx={{
                marginBottom: onlyLabel ? undefined : theme.spacing(1),
                whiteSpace: nowrap ? "nowrap" : undefined,
              }}
            >
              <label aria-label={ariaLabel}>
                {label}
              </label>
            </Box>
            <HelperText>{helperLabel}</HelperText>
            <HelperText type={EHelperTextType.ERROR}>{validationError}</HelperText>
          </CenterContainer>
        </FlexItemMax>

      </FlexContainerHorizontal>
    </Box>
  );
};
