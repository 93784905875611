import * as L from "leaflet";
import {Map} from "leaflet";

import 'leaflet.offline';
import {deleteDB} from 'idb';

import {
  DbTiles,
  THandleSaveMaps,
} from "./DbTiles";

import {findElements} from "./utils/findElements";

export type THandleDeleteMaps = (args: THandleDeleteMapsArgs) => void;

export type THandleDeleteMapsArgs = {
  savedMapsCount: number;
  savedMapsLabel: string;
  deleteMaps: () => Promise<void>;
};

export interface ILeafletOfflineConfig {
  leafletMap: Map;
  showOfflineSaveDeleteButtons: boolean;
  onSaveOfflineMaps: THandleSaveMaps;
  onDeleteOfflineMaps: THandleDeleteMaps;
}

export interface ILeafletOfflineApi {
  enableSaveButton: (disable: boolean) => void;
}

export const leafletOffline = (
  {
    leafletMap,
    showOfflineSaveDeleteButtons,
    onSaveOfflineMaps,
    onDeleteOfflineMaps,
  }: ILeafletOfflineConfig,
): ILeafletOfflineApi => {
  const dbTiles = new DbTiles({onSaveOfflineMaps});

  const offlineLayer =
    (L as any)
      .tileLayer
      .offline(
        'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          subdomains: 'abc',
          minZoom: 13,
          maxZoom: 19,
          crossOrigin: true,
        },
      );
  offlineLayer.addTo(leafletMap);

  // Delete indexedDB used by leaflet.offline
  // We use our own idb in the DbTiles class through localforage
  deleteDB('leaflet.offline');

  if (showOfflineSaveDeleteButtons) {
    const offlineControl =
      (L as any)
        .control
        .savetiles(
          offlineLayer,
          {
            zoomlevels: [13, 19],
            confirm: async (nTilesToSave: any) => {
              await dbTiles.saveTiles(nTilesToSave._tilesforSave);
            },
            confirmRemoval: async () => {
              const savedMapsCount = await dbTiles.getSavedMapsCount();
              const savedMapsLabel = dbTiles.getMapsCountLabel(savedMapsCount);
              onDeleteOfflineMaps({
                savedMapsCount,
                savedMapsLabel,
                deleteMaps: () => dbTiles.clear(),
              });
            },
            saveText:
            `<div class="geo-map-offline-save-button" style="${buttonStyle}">
                <i class="fa fa-save"></i>
              </div>`,
            rmText:
              `<div style="${buttonStyle}">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </div>`,
          });
    offlineControl.addTo(leafletMap);
  }

  return {
    enableSaveButton: (enable: boolean): void => {
      findElements('.geo-map-offline-save-button').forEach(element => {
        element.parentNode.style.transition = 'opacity 150ms ease-in-out, backgroundColor 150ms ease-in-out';
        element.parentNode.style.pointerEvents = enable ? '' : 'none';
        element.parentNode.style.opacity = enable ? 1 : 0.5;
      });
    },
  };
};

const buttonStyle: string = `
  font-size: 16px;
  text-align: center;
`;
