import {IDevice} from "../../interfaces/IDevice";

export const API_PATH_ApiDeviceMgmtDevicesGet = '/services/devices-mgtm/apiDeviceMgmtDevicesGet';

export interface IApiDeviceMgmtDevicesGetQueryRequest {
  regionId: string;
  locationId: string;
  search: string;
  archived: "true" | "false";
  deleted: "true" | "false";
}

export interface IApiDeviceMgmtDevicesGetResponse {
  devices: IDevice[];
}
