import * as React from "react";
import {useEffect} from "react";

import MUI5ThemeProvider from "@mui/material/styles/ThemeProvider";

import CssBaseline from '@mui/material/CssBaseline';

import {MUIV5_PALETTE_LIGHT} from "./palettes/MUIV5_PALETTE_LIGHT";
import {MUIV5_PALETTE_DARK} from "./palettes/MUIV5_PALETTE_DARK";
import {MUIV4_PALETTE_LIGHT} from "./palettes/MUIV4_PALETTE_LIGHT";
import {MUIV4_PALETTE_DARK} from "./palettes/MUIV4_PALETTE_DARK";

import {Theme as MuiTheme} from "@mui/material/styles";
import muiCreateTheme from "@mui/material/styles/createTheme";

export interface IThemeProviderProps {
  // Theme setup
  themeName?: EThemeName;
  themeSize: EThemeSize;
  themeChange?: (themeName: EThemeName, theme: Theme) => void;

  children: any;
}

// eslint-disable-next-line
export interface Theme extends MuiTheme {
}

export enum EThemeName {
  MUI4_LIGHT = 'MUI4_LIGHT',
  MUI4_DARK = 'MUI4_DARK',
  MUI5_LIGHT = 'MUI5_LIGHT',
  MUI5_DARK = 'MUI5_DARK',
}

export enum EThemeSize {
  XSMALL = "XSMALL",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  XLARGE = "XLARGE",
}

const themeSizes: Record<EThemeSize, { spacing: number; fontSize: number }> = {
  [EThemeSize.XSMALL]: {
    spacing: 4,
    fontSize: 12,
  },
  [EThemeSize.SMALL]: {
    spacing: 6,
    fontSize: 13,
  },
  [EThemeSize.MEDIUM]: {
    spacing: 8,
    fontSize: 14,
  },
  [EThemeSize.LARGE]: {
    spacing: 8,
    fontSize: 16,
  },
  [EThemeSize.XLARGE]: {
    spacing: 10,
    fontSize: 18,
  },
};

export const createTheme = (
  {
    themeName,
    themeSize,
  }: {
    themeName: EThemeName;
    themeSize: EThemeSize;
  },
): Theme => {
  const muiMode: any = (themeName as any).split('_').pop()
    .toLocaleLowerCase();

  const {
    spacing,
    fontSize,
  } = themeSizes[themeSize];

  const theme: Theme = muiCreateTheme({
    palette: {mode: muiMode},
    spacing,
    typography: {fontSize},
  });

  theme.palette = {
    ...theme.palette,
    ...(
      {
        [EThemeName.MUI5_LIGHT]: MUIV5_PALETTE_LIGHT,
        [EThemeName.MUI5_DARK]: MUIV5_PALETTE_DARK,
        [EThemeName.MUI4_LIGHT]: MUIV4_PALETTE_LIGHT,
        [EThemeName.MUI4_DARK]: MUIV4_PALETTE_DARK,
      }[themeName] as any
    ),
  };

  const getRem = (rem: number): string => `${fontSize * rem}px`;

  theme.typography.fontSize = fontSize;
  theme.typography.htmlFontSize = fontSize;

  theme.typography.h1.fontSize = getRem(2);
  theme.typography.h1.fontWeight = "bold";
  theme.typography.h2.fontSize = getRem(1.8);
  theme.typography.h2.fontWeight = "normal";
  theme.typography.h3.fontSize = getRem(1.4);
  theme.typography.h3.fontWeight = "bold";
  theme.typography.h4.fontSize = getRem(1.3);
  theme.typography.h4.fontWeight = "normal";
  theme.typography.h5.fontSize = getRem(1.2);
  theme.typography.h5.fontWeight = "bold";
  theme.typography.h6.fontSize = getRem(1.1);
  theme.typography.h6.fontWeight = "normal";

  theme.typography.body1.fontSize = getRem(0.9);
  theme.typography.body1.lineHeight = "";
  theme.typography.body1.marginBottom = "";
  theme.typography.body2.fontSize = getRem(0.8);
  theme.typography.body2.lineHeight = "";
  theme.typography.body2.marginBottom = "";

  theme.typography.button.textTransform = "none";

  theme.components = {
    MuiUseMediaQuery: {
      defaultProps: {
        defaultMatches: true,
        // Fixes the problem that on start up the MuiUseMediaQuery doesn't recognize the initial breakpoint
        // For more: https://github.com/chakra-ui/chakra-ui/issues/3124
        noSsr: true,
      },
    },
  };

  return theme;
};

export const ThemeProvider = (props: IThemeProviderProps): JSX.Element => {
  const {
    themeName = EThemeName.MUI4_LIGHT,
    themeSize = EThemeSize.SMALL,
    themeChange,
    children,
  } = props;


  const theme = createTheme({
    themeName,
    themeSize,
  });

  useEffect(() => {
    themeChange && themeChange(themeName, theme);
  }, [themeName]);

  return (
    <MUI5ThemeProvider theme={theme}>
      <CssBaseline/>
      {children}
    </MUI5ThemeProvider>
  );
};
