export const API_PATH_ApiAppPresetPost = '/services/app/presets/apiAppPresetPost';

export interface IApiAppPresetPostRequest<TPreset> {
  groupId: string;
  title: string;
  description: string;
  preset: TPreset;
  deleteAfterDaysOfInactivity: number;
}

export interface IApiAppPresetPostResponse {
  id: string;
}
