import * as React from "react";
import {useMemo} from "react";

import {guid} from "dyna-guid";

import {dynaSwitchEnum} from "dyna-switch";

import SwipeableViews from "react-swipeable-views";
import {AxisType} from "react-swipeable-views";

import {SxProps} from "../ThemeProvider";
import AppBar from "@mui/material/AppBar";
import MuiTabs from "@mui/material/Tabs";
import MuiTab from "@mui/material/Tab";

import {BreakpointDeviceContainer} from "../BreakpointDeviceContainer";
import {Condition} from "../Condition";
import {IconViewer} from "../IconViewer";
import {IIconComponent} from "../IconComponent";
import {
  FlexContainer,
  EFlexContainerOrientation,
  FlexItemMin,
  FlexItemMax,
} from "../FlexContainer";
import {Box} from "../Box";

import {
  useTheme,
  Theme,
} from "../ThemeProvider";
import ErrorIcon from "@mui/icons-material/Error";

export interface ITabsProps<TETab extends string | number | symbol> {
  sx?: SxProps<Theme>;
  dataComponentName?: string;
  ariaLabel: string;

  tab: TETab;                       // The selected tab
  tabs: Record<TETab, ITab>;        // The available tabs

  slideMode?: boolean;              // Default is false
  variant?: ETabVariant;            // Default is ETabVariant.STANDARD
  orientation?: ETabOrientation;    // Default is ETabDirection.HORIZONTAL
  hideLabelsOnMobile?: boolean;     // Default is false
  hideLabelsOnTablet?: boolean;     // Default is false
  fullHeight?: boolean;             // Default is false
  iconPosition?: ETabIconPosition;  // Default is ETabIconPosition.TOP
  tabSize?: ETabSize;               // Default is ETabLabelSize.MEDIUM

  onChange: (tab: TETab) => void;
}

export interface ITab {
  show?: boolean;
  label: string | JSX.Element;
  ariaLabel: string;
  Icon?: IIconComponent;
  validationError?: string;
  content: JSX.Element;
}

export enum ETabIconPosition {
  TOP = "TOP",
  LEFT = "LEFT",
}

export enum ETabSize {
  XSMALL = "XSMALL",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export enum ETabOrientation {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
}

export enum ETabVariant {
  STANDARD = "standard",
  FULL_WIDTH = "fullWidth",
  SCROLLABLE = "scrollable",
}

export const Tabs = <TETab extends string | number | symbol, >(props: ITabsProps<TETab>): JSX.Element => {
  const theme = useTheme();
  const {
    sx = {},
    dataComponentName,
    slideMode = false,
    variant = ETabVariant.STANDARD,
    orientation = ETabOrientation.HORIZONTAL,
    hideLabelsOnMobile = false,
    hideLabelsOnTablet = false,
    fullHeight = false,
    ariaLabel,
    tabs: tabDic,
    tab,
    iconPosition = ETabIconPosition.TOP,
    tabSize = ETabSize.MEDIUM,
    onChange,
  } = props;

  const idBase = useMemo(() => guid(0), []);

  const value = Math.max(
    Object.keys(tabDic).indexOf(tab as any),
    0,  // In case of not found, show the 1st one
  );

  const handleChange = (event: any, newValue: number) => {
    event; // 4ts
    onChange(Object.keys(tabDic)[newValue] as any);
  };

  const handleChangeIndex = (index: number) => handleChange(null, index);

  const sxContent: SxProps<Theme> = {
    overflowY: fullHeight ? 'auto' : undefined,
    height: fullHeight ? '100%' : undefined,    // Just a random number, is needed to activate DOM's scrolling on first render.
    minHeight: fullHeight ? '100%' : undefined,
  };

  return (
    <FlexContainer
      sx={sx}
      fullHeight={fullHeight}
      orientation={
        dynaSwitchEnum<ETabOrientation, EFlexContainerOrientation>(
          orientation,
          {
            [ETabOrientation.HORIZONTAL]: EFlexContainerOrientation.VERTICAL,
            [ETabOrientation.VERTICAL]: EFlexContainerOrientation.HORIZONTAL,
          },
        )
      }
      dataComponentName={[dataComponentName, "Tabs"].filter(Boolean).join(' ')}
    >

      <FlexItemMin>
        <AppBar position="static" color="default">
          <MuiTabs
            variant={variant}
            indicatorColor="primary"
            textColor="primary"
            aria-label={ariaLabel}
            orientation={
              dynaSwitchEnum<ETabOrientation, 'horizontal' | 'vertical'>(
                orientation,
                {
                  [ETabOrientation.HORIZONTAL]: 'horizontal',
                  [ETabOrientation.VERTICAL]: 'vertical',
                },
              )
            }
            value={value}
            onChange={handleChange}
          >
            {Object.values<ITab>(tabDic)
              .filter(tab => tab.show !== false)
              .map((
                {
                  Icon,
                  label,
                  ariaLabel,
                  validationError,
                },
                index,
              ) => (
                <MuiTab
                  sx={{
                    minWidth: dynaSwitchEnum<ETabSize, number>(
                      tabSize,
                      {
                        [ETabSize.XSMALL]: 38,
                        [ETabSize.SMALL]: 42,
                        [ETabSize.MEDIUM]: 52,
                        [ETabSize.LARGE]: 58,
                      },
                    ),
                    padding: dynaSwitchEnum<ETabSize, string | number | undefined>(
                      tabSize,
                      {
                        [ETabSize.XSMALL]: '2px',
                        [ETabSize.SMALL]: undefined,
                        [ETabSize.MEDIUM]: undefined,
                        [ETabSize.LARGE]: undefined,
                      },
                    ),
                  }}
                  key={index}
                  id={`tab-${index}--${idBase}`}
                  aria-controls={`tabpanel-${index}--${idBase}`}
                  aria-label={ariaLabel}
                  label={(
                    <FlexContainer
                      alignCenter
                      orientation={
                        iconPosition === ETabIconPosition.TOP
                          ? EFlexContainerOrientation.VERTICAL
                          : EFlexContainerOrientation.HORIZONTAL
                      }
                    >
                      <Box
                        sx={{
                          minWidth: 50,
                          position: 'relative',
                          '& > svg': (() => {
                            const size = dynaSwitchEnum<ETabSize, number>(
                              tabSize,
                              {
                                [ETabSize.XSMALL]: 14,
                                [ETabSize.SMALL]: 18,
                                [ETabSize.MEDIUM]: 24,
                                [ETabSize.LARGE]: 32,
                              },
                            );
                            return {
                              width: size,
                              height: size,
                            };
                          })(),
                        }}
                      >
                        <IconViewer Icon={Icon} width={24}/>
                        {!!validationError && (
                          <Box
                            component="span"
                            sx={{
                              position: 'absolute',
                              top: -6,
                              left: 4,
                              ...(
                                tabSize === ETabSize.SMALL
                                  ? {top: -11}
                                  : {}
                              ),
                            }}
                            title={validationError}
                          >
                            <ErrorIcon
                              sx={{
                                color: theme.palette.error.main,
                                width: dynaSwitchEnum<ETabSize, number>(
                                  tabSize,
                                  {
                                    [ETabSize.XSMALL]: 14,
                                    [ETabSize.SMALL]: 18,
                                    [ETabSize.MEDIUM]: 20,
                                    [ETabSize.LARGE]: 22,
                                  },
                                ),
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                      <BreakpointDeviceContainer
                        mobile={!hideLabelsOnMobile}
                        tablet={!hideLabelsOnTablet}
                        laptop
                        desktop
                        wide
                      >
                        <Box
                          sx={{
                            textTransform: 'none',
                            fontSize: theme => dynaSwitchEnum<ETabSize, number>(
                              tabSize,
                              {
                                [ETabSize.XSMALL]: theme.typography.fontSize * 1,
                                [ETabSize.SMALL]: theme.typography.fontSize * 1.1,
                                [ETabSize.MEDIUM]: theme.typography.fontSize * 1.2,
                                [ETabSize.LARGE]: theme.typography.fontSize * 1.3,
                              },
                            ),
                          }}
                        >
                          {label}
                        </Box>
                      </BreakpointDeviceContainer>
                    </FlexContainer>
                  )}
                />
              ))}
          </MuiTabs>
        </AppBar>
      </FlexItemMin>

      <FlexItemMax sx={{paddingTop: theme.spacing(1)}}>
        <Condition
          if={slideMode && orientation === ETabOrientation.HORIZONTAL} // Todo: Slide mode the horizontal direction is buggy and not supported
          then={
            <SwipeableViews
              axis={
                dynaSwitchEnum<ETabOrientation, AxisType>(
                  orientation,
                  {
                    [ETabOrientation.VERTICAL]: theme.direction === 'rtl' ? 'y-reverse' : 'y',
                    [ETabOrientation.HORIZONTAL]: theme.direction === 'rtl' ? 'x-reverse' : 'x',
                  },
                )
              }
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              {Object.values<ITab>(tabDic).map((tab, index) => (
                <Box
                  key={index}
                  id={`tabpanel-${index}--${idBase}`}
                  sx={sxContent}
                  aria-labelledby={`tab-${index}--${idBase}`}
                  dir={theme.direction}
                >
                  {tab.content}
                </Box>
              ))}
            </SwipeableViews>
          }
          else={
            Object.values<ITab>(tabDic).map((tab, index) => (
              <Condition key={index} if={value === index}>
                <Box
                  key={index}
                  id={`tabpanel-${index}--${idBase}`}
                  sx={sxContent}
                  aria-labelledby={`tab-${index}--${idBase}`}
                  dir={theme.direction}
                >
                  {tab.content}
                </Box>
              </Condition>
            ))
          }
        />
      </FlexItemMax>

    </FlexContainer>
  );
};
