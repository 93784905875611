import {convertDictionaryToViewLabelArray} from "utils-library/dist/commonJs/utils";

export enum EUserAuthenticationRights {
  ACTIVE = "UA_S",              // Not active means invalid for some reason, maybe due to a violation.
  ADMINISTRATOR = "UA_A",
  SYSTEM_ADMINISTRATOR = "UA_SA",
}

const labelsDic: Record<EUserAuthenticationRights, string> = {
  [EUserAuthenticationRights.ACTIVE]: 'User / Active',
  [EUserAuthenticationRights.ADMINISTRATOR]: 'Administrator',
  [EUserAuthenticationRights.SYSTEM_ADMINISTRATOR]: 'Admin / System Administrator',
};

export const EUserAuthenticationRightsArray = convertDictionaryToViewLabelArray(labelsDic);
