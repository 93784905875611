import {IGeoPosition} from "utils-library/dist/commonJs/geo";
import {getMiddlePosition} from "../../../../utils";

export const getBreakLinePositions = (points: IGeoPosition[]): IGeoPosition[] => {
  const breakLinePositions: IGeoPosition[] = [];

  for (let i = 0; i < points.length - 1; i++) {
    breakLinePositions.push(getMiddlePosition(points[i], points[i + 1]));
  }

  return breakLinePositions;
};
