export const API_PATH_ApiClientsBroadcasterPingSubscriptionPost = '/services/broadcaster/apiClientsBroadcasterPingSubscriptionPost';

export interface IApiClientsBroadcasterPingSubscriptionPostBodyRequest {
  wsConnectionId: string;
  action: "subscribe" | "unsubscribe";
}

export interface IApiClientsBroadcasterPingSubscriptionPostResponse {
  ok: true;
}

