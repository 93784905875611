import {IGeoPosition} from "utils-library/dist/commonJs/geo";
import {IMapElement} from "utils-library/dist/commonJs/geo";

export const API_PATH_ApiLanePlanningSearchItemsGet = '/services/lane-planning/apiLanePlanningSearchItemsGet';

export interface IApiLanePlanningSearchItemsGetRequest {
  nearPosition: IGeoPosition;
  nearInMeters: number;
  searchText?: string;
  skip: number;
  limit: number;
}

export interface IApiLanePlanningSearchItemsGetResponse {
  mapElements: IMapElement<any, any, any>[];
}
