import * as React from "react";
import {ReactElement} from "react";

import {TDataComponentName} from "../ui-interfaces";
import {Box} from "../Box";
import {getDataComponentName} from "../web-utils";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";

export interface IContainerEllipsisProps {
  sx?: SxProps<Theme>;
  show?: boolean;
  dataComponentName?: TDataComponentName;
  component?:
    | 'div' | 'span'
    | 'area' | 'article' | 'aside' | 'base'
    | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'
    | 'data'
    | 'nav'
    | 'li' | 'ul' | 'dl' | 'dt' | 'dd'
    | 'blockquote';
  children: string;
  maxLines?: number; // Optional prop to specify the maximum number of lines
}

/**
 * Div Container with Ellipsis effect for text content
 * If this is in FlexItemMax, set FlexItemMax's overFlowX to true to be effective!
 * @param props
 * @constructor
 */
export const ContainerEllipsis = (props: IContainerEllipsisProps): ReactElement => {
  const {
    sx,
    show,
    dataComponentName,
    component = "div",
    children,
    maxLines = 1,
  } = props; // Default maxLine to 1

  const applySx: SxProps = {
    ...sx,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    boxOrient: 'vertical',
    lineClamp: maxLines.toString(),
    WebkitLineClamp: maxLines.toString(),
    wordBreak: 'break-word',      // Todo: doesn't break on words but on letters
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return (
    <Box
      show={show}
      dataComponentName={getDataComponentName(dataComponentName, "ContainerEllipsis")}
      component={component}
      sx={applySx}
    >
      {children}
    </Box>
  );
};
