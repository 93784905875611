exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Ghost-module-ghost--3d3RAVJAR9Rw {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  -webkit-animation: Ghost-module-ghostAnimationKeyframes--UTYuaTwL0hKQ 0.25s infinite;\n          animation: Ghost-module-ghostAnimationKeyframes--UTYuaTwL0hKQ 0.25s infinite;\n  -webkit-animation-timing-function: linear;\n          animation-timing-function: linear;\n  background-repeat: no-repeat;\n  background-image: -webkit-linear-gradient(7deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 85%);\n  background-image: -o-linear-gradient(7deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 85%);\n  background-image: linear-gradient(83deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 85%);\n  background-size: 75% 100%;\n  background-position: -280% 0;\n}\n@-webkit-keyframes Ghost-module-ghostAnimationKeyframes--UTYuaTwL0hKQ {\n  to {\n    background-position: 400% 0;\n  }\n}\n@keyframes Ghost-module-ghostAnimationKeyframes--UTYuaTwL0hKQ {\n  to {\n    background-position: 400% 0;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"ghost": "Ghost-module-ghost--3d3RAVJAR9Rw",
	"ghostAnimationKeyframes": "Ghost-module-ghostAnimationKeyframes--UTYuaTwL0hKQ"
};