import * as React from "react";

import {Circle as _Circle} from "react-leaflet";  // Todo: MHC-00783 react-leaflet v4 has some typescript errors https://trello.com/c/U7DFYEIA

import {IGeoPosition} from "utils-library/dist/commonJs/geo";

export interface IMapCircleProps {
  center: IGeoPosition;
  radiusInMeters: number;
  color?: string;         // Default is red
  opacity?: number;
  fill?: boolean;         // Default is true
  fillColor?: string;     // Default is red
  fillOpacity?: number;   // Default is 0.3
}

const Circle: any = _Circle;

export const MapCircle = (props: IMapCircleProps): JSX.Element => {
  const {
    center,
    radiusInMeters,

    color = 'red',
    opacity = 1,

    fill = true,
    fillColor = 'red',
    fillOpacity = 0.3,
  } = props;

  return (
    <Circle
      center={center}
      radius={radiusInMeters}

      color={color}
      opacity={opacity}

      fill={fill}
      fillColor={fillColor}
      fillOpacity={fillOpacity}
    />
  );
};
