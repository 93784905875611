import * as React from "react";

export interface IConditionalContainerProps {
  if: boolean;
  not?: true;
  then?: any;
  children?: any;
  else?: any;
}

export const Condition = (props: IConditionalContainerProps): JSX.Element | null => {
  const {
    "if": _if,
    "not": _not,
    then = null,
    children = null,
    "else": _else = null,
  } = props;

  const isTrue: boolean = (() => {
    if (_not === undefined && _if) return true;
    if (_not && !_if) return true;
    return false;
  })();

  return isTrue
    ? (
      <>
        {then}
        {children}
      </>
    )
    : _else;
};
