import {IGeoPosition} from "utils-library/dist/commonJs/geo";
import {apiGeoPointElevationsGet} from "./apiGeoPointElevationsGet";
import {dynaError} from "dyna-error";

/**
 * Get elevation of a coordinate in meters
 * @param position
 */
export const apiGeoPointElevationGet = async (position: IGeoPosition): Promise<number> => {
  const response = await apiGeoPointElevationsGet([position]);
  const responsePosition = response[0];

  if (!responsePosition) { // 4TS
    throw dynaError({
      code: 20230804111905,
      message: 'Internal client error, the apiGeoPointElevationsGet did not return items',
      data: {response},
    });
  }

  return responsePosition.alt || 0;
};
