import * as React from "react";
import {ReactElement} from "react";
import {TObject} from "utils-library/dist/commonJs/typescript";

export const forwardPropsToChildren = (
  {
    children,
    props,
  }: {
    children: ReactElement[];
    props: TObject;
  },
): ReactElement[] =>
  React.Children.map(children, child => {
    return React.cloneElement(child, props);
  });
