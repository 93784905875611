import {WebSocket} from "ws";

import {DynaJobQueue} from "dyna-job-queue";
import {
  dynaError,
  IDynaError,
} from "dyna-error";

export interface IWSClientConfig {
  onStatusChange?: (status: EConnectionStatus) => void;
  /**
   * Promised dispatch of the incoming message
   * The WSClient is serializing the calls of the onMessage.
   * That means that is not possible to have concurrent calls of this callback.
   * As a developer, you have to fulfill the promise as soon as possible
   * @param message It is always object
   */
  onMessage?: (message: any) => Promise<void>;
  onGeneralError?: (error: IDynaError) => void;
}

export enum EConnectionStatus {
  ONLINE = "ONLINE",
  CONNECTING = "CONNECTING",
  RECONNECTING = "RECONNECTING",
  DISCONNECTING = "DISCONNECTING",
  OFFLINE = "OFFLINE",
}

export class WSClient {
  private webSocket: WebSocket | null = null;
  private queue = new DynaJobQueue({parallels: 1});
  private _status: EConnectionStatus = EConnectionStatus.OFFLINE;

  constructor(private config: IWSClientConfig) {
    this.setStatus(EConnectionStatus.OFFLINE);
    this.handleIncomingMessage = this.queue.jobFactory(this.handleIncomingMessage);  // That's all
  }

  public connect(address: string): Promise<void> {
    this.setStatus(EConnectionStatus.CONNECTING);
    return new Promise<void>((resolve) => {
      this.webSocket = new WebSocket(address);
      this.webSocket.on('message', this.handleIncomingMessage);
      this.webSocket.on('error', this.handleError);
      this.webSocket.on("open", () => {
        resolve();
        this.setStatus(EConnectionStatus.ONLINE);
      });
    });
  }

  public async disconnect(): Promise<void> {
    if (!this.webSocket) return;
    this.webSocket.off('message', this.handleIncomingMessage);
    this.webSocket.off('error', this.handleError);
    this.webSocket.close();
    this.webSocket = null;
  }

  public get status(): EConnectionStatus {
    return this._status;
  }

  public get online(): boolean {
    return this._status === EConnectionStatus.ONLINE;
  }

  public async send<TData>(data: TData): Promise<void | never> {
    if (this._status !== EConnectionStatus.ONLINE) {
      throw dynaError({
        code: 202303011214,
        message: 'WSClient is not in ONLINE status',
      });
    }
    let serialized = "";
    try {
      serialized = JSON.stringify(data);
    }
    catch (error) {
      throw dynaError({
        code: 202303011215,
        message: `WSClient.send(): Error stringifying the object: ${error.message || 'unknown'}`,
        parentError: error,
      });
    }

    return new Promise((resolve, reject) => {
      if (!this.webSocket) {
        reject(dynaError({
          code: 202303011219,
          message: 'WSClient is not ready (and not online)',
        }));
        return;
      }
      this.webSocket.send(
        serialized,
        error => {
          if (!error) {
            resolve();
            return;
          }
          reject(dynaError({
            code: 202303011216,
            message: `WSClient.send(): Cennot send: ${error.message || 'unknown'}`,
            parentError: error,
          }));
        },
      );
    });

  }

  private readonly handleIncomingMessage = async (rawMessage: string): Promise<void> => {
    const {onMessage} = this.config;
    if (!onMessage) return;

    const rawTextMessage: string = (() => {
      if (typeof rawMessage === "string") return rawMessage;
      if (Buffer.isBuffer(rawMessage)) return (rawMessage as Buffer).toString();
      return "{}";
    })();

    let message: any = null;
    try {
      message = JSON.parse(rawTextMessage);
    }
    catch (e) {
      console.error(
        'WSClient: error parsing incoming message',
        {
          wsMessage: rawMessage,
          error: e,
        },
      );
    }
    if (!message) return; // 4TS

    return onMessage(message);
  };

  private handleError = (error: Error): void => {
    const {onGeneralError} = this.config;
    onGeneralError && onGeneralError(error);
  };

  private setStatus(status: EConnectionStatus): void {
    this._status = status;
    this.config.onStatusChange && this.config.onStatusChange(this._status);
  }
}
