import {convertDictionaryToViewLabelArray} from "utils-library/dist/commonJs/utils";

export enum EAlertsManagementRights {
  ALERT_CONFIG_VIEW = "AM_CV",
  ALERT_CONFIG_EDIT = "AM_CE",
  ALERT_HANDLING_VIEW = "AM_HV",
  ALERT_HANDLING_EDIT = "AM_HE",
  ALERT_HANDLING_RESOLVE = "AM_HR",
  ALERT_HANDLING_UNRESOLVE = "AM_HU",
  ALERTS_TEST_PAGE = "AM_TP",
}

const labelsDic: Record<EAlertsManagementRights, string> = {
  [EAlertsManagementRights.ALERT_CONFIG_VIEW]: 'Alert Mgtm / Config view',
  [EAlertsManagementRights.ALERT_CONFIG_EDIT]: 'Alert Mgtm / Config edit',
  [EAlertsManagementRights.ALERT_HANDLING_VIEW]: 'Alert Mgtm / Handling view',
  [EAlertsManagementRights.ALERT_HANDLING_EDIT]: 'Alert Mgtm / Handling edit',
  [EAlertsManagementRights.ALERT_HANDLING_RESOLVE]: 'Alert Mgtm / Handling resolve',
  [EAlertsManagementRights.ALERT_HANDLING_UNRESOLVE]: 'Alert Mgtm / Handling unresolve',
  [EAlertsManagementRights.ALERTS_TEST_PAGE]: 'Alert Mgtm / Test page',
};

export const EAlertsManagementRightsArray = convertDictionaryToViewLabelArray(labelsDic);
