import * as React from "react";
import {
  useRef,
  useState,
  useEffect,
} from "react";

import MuiCollapse from "@mui/material/Collapse";

import {useIsMounted} from "../useIsMounted";

import {
  Theme,
  SxProps,
} from "../ThemeProvider";

export interface ICollapseProps {
  sx?: SxProps<Theme>;
  expanded: boolean;
  show?: boolean;
  animation?: boolean;          // Default is true
  animationDuration?: number;   // In ms
  doNotRemoveFromDom?: boolean; // Set to true to do not vanish form fields from the dom on collapse
  orientation?: 'vertical' | 'horizontal';
  children: any;
}

export const Collapse = (props: ICollapseProps): JSX.Element | null => {
  const {
    sx,
    expanded,
    show = true,
    animation = true,
    animationDuration = 150,
    doNotRemoveFromDom = false,
    orientation = 'vertical',
    children,
  } = props;

  const getIsMounted = useIsMounted();
  const refCollapseTimer = useRef<any>(null);
  const [renderContent, setRenderContent] = useState<boolean>(expanded);

  useEffect(() => {
    if (expanded) {
      if (!doNotRemoveFromDom) setRenderContent(true);
      if (refCollapseTimer.current) clearTimeout(refCollapseTimer.current);
      refCollapseTimer.current = null;
    }
    else {
      refCollapseTimer.current = setTimeout(() => {
        if (expanded) return;
        if (!getIsMounted()) return;
        if (!doNotRemoveFromDom) setRenderContent(false);
      }, animationDuration);
    }
  }, [expanded]);

  if (!show) return null;
  if (!animation) return expanded ? children : null;

  return (
    <MuiCollapse
      sx={sx}
      in={expanded}
      timeout={animationDuration}
      orientation={orientation}
    >
      {renderContent && children}
    </MuiCollapse>
  );
};
