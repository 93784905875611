export const API_PATH_ApiDeviceMgmtRegionChangeId = '/services/devices-mgtm/apiDeviceMgmtRegionChangeId';

export interface IApiDeviceMgmtRegionChangeIdRequest {
  id: string;
  newId: string;
}

export interface IApiDeviceMgmtRegionChangeIdResponse {
  ok: true;
}
