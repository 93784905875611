import {IUser} from "../../user-authentication/interfaces/IUser";

export const API_PATH_apiUserMngmtUserDelete = '/services/user-management/user';

export interface IApiUserMngmtUserDeleteBodyRequest {
  userId: string;
}

export interface IApiUserMngmtUserDeleteResponse {
  user: IUser;
}
