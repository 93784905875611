export const API_PATH_ApiDeviceMgmtDeviceChangeOrder = '/services/devices-mgtm/apiDeviceMgmtDeviceChangeOrder';

export interface IApiDeviceMgmtDeviceChangeOrderBodyRequest {
  regionId: string;
  locationId: string;
  deviceId: string;
  beforeDeviceId: string;
}

export interface IApiDeviceMgmtDeviceChangeOrderResponse {
  ok: true;
}
