import * as React from "react";
import {
  ReactElement,
  useState,
  useEffect,
} from 'react';

import {
  round,
  isConvertibleToNumber,
  countDecimalPoints,
} from "utils-library/dist/commonJs/utils";

export interface IInputDualUnitProps {
  convertActualToUserValue: (actualValue: number) => number;
  convertUserValueToActual: (userValue: number) => number;
  value: number;
  /**
   * Render your generic input component.
   * These are the props for the generic input component, similar to the Input of this library.
   * You can pass the `Input` if you don't want to specify props other than IInputDualCustomComponentProps.
   * The `InputDualUnit` is a HOC managed component and returns this component directly altering some props.
   * **You should pass** these props into your input component but **never** use value of the `renderInput`!
   * Instead, you should only read the values from the `onChange` and `onBlur` and write the `value` properties of the `InputDualUnit`.
   * Do not use the `name` property (for <form>) in your component because it will have the user's unit value, not the actual unit in return.
   * @param props
   */
  children: (props: IInputDualCustomComponentProps) => ReactElement;
  onBlur?: (value: number) => void;
  onChange?: (value: number) => void;
}

export interface IInputDualCustomComponentProps {
  value: string;
  validationError: string;
  onBlur: (value: string) => void;    // Required to be used and called
  onChange: (value: string) => void;  // Required to be used and called
}

export const InputDualUnit: React.FC<IInputDualUnitProps> = (
  {
    convertActualToUserValue,
    convertUserValueToActual,
    value,
    children: input,
    onBlur,
    onChange,
  },
) => {
  const [userValue, setUserValue] = useState<number>(value);
  const [validationError, setValidationError] = useState<string>('');

  // Watch external changes
  useEffect(() => {
    const fullUserValue = convertActualToUserValue(value);
    const decimalPoints = countDecimalPoints(fullUserValue);
    if (decimalPoints >= 14) {
      // Too many decimal points or floating-point precision issue!
      // Just round the number to one decimal place less.
      const fixedUserValue = round(fullUserValue, decimalPoints - 1);
      setUserValue(fixedUserValue);
    }
    else {
      setUserValue(fullUserValue);
    }
  }, [value]);

  const handleUserValueChange = (userValue: string): number | null => {
    if (!isConvertibleToNumber(userValue)) {
      setValidationError('Invalid numeric value');
      return null;
    }
    if (validationError) setValidationError("");
    const numericValue = Number(userValue);
    const actualValue = convertUserValueToActual(numericValue);
    return actualValue;
  };

  const handleBlur = (userValue: string): void => {
    const actualValue = handleUserValueChange(userValue);
    if (!onBlur) return;
    if (actualValue === null) return;
    onBlur(actualValue);
  };
  const handleChange = (userValue: string): void => {
    const actualValue = handleUserValueChange(userValue);
    if (!onChange) return;
    if (actualValue === null) return;
    onChange(actualValue);
  };

  return (
    input({
      value: userValue.toString(),
      validationError,
      onBlur: handleBlur,
      onChange: handleChange,
    })
  );
};
