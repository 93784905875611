import * as React from "react";
import {useMemo} from "react";

import {TNodeElement} from "utils-library/dist/commonJs/typescript";
import {sanitizeHtml} from "utils-library/dist/commonJs/web";

export interface IHtmlContentProps {
  component?: TNodeElement;
  style?: React.CSSProperties;
  html: string;
  sanitize?: boolean; // Default true
}

export const HtmlContent = (props: IHtmlContentProps): JSX.Element => {
  const {
    component = "div",
    html: userHtml,
    sanitize = true,
    style,
  } = props;

  const html = useMemo(
    () =>
      sanitize
        ? sanitizeHtml(userHtml)
        : userHtml,
    [userHtml]);

  const Node: any = component;

  return (
    <Node
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{__html: html}}
      style={style}
    />
  );
};
