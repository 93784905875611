import * as React from "react";
import {useState} from 'react';

export interface IWindowManagerContextProps {
  activeWindowId: string | null;
  setActiveWindowId: (id: string) => void;
}

export const WindowManagerContext = React.createContext<IWindowManagerContextProps>({
  activeWindowId: null,
  setActiveWindowId: () => undefined,
});

export interface IWindowManagerProps {
  children: React.ReactNode;
}

export const WindowManager: React.FC<IWindowManagerProps> = (
  {children},
) => {
  const [activeWindowId, setActiveWindowId] = useState<string | null>(null);
  return (
    <WindowManagerContext.Provider value={{
      activeWindowId,
      setActiveWindowId,
    }}
    >
      {children}
    </WindowManagerContext.Provider>
  );
};
