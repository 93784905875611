import {buildUrl} from "utils-library/dist/commonJs/utils";
import {apiFetch} from "utils-library/dist/commonJs/node-net";

import {
  API_PATH_ApiAlertMgtmAlertHandlingPost,
  IApiAlertMgtmAlertHandlingPostRequest,
  IApiAlertMgtmAlertHandlingPostResponse,
} from "../../endpoints/alert-handling/apiAlertMgtmAlertHandlingPost.interfaces";

import {EAlertType} from "../../interfaces/EAlertType";
import {IDeviceStatus} from "../../../device-management/interfaces/IDeviceStatus";

export const apiAlertMgtmAlertHandlingPost =
  async <TAlert, TAlertHandle, >(
    {
      deviceStatus: {
        regionId,
        locationId,
        deviceId,
      },
      alertType: type,
      title,
      description,
      alert,
      alertHandle,
      userId = '--system--apiAlertMgtmAlertHandlingPost--',
    }: {
      deviceStatus: IDeviceStatus<any>;
      alertType: EAlertType;
      title: string;
      description: string;
      alert: TAlert;
      alertHandle: TAlertHandle;
      userId?: string;
    },
  ): Promise<void> => {
    const body: IApiAlertMgtmAlertHandlingPostRequest<TAlert, TAlertHandle> = {
      accessKey: process.env.AUTHENTICATION_ENCRYPTION_KEY || '',
      type,
      title,
      description,
      regionId,
      locationId,
      deviceId,
      alert,
      alertHandle,
      userId,
    };
    await apiFetch<null, IApiAlertMgtmAlertHandlingPostRequest<TAlert, TAlertHandle>, IApiAlertMgtmAlertHandlingPostResponse>({
      method: 'POST',
      path: buildUrl(process.env.ZIP_CREATOR_SERVICE__ADDRESS, API_PATH_ApiAlertMgtmAlertHandlingPost),
      body,
    })
      .catch(error => {
        console.error(
          'CRITICAL ERROR 202301031323: apiAlertMgtmAlertHandlingPost()[intranet]: Request to alerts-management service failed! Alert is lost!',
          {
            body,
            error,
          },
        );
      });
  };
