import {IGeoPosition} from "utils-library/dist/commonJs/geo";
import {TObject} from "utils-library/dist/commonJs/typescript";

export const API_PATH_ApiLanePlanningPost = '/services/lane-planning/apiLanePlanningPost';

export interface IApiLanePlanningPostRequest {
  id: string;
  elementType: string;
  groupIds: string[];
  position: IGeoPosition;
  config: TObject;
  data: TObject;
}

export interface IApiLanePlanningPostResponse {
  ok: true;
}
