import {
  IDBEntityBase,
  getDefaultDBEntityDocument,
} from "utils-library/dist/commonJs/db-entity-interfaces";

export interface IRegion extends IDBEntityBase {
  order: number;

  label: string;
  description: string;
  notes: string;
}

export const getDefaultRegion = () => getDefaultDBEntityDocument<IRegion>({
  order: -1,
  label: '',
  description: '',
  notes: '',
});
