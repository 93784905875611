import * as React from "react";
import {
  useState,
  useEffect,
  useImperativeHandle,
} from "react";

import {useDebouncedCallback} from 'use-debounce';

import {dynaSwitchEnum} from "dyna-switch";

import {pluralize} from "utils-library/dist/commonJs/utils";

import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import {Box} from "../Box";
import {IsLoadingBox} from "../IsLoadingBox";
import {
  HelperText,
  EHelperTextType,
} from "../HelperText";
import {IconViewer} from "../IconViewer";
import {IIconComponent} from "../IconComponent";
import {convertSizeToPx} from "../utils";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";
import EndlessIcon from '@mui/icons-material/AllInclusive';


export interface IInputProps<TData = any> {
  sx?: SxProps<Theme>;
  show?: boolean;
  styleInput?: React.CSSProperties;
  dataComponentName?: string;
  variant?: EInputVariant;    // Default is EInputVariant.STANDARD
  spacing?: EInputSpacing;    // Default is EInputSpacing.NONE
  type?: EInputType;              // Default is EInputType.TEXT

  ref?: React.RefObject<IInputRef>;

  disabled?: boolean;             // Default is false
  readOnly?: boolean;             // Default is false
  required?: boolean;             // Default is false
  autoFocus?: boolean;            // Default is false
  disableScroll?: boolean;        // Default is true. Change the value by scroll events over the input control.
  browserAutoComplete?: boolean;  // Default is false

  name?: keyof TData;             // Needed for the <form>s
  nameOnInput?: boolean;          // The default is true. Set it to `false` to not apply the name on the <input> elements. It can still be used for events. Set it to false when you don't want this input to be used by a parent <form>

  label?: string;
  ariaLabel?: string;
  placeholder?: string;
  helperLabel?: string;
  HelperIcon?: IIconComponent;
  hidden?: boolean;
  prefix?: JSX.Element;
  suffix?: JSX.Element;
  isLoading?: boolean;

  fontFamily?: string;
  fontStyle?:
    | 'inherit'
    | 'normal'
    | 'italic'
    | 'oblique'
    | 'revert';
  fontWeight?: 'bold' | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  fontSize?: number | string;

  minLength?: number;
  maxLengthWarn?: number;
  maxLength?: number;
  maxLengthRestriction?: boolean;

  showLengthHelperLabel?: boolean;

  validationError?: string;     // Validation error message, '' = success

  minRows?: number;             // Default is 1
  maxRows?: number;             // For multilines

  buttons?: IInputButton[];

  defaultValue?: string;
  value?: string;

  debounceOnChangeMs?: number;  // Default is 0

  onFocus?: (name?: keyof TData) => void;
  onBlur?: (value: string, name?: keyof TData) => void;
  onEnter?: (value: string, name?: keyof TData) => void;
  onChange?: (value: string, name?: keyof TData) => void;
}

export enum EInputVariant {
  FILLED = "filled",
  OUTLINED = "outlined",
  STANDARD = "standard",
}

export interface IInputButton {
  color?:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  show?: boolean;
  title?: string;
  disabled?: boolean;
  Icon: IIconComponent;
  onClick: () => void;
}

export interface IInputRef {
  value: string;
}

export enum EInputType {
  TEXT = "text",
  NUMBER = "number",      // You can use the <InputNumber> instead
  PASSWORD = "password",
}

export enum EInputSpacing {
  NONE = "none",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

const InputCore = React.forwardRef<IInputRef, IInputProps>((props, ref): JSX.Element => {
  const {
    sx = {},
    show = true,
    styleInput,
    dataComponentName,
    variant = EInputVariant.STANDARD,
    spacing = EInputSpacing.NONE,

    disabled = false,
    readOnly = false,
    required = false,
    autoFocus = false,
    disableScroll = true,
    browserAutoComplete = false,

    name,
    nameOnInput = true,
    type = EInputType.TEXT,

    label,
    ariaLabel: userAriaLabel,
    placeholder,
    helperLabel,
    HelperIcon,
    hidden = false,
    prefix,
    suffix,
    isLoading,

    fontFamily,
    fontStyle,
    fontWeight = 'inherit',
    fontSize,

    minLength,
    maxLengthWarn,
    maxLength,
    maxLengthRestriction = true,

    showLengthHelperLabel = false,

    validationError,

    minRows = 1,
    maxRows = 1,

    buttons = [],

    defaultValue,
    value: userValue,

    debounceOnChangeMs = 0,

    onFocus = () => undefined,
    onBlur = () => undefined,
    onEnter = () => undefined,
    onChange: userOnChange = () => undefined,
  } = props;

  const [value, setValue] = useState<string>(userValue || defaultValue || "");
  const onChange = useDebouncedCallback(userOnChange, debounceOnChangeMs);

  useImperativeHandle(ref, () => ({
    get value(): string {
      return value;
    },
    set value(newValue) {
      setValue(newValue);
    },
  }));

  useEffect(() => {
    if (userValue === undefined) return;
    setValue(userValue);
  }, [userValue]);

  const handleFocus = (): void => {
    onFocus(name);
  };
  const handleBlur = (): void => {
    onBlur(value, name);
  };
  const handleKeyUp = (event: any): void => {
    if (event.keyCode === 13) onEnter(value, name);
  };
  const handleChange = (e: any): void => {
    if (value === e.target.value) return;
    setValue(e.target.value);
    onChange(e.target.value, name);
  };

  const ariaLabel =
    userAriaLabel ||
    (label && `Input for ${label}`) ||
    undefined;

  const onWheel = (e: any) => {
    if (disableScroll) e.target.blur();
  };

  const whiteSpace = <span style={{whiteSpace: 'pre'}}>{" "}</span>;

  return (
    <Box
      dataComponentName={[dataComponentName, "Input"]}
      show={show}
      sx={{
        margin: dynaSwitchEnum<EInputSpacing, string | 0>(
          spacing,
          {
            [EInputSpacing.NONE]: 0,
            [EInputSpacing.SMALL]: '0 4px 4px 0',
            [EInputSpacing.MEDIUM]: '0 8px 8px 0',
            [EInputSpacing.LARGE]: '0 16px 16px 0',
          },
        ),
        '& input, & textarea': {
          fontStyle,
          fontSize: theme => convertSizeToPx(theme, fontSize),
        },
        ...(
          fontFamily
            ? {
              'div': {
                fontWeight,
                fontFamily,
                'div': {
                  fontFamily,
                  fontWeight,
                },
              },
            }
            : {}
        ),
        ...sx,
      }}
      hidden={hidden}
    >
      <TextField
        sx={{width: '100%'}}
        variant={variant}
        onWheel={onWheel}

        disabled={disabled}
        required={!disabled && !readOnly && required}
        autoFocus={autoFocus}
        autoComplete={browserAutoComplete ? undefined : "off"}

        inputProps={{
          style: styleInput,
          readOnly,
          maxLength: maxLengthRestriction ? maxLength : undefined,
        }}

        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {prefix}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              sx={
                variant === EInputVariant.STANDARD
                  ? {
                    position: 'relative',
                    left: '-12px',
                  }
                  : undefined
              }
            >
              {suffix}
              <IsLoadingBox
                sx={{
                  position: 'relative',
                  top: '2px',
                }}
                inline
                isLoading={!!isLoading}
                iconSize={3}
                spacing={0}
              />
              {buttons
                .filter(button => button.show !== false)
                .map((button, index) => (
                  <IconButton
                    key={index}
                    sx={{marginLeft: '16px'}}
                    title={button.title}
                    edge="end"
                    color={button.color}
                    disabled={button.disabled}
                    onClick={button.onClick}
                  >
                    <IconViewer
                      Icon={button.Icon}
                      width={28}
                    />
                  </IconButton>
                ))
              }
            </InputAdornment>
          ),
        }}

        name={nameOnInput ? name as any : undefined}

        type={type}

        label={label}
        aria-label={ariaLabel}
        placeholder={placeholder}

        multiline={minRows > 1 || maxRows > 1}
        minRows={minRows}
        maxRows={minRows > maxRows ? minRows : maxRows}

        error={!!validationError}

        value={value}

        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
        onChange={handleChange}
      />
      <HelperText Icon={HelperIcon}>{helperLabel}</HelperText>
      <HelperText
        show={showLengthHelperLabel}
        type={(() => {
          if (
            minLength !== undefined
            && maxLengthWarn
            && maxLength
            && value.length >= minLength
            && value.length >= maxLengthWarn
            && value.length <= maxLength
          ) return EHelperTextType.WARNING;
          if (
            (minLength !== undefined && value.length < minLength) ||
            (maxLength !== undefined && value.length > maxLength)
          ) return EHelperTextType.ERROR;
          return;
        })()}
      >
        <span>{value.length} chars.</span>
        {minLength !== undefined && (
          <Box
            inline
            show={value.length < minLength}
          >
            {whiteSpace}
            {minLength - value.length} {pluralize('char', minLength - value.length)} more.
          </Box>
        )}
        {maxLength !== undefined && (
          <Box
            inline
            show={value.length > maxLength}
          >
            {whiteSpace}
            {value.length - maxLength} {pluralize('char', value.length - maxLength)} less.
          </Box>
        )}
        <Box inline>
          {whiteSpace}
          [Valid length: {minLength || 0}{maxLengthWarn ? `..${maxLengthWarn}` : ''}...{maxLength || <IconViewer MuiIcon={EndlessIcon}/>}]
        </Box>
      </HelperText>
      <HelperText type={EHelperTextType.ERROR}>{validationError}</HelperText>
    </Box>
  );
});

export const Input: <TData, >(props: IInputProps<TData>) => JSX.Element | null = InputCore;
