import {ICommentContent} from "../../../../../features/db-comments";
import {IDTOPost} from "../../../../../features/db-posts";

export const API_PATH_ApiTrafficStudyCommentPost = '/services/traffic-studies/apiTrafficStudyCommentPost';

export interface IApiTrafficStudyCommentPostRequest {
  trafficStudyId: string;
  postId: string;
  comment: ICommentContent;
}

export interface IApiTrafficStudyCommentPostResponse {
  post: IDTOPost<any, any>;
}
