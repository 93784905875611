import * as React from "react";
import {
  ReactElement,
  useEffect,
} from "react";

import {IMuiIcon} from "utils-library/dist/commonJs/typescript";
import {Icon as Iconify} from "@iconify/react";

import {Box} from "../Box";
import {HtmlContent} from "../HtmlContent";
import {IIconComponent} from "../IconComponent";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";

export interface IIconViewerProps {
  /**
   * Determines whether the IconViewer should be visible.
   */
  show?: boolean;

  /**
   * Style props for the root container.
   */
  sx?: SxProps<Theme>;

  /**
   * Style props for the SVG container.
   */
  sxSvg?: SxProps<Theme>;

  /**
   * Source of the icon image.
   *
   * It can be a regular webpack image, content, or URL.
   */
  image?: string;

  /**
   * Emoji icon
   *
   * Directly the UTF-8 sequence of the icon and not as human string.
   *
   * You can pick emoji's from https://emojipedia.org
   *
   * @example Apply it like `🍔` NOT like `:hamburger:`
   */
  emoji?: string;

  /**
   * Icon component created by one of the createIcon methods.
   */
  Icon?: IIconComponent;

  /**
   * SVG element, to be used when webpack provides it.
   */
  svgElement?: React.ReactElement<SVGElement>;

  /**
   * SVG script as a string, to be used when webpack resolves the SVG script.
   */
  svgScript?: string;

  /**
   * MUI's icon from https://mui.com/material-ui/material-icons.
   * Pick one from the provided link.
   */
  MuiIcon?: IMuiIcon;

  /**
   * Create icon by Iconify icons
   *
   * Iconify icons are a larger open-source collection than MUI's repository.
   *
   * Website: https://iconify.design
   *
   * Instructions:
   * - Explore icons: https://icon-sets.iconify.design
   * - Choose an icon
   * - In the components section, select the "Unplugin icons" option
   * - Copy the import script, for example: `import MdiFlight from '~icons/mdi/flight';`
   * - Replace the `~icons` in the import with `@iconify-icons`
   * - If the icons package not installed, you may need to run `yarn add @iconify-icons/mdi` for the above import example.
   */
  IconifyIcon?: any;

  /**
   * Width of the icon.
   * Default is the current font's size.
   */
  width?: number;

  /**
   * Height of the icon.
   * Default is the same as the width.
   */
  height?: number;

  /**
   * Title for the icon.
   */
  title?: string;
}

export const IconViewer = (props: IIconViewerProps): ReactElement | null => {
  const {
    show = true,
    sx = {},
    sxSvg: userSxSvg = {},
    Icon,
    MuiIcon,
    emoji,
    svgElement,
    svgScript,
    IconifyIcon,
    image,
    width: userWidth,
    height: userHeight,
    title,
  } = props;

  const width = userWidth || userHeight;
  const height = userHeight || userWidth;

  useEffect(() => {
    const imagesCount = [
      MuiIcon,
      Icon,
      emoji,
      svgElement,
      svgScript,
      IconifyIcon,
      image,
    ].filter(Boolean).length;
    if (imagesCount > 1) {
      console.error(
        '<Icon> 20230525110318 error: More than one image provided',
        {
          Icon,
          MuiIcon,
          emoji,
          svgElement,
          svgScript,
          IconifyIcon: IconifyIcon,
          image,
        },
      );
    }
  }, []);

  if (!show) return null;

  const sxBase: SxProps<Theme> =
    width || height
      ? {
        width,
        height,
      }
      : {fontSize: 'inherit'};
  const sxSvg: any = {
    ...userSxSvg,
    ...sxBase,
  };

  const wrapper = (content: any): ReactElement => (
    <Box
      dataComponentName="IconViewer"
      inline="flex"
      sx={{
        ...sx,
        'svg': sxSvg,
      }}
      title={title}
    >
      {content}
    </Box>
  );

  if (svgElement) return wrapper(svgElement);

  if (svgScript) {
    return wrapper(
      <HtmlContent
        component="span"
        html={svgScript}
      />,
    );
  }
  if (image) {
    return wrapper(
      <img
        src={image}
        alt={title}
        style={{
          width: width ? width + 'px' : undefined,
          height: height ? height + 'px' : undefined,
        }}
      />,
    );
  }

  if (Icon) {
    return wrapper(
      <Icon
        width={width}
        height={height}
      />,
    );
  }
  if (MuiIcon) return wrapper(<MuiIcon/>);

  if (IconifyIcon) return wrapper(<Iconify icon={IconifyIcon}/>);

  if (emoji) {
    return (
      <span
        style={{fontSize: `${width}px`}}
        children={emoji}
      />
    );
  }

  return null;
};
