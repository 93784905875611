import * as React from "react";


export interface ISVGIconProps {
  style?: React.CSSProperties;
  src: string;
  alt?: string;
  width?: number;
  height?: number;
}

export const SVGIcon = (props: ISVGIconProps): JSX.Element => {
  const {
    style,
    src,
    alt = "",
    width = 24,
    height = 24,
  } = props;

  return (
    <img
      style={style}
      src={src}
      alt={alt}
      width={width}
      height={height}
    />
  );
};
