import {IApiAppVersionGetResponse} from "mhc-server";

import {apiAppVersionGet} from "../api/version/apiAppVersionGet";

import {Box} from "mhc-ui-components/dist/Box";

import {useLoadData} from "mhc-ui-components/dist/useLoadData";

import {appConfig} from "../config/appConfig";

import {
  SxProps,
  Theme,
} from "mhc-ui-components/dist/ThemeProvider";

export interface IAppVersionProps {
  sx?: SxProps<Theme>;
  dataComponentName?: string;
}

export const AppVersion = (props: IAppVersionProps): JSX.Element => {
  const {sx = {}} = props;

  const {
    data: {
      version: serverVersion,
      codeName: serverCodeName,
    },
  } = useLoadData<IApiAppVersionGetResponse>({
    defaultData: {
      version: 'loading...',
      codeName: '...',
      builtAt: '...',
    },
    load: apiAppVersionGet,
  });

  return (
    <Box
      sx={sx}
      dataComponentName="AppVersion"
    >
      <p>Server version: {serverVersion} ({serverCodeName})</p>
      <p>Client version: {appConfig.appVersion} ({appConfig.appCodeName})</p>
      <p>Built at: {appConfig.builtAt})</p>
    </Box>
  );
};
