export {
  ITrafficVehiclesCountConfig,
  getDefaultTrafficVehiclesCountConfig,
} from "../../interfaces/ITrafficVehiclesCountConfig";
export {
  ITrafficVehiclesCountData,
} from "../../interfaces/ITrafficVehiclesCountData";
export {
  ITrafficVehiclesCountEnvironmentalData,
} from "../../interfaces/ITrafficVehiclesCountEnvironmentalData";

export * from "../../endpoints/for-web-app/apiTrafficStudiesVehiclesCountEnvironmentalDataSearchGet.interfaces";
export * from "../../endpoints/for-web-app/apiTrafficStudiesVehiclesCounterDataSearchGet.interfaces";
