import {
  TEnum,
  TObject,
} from "../typescript";

import {
  EValueType,
  IFieldConfiguration,
  TInputValueType,
  ITranslatedContent,
} from "./IFieldConfiguration";

import {formatField} from "./formatField";
import {getDeepValue} from "../utils";

export interface IFormatFieldEngine<TData> {
    field: (
        fieldName: keyof TData,
        options?: {
            value?: any;          // Optionally, you provide a custom value, from a different field or resource
            timezone?: string;    // Optionally, for date time fields, optionally again
        },
    ) => string;
    anyField: (
        fieldName: string,        // Untyped field name
        options?: {
            value?: any;          // Optionally, you provide a custom value, from a different field or resource
            timezone?: string;    // Optionally, for date time fields, optionally again
        },
    ) => string;
    label: (
        fieldName: keyof TData,
        defaultLabel: ITranslatedContent,
    ) => ITranslatedContent;
    data: TData;
}

export const fieldsEngine = <TData extends TObject, TCustomValueType extends TEnum = any>(
  {
    fieldConfigurations,
    data,
    customValueTypeConverters,
  }: {
        fieldConfigurations: IFieldConfiguration[];
        data: TData;
        customValueTypeConverters?: Record<TCustomValueType, (value: TInputValueType) => string>;
    },
): IFormatFieldEngine<TData> => {
  const dic =
        fieldConfigurations
          .reduce((acc: Record<string, IFieldConfiguration>, fieldConfiguration) => {
            if (acc[fieldConfiguration.fieldName]) {
              console.error(`formatFieldEngine(): field name [${fieldConfiguration.fieldName}] has more than one configurations`, {fieldConfigurations});
            }
            else {
              acc[fieldConfiguration.fieldName] = fieldConfiguration;
            }
            return acc;
          }, {});

  const getFieldConfiguration = (fieldName: string): IFieldConfiguration => {
    const fieldConfiguration = dic[fieldName];
    if (fieldConfiguration) {
      return fieldConfiguration;
    }
    else {
      console.error(`formatFieldEngine(): field name [${fieldName}] doesn't have configuration, the TEXT is applied as default`, {fieldConfigurations});
      return {
        fieldName,
        valueType: EValueType.TEXT,
        label: {default: fieldName as any},
      };
    }
  };

  return {
    field: (
      fieldName,
      {
        value,
        timezone,
      } = {},
    ): string => {
      const fieldConfiguration = getFieldConfiguration(fieldName as any);
      const dataFieldName = fieldConfiguration.sourceFieldName || fieldConfiguration.fieldName;
      return formatField<TCustomValueType>({
        fieldConfiguration,
        value:
                    value === undefined
                      ? getDeepValue(data, dataFieldName as any) || ""
                      : value,
        timezone,
        customValueTypeConverters,
      });
    },
    anyField: function (
      fieldName,
      options,
    ): string {
      return this.field(fieldName as any, options);
    },
    label: (
      fieldName: keyof TData,
      defaultLabel: ITranslatedContent,
    ): ITranslatedContent => {
      return (
        dic[fieldName as any]?.label
                || defaultLabel
                || {
                  default: fieldName as any,
                  tk: '',
                }
      );
    },
    data,
  };
};
