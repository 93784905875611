import {
  API_PATH_apiAuthCurrentUserInfoGet,
  IApiAuthCurrentUserInfoGetRequestQuery,
  IApiAuthCurrentUserInfoGetResponse,
} from "mhc-server";

import {appConfig} from "../../application/config/appConfig";
import {apiFetch} from "../../../api/apiFetch";

import {getOnline} from "utils-library/dist/commonJs/web";
import {apiAuthLogoutGet} from "./apiAuthLogoutGet";

import {localstorageSignState} from "./localstorageSignState";

export const apiAuthCurrentUserInfoGet = async (request: IApiAuthCurrentUserInfoGetRequestQuery = {}): Promise<IApiAuthCurrentUserInfoGetResponse> => {
  if (getOnline()) {
    // Online
    if (localstorageSignState.data.offlineLogoff) {
      // User previously has signed off when he was offline.
      // Update the local storage and apiAuthLogoutGet before login again.
      localstorageSignState.data.offlineLogoff = false;
      localstorageSignState.save();
      await apiAuthLogoutGet();
    }

    const currentUserInfo = await apiFetch.request<IApiAuthCurrentUserInfoGetRequestQuery, void, IApiAuthCurrentUserInfoGetResponse>({
      path: API_PATH_apiAuthCurrentUserInfoGet,
      query: request,
    });

    localstorageSignState.data.lastUserLogin = currentUserInfo;
    localstorageSignState.data.offlineLogoff = false;
    localstorageSignState.save();
    return currentUserInfo;
  }
  else {
    // Offline
    const lastUser: IApiAuthCurrentUserInfoGetResponse | null = localstorageSignState.data.lastUserLogin;

    if (!lastUser || !lastUser.user) {
      return {
        companyId: appConfig.companyId,
        user: null,
        authErrorMessage: [
          'You are offline',
          'In order to use the app offline, you must login prior to offline use',
        ].join('\n'),
        signedIn: false,
        tokenExpiresAt: 0,
      };
    }

    if (lastUser.tokenExpiresAt < Date.now()) {
      return {
        companyId: appConfig.companyId,
        user: null,
        authErrorMessage: 'You are offline and your last login was too long ago for your token to be valid.',
        signedIn: false,
        tokenExpiresAt: 0,
      };
    }

    return lastUser;
  }
};
