export const API_PATH_ApiDeviceMgmtRegionDeviceStatusSubscriptionPost = '/services/devices-mgtm/apiDeviceMgmtRegionDeviceStatusSubscriptionPost';

export interface IApiDeviceMgmtRegionDeviceStatusSubscriptionPostBodyRequest {
  wsConnectionId: string;
  regionIds: string[];
  subscribe: boolean;
}

export interface IApiDeviceMgmtRegionDeviceStatusSubscriptionPostResponse {
  ok: true;
}
