import {IDeviceStatus} from "../../interfaces/IDeviceStatus";

export const API_PATH_ApiDeviceMgmtDevicesStatusGet = '/services/devices-mgtm/apiDeviceMgmtDevicesStatusGet';

export interface IApiDeviceMgmtDevicesStatusGetQueryRequest {
  deviceIds: string[];
}

export interface IApiDeviceMgmtDevicesStatusGetResponse {
  devicesStatusDic: {
    [deviceId: string]: IDeviceStatus<any>;
  };
}
