import * as React from "react";
import {ReactElement} from "react";

import Grid from "@mui/material/Grid";

import {pickValidValue} from "utils-library/dist/commonJs/utils";

import {Box} from "../Box";

import {TBoxSpacing} from "../ui-interfaces";

import {
  TDataComponentName,
  getDataComponentName,
} from "../ui-interfaces";

export interface IGridContainerProps {
  show?: boolean;

  fullHeight?: boolean;

  dataComponentName?: TDataComponentName;

  /**
   * Default spacing between grid items.
   *
   * Default is 0.
   */
  spacing?: TBoxSpacing;

  /**
   * Specific horizontal spacing for grid items, overriding the default spacing.
   *
   * Default is 0.
   */
  spacingHorizontal?: TBoxSpacing;
  /**
   * Specific vertical spacing for grid items, overriding the default spacing.
   *
   * Default is 0.
   */
  spacingVertical?: TBoxSpacing;

  /**
   * Controls the presence of top and bottom spacing within the grid container.
   *
   * Default is true.
   *
   * **Warning:** The children's `position: sticky` is ineffective when using this.
   */
  topBottomSpace?: boolean;
  /**
   * Controls the presence of left and right spacing within the grid container.
   *
   * Default is true.
   *
   * **Warning:** The children's `position: sticky` is ineffective when using this.
   */
  leftRightSpace?: boolean;

  /**
   * Flex alignment of the grid items. Defaults to 'top'.
   * Possible values: 'top', 'stretch', 'start', 'center', 'end'.
   */
  verticalAlign?: "top" | "stretch" | "start" | "center" | "end";

  /**
   * The content to be rendered within the grid container.
   */
  children: any;
}

export const GridContainer = (props: IGridContainerProps): ReactElement | null => {
  const {
    show = true,
    fullHeight = false,
    dataComponentName,
    spacing = 0,
    spacingHorizontal = spacing,
    spacingVertical = spacing,
    leftRightSpace = true,
    topBottomSpace = true,
    verticalAlign = "top",
    children,
  } = props;

  const horizontalSpace = pickValidValue(spacingHorizontal, spacing, 0);
  const verticalSpace = pickValidValue(spacingVertical, spacing, 0);

  if (!show) return null;

  return (
    <Box
      dataComponentName={getDataComponentName(dataComponentName, "GridContainer")}
      sx={{
        overflow:
          (spacingHorizontal || spacingVertical)
          && (!leftRightSpace || !topBottomSpace)
            ? 'hidden'
            : undefined,
      }}
      fullHeight={fullHeight}
    >
      <Grid
        container
        sx={{
          position: 'relative',
          height: fullHeight ? '100%' : undefined,
          left:
            theme =>
              leftRightSpace
                ? undefined
                : theme.spacing(horizontalSpace * -1),
          width:
            theme =>
              leftRightSpace
                ? 'initial' // Fix scrollbars caused by the spacing
                : `calc(100% + ${theme.spacing(horizontalSpace * 2)})`,
          my:
            theme =>
              topBottomSpace
                ? undefined
                : theme.spacing(verticalSpace * -1),
          paddingLeft: horizontalSpace,
          paddingTop: verticalSpace,
          '& > div': {
            paddingRight: horizontalSpace,
            paddingBottom: verticalSpace,
          },
        }}
        alignItems={(() => {
          if (verticalAlign === "start") return "flex-start";
          if (verticalAlign === "end") return "flex-end";
          return verticalAlign;
        })()}
      >
        {children}
      </Grid>
    </Box>
  );
};
