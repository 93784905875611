import * as React from "react";

import {
  TDataComponentName,
  getDataComponentName,
} from "../ui-interfaces";
import {Box} from "../Box";

import {
  Theme,
  SxProps,
} from "../ThemeProvider";
import {
  sxTransition,
  ECSSDuration,
} from "../sxTransition";

export interface IFlexItemMinProps {
  innerRef?: React.Ref<HTMLDivElement>;
  fullHeight?: boolean;
  sx?: SxProps<Theme>;
  dataComponentName?: TDataComponentName;
  show?: boolean;       // Render or not
  hidden?: boolean;     // In the DOM but not visible
  noSpacing?: boolean;  // Do not apply spacing for this item. Useful when the content is shown with animation.
  title?: string;
  children?: any;
  onClick?: (e: React.MouseEvent) => void;
  onMouseMove?: (event: React.MouseEvent) => void;
  onMouseDown?: (event: React.MouseEvent) => void;
  onMouseUp?: (event: React.MouseEvent) => void;
  onMouseEnter?: (event: React.MouseEvent) => void;
  onMouseLeave?: (event: React.MouseEvent) => void;
  onScroll?: (event: React.UIEvent) => void;
  onWheel?: (event: React.WheelEvent<HTMLDivElement>) => void;
}

export const FlexItemMin = (props: IFlexItemMinProps): JSX.Element | null => {
  const {
    innerRef,
    fullHeight,
    sx = {},
    dataComponentName,
    show = true,
    hidden = false,
    noSpacing = false,
    title,
    children,
    onClick,
    onMouseMove,
    onMouseDown,
    onMouseUp,
    onMouseEnter,
    onMouseLeave,
    onScroll,
    onWheel,
  } = props;

  return (
    <Box
      ref={innerRef}
      sx={{
        flex: '0 0',
        minHeight: fullHeight ? '100%' : undefined,
        position: 'relative',
        margin:
          noSpacing
            ? 0
            : undefined,
        transition: theme => sxTransition(theme, "margin", ECSSDuration.SHORT),
        ...sx,
      }}
      dataComponentName={getDataComponentName(dataComponentName, "FlexItemMin")}
      show={show}
      hidden={hidden}
      title={title}
      onClick={onClick}
      onMouseMove={onMouseMove}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onScroll={onScroll}
      onWheel={onWheel}
    >
      {children}
    </Box>
  );
};
