export {
  isMac,
} from "../web-utils/isMac";

export {
  getDataComponentName,
} from "../web-utils/getDataComponentName";

export {
  getElementPositionInViewport,
} from "../web-utils/getElementPositionInViewport";

export {
  convertHtmlToPlain,
} from "./convertHtmlToPlain";
