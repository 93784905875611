import * as React from "react";
import {dynaSwitch} from "dyna-switch";

import {Box} from "../Box";

import MuiCircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";

export enum ECircularProgressVariant {
  DETERMINATE = "DETERMINATE",
  INDETERMINATE = "INDETERMINATE",
}

export enum ECircularProgressColor {
  INHERIT = "INHERIT",
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export enum ECircularProgressSize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  XLARGE = "XLARGE",
  XXLARGE = "XXLARGE",
}

interface ICircularProgressProps {
  sx?: SxProps<Theme>;
  variant?: ECircularProgressVariant;
  color?: ECircularProgressColor;
  thickness?: number;
  size?: ECircularProgressSize;
  label?: string;
  value?: number;
}

export const CircularProgress = (props: ICircularProgressProps) => {
  const {
    sx = {},
    variant = ECircularProgressVariant.DETERMINATE,
    color = ECircularProgressColor.PRIMARY,
    thickness,
    size = ECircularProgressSize.MEDIUM,
    label,
    value = 0,
  } = props;

  const muiCircularProgressVariant = dynaSwitch<'determinate' | 'indeterminate'>(
    variant,
    'determinate',
    {
      [ECircularProgressVariant.DETERMINATE]: 'determinate',
      [ECircularProgressVariant.INDETERMINATE]: 'indeterminate',
    },
  );

  const muiCircularProgressColor = dynaSwitch<'inherit' | 'primary' | 'secondary'>(
    color,
    'primary',
    {
      [ECircularProgressColor.PRIMARY]: 'primary',
      [ECircularProgressColor.SECONDARY]: 'secondary',
      [ECircularProgressColor.INHERIT]: 'inherit',
    },
  );

  const muiCircularProgressSize = dynaSwitch<30 | 40 | 55 | 70 | 85>(
    size,
    40,
    {
      [ECircularProgressSize.SMALL]: 30,
      [ECircularProgressSize.MEDIUM]: 40,
      [ECircularProgressSize.LARGE]: 55,
      [ECircularProgressSize.XLARGE]: 70,
      [ECircularProgressSize.XXLARGE]: 85,
    },
  );

  return (
    <Box
      sx={{
        display: 'inline-flex',
        position: 'relative',
        padding: 2,
        ...sx,
      }}
      dataComponentName="CircularProgress"
    >
      <MuiCircularProgress
        variant={muiCircularProgressVariant}
        color={muiCircularProgressColor}
        thickness={thickness}
        size={muiCircularProgressSize}
        value={value}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
};
