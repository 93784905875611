import {IUserInvitation} from "../interfaces/IUserInvitation";

export const API_PATH_apiUserInvitationGet = '/services/user-invitation';

export interface IApiUserInvitationGetQuery {
  email: string;
}

export interface IApiUserInvitationGetResponse {
  userInvitation: IUserInvitation;
}

