import * as React from "react";

import {
  TDataComponentName,
  getDataComponentName,
} from "../ui-interfaces";

import {BreakpointDeviceContainer} from "../BreakpointDeviceContainer";
import {FlexContainerVertical} from "./FlexContainerVertical";
import {FlexContainerHorizontal} from "./FlexContainerHorizontal";
import {TBoxSpacing} from "../ui-interfaces";
import {
  Theme,
  SxProps,
} from "../ThemeProvider";

export interface IFlexContainerResponsiveProps {
  sx?: SxProps<Theme>;
  dataComponentName?: TDataComponentName;

  spacing?: TBoxSpacing;
  inline?: boolean;
  fullHeight?: boolean;
  alignVertical?: 'top' | 'middle' | 'bottom';    // For horizontal view
  alignHorizontal?: 'left' | 'center' | 'right';  // For vertical view
  reverseOrder?: boolean;

  // By default, the orientation is horizontal
  // Use the below flags to switch it per screen breakpoint
  verticalOnMobile?: boolean;   // Default is false
  verticalOnTablet?: boolean;   // Default is false
  verticalOnLaptop?: boolean;   // Default is false
  verticalOnDesktop?: boolean;  // Default is false
  verticalOnDnWide?: boolean;    // Default is false

  children: any;
}

/**
 *  The FlexContainerResponsive component displays the items vertically according to the boolean prop "verticalOnXxx." Otherwise, it shows the items horizontally.
 */
export const FlexContainerResponsive = (props: IFlexContainerResponsiveProps): JSX.Element => {
  const {
    sx,
    dataComponentName,
    inline,
    spacing,
    fullHeight,
    alignVertical,
    alignHorizontal,
    reverseOrder,
    verticalOnMobile = false,
    verticalOnTablet = false,
    verticalOnLaptop = false,
    verticalOnDesktop = false,
    verticalOnDnWide = false,
    children,
  } = props;

  const renderContent = (vertical: boolean): JSX.Element =>
    vertical
      ? (
        <FlexContainerVertical
          sx={sx}
          dataComponentName={getDataComponentName(dataComponentName, 'FlexContainerResponsive')}
          inline={inline}
          spacing={spacing}
          alignHorizontal={alignHorizontal}
          fullHeight={fullHeight}
          reverseOrder={reverseOrder}
        >
          {children}
        </FlexContainerVertical>
      )
      : (
        <FlexContainerHorizontal
          sx={sx}
          dataComponentName={getDataComponentName(dataComponentName, 'FlexContainerResponsive')}
          inline={inline}
          spacing={spacing}
          alignVertical={alignVertical}
          fullHeight={fullHeight}
          reverseOrder={reverseOrder}
        >
          {children}
        </FlexContainerHorizontal>
      )
  ;

  return (
    <>
      <BreakpointDeviceContainer mobile>
        {renderContent(verticalOnMobile)}
      </BreakpointDeviceContainer>
      <BreakpointDeviceContainer tablet>
        {renderContent(verticalOnTablet)}
      </BreakpointDeviceContainer>
      <BreakpointDeviceContainer laptop>
        {renderContent(verticalOnLaptop)}
      </BreakpointDeviceContainer>
      <BreakpointDeviceContainer desktop>
        {renderContent(verticalOnDesktop)}
      </BreakpointDeviceContainer>
      <BreakpointDeviceContainer wide>
        {renderContent(verticalOnDnWide)}
      </BreakpointDeviceContainer>
    </>
  );
};
