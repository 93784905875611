import * as React from "react";
import {useMemo} from "react";

import {TNodeElement} from "utils-library/dist/commonJs/typescript";
import {pluralize} from "utils-library/dist/commonJs/utils";

import MuiBox from "@mui/material/Box";

import {
  TDataComponentName,
  getDataComponentName,
} from "../ui-interfaces";

import {Condition} from "../Condition";

import {Theme} from "../ThemeProvider";

import {
  SxProps,
} from "../ThemeProvider";

export interface IBoxProps {
  id?: string;
  sx?: SxProps<Theme>;
  inline?:        // Default is false
    | boolean     // Set true for inline-block
    | 'flex';     // Set 'flex' for inline-flex
  style?: React.CSSProperties;
  dir?: string;
  dataComponentName?: TDataComponentName;
  dataAttributes?: Record<string, string>;    // Properties should start with "data-" otherwise consoles errors
  lowLevelClassName?: string;   // Do not use it for styling just for special cases!

  component?: TNodeElement;

  show?: boolean;         // Render or not
  visible?: boolean;      // CSS visibility, when is false is not show but the height is reserved
  not?: true;             // Negative for `show`

  hidden?: boolean;       // In the DOM but not visible

  fullWidth?: boolean;
  fullHeight?: boolean;

  title?: string;
  tabIndex?: number;

  children?: any;

  onClick?: (event: React.MouseEvent) => void;
  onMouseMove?: (event: React.MouseEvent) => void;
  onMouseDown?: (event: React.MouseEvent) => void;
  onMouseUp?: (event: React.MouseEvent) => void;
  onMouseEnter?: (event: React.MouseEvent) => void;
  onMouseLeave?: (event: React.MouseEvent) => void;
  onScroll?: (event: React.UIEvent) => void;
  onWheel?: (event: React.WheelEvent<HTMLDivElement>) => void;
}

export const Box = React.forwardRef<HTMLDivElement, IBoxProps>((props, ref): JSX.Element | null => {
  const {
    id,
    sx = {},
    style = {},
    inline = false,
    dir,
    dataComponentName = [],
    dataAttributes: userDataAttributes,
    lowLevelClassName,

    component,

    show = true,
    visible = true,
    not,

    hidden = false,

    fullWidth = false,
    fullHeight = false,

    title,
    tabIndex,

    children,

    onClick,
    onMouseMove,
    onMouseDown,
    onMouseUp,
    onMouseEnter,
    onMouseLeave,
    onScroll,
    onWheel,
  } = props;

  const dataAttributes = useMemo<Record<string, string> | undefined>(() => {
    if (!userDataAttributes) return;
    let invalidDataAttributes = 0;
    const dataAttributes =
      Object.entries(userDataAttributes)
        .reduce((acc, [key, value]) => {
          key.startsWith('data-')
            ? acc[key] = value
            : invalidDataAttributes++;
          return acc;
        }, {});
    if (invalidDataAttributes) {
      console.error(
        `Box component 20230608114528 error: The dataAttributes contain ${invalidDataAttributes} ${pluralize('key', invalidDataAttributes)} that ${pluralize('does', invalidDataAttributes)} not start with 'data-'.`,
        {dataAttributes: userDataAttributes},
      );
    }
    return dataAttributes;
  }, []);

  return (
    <Condition if={show} not={not}>
      <MuiBox
        id={id}
        className={lowLevelClassName}
        sx={{
          ...(
            hidden
              ? {
                height: 0,
                overflow: 'hidden',
                position: 'absolute',
                right: '100000px',
              }
              : {}
          ),
          ...(
            inline === 'flex'
              ? {display: 'inline-flex'}
              : inline
                ? {display: 'inline-block'}
                : {}
          ),
          visibility: visible ? undefined : 'hidden',
          ...sx,
        }}
        ref={ref}
        style={{
          ...style,
          ...(
            fullWidth
              ? {width: '100%'}
              : {}
          ),
          ...(
            fullHeight
              ? {height: '100%'}
              : {}
          ),
        }}
        data-component-name={getDataComponentName(dataComponentName, "Box")}
        {...dataAttributes}
        dir={dir}
        title={title}
        tabIndex={tabIndex}
        component={component}
        onClick={onClick}
        onMouseMove={onMouseMove}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onScroll={onScroll}
        onWheel={onWheel}
      >
        {children}
      </MuiBox>
    </Condition>
  );
});
