import * as React from "react";
import {useState} from "react";

import {IGeoPosition} from "utils-library/dist/commonJs/geo";
import {
  EMapType,
} from "../geoInterfaces";

import {
  GeoMapLeafletCore,
  IGeoMapLeafletCoreProps,
  IGeoMapLeafletMarker,
  IGeoMapLeafletCoreRef,
  THandleSaveMaps,
  THandleSaveMapsArgs,
  THandleDeleteMaps,
  THandleDeleteMapsArgs,
} from "../GeoMapLeafletCore";

import {SaveOfflineMapsDialog} from "./components/SaveOfflineMapsDialog";
import {DeleteOfflineMapsDialog} from "./components/DeleteOfflineMapsDialog";

export type IGeoMapLeafletOfflineManagerProps = Pick<IGeoMapLeafletCoreProps, Exclude<keyof IGeoMapLeafletCoreProps, keyof IExcludeProps>>;

interface IExcludeProps {
  readOnly?: boolean;
  disabled?: boolean;

  googleMapApiKey?: string;

  defaultMapType?: EMapType;
  availableMapTypes?: EMapType[];

  defaultZoom?: number;
  minZoom?: number;
  maxZoom?: number;

  defaultCenter?: IGeoPosition;

  centerButton?: boolean;

  markers?: IGeoMapLeafletMarker[];

  showOfflineSaveDeleteButtons?: boolean;

  ref?: IGeoMapLeafletCoreRef;

  rightToolbar?: JSX.Element;
  mapOverlay?: JSX.Element;

  onOfflineSaveMaps?: THandleSaveMaps;
  onOfflineDeleteMaps?: THandleDeleteMaps;
  onClick?: (geoPosition: IGeoPosition) => void;
}

export const GeoMapLeafletOfflineManager = (props: IGeoMapLeafletOfflineManagerProps): JSX.Element => {
  const [saveMapsArgs, setSaveMapsArgs] = useState<THandleSaveMapsArgs | null>(null);
  const [deleteMapsArgs, setDeleteMapsArgs] = useState<THandleDeleteMapsArgs | null>(null);

  const handleDialogClose = () => {
    setSaveMapsArgs(null);
    setDeleteMapsArgs(null);
  };

  const mapOverlay: JSX.Element | undefined = (() => {
    if (saveMapsArgs) {
      return (
        <SaveOfflineMapsDialog
          saveMapsArgs={saveMapsArgs}
          onClose={handleDialogClose}
        />
      );
    }
    if (deleteMapsArgs) {
      return (
        <DeleteOfflineMapsDialog
          deleteMapsArgs={deleteMapsArgs}
          onClose={handleDialogClose}
        />
      );
    }
    return undefined;
  })();

  return (
    <GeoMapLeafletCore
      {...props}
      maxZoom={19}
      showOfflineSaveDeleteButtons
      mapOverlay={mapOverlay}
      onOfflineSaveMaps={setSaveMapsArgs}
      onOfflineDeleteMaps={setDeleteMapsArgs}
    />
  );
};
