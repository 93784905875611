import {EDeviceType} from "../../interfaces/EDeviceType";
import {IDevice} from "../../interfaces/IDevice";

export const API_PATH_ApiDeviceMgmtDeviceCreate = '/services/devices-mgtm/apiDeviceMgmtDeviceCreate';

export interface IApiDeviceMgmtDeviceCreateBodyRequest {
  id: string;

  beforeDeviceId: string;

  activationId: string;

  isActive: boolean;

  deviceBrokerId: string;
  deviceType: EDeviceType;
  regionId: string;
  locationId: string;

  geoPosition: {
    lat: number;
    lng: number;
    alt?: number;
  };
  showOnMap: boolean;     // Do not show it for some reason

  label: string;          // Used only for 1st load
  description: string;

  manufacturerName: string;

  outdatedMsThreshold: number;
  deadMsThreshold: number;

  notes: string;
}

export interface IApiDeviceMgmtDeviceCreateResponse {
  device: IDevice;
}
