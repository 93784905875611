/**
 * Get the industrial hours number for the given hour and minutes.
 *
 * @example getIndustrialHours(14, 30) = 14.50
 * @param hour The hour (an integer).
 * @param minute The minute (an integer).
 */
export const getIndustrialHours = (hour: number, minute: number): number => {
  return Math.floor(hour) + (minute / 60);
};
