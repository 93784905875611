import {
  IUser,
  EUserAuthenticationRights,
  EAuthStrategy,
} from "../../../user-authentication/client/for-web-app";

export const testCreateUser = (
  user: Partial<IUser> = {},
  index_?: number,
): IUser => {
  const index = (index_ || 0) + 42323;
  const applyUser = {
    title: 'Mr.',
    firstName: 'John',
    lastName: 'Smith',
    ...user,
  };
  const timestamp =
    1610521000000
    + applyUser.lastName.split('').reduce((acc, letter) => acc + letter.charCodeAt(0), 0);
  return {
    id: `ID-${index}-TEST-USER`,
    authUserId: `${EAuthStrategy.OAUTH_GOOGLE}/0245nj2to240t2tj23` + index,
    createdAt: timestamp + 10,
    updatedAt: timestamp + 20,
    deletedAt: 0,
    rights: applyUser.rights || [EUserAuthenticationRights.ACTIVE],
    ...applyUser,
    displayName: `${applyUser.firstName[0]}.${applyUser.lastName}`,
    email: user.email || `${applyUser.firstName}-${applyUser.lastName[0]}@example.com`.toLowerCase(),
    createdBy: 'test-system',
    updatedBy: 'test-system',
    deletedBy: '',
    archivedAt: 0,
    archivedBy: '',
  };
};
