import {
  EWorkZonesManagementRights,
} from "mhc-server";

import {IAppRoute} from "../../application/config/IAppRoute";
import {buildUrlByRoutePath} from "utils-library/dist/commonJs/utils";

import {
  ECIMTabRoot,
  ECIMTabMap,
} from "../components/WorkzoneForm";

export const routeWorkzoneCreatePaths: IAppRoute<{
  tabRoot?: ECIMTabRoot;
  tabMap?: ECIMTabMap;
}> = {
  menuId: '',
  title: 'Create CIM',
  routePath: '/cim/create/:tabRoot?/:tabMap?',
  getRoutePath:
    pathParams =>
      buildUrlByRoutePath({
        routePath: routeWorkzoneCreatePaths.routePath,
        pathParams,
      }),
  userHasAllRights: [EWorkZonesManagementRights.WORKZONES_EDIT],
  userHasAnyOfRights: [],
  render: () => null,
};

