import {ISrcSet} from "dyna-image";
import {getFilenameWithDotSuffix} from "utils-library/dist/commonJs/utils";

export enum EImageSizes {
  W192 = "W192",
  W384 = "W384",
  W768 = "W768",
  W1024 = "W1024",
  W2048 = "W2048",
  W4096 = "W4096",
}

export const getImageSrcSet =
  (mainUrl: string): Required<ISrcSet> => ({
    main: mainUrl,
    W192: getFilenameWithDotSuffix(mainUrl, EImageSizes.W192),
    W384: getFilenameWithDotSuffix(mainUrl, EImageSizes.W384),
    W768: getFilenameWithDotSuffix(mainUrl, EImageSizes.W768),
    W1024: getFilenameWithDotSuffix(mainUrl, EImageSizes.W1024),
    W2048: getFilenameWithDotSuffix(mainUrl, EImageSizes.W2048),
    W4096: getFilenameWithDotSuffix(mainUrl, EImageSizes.W4096),
  });
