export const API_PATH_ApiDeviceMgmtRegionDelete = '/services/devices-mgtm/apiDeviceMgmtRegionDelete';

export interface IApiDeviceMgmtRegionDeleteBodyRequest {
  regionId: string;
  deleteIt: boolean;
}

export interface IApiDeviceMgmtRegionDeleteResponse {
  ok: true;
}
