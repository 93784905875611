import {EDeviceManagementRights} from "mhc-server";

import {buildUrlByRoutePath} from "utils-library/dist/commonJs/utils";

import {IAppRoute} from "../../application/config/IAppRoute";

import MercuryMapIcon from "@mui/icons-material/Sensors";

export const routeDeviceManagementMapPaths: IAppRoute<{ searchObjectText: string }> = {
  menuId: 'device-management',
  icon: <MercuryMapIcon/>,
  title: 'Mercury',
  description: 'Devices map',
  routePath: '/device-management-map/:searchObjectText?/',
  getRoutePath: ({searchObjectText} = {searchObjectText: ''}) =>
    buildUrlByRoutePath({
      routePath: routeDeviceManagementMapPaths.routePath,
      pathParams: {searchObjectText},
    }),
  userHasAllRights: [
  ],
  userHasAnyOfRights: [
    EDeviceManagementRights.VIEW,
  ],
  render: () => null,
};
