import * as React from "react";
import {useRef} from "react";

import {Box} from "../Box";
import {ContainerPortal} from "../ContainerPortal";
import {
  Button,
  EButtonColor,
} from "../Button";
import {useClipboardCopy} from "../useClipboardCopy";

import {
  IIconComponent,
  createIcon,
} from "../IconComponent";
import CopyIcon from '@mui/icons-material/ContentCopy';
import CopiedIcon from '@mui/icons-material/CheckCircleOutline';
import IsLoadingIcon from '@mui/icons-material/Cached';


export interface IButtonCopyToClipboardProps {
  Icon?: IIconComponent;
  label?: string;
  title?: string;
  color?: EButtonColor;
  description?: string;
  show?: boolean;
  disabled?: boolean;
  hideLabelOnMobile?: boolean;
  resetCopyTimeout?: number;
  children: JSX.Element | string; // The content that will be copied to clipboard as text
}

export const ButtonCopyToClipboard = (props: IButtonCopyToClipboardProps): JSX.Element => {
  const {
    Icon = createIcon.byMuiIcon(CopyIcon),
    label = "Copy to clipboard",
    description,
    title,
    color,
    show,
    disabled,
    hideLabelOnMobile,
    resetCopyTimeout = 3000,
    children,
  } = props;

  const refButton = useRef<HTMLDivElement>(null);
  const refSource = useRef<HTMLDivElement>(null);

  const {
    isLoading,
    isClipboardSupported,
    isCopied,
    error,
  } = useClipboardCopy({
    refButton,
    refContent: refSource,
    resetCopyTimeout,
  });

  return (
    <>
      <Box inline ref={refButton}>
        <Button
          sx={{cursor: isClipboardSupported ? undefined : "not-allowed"}}
          show={show}
          Icon={
            isLoading
              ? createIcon.byMuiIcon(IsLoadingIcon)
              : isCopied
                ? createIcon.byMuiIcon(CopiedIcon)
                : Icon
          }
          description={
            [
              description,
              error && `Error: ${error.userMessage || error.message || 'Unknown error'}`,
            ]
              .filter(Boolean)
              .join('\n')
          }
          title={isClipboardSupported ? title : 'Clipboard not supported by your browser'}
          color={
            error
              ? EButtonColor.ERROR
              : isCopied
                ? EButtonColor.SUCCESS
                : color
          }
          disabled={
            isLoading ||
            disabled ||
            !isClipboardSupported
          }
          hideLabelOnMobile={hideLabelOnMobile}
        >
          {label}
        </Button>
      </Box>

      <ContainerPortal>
        <div
          hidden
          ref={refSource}
        >
          {children}
        </div>
      </ContainerPortal>
    </>
  );
};
