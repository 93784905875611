export const convertEnumKeyToHumanReadable = (text: string, everyWord = false): string => {
  let t = text.toLowerCase().replace(/_/g, ' ')
    .trim();
  if (!t[0]) {
    return t;
  }
  if (everyWord) {
    t = t.split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }
  else {
    t = t[0].toUpperCase() + t.substring(1);
  }
  return t;
};
