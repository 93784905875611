import * as React from "react";
import {ReactElement} from "react";

import {Box} from "../Box";
import {IIconComponent} from "../IconComponent";
import {IconViewer} from "../IconViewer";
import {TBoxSpacing} from "../ui-interfaces";

import {Theme} from "../ThemeProvider";

export interface ILabelOverlayProps {
  show?: boolean;
  fullHeight?: boolean;         // Default is true
  spacing?: TBoxSpacing;
  Icon?: IIconComponent;
  iconColor?: (theme: Theme) => string;
  size?: number;                // Default 128
  iconElement?: ReactElement;   // Use it only for custom icon elements (not actual icons)
  title?: string | ReactElement;
  description?: string | ReactElement;
  children?: any;
}

export const LabelOverlay = (
  {
    show = true,
    fullHeight = true,
    spacing = 3,
    Icon,
    iconColor,
    size = 128,
    iconElement,
    title,
    description,
    children,
  }: ILabelOverlayProps,
): ReactElement | null => {

  if (!show) return null;

  return (
    <Box
      sx={{
        color: theme => theme.palette.grayShades.gray5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        py: spacing,
        '& > *': {
          marginBottom: theme => theme.spacing(spacing),
          '&:last-child': {marginBottom: 0},
        },
      }}
      fullWidth
      fullHeight={fullHeight}
      dataComponentName="No"
    >
      <IconViewer
        sx={{color: theme => iconColor?.(theme)}}
        Icon={Icon}
        width={size}
      />
      {iconElement}
      <Box
        show={!!title}
        sx={{
          maxWidth: '50%',
          fontFamily: theme => theme.typography.body1.fontFamily,
          fontSize: theme => theme.typography.fontSize * 2,
          fontWeight: 'bold',
        }}
        children={title}
      />
      <Box
        show={!!description}
        sx={{
          maxWidth: '70%',
          fontFamily: theme => theme.typography.body2.fontFamily,
          fontSize: theme => theme.typography.fontSize - 1,
        }}
        children={description}
      />
      {children}
    </Box>
  );
};
