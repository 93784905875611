import {parseJSONDefault} from "utils-library/dist/commonJs/utils";

import {IFilterSort} from "mhc-ui-components/dist/ViewController";

import {IAppRoute} from "../../application/config/IAppRoute";

import {routeTrafficStudyListPaths} from "./routeTrafficStudyList.paths";

import {
  IFilter,
  ISort,
  TrafficStudiesList,
} from "../pages/TrafficStudiesList";

export const routeTrafficStudyList: IAppRoute<{ searchObjectText: string }> = {
  ...routeTrafficStudyListPaths,
  render: (
    {pathParams: {searchObjectText}},
  ) => {
    const {
      filter,
      sort,
    } = parseJSONDefault<IFilterSort<IFilter, ISort>>({
      serialized: searchObjectText,
      default: {
        filter: {
          searchText: '',
          start: null,
          end: null,
          archived: false,
          deleted: false,
        },
        sort: {},
      },
      errorCode: 20240102094625,
      errorMessage: 'routeTrafficStudyList, cannot parse searchObjectText from URL',
    });
    return (
      <TrafficStudiesList
        filter={filter}
        sort={sort}
      />
    );
  },
};
