import {setIntervals} from "utils-library/dist/commonJs/utils";
import {isInViewPort} from "utils-library/dist/commonJs/web";

import {watchElementRemoval} from "./watchElementRemoval";

export const watchElementVisibility = (
  {
    element,
    onVisibilityChange,
  }: {
    element: HTMLElement;
    onVisibilityChange: (isShown: boolean) => void;
  },
): void => {
  let lastIsShown: boolean | null = null;

  const handleVisibilityChange = (isShown: boolean): void => {
    if (isShown === lastIsShown) return;
    lastIsShown = isShown;
    onVisibilityChange(isShown);
  };

  const observer = new IntersectionObserver((entries) => {
    const isShown = entries[0].isIntersecting;
    handleVisibilityChange(isShown);
  });
  observer.observe(element);

  watchElementRemoval({
    element,
    onRemove: observer.disconnect,
  });

  // Sometimes, on startup only, the above observer still cannot resolve the initial state visibility.
  // For a white watch the visibility and apply it.
  setIntervals({
    timeout: 100,
    times: 10,
    leading: false,
    exec: async () => {
      const isShown = await isInViewPort(element);
      handleVisibilityChange(isShown);
    },
  });
};
