import * as React from "react";
import {
  BreakpointContainer,
  EBreakpoint,
} from "../BreakpointContainer";

import {EBreakpointDevice} from "../ui-interfaces";

export interface IBreakpointDeviceContainerProps {
  allExcept?: boolean;      // Default is false, if true reverts the rest props

  mobile?: boolean;         // Default is false
  tablet?: boolean;         // Default is false
  laptop?: boolean;         // Default is false
  desktop?: boolean;        // Default is false
  wide?: boolean;           // Default is false, aka: infinite

  in?: EBreakpointDevice[]; // Default is [], in breakpoints

  children: any;
}

export const BreakpointDeviceContainer = (props: IBreakpointDeviceContainerProps): JSX.Element => {
  const {
    allExcept = false,
    mobile = false,
    tablet = false,
    laptop = false,
    desktop = false,
    wide = false,
    "in": _in = [],
    children,
  } = props;

  const calcAllExcept = (show: boolean): boolean => allExcept ? !show : show;

  return (
    <>
      {calcAllExcept(mobile || _in.includes(EBreakpointDevice.MOBILE)) && (
        <BreakpointContainer
          breakpoint={EBreakpoint.XSMALL}
          toBreakpoint={EBreakpoint.SMALL}
        >
          {children}
        </BreakpointContainer>
      )}
      {calcAllExcept(tablet || _in.includes(EBreakpointDevice.TABLET)) && (
        <BreakpointContainer
          breakpoint={EBreakpoint.SMALL}
          toBreakpoint={EBreakpoint.MEDIUM}
        >
          {children}
        </BreakpointContainer>
      )}
      {calcAllExcept(laptop || _in.includes(EBreakpointDevice.LAPTOP)) && (
        <BreakpointContainer
          breakpoint={EBreakpoint.MEDIUM}
          toBreakpoint={EBreakpoint.LARGE}
        >
          {children}
        </BreakpointContainer>
      )}
      {calcAllExcept(desktop || _in.includes(EBreakpointDevice.DESKTOP)) && (
        <BreakpointContainer
          breakpoint={EBreakpoint.LARGE}
          toBreakpoint={EBreakpoint.XLARGE}
        >
          {children}
        </BreakpointContainer>
      )}
      {calcAllExcept(wide || _in.includes(EBreakpointDevice.WIDE)) && (
        <BreakpointContainer
          breakpoint={EBreakpoint.XLARGE}
        >
          {children}
        </BreakpointContainer>
      )}
    </>
  );

};
