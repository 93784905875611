import * as React from "react";

import MuiPaper from '@mui/material/Paper';

import {
  Theme,
  SxProps,
} from "../ThemeProvider";

export interface IPaperProps {
  sx?: SxProps<Theme>;
  variant?: EPaperVariant;
  square?: boolean;
  elevation?: number;
  fullWidth?: boolean;
  fullHeight?: boolean;
  children: any;
  onClick?: () => void;
}

export enum EPaperVariant {
  ELEV = 'elevation',
  OUTLINED = 'outlined',
}

export const Paper = (props: IPaperProps): JSX.Element => {
  const {
    sx = {},
    variant = EPaperVariant.ELEV,
    square = false,
    elevation,
    fullWidth = false,
    fullHeight = false,
    children,
    onClick,
  } = props;

  return (
    <MuiPaper
      data-component-name="Paper"
      sx={{
        padding: theme => theme.spacing(1),
        width: fullWidth ? '100%' : undefined,
        height: fullHeight ? '100%' : undefined,
        ...sx,
      }}
      variant={variant}
      elevation={elevation}
      square={square}
      onClick={onClick}
    >
      {children}
    </MuiPaper>
  );
};
