import {IAlertConfig} from "../../interfaces/IAlertConfig";

export const API_PATH_ApiAlertMgtmAlertConfigSearchGet = '/services/alerts-mgtm/apiAlertMgtmAlertConfigSearchGet';

export interface IApiAlertMgtmAlertConfigSearchGetRequest {
  // Basics
  searchText: string;
  // Sort
  sortByFieldName?: keyof IAlertConfig;
  sortDirection?: 0 | 1 | -1;
  // Pagination
  skip: number;
  limit: number;
}

export interface IApiAlertMgtmAlertConfigSearchGetResponse {
  alertConfigs: Array<IAlertConfig>;
}
