/**
 * Execute a method at intervals with the possibility of cancellation.
 */
export const setIntervals = (
  {
    timeout,
    times,
    exec,
    leading = true,
  }: {
        /**
         * Timeout of interval in ms
         */
        timeout: number;
        /**
         * How many times
         */
        times: number;
        /**
         * If the 1st call should be before or after the timeout
         */
        leading?: boolean;
        /**
         * The method to execute
         */
        exec: (cancel: () => void) => void;
    },
): {
    cancel: () => void;
} => {
  let count = 0;
  let lastHandler: any | null = null;
  let isCanceled = false;

  const handleCancel = (): void => {
    if (lastHandler) clearTimeout(lastHandler);
    isCanceled = true;
  };
  const execute = () => {
    count++;
    if (isCanceled) return;
    if (leading) exec(handleCancel);
    lastHandler = setTimeout(
      () => {
        if (!leading) exec(handleCancel);
        if (count < times) execute();
      },
      timeout,
    );
  };
  if (times) execute();

  return {cancel: handleCancel};
};
