import {TEnum} from "utils-library/dist/commonJs/typescript";

export enum EPostInteractions {
  VOTE = "@@IPV",
  CLAP = "@@IPC",
  REACT = "@@IPR",
}

export interface IVote {
  vote: 1 | -1;
}

export interface IClap {
  c: number;  // Claps counter
}

export interface IReact {
  t: TEnum;   // Enum Type
  c: 1 | -1;  // Counter
}
