import {convertEnumKeyToHumanReadable} from "utils-library/dist/commonJs/utils";

import {ESendUserInvitationStatus} from "mhc-server";

import {Box} from "mhc-ui-components/dist/Box";

import {
  Theme,
  SxProps,
  useTheme,
} from "mhc-ui-components/dist/ThemeProvider";
import NonCreatedIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SuccessIcon from "@mui/icons-material/CheckCircleOutline";
import DeletedIcon from "@mui/icons-material/Delete";
import SentIcon from "@mui/icons-material/CardGiftcard";
import SendingIcon from "@mui/icons-material/Cached";
import SendFailedIcon from "@mui/icons-material/ErrorOutline";
import UndeletedIcon from "@mui/icons-material/RestoreFromTrash";
import NotSentIcon from "@mui/icons-material/AddCircleOutline";
import FallbackIcon from "@mui/icons-material/Adjust";

export interface IInvitationStatusProps {
  sx?: SxProps<Theme>;
  status: ESendUserInvitationStatus;
}

export const InvitationStatus = (props: IInvitationStatusProps): JSX.Element => {
  const {
    sx : sxUser = {},
    status,
  } = props;
  const theme = useTheme();

  const {
    sx: sxIcon,
    icon,
    label,
  } = (() => {
    switch (status) {
      case ESendUserInvitationStatus.NOT_CREATED:
        return {
          sx: {color: theme.palette.error.main},
          icon: <NonCreatedIcon/>,
          label: convertEnumKeyToHumanReadable(status),
        };
      case ESendUserInvitationStatus.ACCEPTED:
        return {
          sx: {color: theme.palette.success.main},
          icon: <SuccessIcon/>,
          label: 'Accepted',
        };
      case ESendUserInvitationStatus.DELETED:
        return {
          sx: {color: theme.palette.error.main},
          icon: <DeletedIcon/>,
          label: 'Deleted',
        };
      case ESendUserInvitationStatus.SENT:
        return {
          sx: {color: theme.palette.warning.main},
          icon: <SentIcon/>,
          label: 'Sent',
        };
      case ESendUserInvitationStatus.SENDING:
        return {
          sx: {color: theme.palette.warning.main},
          icon: <SendingIcon/>,
          label: 'Sending...',
        };
      case ESendUserInvitationStatus.SEND_FAILED:
        return {
          sx: {color: theme.palette.warning.main},
          icon: <SendFailedIcon/>,
          label: 'Send failed',
        };
      case ESendUserInvitationStatus.UNDELETED:
        return {
          sx: {color: theme.palette.error.main},
          icon: <UndeletedIcon/>,
          label: 'Undeleted',
        };
      case ESendUserInvitationStatus.NOT_SENT:
        return {
          sx: {color: theme.palette.error.main},
          icon: <NotSentIcon/>,
          label: 'Not sent',
        };
      default:
        return {
          sx: {color: theme.palette.error.main},
          icon: <FallbackIcon/>,
          label: (status as any || '').toLowerCase(),
        };
    }
  })();

  return (
    <Box
      dataComponentName="InvitationStatus"
      sx={{
        ...sxUser,
        ...sxIcon,
        textAlign: 'center',
      }}
    >
      <div>{icon}</div>
      <div>{label}</div>
    </Box>
  );
};
