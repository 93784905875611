import {IAppRoute} from "../../application/config/IAppRoute";
import {EDeviceManagementRights} from "mhc-server";

export const routeDeviceManagementTreePaths: IAppRoute = {
  menuId: '#84583493432455343',
  title: 'Mercury Config',
  routePath: '/device-management-tree--v1',
  getRoutePath: () => routeDeviceManagementTreePaths.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [
    EDeviceManagementRights.VIEW,
    EDeviceManagementRights.CONFIG_DEVICES,
  ],
  render: () => null,
};
