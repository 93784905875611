import * as React from "react";
import {keyframes} from "@emotion/react";
import {Box} from "../../Box";
import {
  SxProps,
  Theme,
} from "../../ThemeProvider";

export interface IDoublePulseProgressIconProps {
  sizePx: string;
  duration?: number;
}

export const DoublePulseProgressIcon: React.FC<IDoublePulseProgressIconProps> = (
  {
    sizePx,
    duration = 1000,
  },
) => {
  const ballPulseDoubleAnimation = keyframes`
    0%, 100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.0);
    }
  `;

  const sxBall: SxProps<Theme> = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme => theme.palette.grayShades.gray7,
    borderRadius: '50%',
    animation: `${ballPulseDoubleAnimation} ${duration}ms ease-in-out infinite`,
    opacity: 0.5,
  };

  return (
    <Box
      sx={{
        width: sizePx,
        height: sizePx,
        position: 'relative',
      }}
    >
      <Box
        sx={sxBall}
      />
      <Box
        sx={{
          ...sxBall,
          animationDelay: `-${duration / 2}ms`,
        }}
      />
    </Box>
  );
};

