export const convertBooleanBoth = {
  toString: (value: boolean | "both"): string => String(value),
  fromString: (value: string): boolean | "both" => {
    switch (value) {
      case "true":
        return true;
      case "false":
        return false;
      case "both":
        return "both";
      default:
        console.error(`convertBooleanBothFromString: cannot convert given value [${value}]`, {value});
        return false;
    }
  },
};
