import {EDeviceManagementRights} from "mhc-server";
import {IAppRoute} from "../../application/config/IAppRoute";
import MercuryTreeIconIcon from '@mui/icons-material/AccountTree';

export const routeDeviceManagementVirtualTreePaths: IAppRoute<{ presetId: string }> = {
  menuId: 'device-management-tree',
  icon: <MercuryTreeIconIcon/>,
  title: 'Device management',
  description: 'Install/Remove devices',
  routePath: '/device-management-tree/:presetId?',
  getRoutePath: ({presetId} = {presetId: ''}) =>
    routeDeviceManagementVirtualTreePaths.routePath
      .replace(':presetId?', presetId),
  userHasAllRights: [],
  userHasAnyOfRights: [
    EDeviceManagementRights.VIEW,
    EDeviceManagementRights.CONFIG_DEVICES,
  ],
  render: () => null,
};
