export const API_PATH_ApiAppPresetArchive = '/services/app/presets/apiAppPresetArchive';

export interface IApiAppPresetArchiveRequest {
  id: string;
  archiveIt: boolean;
}

export interface IApiAppPresetArchiveResponse {
  ok: true;
}
