import {ITrafficStudy} from "../../../interfaces/ITrafficStudy";
import {IValidationResult} from "utils-library/dist/commonJs/validation-engine";

export const API_PATH_ApiTrafficStudyGet = '/services/traffic-studies/apiTrafficStudyGet';

export interface IApiTrafficStudyGetRequest {
  id: string;
}

export interface IApiTrafficStudyGetResponse {
  trafficStudy: ITrafficStudy | null;
  validation: IValidationResult<ITrafficStudy> | null;
}
