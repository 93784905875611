export const findStringDuplicates = (...arrays: string[][]): string[] => {
  const duplicates =
    arrays
      .flat()
      .filter((value, index, self) =>
        self.indexOf(value) !== index &&
        arrays.some(array => array.includes(value)),
      );
  return Array.from(new Set(duplicates));
};
