import * as React from "react";
import {useMemo} from "react";
import {guid} from "dyna-guid";

import {convertEnumToValueLabel} from "utils-library/dist/commonJs/utils";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';

import {
  TDataComponentName,
  getDataComponentName,
} from "../ui-interfaces";
import {Box} from "../Box";
import {
  HelperText,
  EHelperTextType,
} from "../HelperText";
import {
  FlexContainerHorizontal,
  FlexItemMax,
  FlexItemMin,
} from "../FlexContainer";

import {IIconComponent} from "../IconComponent";

import {
  SxProps,
  Theme,
} from '../ThemeProvider';
import {
  Button,
  EButtonColor,
  EButtonSize,
} from "../Button";

export interface IInputSelectProps<TData> {
  sx?: SxProps<Theme>;
  dataComponentName?: TDataComponentName;
  /**
   * Use false to do not use the native one, better ui but not so good for mobile devices since doesn't use the native pickers of the device.
   */
  native?: boolean;
  variant?: EIInputSelectVariant;
  name?: keyof TData;
  nameOnInput?: boolean;
  Icon?: IIconComponent;
  label?: string;
  helperLabel?: string;
  ariaLabel?: string;
  readOnly?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  required?: boolean;
  validationError?: string;
  options?: IInputSelectOption[];
  optionsEnum?: any;
  emptyOption?: boolean;
  emptyOptionLabel?: string;
  groupedOptions?: IInputSelectGroup[];
  listDeprecatedValues?: boolean;
  deprecatedValues?: string[];
  value?: string;
  buttons?: IInputSelectButton[];
  onOpen?: () => void;
  onChange?: (value: string, name: keyof TData) => void;
}

export enum EIInputSelectVariant {
  FILLED = "filled",
  OUTLINED = "outlined",
  STANDARD = "standard",
}

export interface IInputSelectGroup {
  label: string;
  richLabel?: JSX.Element;  // Used in `native` mode only
  options: IInputSelectOption[];
}

export interface IInputSelectOption {
  label: string;
  richLabel?: JSX.Element;  // Used in `native` mode only
  disabled?: boolean;
  value: string;
}

export interface IInputSelectButton {
  show?: boolean;
  Icon: IIconComponent;
  color?: EButtonColor;
  label?: string;
  title?: string;
  disabled?: boolean;
  onClick: () => void;
}

export const InputSelect = <TData, >(props: IInputSelectProps<TData>): JSX.Element => {
  const {
    sx = {},
    dataComponentName,
    native = true,
    variant = EIInputSelectVariant.STANDARD,
    name = '',
    nameOnInput = true,
    label,
    helperLabel,
    ariaLabel = label || "Select",
    Icon,
    readOnly,
    disabled,
    hidden,
    required,
    validationError,
    emptyOption = false,
    emptyOptionLabel = "Select...",
    options: userOptions = [],
    optionsEnum = {},
    groupedOptions = [],
    listDeprecatedValues = false,
    deprecatedValues = [],
    value,
    buttons = [],
    onOpen,
    onChange,
  } = props;

  const id = useMemo(guid, []);

  const options = [
    ...userOptions,
    ...convertEnumToValueLabel(optionsEnum),
  ];

  const deprecatedValuesDic = deprecatedValues
    .reduce((acc: { [key: string]: true }, v) => {
      acc[v] = true;
      return acc;
    }, {});

  const handleChange = (event: any): void => {
    if (disabled) return;
    if (readOnly) return;
    onChange && onChange(event.target.value, name as any);
  };

  const renderOptions = (options: IInputSelectOption[]): JSX.Element[] => {
    return options
      .map(checkOptionsForDeprecated)
      .map(renderOption);
  };

  const renderOption = (
    {
      label,
      richLabel,
      disabled,
      value,
    }: IInputSelectOption,
    index: string | number,
  ) =>
    native
      ? (
        <option
          key={index}
          aria-label={label || 'None'}
          disabled={disabled || deprecatedValuesDic[value]}
          value={value}
          hidden={!listDeprecatedValues && deprecatedValuesDic[value]} // Need deprecated values as options to prevent react error
        >
          {label}
        </option>
      )
      : (
        <MenuItem
          key={index}
          aria-label={label}
          disabled={disabled}
          value={value}
          hidden={!listDeprecatedValues && deprecatedValuesDic[value]} // Need deprecated values as options to prevent react error
        >
          {richLabel || label}
        </MenuItem>
      );

  const renderGroupedOptions = (groupedOptions: IInputSelectGroup[]): any => {
    if (native) {
      return groupedOptions
        .map((
          {
            label,
            options,
          },
          index,
        ) => (
          <optgroup
            key={index}
            label={label}
            aria-label={label}
          >
            {options
              .map(checkOptionsForDeprecated)
              .map(renderOption)}
          </optgroup>
        ));
    }

    return groupedOptions
      .reduce(
        (acc: JSX.Element[],
          {
            label,
            richLabel,
            options,
          },
          groupIndex,
        ) => {
          acc.push(
            <ListSubheader
              key={groupIndex}
              aria-label={label}
            >
              {richLabel || label}
            </ListSubheader>,
          );
          options
            .map(checkOptionsForDeprecated)
            .map((option, optionIndex) => renderOption(option, [groupIndex, optionIndex].join('---')))
            .forEach(option => acc.push(option));
          return acc;
        },
        [],
      );
  };

  const checkOptionsForDeprecated = (option: IInputSelectOption): IInputSelectOption => {
    const isDeprecated = deprecatedValuesDic[option.value];
    if (!isDeprecated) return option;
    return {
      ...option,
      label: option.label + ' (deprecated)',
      richLabel:
        option.richLabel
          ? <span>{option.richLabel} (deprecated)</span>
          : undefined,
    };
  };

  return (
    <Box dataComponentName={getDataComponentName(dataComponentName, 'InputSelect')}>
      <FormControl
        sx={{
          width: '100%',
          ...(hidden
            ? {
              position: 'absolute',
              overflow: 'hidden',
              height: 0,
            }
            : {}),
          ...sx,
        }}
        variant={variant}
        required={required}
        error={!!validationError}
      >
        <InputLabel
          htmlFor={id}
          aria-label={ariaLabel}
          hidden={hidden}
        >
          {label}
        </InputLabel>
        <FlexContainerHorizontal>
          <FlexItemMax>
            <Select
              sx={{width: '100%'}}
              native={native}
              hidden={hidden}
              value={value}
              IconComponent={
                Icon
                  ? () => (
                    <Box
                      sx={{
                        marginRight: 1,
                        position: 'relative',
                        top: 2,
                      }}
                    >
                      <Icon width={28}/>
                    </Box>
                  )
                  : undefined
              }
              inputProps={{
                id,
                name: nameOnInput ? name : undefined,
                disabled: disabled || readOnly, // Prop readOnly should be disabling input/select
                value,                          // Use value prop for native, this passes it to options
              }}
              MenuProps={{style: {zIndex: 4000}}}
              onClick={onOpen}
              onChange={handleChange}
            >
              {!!emptyOption && (
                renderOption(
                  {
                    label: emptyOptionLabel,
                    value: '',
                    disabled: true,
                  },
                  -1,
                )
              )}
              {renderOptions(options)}
              {renderGroupedOptions(groupedOptions)}
            </Select>
          </FlexItemMax>
          <FlexItemMin>
            {buttons.map(
              (
                {
                  show = true,
                  Icon,
                  color,
                  label,
                  title,
                  disabled: buttonDisabled,
                  onClick,
                },
                index,
              ) => (
                <Button
                  sx={{ml: 1}}
                  key={index}
                  size={EButtonSize.SMALL}
                  show={show}
                  Icon={Icon}
                  color={color}
                  title={title}
                  children={label}
                  disabled={buttonDisabled || disabled || readOnly}
                  onClick={onClick}
                />
              ),
            )}
          </FlexItemMin>
        </FlexContainerHorizontal>
        <HelperText>{helperLabel}</HelperText>
        <HelperText type={EHelperTextType.ERROR}>{validationError}</HelperText>
      </FormControl>
    </Box>
  );
};
