import {IDeviceBrokerInfo} from "../../interfaces/IDeviceBrokerInfo";
import {IDeviceDriverInfo} from "../../interfaces/IDeviceDriverInfo";
import {EDeviceType} from "../../interfaces/EDeviceType";

export const API_PATH_ApiDeviceMgmtDeviceDriversInfoGet = '/services/devices-mgtm/apiDeviceMgmtDeviceDriversInfoGet';

export interface IApiDeviceMgmtDeviceDriversInfoGetRequest {
}

export interface IApiDeviceMgmtDeviceDriversInfoGetResponse {
  deviceDriversInfo: IDeviceDriverInfo[];
  deviceBrokersInfo: IDeviceBrokerInfo[];
  deviceDriverLabels: Record<EDeviceType, string>;
}
