import {IMapElement} from "utils-library/dist/commonJs/geo";

export const API_PATH_ApiLanePlanningLoadItemGet = '/services/lane-planning/apiLanePlanningLoadItemGet';

export interface IApiLanePlanningLoadItemGetRequest {
  id: string;
}

export interface IApiLanePlanningLoadItemGetResponse {
  mapElement: IMapElement<any, any, any>;
}
