export const API_PATH_ApiDeviceMgmtLocationDelete = '/services/devices-mgtm/apiDeviceMgmtLocationDelete';

export interface IApiDeviceMgmtLocationDeleteBodyRequest {
  locationId: string;
  deleteIt: boolean;
}

export interface IApiDeviceMgmtLocationDeleteResponse {
  ok: true;
}
