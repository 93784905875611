import {IWorkzoneHistory} from "../interfaces/IWorkzoneHistory";

export const API_PATH_apiWorkzoneItemHistorySearch = '/services/devices-mgtm/apiWorkzoneItemHistorySearch';

export interface IApiWorkzoneItemHistorySearchGetQueryRequest {
  id: string;
  search: string;
  skip: string;
  limit: string;
}

export interface IApiWorkzoneItemHistorySearchGetResponse {
  workzones: IWorkzoneHistory[];
}
