import {convertDictionaryToViewLabelArray} from "utils-library/dist/commonJs/utils";

export enum EDeviceManagementRights {
  ADMINISTRATOR = "DM_A",

  VIEW = "DM_V",
  CONFIG_DEVICES = "DM_C",
  CONFIG_PRESETS = "DM_CP",

  ALPHA_DRIVERS = "DM_AD",
  BETA_DRIVERS = "DM_BD",
}

const labelsDic: Record<EDeviceManagementRights, string> = {
  [EDeviceManagementRights.ADMINISTRATOR]: "Device Management / Administrator",

  [EDeviceManagementRights.VIEW]: "Device Management / View",
  [EDeviceManagementRights.CONFIG_DEVICES]: "Device Management / Config Devices",
  [EDeviceManagementRights.CONFIG_PRESETS]: "Device Management / Config Presets",

  [EDeviceManagementRights.ALPHA_DRIVERS]: "Device Management / Add Alpha drivers",
  [EDeviceManagementRights.BETA_DRIVERS]: "Device Management / Add Beta drivers",
};

export const EDeviceManagementRightsArray = convertDictionaryToViewLabelArray(labelsDic);
