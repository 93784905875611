import * as React from "react";

export type TContent = string | number | JSX.Element | null;

export interface IAppContainerDashboardProps {
  appTitle?: string;
  appTitleHelper?: string;
  appTitleContent?: JSX.Element;        // Override the above, to render special content
  logoSquareImage?: string;
  logoLandscapeImage?: string;          // Ratio max width: 200px height: 56px

  initialOpenMode?: EOpenMode | "LAST"; // Default EOpenMode.COLLAPSED
  showMinimizedOption?: boolean;        // Default false

  asideMenuWidth?: number;              // Default 256

  selectedMenuId?: string;
  menuItemsTop?: IMenuItemDivider[];    // Fixed top
  menuItems: IMenuItemDivider[];        // In scrollable area
  menuItemsBottom?: IMenuItemDivider[]; // Fixed at bottom
  bottomAppInfo?: React.ReactNode;
  menuFullScreenOnMobile?: boolean;     // Default is true
  menuFullScreenOnTablet?: boolean;     // Default is false

  profileIcon?: JSX.Element;            // To show the profile icon, user name & options
  profileUserName?: string;
  profileOptions?: IProfileOption[];

  notificationIcons?: INotificationIcon[];

  children: any;

  onSearchBarChange?: (searchText: string) => void;

  onLogoClick?: () => void;

  onExpand?: () => void;
  onMinimize?: () => void;
  onCollapse?: () => void;
}

export type IMenuItemDivider = IMenuItem | "DIVIDER";

export interface IMenuItem {
  menuId?: string;
  icon?: React.ReactNode;
  hidden?: boolean;
  disabled?: boolean;
  title: string;
  description?: string;
  content?: React.ReactNode;
  children?: IMenuItemDivider[];
  onClick?: () => void;
}

export enum EOpenMode {
  EXPANDED = "EXPANDED",
  MINIMIZED = "MINIMIZED",
  COLLAPSED = "COLLAPSED",
}

export interface INotificationIcon {
  icon: JSX.Element;
  label: string;
  counter?: number;
  disabled?: boolean;
  onClick?: () => void;
}

export interface IProfileOption {
  disabled?: boolean;
  hidden?: boolean;
  icon?: JSX.Element;
  label: string;
  onClick?: () => void;
}
