export const API_PATH_ApiDeviceMgmtLocationUpdate = '/services/devices-mgtm/apiDeviceMgmtLocationUpdate';

export interface IApiDeviceMgmtLocationUpdateBodyRequest {
  locationId: string;
  regionId: string;
  showOnMap: boolean;
  maintenanceMode?: boolean;
  restartDeviceOnMaintenanceModeChange?: boolean;
  label?: string;
  description: string;
  geoPosition: {
    lat: number;
    lng: number;
    alt?: number;
  };
  notes: string;
}

export interface IApiDeviceMgmtLocationUpdateResponse {
  ok: true;
}
