import {PaletteOptions} from "@mui/material/styles/createPalette";
import {grayShades} from "./grayShades";

export const MUIV5_PALETTE_LIGHT: PaletteOptions = {
  common: {
    black: '#000',
    white: '#fff',
  },
  background: {
    default: '#fafafa',
    paper: '#f4f4f4',
  },
  appMainColor: {
    main: '#1976d2',
    light: 'rgb(71, 145, 219)',
    dark: 'rgb(17, 82, 147)',
    contrastText: '#fff',
  },
  primary: {
    main: '#1976d2',
    light: 'rgb(71, 145, 219)',
    dark: 'rgb(17, 82, 147)',
    contrastText: '#fff',
  },
  secondary: {
    main: '#dc004e',
    light: 'rgb(255, 105, 135)',
    dark: 'rgb(178, 0, 53)',
    contrastText: '#fff',
  },
  error: {
    main: '#f44336',
    light: 'rgb(246, 104, 94)',
    dark: 'rgb(170, 46, 37)',
    contrastText: '#fff',
  },
  warning: {
    main: '#ff9800',
    light: 'rgb(255, 171, 64)',
    dark: 'rgb(178, 112, 0)',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  info: {
    main: '#2196f3',
    light: 'rgb(71, 145, 219)',
    dark: 'rgb(17, 82, 147)',
    contrastText: '#fff',
  },
  success: {
    main: '#4caf50',
    light: 'rgb(111, 191, 115)',
    dark: 'rgb(53, 122, 56)',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  grayShades: {
    gray0: grayShades[0],
    gray1: grayShades[1],
    gray2: grayShades[2],
    gray3: grayShades[3],
    gray4: grayShades[4],
    gray5: grayShades[5],
    gray6: grayShades[6],
    gray7: grayShades[7],
    gray8: grayShades[8],
    gray9: grayShades[9],
  },
};
