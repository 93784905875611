import * as React from "react";
import {useEffect} from "react";

import {Box} from "../Box";

import {
  SxProps,
  useTheme,
  Theme,
} from "../ThemeProvider";

export interface IThemeContainerProps {
  sx?: SxProps<Theme>;
  fullHeight?: boolean;
  children: any;
}

/**
 * Apply the MUI theme as default on the DOM. Applies colors and typography.
 * @param props
 * @constructor
 */
export const ThemeContainer = (props: IThemeContainerProps): JSX.Element => {
  const theme = useTheme();
  const {
    sx = {},
    fullHeight = false,
    children,
  } = props;

  useEffect(() => {
    document.body.style.color = theme.palette.text.primary;
    document.body.style.backgroundColor = theme.palette.background.default;
  }, [theme]);

  return (
    <Box
      sx={{
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
        ...theme.typography,
        ...(
          fullHeight
            ? {height: '100%'}
            : {}
        ),
        ...sx,
      }}
      dataComponentName="ThemeContainer"
    >
      {children}
    </Box>
  );
};
