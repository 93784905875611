import {validateDataMethods} from "utils-library/dist/commonJs/validation-engine";
import {isLocalhost} from "utils-library/dist/commonJs/web";

import {
  appVersion,
  appCodeName,
  builtAt,
} from "./appVersion";

const DEV_COMPANY_ID = "super-company";

export interface IAppConfig {
  appName: string;
  appVersion: string;
  appCodeName: string;
  mode: 'PRODUCTION' | 'DEV';
  companyId: string;      // For which client company this app working on
  webBaseUrl: string;
  apiBaseUrl: string;
  googleMapsApiKey: string;
  sentryIoDsn: string;
  builtAt: string;
}

const getDebugCompanyId = (): string | undefined => {
  const lsDebugCompanyId = localStorage.getItem('_debug_companyId');
  if (!lsDebugCompanyId) return undefined;
  const validationErrorCompanyId = validateDataMethods.isValidCompanyId(lsDebugCompanyId);
  if (validationErrorCompanyId) {
    console.error(`Internal error 20231003110158: debug company id validation error: ${validationErrorCompanyId}`);
    return undefined;
  }
  console.warn('The App is running for the debugCompanyId: ', getDebugCompanyId);
  return lsDebugCompanyId;
};

const getProductionCompanyId = () => {
  const hostNameParts = window.location.hostname.split('.').reverse();
  const subDomain = hostNameParts[2] || '';
  if (!subDomain) {
    alert("We do not recognize the company ID provided. Please ensure you are using the correct URL or contact the system administrator for assistance.");
    throw new Error(`Cannot get the company id from the subdomain for the hostname. Hostname: ${window.location.hostname}`);
  }
  return subDomain;
};

// Sentry config
const SENTRY_DSN_PRODUCTION = "https://5674054c3b299e7dfb2a8681a5716b07@o4506004033896448.ingest.sentry.io/4506020851089408";
const SENTRY_DSN_STAGING = "https://48293b81729bb391388aea08ba211b45@o4506004033896448.ingest.sentry.io/4506020848140288";
const SENTRY_DSN_DEV = "https://87dd7779fa028b4f1ec8319b4ed90a13@o4506004033896448.ingest.sentry.io/4506020798070784";

const SENTRY_CLIENT_ACTIVE_FOR_DEBUGGING = false;  // Set it to true for debugging Sentry.io integration only

if (SENTRY_CLIENT_ACTIVE_FOR_DEBUGGING) console.warn('SENTRY_CLIENT_ACTIVE_FOR_DEBUGGING is turned to true, you should not merge this on release! This is only do debug Sentry on dev env!');

// Note: This is the main source of the client's config, there are no env vars on the client side.
export const appConfig: IAppConfig =
  isLocalhost
    ? {
      appName: 'Metiri',
      appVersion,
      appCodeName,
      mode: 'DEV',
      companyId: getDebugCompanyId() || DEV_COMPANY_ID,
      apiBaseUrl: 'http://localhost:4000',
      webBaseUrl: `http://localhost:3600`,
      googleMapsApiKey: "AIzaSyAqJsj9wPGrKLEIOZRQEozWrHe3mM-QikE", // Key with domain restriction
      sentryIoDsn:
        SENTRY_CLIENT_ACTIVE_FOR_DEBUGGING
          ? SENTRY_DSN_DEV
          : "",
      builtAt,
    }
    : {
      appName: 'Metiri',
      appVersion,
      appCodeName,
      mode: 'PRODUCTION',
      companyId: getProductionCompanyId(),
      apiBaseUrl: window.location.origin,
      webBaseUrl: window.location.origin,
      googleMapsApiKey: "AIzaSyAqJsj9wPGrKLEIOZRQEozWrHe3mM-QikE", // Key with domain restriction
      sentryIoDsn:
        window.location.host.includes('staging')
          ? SENTRY_DSN_STAGING
          : SENTRY_DSN_PRODUCTION,
      builtAt,
    };
