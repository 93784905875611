import {roundToString} from "../utils";

export const getSizeLabel = (
  {
    size,
    unit,
    pluralize = true,
    precision = 1,
  }: {
    size: number;
    unit: string;
    pluralize?: boolean;
    precision?: number;
  },
): string => {
  return (
    getSize({
      value: size,
      factor: 1000000000000,
      volume: 'T',
      unit,
      pluralize,
      precision,
    })
    || getSize({
      value: size,
      factor: 1000000000,
      volume: 'G',
      unit,
      precision,
      pluralize,
    })
    || getSize({
      value: size,
      factor: 1000000,
      volume: 'M',
      unit,
      precision,
      pluralize,
    })
    || getSize({
      value: size,
      factor: 1000,
      volume: 'K',
      unit,
      precision,
      pluralize,
    })
    || getSize({
      value: size,
      factor: 1,
      volume: '',
      unit,
      precision,
      pluralize,
    })
  );
};

const getSize = (
  {
    value,
    factor,
    volume,
    unit,
    precision,
    pluralize = true,
  }: {
    value: number;
    factor: number;
    volume: string;
    unit: string;
    precision: number;
    pluralize?: boolean;
  },
): string => {
  if (value < factor) return '';
  const outputValue = value / factor;
  const plural = pluralize && value > 1;
  return `${roundToString(outputValue, precision)}${volume}${unit}${plural ? 's' : ''}`;
};
