import * as React from "react";
import {ReactElement} from "react";

import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';

import {Box} from "../Box";
import {TBoxSpacing} from "../ui-interfaces";

import MoreIcon from '@mui/icons-material/MoreVert';

export interface IChipsProps {
  dataComponentName?: string;
  inline?: boolean;
  spacing?: TBoxSpacing;  // Default is 1
  size?: EChipSize;       // Default is MEDIUM
  chips: IChip[];
}

export interface IChip {
  show?: boolean;
  variant?: EChipVariant;
  color?: EChipColor;
  avatar?: {
    // One of those will be used in the end
    image?: string;     // Priority
    icon?: JSX.Element; // Priority
    text?: string;      // Fallback
  };
  label: string;
  rightIcon?: JSX.Element;
  onRightIconClick?: () => void;
  onChipClick?: () => void;
}

export enum EChipVariant {
  FILLED = "filled",
  OUTLINE = "outlined",
}

export enum EChipColor {
  DEFAULT = "default",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
}

export enum EChipSize {
  SMALL = "small",
  MEDIUM = "medium",
}

export const Chips = (props: IChipsProps): ReactElement | null => {
  const {
    dataComponentName,
    inline,
    spacing = 1,
    size = EChipSize.MEDIUM,
    chips,
  } = props;

  if (!chips.length) return null;

  return (
    <Box
      dataComponentName={[dataComponentName, "Chips"]}
      inline={inline}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: theme => theme.spacing(spacing),
        '& > div': {
          marginRight: theme => theme.spacing(spacing),
          marginBottom: theme => theme.spacing(spacing),
        },
      }}
    >
      {chips
        .filter(chip => chip.show !== false)
        .map(
          (
            {
              variant = EChipVariant.FILLED,
              color,
              avatar: {
                image = undefined,
                icon = undefined,
                text = undefined,
              } = {},
              label,
              rightIcon,
              onRightIconClick,
              onChipClick,
            },
            index,
          ) => (
            <Chip
              key={index}
              variant={variant as any}
              color={color}
              size={size}
              avatar={
                (image || icon || text)
                  ? (
                    <Avatar
                      src={image}
                      alt={image ? label : undefined}
                      sx={{
                        backgroundColor: '#bdbdbd8f',
                        '& > svg': {width: '80%'},
                      }}
                    >
                      {
                        image
                          ? null
                          : icon || text
                      }
                    </Avatar>
                  )
                  : undefined
              }
              label={label}
              deleteIcon={
                rightIcon || onRightIconClick
                  ? rightIcon || <MoreIcon/>
                  : undefined
              }
              onDelete={
                rightIcon || onRightIconClick
                  ? onRightIconClick
                  : undefined
              }
              onClick={onChipClick}
            />
          ),
        )}
    </Box>
  );
};
