import {IGeoPosition} from "utils-library/dist/commonJs/geo";
import {isConvertibleToNumber} from "utils-library/dist/commonJs/utils";

export interface IGetGeoPositionsByStringsResult {
  coordinates: IGeoPosition[];
  errors: string[];
}

export const getGeoPositionsByStrings = (lines: string[]): IGetGeoPositionsByStringsResult => {
  const coordinates: IGeoPosition[] = [];
  const errors: string[] = [];

  for (const line of lines) {
    if (line.trim() === "") continue;
    const {
      position, error,
    } = parseCoordinates(line);
    if (position) {
      coordinates.push(position);
    }
    if (error) {
      errors.push(error);
    }
  }

  return {
    coordinates,
    errors,
  };
};

const parseCoordinates = (line: string): { position: IGeoPosition | null; error: string | null } => {
  let parts: string[];
  if (line.includes(',')) {
    parts = line.split(',');
  }
  else if (line.includes(';')) {
    parts = line.split(';');
  }
  else if (line.includes('\t')) {
    parts = line.split('\t');
  }
  else {
    return {
      position: null,
      error: `Invalid delimiter in line: [${line}], should be comma, semicolon, or tab`,
    };
  }

  if (parts.length < 2) {
    return {
      position: null,
      error: `Insufficient coordinates in line: [${line}] should be at least two (lat & lon)`,
    };
  }

  if (!isConvertibleToNumber(parts[0]) || !isConvertibleToNumber(parts[1])) {
    return {
      position: null,
      error: `Invalid coordinates in line: [${line}], cannot all values be processed as numbers`,
    };
  }

  const position: IGeoPosition = {
    lat: Number(parts[0]),
    lng: Number(parts[1]),
  };

  if (parts.length > 2) {
    if (isConvertibleToNumber(parts[2])) {
      position.alt = Number(parts[2]);
    }
    else {
      return {
        position: null,
        error: `Invalid alt in line: [${line}], cannot be processed as number`,
      };
    }
  }

  return {
    position,
    error: null,
  };
};
