import * as React from "react";
import {
  Suspense,
  useEffect,
  useState,
} from "react";
import {ErrorBoundary} from "../ErrorBoundary";

import {
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "../FlexContainer";
import {Box} from "../Box";
import {Condition} from "../Condition";
import {ErrorBanner} from "../ErrorBanner";
import {
  Button,
  EButtonSize,
} from "../Button";
import {ProgressIcon} from "../ProgressIcon";

import ErrorIcon from '@mui/icons-material/Error';
import RefreshIcon from '@mui/icons-material/Refresh';

import {useTheme} from "../ThemeProvider";

export interface ILazyLoadComponentProps {
  children: any;
  showLoadingProgress?: boolean;      // Default is true
  showLoadingProgressDelay?: number;  // Default is 1000ms, delay showing the indicator to reduce flicking
  loadingIconSize?: 1 | 2 | 3 | 4;    // Default 2
  loadingLabel?: string;              // Default none
  errorLabel?: string;                // Error label used in case of load error
  onError?: (error: any, errorInfo: any) => void;
}

export const LazyLoadComponent = (props: ILazyLoadComponentProps): JSX.Element => {
  const {
    children,
    showLoadingProgress = true,
    showLoadingProgressDelay = 1000,
    loadingIconSize = 2,
    loadingLabel,
    errorLabel = "Something went wrong. Try refreshing.",
    onError,
  } = props;

  const theme = useTheme();

  const [showIsLoading, setShowIsLoading] = useState<boolean>(false);

  useEffect(() => {
    // Show the loading indicator after some ms
    const timeHandler = setTimeout(() => setShowIsLoading(true), showLoadingProgressDelay);
    return () => clearTimeout(timeHandler);
  }, []);

  const handleRefreshClick = (): void => {
    window.location.reload();
  };

  const handleErrorRender = (error: any): JSX.Element => {
    return (
      <FlexContainerHorizontal alignVertical="middle" spacing={3}>
        <FlexItemMin>
          <ErrorIcon/>
        </FlexItemMin>
        <FlexItemMax>
          <Box>{errorLabel}</Box>
          <Box
            show={!!localStorage.getItem('debugMode')}
            sx={{pt: 1}}
          >
            <ErrorBanner
              title="Lazy load error (debug mode)"
              error={error}
            />
          </Box>
        </FlexItemMax>
        <FlexItemMin>
          <Button
            size={EButtonSize.LARGE}
            icon={<RefreshIcon/>}
            onClick={handleRefreshClick}
          >
            Refresh
          </Button>
        </FlexItemMin>
      </FlexContainerHorizontal>
    );
  };

  const handleILoadingRender = (): JSX.Element => {
    return (
      <Condition if={showLoadingProgress && showIsLoading}>
        <ProgressIcon size={loadingIconSize}/>
        <Box
          show={!!loadingLabel}
          sx={{
            marginTop: theme.spacing(3),
            fontSize: theme.typography.fontSize,
            padding: '4px 8px',
            borderRadius: 13,
            color: theme.palette.background.default,
            backgroundColor: theme.palette.action.active,
          }}
        >
          {loadingLabel}
        </Box>
      </Condition>
    );
  };

  return (
    <ErrorBoundary
      onErrorRender={handleErrorRender}
      onError={onError}
    >
      <Suspense fallback={handleILoadingRender()}>
        {children}
      </Suspense>
    </ErrorBoundary>
  );
};
