export const API_PATH_ApiAppPresetPut = '/services/app/presets/apiAppPresetPut';

export interface IApiAppPresetPutRequest<TPreset> {
  id: string;
  title?: string;
  description?: string;
  preset?: Partial<TPreset>;
  deleteAfterDaysOfInactivity?: number;
}

export interface IApiAppPresetPutResponse {
  ok: true;
}
