import * as React from "react";

import {ErrorBanner} from "../ErrorBanner";
import {
  IDependency,
  useLoadExternalDependencies,
} from "../useLoadExternalDependencies";
import {
  LabelOverlayDataProgress,
  EProgressType,
} from "../LabelOverlayDataProgress";

export interface ILoadExternalDependenciesProps {
  /**
   * External dependencies to the loaded
   */
  dependencies: IDependency[];
  /**
   * Full height is used only for the progress phase
   */
  fullHeightProgress?: boolean;
  /**
   * Loading prompt message
   */
  loadingMessage?: string;
  /**
   * Will be shown once all dependencies have been loaded successfully.
   *
   * If one or more failed, their error banners will be rendered instead
   */
  children: any;
}

/**
 * Load external dependencies like `<style type="text/css" href="...">` & `<script src="...">`
 * only when they are rendered.
 *
 * Once loaded, they stay in the DOM, are not removed on unmount.
 *
 * Dependencies can't be re-imported in the DOM, so this component can safely be used with the same
 * dependencies from multiple other components that are used on the same time or not.
 */
export const LoadExternalDependencies: React.FC<ILoadExternalDependenciesProps> = (
  {
    dependencies,
    fullHeightProgress,
    loadingMessage = "loading...",
    children,
  },
) => {
  const {
    isLoading,
    errors,
  } = useLoadExternalDependencies(dependencies);
  return (
    <>
      <LabelOverlayDataProgress
        show={isLoading}
        type={EProgressType.IS_LOADING_ANIMATION}
        fullHeight={fullHeightProgress}
        title={loadingMessage}
      />
      {!isLoading && !!errors.length && (
        <div>
          <h1>Loading dependencies failed</h1>
          {
            errors.map((error, index) =>
              <ErrorBanner
                key={index}
                error={error}
              />,
            )
          }
        </div>
      )}
      {!isLoading && !errors.length && children}
    </>
  );
};
