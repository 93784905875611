/**
 * Get the current position of an element within the user's viewport,
 * along with the current dimensions of the viewport.
 * @param element
 */
export const getElementPositionInViewport = (element: HTMLElement): {
    element: {
        top: number;
        left: number;
        width: number;
        height: number;
    };
    viewport: {
        width: number;
        height: number;
    };
} => {
  const rect = element.getBoundingClientRect();

  return {
    element: {
      top: rect.top + window.scrollY - window.scrollY,
      left: rect.left,
      width: rect.width,
      height: rect.height,
    },
    viewport: {
      width: window.innerWidth || document.documentElement.clientWidth,
      height: window.innerHeight || document.documentElement.clientHeight,
    },
  };
};
