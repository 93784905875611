import {EAuthStrategy} from "../../interfaces/EAuthStrategy";

export const getOpenIdEndpoint = (
  {
    host,
    strategy,
    endpoint,
    companyId,
  }: {
    host: string;                       // Example: mail.example.com
    strategy: EAuthStrategy;
    endpoint: 'client' | 'provider';
    companyId: string;
  },
): string => [
  '/services/authentication/oAuth/open-id-v1/',
  strategy,
  '/',
  endpoint === "client"
    ? 'sign-in'
    : 'provider-callback',
  '/',
  host,
  '/',
  companyId,
].join('');
