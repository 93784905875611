import * as React from "react";

import Checkbox from "@mui/material/Checkbox";

import {Box} from "../Box";
import {
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "../FlexContainer";
import {
  HelperText,
  EHelperTextType,
} from "../HelperText";
import {IIconComponent} from "../IconComponent";

import {
  sxTransition,
  ECSSDuration,
} from "../sxTransition";
import {
  SxProps,
  Theme,
} from "../ThemeProvider";
import {IconViewer} from "../IconViewer";

export interface IInputCheckboxProps<TData> {
  sx?: SxProps<Theme>;
  dataComponentName?: string;
  id?: string;
  Icon?: IIconComponent;
  name?: keyof TData;
  label?: string | JSX.Element;
  disabled?: boolean;
  readOnly?: boolean;
  helperLabel?: string;
  size?: EInputCheckboxSize;
  position?: ECheckBoxPosition;
  validationError?: string;
  value: boolean | 'mixed';
  onChange?: (value: boolean, name: keyof TData) => void;
}

export enum EInputCheckboxSize {
  SMALL = "small",
  MEDIUM = "medium",
}

export enum ECheckBoxPosition {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export const InputCheckbox = <TData, >(props: IInputCheckboxProps<TData>): JSX.Element => {
  const {
    sx = {},
    dataComponentName,
    id,
    name = "",
    Icon,
    label,
    disabled = false,
    readOnly = false,
    size = EInputCheckboxSize.SMALL,
    position = ECheckBoxPosition.LEFT,
    helperLabel,
    validationError,
    value,
    onChange,
  } = props;

  const handleChange = () => {
    if (disabled) return;
    if (readOnly) return;
    onChange?.(!value, name as any);
  };

  return (
    <Box
      sx={{
        ...sx,
        cursor: !disabled && !readOnly ? 'pointer' : undefined,
        opacity: disabled ? 0.7 : undefined,
        transition: theme => sxTransition(theme, 'opacity', ECSSDuration.SHORT),
      }}
      dataComponentName={[dataComponentName, 'InputCheckbox']}
    >
      <FlexContainerHorizontal
        alignVertical="middle"
        sx={{userSelect: 'none'}}
        reverseOrder={position === ECheckBoxPosition.RIGHT}
        onClick={handleChange}
      >
        <FlexItemMin>
          <Checkbox
            sx={{'svg': {color: theme => theme.palette.mode === 'dark' ? theme.palette.primary.light : undefined}}}
            id={id}
            name={name as any}
            size={size}
            disabled={disabled || readOnly}
            checked={(() => {
              if (value === true || value === false) return value;
              if (value === "mixed") return true;
              return undefined;
            })()}
            indeterminate={value === 'mixed'}
          />
        </FlexItemMin>
        <FlexItemMin show={!!Icon}>
          <IconViewer
            sx={{marginRight: 1}}
            Icon={Icon} width={16}
          />
        </FlexItemMin>
        <FlexItemMax
          show={!!label}
          sx={{textAlign: position === ECheckBoxPosition.RIGHT ? "right" : undefined}}
        >
          <span style={{userSelect: "none"}}>{label}</span>
        </FlexItemMax>
      </FlexContainerHorizontal>
      <HelperText>{helperLabel}</HelperText>
      <HelperText type={EHelperTextType.ERROR}>{validationError}</HelperText>
    </Box>
  );
};
