export const API_PATH_ApiDeviceMgmtRegionUpdate = '/services/devices-mgtm/apiDeviceMgmtRegionUpdate';

export interface IApiDeviceMgmtRegionUpdateBodyRequest {
  regionId: string;
  label?: string;
  description?: string;
  notes?: string;
}

export interface IApiDeviceMgmtRegionUpdateResponse {
  ok: true;
}
