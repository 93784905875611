import * as React from "react";

import {
  TDataComponentName,
  getDataComponentName,
  TBoxSpacing,
} from "../ui-interfaces";
import {ContainerDetectResize} from "../ContainerDetectResize";

import {FlexContainerVertical} from "./FlexContainerVertical";
import {FlexContainerHorizontal} from "./FlexContainerHorizontal";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";

export interface IFlexContainerParentProps {
  sx?: SxProps<Theme>;
  dataComponentName?: TDataComponentName;

  spacing?: TBoxSpacing;
  inline?: boolean;
  fullHeight?: boolean;
  alignVertical?: 'top' | 'middle' | 'bottom';    // For horizontal view
  alignHorizontal?: 'left' | 'center' | 'right';  // For vertical view
  reverseOrder?: boolean;

  verticalMaxWidth: number; // Beyond this

  children: any;

  onClick?: (e: React.MouseEvent) => void;
  onMouseMove?: (event: React.MouseEvent) => void;
  onMouseDown?: (event: React.MouseEvent) => void;
  onMouseUp?: (event: React.MouseEvent) => void;
  onMouseEnter?: (event: React.MouseEvent) => void;
  onMouseLeave?: (event: React.MouseEvent) => void;
  onScroll?: (event: React.UIEvent) => void;
  onWheel?: (event: React.WheelEvent<HTMLDivElement>) => void;
}

/**
 * FlexContainerByParent displays the items vertically based on the verticalMaxWidth. Beyond that width, the items are shown in a horizontal manner.
 */
export const FlexContainerParent: React.FC<IFlexContainerParentProps> = (
  {
    sx,
    dataComponentName,
    spacing,
    inline,
    fullHeight,
    alignVertical,
    alignHorizontal,
    reverseOrder,
    verticalMaxWidth,
    children,
    onClick,
    onMouseMove,
    onMouseDown,
    onMouseUp,
    onMouseEnter,
    onMouseLeave,
    onScroll,
    onWheel,
  },
) => {
  return (
    <ContainerDetectResize>
      {
        ({width}) => (
          width <= verticalMaxWidth
            ? (
              <FlexContainerVertical
                sx={sx}
                dataComponentName={getDataComponentName(dataComponentName, 'FlexContainerParent')}
                inline={inline}
                spacing={spacing}
                alignHorizontal={alignHorizontal}
                fullHeight={fullHeight}
                reverseOrder={reverseOrder}
                onClick={onClick}
                onMouseMove={onMouseMove}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onScroll={onScroll}
                onWheel={onWheel}
              >
                {children}
              </FlexContainerVertical>
            )
            : (
              <FlexContainerHorizontal
                sx={sx}
                dataComponentName={getDataComponentName(dataComponentName, 'FlexContainerParent')}
                inline={inline}
                spacing={spacing}
                alignVertical={alignVertical}
                fullHeight={fullHeight}
                reverseOrder={reverseOrder}
                onClick={onClick}
                onMouseMove={onMouseMove}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onScroll={onScroll}
                onWheel={onWheel}
              >
                {children}
              </FlexContainerHorizontal>

            )
        )
      }
    </ContainerDetectResize>
  );
};
