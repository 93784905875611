export const API_PATH_ApiDeviceMgmtDeviceChangeId = '/services/device-mgtm/apiDeviceMgmtDeviceChangeId';

export interface IApiDeviceMgmtDeviceChangeIdRequest {
  id: string;
  newId: string;
}

export interface IApiDeviceMgmtDeviceChangeIdResponse {
  ok: true;
}
