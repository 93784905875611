export const grayShades: string[] = [
  '#f5f5f5',
  '#e5e5e5',
  '#d3d3d3',
  '#bbbbbb',
  '#a4a4a4',
  '#858585',
  '#7c7c7c',
  '#676767',
  '#575757',
  '#2d2d2d',
];
