import {IDeviceDriver} from "./IDeviceDriver";
import {validateDataMethods} from "utils-library/dist/commonJs/validation-engine";
import {EDeviceType} from "../interfaces/EDeviceType";

export interface IDemoSpeedCameraConfig {
  scanDistanceInMeters: number;
  speedLimitInKm: number;
}

export interface IDemoSpeedCameraStatus {
  incidentsInLast30Mins: number;
  voltage: number;
}

export const DemoSpeedCamera: IDeviceDriver<IDemoSpeedCameraConfig, IDemoSpeedCameraStatus> = {
  deviceType: EDeviceType.DEMO_SPEED_CAMERA,
  label: '(demo) Speed camera',
  validateConfig: {
    scanDistanceInMeters: value => validateDataMethods.isNumberInRange(value, 0, 1000),
    speedLimitInKm: value => validateDataMethods.isNumberInRange(value, 0, 1000),
  },
  validateStatus: {
    incidentsInLast30Mins: value => validateDataMethods.isNumberInRange(value, 0, 10000),
    voltage: value => validateDataMethods.isNumberInRange(value, 0, 1000),
  },
};

export const getDefaultDemoSpeedCameraConfig = (): IDemoSpeedCameraConfig => ({
  scanDistanceInMeters: 1,
  speedLimitInKm: 0,
});
