import {dynaError} from "dyna-error";

import {
  getDeepValue,
  camelToHuman,
} from "../utils";

import {
  IValidateDataEngineRules,
  IValidationResult,
  IValidationDataResults,
} from "./interfaces";

/**
 * The primary validation engine executes all the validation rules against the given data.
 */
export const validationEngine = <TData>(
  {
    data,
    validationRules,
    checkForUnexpectedProperties = true,
    ignoreUndefinedRules = false,
    ignoreUndefinedValues = false,
    ignoreFields = [],
    returnDataValidationEmptyStrings = false,
  }: {
    data: TData;
    validationRules: IValidateDataEngineRules<TData>;
    checkForUnexpectedProperties?: boolean;
    ignoreUndefinedRules?: boolean;
    ignoreUndefinedValues?: boolean;
    ignoreFields?: Array<keyof TData>;
    returnDataValidationEmptyStrings?: boolean;         // For the valid properties, return the empty string as result dataValidation, when false, the valid properties are omitted from the dataValidation object.
  },
): IValidationResult<TData> => {
  const dataValidation = validateDataEngine<TData>({
    data,
    validationRules,
    ignoreUndefinedRules,
    ignoreUndefinedValues,
    ignoreFields,
    returnDataValidationEmptyStrings,
  });

  const allMessages: string[] =
    Object
      .keys(dataValidation)
      .reduce((acc: string[], property) => {
        const validationResult = dataValidation[property];
        if (!validationResult) return acc;
        acc.push(`${camelToHuman(property.replace(/\./g, '/'))}: ${validationResult}`);
        return acc;
      }, []);

  const validProps = Object.keys(validationRules);
  const unexpectedProperties =
    checkForUnexpectedProperties
      ? Object.keys(data)
        .reduce((acc: string[], dataProperty) =>
          validProps.includes(dataProperty)
            ? acc
            : acc.concat(`Property [${dataProperty}] was unexpected`)
        , [])
      : [];

  return {
    isValid:
      allMessages.length === 0 &&
      unexpectedProperties.length === 0,
    dataValidation,
    messages:
      allMessages
        .concat(unexpectedProperties),
  };
};

const validateDataEngine = <TData>(
  {
    data,
    validationRules,
    ignoreUndefinedRules = false,
    ignoreUndefinedValues = false,
    ignoreFields = [],
    returnDataValidationEmptyStrings = false,
  }: {
    data: TData;
    validationRules: IValidateDataEngineRules<TData>;
    ignoreUndefinedRules?: boolean;
    ignoreUndefinedValues?: boolean;
    ignoreFields?: Array<keyof TData>;
    returnDataValidationEmptyStrings?: boolean;
  },
): IValidationDataResults<TData> => {
  return (
    Object
      .keys(validationRules)
      .filter(fieldName => !ignoreFields.includes(fieldName as any))
      .filter(fieldName => !ignoreUndefinedRules || validationRules[fieldName] !== undefined)
      .filter(fieldName => !ignoreUndefinedValues || data[fieldName] !== undefined)
      .map(fieldName => ({
        fieldName,
        value: data[fieldName],
        result: ((): string => {
          const error = (code: number, message: string, parentError?: any) => dynaError({
            code,
            message,
            parentError,
            data: {
              data,
              fieldName,
            },
          });

          if (!validationRules[fieldName]) throw error(202112280808, `There is no validation rule defined for the property [${fieldName}]`);
          if (typeof validationRules[fieldName] !== "function") throw error(202112280809, `Validation rule for the property [${fieldName}] should be a function.`);

          try {
            const value = getDeepValue(data, fieldName);
            return validationRules[fieldName](value, data);
          }
          catch (e) {
            throw error(202109291249, `Validation method for property [${fieldName}] failed with exception: "${e.message}"`, e);
          }
        })(),
      }))
      .filter(
        item =>
          returnDataValidationEmptyStrings
          || Boolean(item.result),
      )
      .reduce((acc: IValidationDataResults<TData>, item) => {
        (acc as any)[item.fieldName] = item.result;
        return acc;
      }, {} as any)
  );
};
