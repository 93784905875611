import * as React from "react";

import {
  Button,
  EButtonSize,
  EButtonColor,
} from "../../../../Button";
import {
  Tooltip,
  ETooltipPosition,
} from "../../../../Tooltip";

import {
  SxProps,
  Theme,
} from "../../../../ThemeProvider";

import IsLoadingIcon from '@mui/icons-material/Autorenew';

export interface IMapButtonProps {
  sx?: SxProps<Theme>;
  label?: string | JSX.Element;
  title: string;
  icon?: JSX.Element;
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const MapButton = (props: IMapButtonProps): JSX.Element => {
  const {
    sx = {},
    label,
    title,
    icon,
    isLoading,
    disabled = false,
    onClick,
  } = props;

  return (
    <Tooltip
      title={title}
      position={ETooltipPosition.LEFT}
    >
      <Button
        sx={{
          borderRadius: 0,
          marginBottom: theme => theme.spacing(1),
          ...sx,
        }}
        size={EButtonSize.MEDIUM}
        color={EButtonColor.PRIMARY}
        icon={isLoading ? <IsLoadingIcon/> : icon}
        disabled={isLoading || disabled}
        onClick={onClick}
      >
        {label}
      </Button>
    </Tooltip>
  );
};
