import {EDeviceType} from "../../interfaces/EDeviceType";

export const API_PATH_ApiDeviceMgmtDeviceUpdate = '/services/devices-mgtm/apiDeviceMgmtDeviceUpdate';

export interface IApiDeviceMgmtDeviceUpdateBodyRequest {
  deviceBrokerId: string;
  deviceType: EDeviceType;

  deviceId: string;
  regionId: string;
  locationId: string;

  isActive: boolean;
  activationId: string;

  geoPosition: {
    lat: number;
    lng: number;
    alt?: number;
  };
  showOnMap: boolean;     // Do not show it for some reason

  label: string;          // Used only for 1st load
  description: string;

  manufacturerName: string;

  outdatedMsThreshold: number;
  deadMsThreshold: number;

  notes: string;
}

export interface IApiDeviceMgmtDeviceUpdateResponse {
  ok: true;
}
