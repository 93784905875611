import {IValidateDataEngineRules} from "./interfaces";

/**
 * Create validation rules by the given data object to create validation rules that always return valid (empty string in precise).
 * _This is needed when we always need a default validation mechanism that will always return that it is correct._
 * @param data
 * @param _output
 */
export const validationEngineEmptyRules = <TData>(
  data: TData,
): IValidateDataEngineRules<TData> => {
  const output: IValidateDataEngineRules<any> = {};
  Object.keys(data)
    .forEach(property => {
      if (!property.includes('.')) output[property] = () => "";
    });

  return output as any;
};
