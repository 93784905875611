import {IUser} from "../../../user-authentication/interfaces/IUser";
import {testCreateUser} from "./testCreateUser";

const testUsers = [
  'Mr.,John,Smith,j.smith@example.com',
  'Mrs.,Nancy,Lorens,n.lorens@example.com',
  'Mr.,John,Lorens,j.lorens@att.com',
  'Mrs.,Noel,Cuba,n.cuba@prixton.com',
  'Mr.,Dennis,Terrence,d.terence@moca.com',
  'Mr.,Pink,Floyd,pink-froyd@gmail.com',
  'Miss.,Nancy,James,nancy.james@hotmail.com',
  'Mr.,Donald,Williams,d.williams@hotmail.com',
  'Miss.,Vivi,Matt,vivi.matt@gmail.com',
  'Mr.,Elliot,James,elliot.james@bing.com',
  'Mr.,Chris,Silvers,csilvers@att.net',
  'Mrs.,Samantha,Scorpio,sscorpio@comcast.net',
  'Mr.,Johan,Freedman,jfreedma@mac.com',
  'Mr.,Jack,Bowman,bowmanbs@aol.com',
  'Mrs.,Dana,Oche,doche@live.com',
  'Mr.,Ashley,Norman,ajlitt@mac.com',
  'Mr.,Tony,Utica,tsuruta@mac.com',
  'Mr.,Ten,Anton,enton@comcast.net',
  'Mrs.,Silver,Nicola,snicolao@live.com',
  'Mr.,Matt,Joseph,matthijs@sbcglobal.net',
  'Mrs.,Agatha,Cristi,arathi@aol.com',
  'Mr.,Shev,Norton,cvrcek@msn.com',
  'Mr.,Antony,Lolec,amichalo@me.com',
  'Mrs.,Debby,Besse,debest@verizon.net',
  'Mr.,Paul,Loren,pplinux@yahoo.com',
  'Mrs.,Tiphany,Smith,telbij@mac.com',
  'Mr.,Mark,Momuk,mgemmons@me.com',
  'Miss.,Betty,Darin,darin@optonline.net',
  'Mr.,Walter,Cuba,wbarker@att.net',
  'Mr.,Johnathan,Taylor,joglo@mac.com',
];

export const testUsersArray: IUser[] =
  testUsers
    .map((userRay, index) => {
      const [title, firstName, lastName, email] = userRay.split(',');
      return {
        id: `id-${index}`,
        title,
        firstName,
        lastName,
        email,
      };
    })
    .map(testCreateUser);

