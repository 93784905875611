import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";

import {EUserAuthenticationRights} from "mhc-server";

import {IAppRoute} from "../config/IAppRoute";

const OptionsPage = lazy(() => import('../pages/OptionsPage'));

export const routeOptionsPage: IAppRoute = {
  menuId: 'options',
  title: 'Options',
  routePath: '/options',
  getRoutePath: () => routeOptionsPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [EUserAuthenticationRights.ACTIVE],
  exact: true,
  render: () =>
    <LazyLoadComponent>
      <OptionsPage/>
    </LazyLoadComponent>,
};
