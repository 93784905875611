import {
  API_PATH_ApiCreateCompanyGet,
  IApiCreateCompanyGetQueryRequest,
  IApiCreateCompanyGetResponse,
} from "mhc-server";

import {apiFetch} from "../../../api/apiFetch";

export const apiCreateCompanyGet = async (args: IApiCreateCompanyGetQueryRequest): Promise<IApiCreateCompanyGetResponse> => {
  return apiFetch.request<IApiCreateCompanyGetQueryRequest, void, IApiCreateCompanyGetResponse>({
    method: 'GET',
    path: API_PATH_ApiCreateCompanyGet,
    query: args,
  });
};
