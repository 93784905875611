import {IAppRoute} from "../../application/config/IAppRoute";
import {EDeviceManagementRights} from "mhc-server";
import DeviceMgtmSettingsSettingsIcon from "@mui/icons-material/Tune";

export const routeDeviceMgtmSettingsPaths: IAppRoute = {
  menuId: '##devive-mgtm-settings',
  icon: <DeviceMgtmSettingsSettingsIcon/>,
  title: 'Settings',
  description: 'Device management settings',
  routePath: '/devices-mgtm-settings',
  getRoutePath: () => routeDeviceMgtmSettingsPaths.routePath,
  userHasAnyOfRights: [EDeviceManagementRights.ADMINISTRATOR],
  userHasAllRights: [],
  render: () => null,
};
