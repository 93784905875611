/**
 * Get the industrial minutes for the given hours and minutes.
 *
 * @example getIndustrialMinutes(2, 30) = 250
 * @param hours The hours (a number).
 * @param minutes The minutes (a number).
 * @returns The industrial minutes calculated based on the input hours and minutes.
 */
export const getIndustrialMinutes = (hours: number, minutes: number): number => {
  return (hours * 60) + minutes;
};
