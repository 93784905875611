import {
  IDBEntityBase,
  getDefaultDBEntityDocument,
} from "utils-library/dist/commonJs/db-entity-interfaces";

import {EDeviceType} from "../device-drivers";

export interface IDevice extends IDBEntityBase {
  order: number;
  isActive: boolean;      // Default is false, Admin has to activate is manually

  deviceBrokerId: string;
  deviceBrokerType: EDeviceBrokerType;
  regionId: string;       // Parent connection
  locationId: string;     // Parent connection

  activationId: string;

  showOnMap: boolean;     // Do not show it for some reason
  geoPosition: {
    lat: number;
    lng: number;
    alt?: number;
  };

  label: string;          // Used only for 1st load
  description: string;

  manufacturerName: string;
  deviceType: EDeviceType;

  outdatedMsThreshold: number;
  deadMsThreshold: number;

  notes: string;
}

export enum EDeviceBrokerType {
  UNKNOWN = "UNKNOWN",
  MHC_MQTT = "MHC_MQTT",
  OCTAVE = "OCTAVE",
}

export const getDefaultDevice = () => getDefaultDBEntityDocument<IDevice>({
  order: -1,
  deviceType: EDeviceType.UNKNOWN,
  isActive: false,
  deviceBrokerId: '',
  deviceBrokerType: EDeviceBrokerType.UNKNOWN,
  regionId: '',
  locationId: '',
  activationId: '',
  manufacturerName: '',
  showOnMap: true,
  label: '',
  description: '',
  geoPosition: {
    lat: -1,
    lng: -1,
    alt: 0,
  },
  outdatedMsThreshold: 5000,
  deadMsThreshold: 60000,
  notes: '',
});
