export const getDataComponentName = (
  dataComponentName: string | (string | undefined | null)[] = "",
  finalDataComponentName: string = "",
): string | undefined =>
  window.location.hostname === "localhost"
    ? (
      Array.isArray(dataComponentName)
        ? dataComponentName
        : typeof dataComponentName === "string"
          ? [dataComponentName]
          : []
    )
      .concat(finalDataComponentName)
      .filter(Boolean)
      .join(' ')
    : undefined;
