import moment from "moment-timezone";
import {Moment} from "moment";

import {formatTime} from "./formatTime";
import {getSystemTimezone} from "./getSystemTimezone";
import {getIndustrialHours} from "./getIndustrialHours";
import {getIndustrialMinutes} from "./getIndustrialMinutes";
import {getWeekNoOfMonth} from "./getWeekNoOfMonth";

export interface IDateTimeDetails {
  moment: Moment;             // The local moment instance
  timezone: string;
  timestamp: number;
  year: number;               // Example: 2023
  month: number;              // Zero based, 0 for January
  day: number;                // 1 for the 1st day of the month
  daysInMonth: number;
  weekday: number;            // Zero based, 0 for Sunday
  weekOfYear: number;         // Week number in the year, one based
  weekOfMonth: number;        // Week number in the month
  hour: number;               // Example 14 for 2:30pm
  minute: number;             // Example: 30
  second: number;             // Example: 30
  labelDate: string;          // Example: 12/25/2023 / 15/12/2023
  labelTime: string;          // Example: 2:30pm / 14:30
  industrialHours: number;    // Hours in the day, example: 14.50
  industrialMinutes: number;  // Minutes in the day
}

/**
 * Gets the local time, formatted time label, and industrial hours for a given date and timezone.
 */
export const getDateTimeDetails = (
  {
    date,
    timezone = getSystemTimezone(),
    format = 'US',
  }: {
    /**
     * Date or Timestamp (not ISO without Z)
     */
    date: number | Date | string;
    timezone?: string;
    format?: 'US' | 'EU';
  },
): IDateTimeDetails => {
  if (
    typeof date === "string"
    && (date[date.length - 1] || "").toLowerCase() !== "z"
  ) {
    console.error(
      `getLocalDateTime: The given date value [${date}] is not UTC ISO 8601 (should end with Z and be UTC time). The value parsed as local and returned value would be wrong!`,
      {
        args: {
          date,
          timezone,
          format,
        },
      },
    );
  }
  const localDate = moment(date).tz(timezone);
  return {
    moment: localDate,
    timezone,
    timestamp: localDate.valueOf(),
    year: localDate.year(),
    month: localDate.month(),
    day: localDate.date(),
    daysInMonth: localDate.daysInMonth(),
    weekday: localDate.weekday(),
    weekOfYear: localDate.weeks(),
    weekOfMonth: getWeekNoOfMonth(date, timezone),
    hour: localDate.hours(),
    minute: localDate.minutes(),
    second: localDate.seconds(),
    labelTime: formatTime(localDate.hours(), localDate.minutes(), format),
    labelDate: localDate.format(format === "US" ? "MM/DD/YYYY" : "DD/MM/YYYY"),
    industrialHours: getIndustrialHours(localDate.hours(), localDate.minutes()),
    industrialMinutes: getIndustrialMinutes(localDate.hours(), localDate.minutes()),
  };
};
