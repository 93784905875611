export const API_PATH_ApiLanePlanningDelete = '/services/lane-planning/apiLanePlanningDelete';

export interface IApiLanePlanningDeleteRequest {
  id: string;
  deleteIt: boolean;
}

export interface IApiLanePlanningDeleteResponse {
  ok: true;
}
