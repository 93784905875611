import {EAlertType} from "../../interfaces/EAlertType";
import {EAlertSeverity} from "../../interfaces/IAlertConfig";
import {IAlertResolveOption} from "../../interfaces/IAlertConfig";

export const API_PATH_ApiAlertMgtmAlertConfigPut = '/services/alerts-mgtm/apiAlertMgtmAlertConfigPut';

export interface IApiAlertMgtmAlertConfigPutRequest {
  alertType: EAlertType;
  severity?: EAlertSeverity;
  postponable?: boolean;
  resolveOptions?: IAlertResolveOption[];
}

export interface IApiAlertMgtmAlertConfigPutResponse {
  ok: true;
}
