export const API_PATH_ApiDeviceMgmtDeviceArchive = '/services/devices-mgtm/apiDeviceMgmtDeviceArchive';

export interface IApiDeviceMgmtDeviceArchiveBodyRequest {
  deviceId: string;
  archiveIt: boolean;
}

export interface IApiDeviceMgmtDeviceArchiveResponse {
  ok: true;
}
