export enum EDeviceType {
  UNKNOWN = "UNKNOWN",
  CONNECT_ITS_LOCATION_HUB = "CONNECT:ITS_v1",
  MHC_TRAFFIC_VEHICLES_COUNTER = "MHC_TRAFFIC_VEHICLES_COUNTER",

  DEMO_CONNECT_ITS_LOCATION_HUB = "CONNECT:ITS",
  DEMO_SPEED_CAMERA = "DEMO_SPEED_CAMERA",
  DEMO_SNOW_SENSOR = "DEMO_SNOW_SENSOR",
}

export enum EDeviceFamily {
  HUBS = "HUBS",
  TRAFFIC_COUNTER = "TRAFFIC_COUNTER",
  DEMO = "DEMO",
  WEATHER = "WEATHER",
}
