import {convertDictionaryToViewLabelArray} from "utils-library/dist/commonJs/utils";

export enum ELanePanningRights {
  LANE_PLANNING_VIEW = "LP_V",
  LANE_PLANNING_CONFIG = "LP_E",
}

const labelsDic: Record<ELanePanningRights, string> = {
  [ELanePanningRights.LANE_PLANNING_VIEW]: 'Lane Planning / View',
  [ELanePanningRights.LANE_PLANNING_CONFIG]: 'Lane Planning / Configure',
};

export const ELanePanningRightsArray = convertDictionaryToViewLabelArray(labelsDic);
