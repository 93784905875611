export const API_PATH_ApiDeviceMgmtDeviceDelete = '/services/devices-mgtm/apiDeviceMgmtDeviceDelete';

export interface IApiDeviceMgmtDeviceDeleteBodyRequest {
  deviceId: string;
  deleteIt: boolean;
}

export interface IApiDeviceMgmtDeviceDeleteResponse {
  ok: true;
}
