import {EDeviceBrokerType} from "./IDevice";
import {EDeviceType} from "./EDeviceType";
import {IDynaError} from "dyna-error";

export interface IDeviceStatus<TDeviceStatus> {
  // This is generated in MQTTDeviceManagementClient.ts from the incoming IMQTTDeviceStatusResponse
  schema: 'https://www.mhcorbin.com/schemas/device-status/1.0';
  deviceBrokerId: string;
  deviceBrokerType: EDeviceBrokerType;
  companyId: string;
  regionId: string;
  locationId: string;
  deviceId: string;
  activationId: string;
  manufacturerName: string;
  deviceType: EDeviceType;
  cycleStatus: EDeviceCycleStatus;
  summaryStatus: EDeviceSummaryStatus;
  status: TDeviceStatus | null;       // It can be null when the device doesn't respond to initial get status action.
  firmwareVersion: string;
  deviceGeoPosition?: {
    lat: number;
    lng: number;
    alt?: number;
  };
  updatedAt: number;
  loadStatusError: IDynaError | null; // This error can happen on the initial get status of the device. For broadcasting, we simply don't have any input. If the broadcast device status is invalid, we have a console.error.
}

export const getDefaultDeviceStatus = <TStatus>(partial?: Omit<Partial<IDeviceStatus<TStatus>>, 'schema'>): IDeviceStatus<any> => ({
  schema: 'https://www.mhcorbin.com/schemas/device-status/1.0',
  deviceBrokerId: partial?.deviceBrokerId ?? '',
  deviceBrokerType: partial?.deviceBrokerType ?? EDeviceBrokerType.UNKNOWN,
  companyId: partial?.companyId ?? '',
  regionId: partial?.regionId ?? '',
  locationId: partial?.locationId ?? '',
  deviceId: partial?.deviceId ?? '',
  activationId: partial?.activationId ?? '',
  manufacturerName: partial?.manufacturerName ?? '',
  deviceType: partial?.deviceType ?? EDeviceType.UNKNOWN,
  cycleStatus: partial?.cycleStatus ?? EDeviceCycleStatus.UNKNOWN,
  summaryStatus: partial?.summaryStatus ?? EDeviceSummaryStatus.UNKNOWN,
  status: partial?.status ?? null,
  firmwareVersion: partial?.firmwareVersion ?? '',
  updatedAt: partial?.updatedAt ?? 0,
  loadStatusError: partial?.loadStatusError ?? null,
});

export enum EDeviceCycleStatus {
  ONLINE = "ONLINE",
  STARTING = "STARTING",
  RESTARTING = "RESTARTING",
  TIMEOUT = "TIMEOUT",
  SHUTDOWN = "SHUTDOWN",
  UNKNOWN = "UNKNOWN",
}

export enum EDeviceSummaryStatus {
  OK = "OK",
  WARN = "WARN",
  ERROR = "ERROR",
  UNKNOWN = "UNKNOWN",
}
