export const API_PATH_ApiCreateCompanyPost = '/services/auth-create-company';

export interface IApiCreateCompanyPostBodyRequest {
  companyId: string;
  fullCompanyName: string;
  email: string;
  title: string;
  firstName: string;
  lastName: string;
  rights: string[];
}

export interface IApiCreateCompanyPostResponse {
  info: string[];
}
