import {IGeoPosition} from "utils-library/dist/commonJs/geo";

export const API_PATH_apiGeoPointElevationsGet = "/services/geo/apiGeoPointElevationsGet";

export interface IApiGeoPointElevationsGetRequest {
  positions: IGeoPosition[];
}

export interface IApiGeoPointElevationsGetResponse {
  positions: IGeoPosition[]; // Altitude is in meters
}
