/**
 * Pick the first valid value that is not false | null | undefined but TValue.
 * As last value, a valid value required to be applied. This method ensured that a valid value will be applied in the end.
 * @param values
 */
export const pickValidValue = <TValue>(...values: [...(TValue | false | null | undefined)[], TValue]): TValue => {
  const find = values.find(v => v !== undefined && v !== null && v !== false);
  if (find === undefined) return values[values.length - 1] as any;
  return find as any;
};
