import * as React from "react";
import {InputCheckbox} from "../../../../InputCheckbox";

export interface IBooleanFilterProps {
  label: string;
  helperLabel?: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export const BooleanFilter = (props: IBooleanFilterProps): JSX.Element => {
  const {
    label,
    helperLabel,
    value,
    onChange,
  } = props;

  return (
    <InputCheckbox
      dataComponentName="BooleanFilter"
      label={label}
      helperLabel={helperLabel}
      value={value}
      onChange={onChange}
    />
  );
};
