export const getQueryFromURL = (url: string): Record<string, string> => {
  return (
    url
      .split('?').pop()
    || ''
  )
    .split('&')
    .reduce((acc: Record<string, string>, text) => {
      const [key, value] = text.split('=');
      acc[key] = value;
      return acc;
    }, {});
};
