import {EAlertType} from "../../interfaces/EAlertType";
import {IAlertConfig} from "../../interfaces/IAlertConfig";

export const API_PATH_ApiAlertMgtmAlertConfigGet = '/services/alerts-mgtm/apiAlertMgtmAlertConfigGet';

export interface IApiAlertMgtmAlertConfigGetRequest {
  alertType: EAlertType;
}

export type IApiAlertMgtmAlertConfigGetResponse = IAlertConfig;
