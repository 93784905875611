export const API_PATH_ApiDeviceMgmtLocationUpdateMasterDeviceId = '/services/device-mgtm/apiDeviceMgmtLocationUpdateMasterDeviceId';

export interface IApiDeviceMgmtLocationUpdateMasterDeviceIdRequest {
  locationId: string;
  masterDeviceId: string;
}

export interface IApiDeviceMgmtLocationUpdateMasterDeviceIdResponse {
  ok: true;
}
