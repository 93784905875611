import {TDBEntityDataOnly} from "utils-library/dist/commonJs/db-entity-interfaces";

import {IDeviceMgtmSettings} from "../../interfaces/IDeviceMgtmSettings";

export const API_PATH_ApiDeviceMgmtSettingsPut = '/services/devices-mgtm/apiDeviceMgmtSettingsPut';

export type IApiDeviceMgmtSettingsPutRequest = Partial<TDBEntityDataOnly<IDeviceMgtmSettings>>;

export interface IApiDeviceMgmtSettingsPutResponse {
  ok: true;
}
