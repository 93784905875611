import * as React from "react";

import {
  ContainerRelative,
  IContainerRelativeAbsolutProps,
} from "../ContainerRelativeAbsolut";
import {getDataComponentName} from "../ui-interfaces";

export type IFlexCleanerProps = IContainerRelativeAbsolutProps;

// Cleans weired margin and paddings issues from flex parent containers for scrolled content
export const FlexCleaner: React.FC<IFlexCleanerProps> = (props )=>
  <ContainerRelative
    {...props}
    dataComponentName={getDataComponentName(props.dataComponentName, "FlexCleaner")}
  />;
