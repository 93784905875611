import {
  TExclude,
  TRequireAllAndOptional,
} from "../typescript";

export type TDBEntityDataOnly<TIDBEntityBase> = TExclude<TIDBEntityBase, IDBEntityBase>;

export interface IDBEntityBase {
  readonly id: string;
  readonly createdAt: number;
  readonly createdBy: string;
  readonly updatedAt: number;
  readonly updatedBy: string;
  readonly deletedAt: number;
  readonly deletedBy: string;
  readonly archivedAt: number;
  readonly archivedBy: string;
}

export const getDefaultDBEntityDocument =
  <TData extends IDBEntityBase>(
    data: TDBEntityDataOnly<TData>,
    {partialBase = {}}: {
      partialBase?: Partial<IDBEntityBase>;
    } = {},
  ): TRequireAllAndOptional<TData> => {
    return {
      ...getDefaultDBEntityBase(),
      ...data,
      ...partialBase,
    } as any;
  };

export const getDefaultDBEntityBase = (): IDBEntityBase => ({
  id: '',
  createdAt: 0,
  createdBy: '',
  updatedAt: 0,
  updatedBy: '',
  archivedAt: 0,
  archivedBy: '',
  deletedAt: 0,
  deletedBy: '',
});

export const getDBEntityBasePropertiesOnly = (data: IDBEntityBase): IDBEntityBase =>
  Object.keys(getDefaultDBEntityBase())
    .reduce(
      (acc: IDBEntityBase, key) => {
        acc[key] = data[key];
        return acc;
      },
      getDefaultDBEntityBase(),
    );
