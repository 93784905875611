export const isURLReachable = async (
  url: string,
  method:
    | "fetch-method"
    | "script-element" // This method bypasses the CORS restrictions
  ,
): Promise<{
  ok: boolean;
  status: number;
  networkError?: Error;
}> => {
  return method === "fetch-method"
    ? isURLReachableByFetchMethod(url)
    : isURLReachableByScriptElement(url);
};

const isURLReachableByFetchMethod = async (url: string): Promise<{
  ok: boolean;
  status: number;
  networkError?: Error;
}> => {
  try {
    const response = await fetch(url);

    if (response.ok) {
      return {
        ok: true,
        status: response.status,
      };
    }
    else {
      return {
        ok: false,
        status: response.status,
      };
    }
  }
  catch (error) {
    return {
      ok: false,
      status: -1,
      networkError: error,
    };
  }
};

const isURLReachableByScriptElement = async (url: string): Promise<{
  ok: boolean;
  status: number;
  networkError?: any;
}> => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = url;

    script.onload = () => {
      resolve({
        ok: true,
        status: 200,
      });
      // Remove the script element after it has been loaded.
      script.parentNode?.removeChild(script);
    };

    script.onerror = (error) => {
      resolve({
        ok: false,
        status: -1,
        networkError: error,
      });
      // Remove the script element if an error occurs.
      script.parentNode?.removeChild(script);
    };

    document.head.appendChild(script);
  });
};
