export const API_PATH_ApiTrafficStudyPostClap = '/services/traffic-studies/apiTrafficStudyPostClap';

export interface IApiTrafficStudyPostClapRequest {
  trafficStudyId: string;
  postId: string;
  claps: number;
}

export interface IApiTrafficStudyPostClapResponse {
  ok: true;
}
