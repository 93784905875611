import * as React from "react";
import {
  forwardRef,
} from "react";

import {THandleSaveMaps} from "../GeoMapLeafletCore/offline/DbTiles";

import {
  IGeoMapLeafletCoreProps,
  GeoMapLeafletCore,
} from "../GeoMapLeafletCore";

import {IGeoMapLeafletCoreRef} from "../GeoMapLeafletCore";

export type IGeoMapLeafletProps = Pick<IGeoMapLeafletCoreProps, Exclude<keyof IGeoMapLeafletCoreProps, keyof IExcludeProps>>;

interface IExcludeProps {
  ref: any;
  showOfflineSaveDeleteButtons?: boolean;   // Default is false
  onOfflineSaveMaps: THandleSaveMaps;
  onOfflineDeleteMaps: THandleSaveMaps;
}

export const GeoMapLeaflet = forwardRef<IGeoMapLeafletCoreRef,IGeoMapLeafletProps>((props, ref): JSX.Element => {
  return (
    <GeoMapLeafletCore
      {...props}
      ref={ref}
    />);
});
