import * as React from "react";
import {
  useMemo,
  ReactElement,
} from "react";
import {guid} from "dyna-guid";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import {isMac} from "utils-library/dist/commonJs/web";

import {
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "../FlexContainer";
import {Box} from "../Box";
import {IIconComponent} from "../IconComponent";

import {IconViewer} from "../IconViewer";
import {
  Button,
  EButtonColor,
  EButtonVariant,
} from "../Button";
import {useBreakpointDevice} from "../useBreakpointDevice";

export interface IModalDialogProps {
  show: boolean;
  Icon?: IIconComponent;
  title: string;
  buttonVariant?: EButtonVariant;   // Default is EButtonVariant.TRANSPARENT
  buttons: IModalDialogButton[];    // The 1st button is the primary button
  children?: any;
  onClose?: () => void;             // If this is defined, the user can click on the background to close it
}

export {
  EButtonVariant,
};

export interface IModalDialogButton {
  Icon?: IIconComponent;
  label: string;
  onClick: () => void;
}

export const ModalDialog = (props: IModalDialogProps): ReactElement => {
  const {
    show,
    Icon,
    title,
    children,
    buttonVariant = EButtonVariant.TRANSPARENT,
    buttons,
    onClose,
  } = props;

  const id = useMemo(guid, []);
  const {isMobile} = useBreakpointDevice();

  return (
    <Dialog
      aria-labelledby={id}
      fullScreen={isMobile}
      open={show}
      onClose={onClose}
    >
      <DialogTitle id={id}>
        <Box
          sx={{
            fontSize: theme => theme.typography.fontSize * 2,
            fontWeight: 'bold',
          }}
        >
          <FlexContainerHorizontal alignVertical="middle">
            <FlexItemMin>
              <IconViewer
                sx={{
                  position: 'relative',
                  top: 4,
                }}
                Icon={Icon}
              />
            </FlexItemMin>
            <FlexItemMin sx={{minWidth: theme => theme.spacing(1)}}/>
            <FlexItemMax>
              {title}
            </FlexItemMax>
          </FlexContainerHorizontal>
        </Box>
      </DialogTitle>

      <DialogContent sx={{opacity: 0.8}}>
        {children}
      </DialogContent>

      <DialogActions sx={{padding: theme => theme.spacing(2)}}>
        {
          (isMac
            ? buttons.concat().reverse()
            : buttons
          )
            .map(({
              Icon, label, onClick,
            }, index) => (
              <Button
                key={index}
                Icon={Icon}
                variant={buttonVariant}
                color={
                  index === (isMac ? buttons.length - 1 : 0)
                    ? EButtonColor.PRIMARY
                    : EButtonColor.SECONDARY
                }
                onClick={onClick}
              >
                {label}
              </Button>
            ))
        }
      </DialogActions>

    </Dialog>
  )
  ;
};
