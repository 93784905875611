export const API_PATH_ApiLanePlanningArchive = '/services/lane-planning/apiLanePlanningArchive';

export interface IApiLanePlanningArchiveRequest {
  id: string;
  archiveIt: boolean;
}

export interface IApiLanePlanningArchiveResponse {
  ok: true;
}
