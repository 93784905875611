import * as React from "react";
import {
  useRef,
  useState,
  useEffect,
} from "react";

import {IDynaError} from "dyna-error";

import {camelToHuman} from "utils-library/dist/commonJs/utils";

import {
  Alert,
  EAlertType,
} from "../Alert";
import {Box} from "../Box";
import {
  ErrorLinkModalViewer,
  ELinkColor,
} from "../ErrorLinkModalViewer";
import {IIconComponent} from "../IconComponent";
import {useKeyStatus} from "../useKeyStatus";
import {useHover} from "../useHover";

export interface IErrorBannerProps {
  show?: boolean;               // Default is true. Show the error or not.
  title?: string;
  // Pass any error to show the banner or null to hide it.
  // Pass a new error to show it, if it is closed will open again.
  error?: IDynaError | null | unknown;
  validationErrors?: { [fieldName: string]: string };

  children?: any;               // Additional content with error

  defaultErrorMessage?: string; // Default is "Error, something went wrong." Used when the error doesn't have the `userError` prop
  showAsWarning?: boolean;      // Default is False

  showShowErrorLink?: boolean;  // Default is true. The link button is shown when the Alt is pressed and the mouse is hovering the banner.

  closeButton?:
    | true
    | {
    Icon?: IIconComponent;          // Default is 'x' icon
    label?: string;
    onClick?: () => void;
  };
}

export const ErrorBanner = (props: IErrorBannerProps): JSX.Element => {
  const {
    show: userShow = true,
    title: userTitle,
    error,
    validationErrors = {},
    children,
    defaultErrorMessage = 'Error, something went wrong.',
    showAsWarning = false,
    showShowErrorLink = true,
    closeButton,
  } = props;
  const hasValidationErrors = !!Object.values(validationErrors).join('');
  const hasError = !!error || !!Object.values(validationErrors).join('');

  const refContainer = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(hasError);
  const {altKeyDown} = useKeyStatus();
  const hovering = useHover(refContainer);

  useEffect(() => {
    setShow(hasError);
  }, [hasError]);

  const {
    title,
    body,
  }:
    {
      title: string;
      body: string;
    } =
    (() => {
      let errorMessage =
        error
          ? (error as any).userMessage || (error as any).message || defaultErrorMessage
          : '';
      const validationErrorMessage =
        (hasValidationErrors ? 'Validation errors\n' : '')
        + Object.entries(validationErrors)
          .map(([fieldName, validationError]) => {
            if (validationError === "") return '';
            return `${camelToHuman(fieldName)}: ${validationError}`;
          })
          .filter(Boolean)
          .join('\n');

      if (validationErrorMessage) {
        if (errorMessage) errorMessage += '\n';
        errorMessage += validationErrorMessage;
      }
      if (errorMessage.includes('\n')) {
        const parts = errorMessage.split('\n');
        const title = parts[0];
        const body = parts.slice(1).join('\n');
        return {
          title,
          body,
        };
      }
      else {
        return {
          title: errorMessage,
          body: '',
        };
      }
    })();

  const errorCode = (error as any || {}).code || '';

  const handleCloseButtonClick = () => setShow(!show);

  return (
    <div ref={refContainer}>
      <Alert
        dataComponentName="ErrorBanner"
        show={show && userShow}
        title={userTitle || title}
        type={showAsWarning ? EAlertType.WARNING : EAlertType.ERROR}
        closeButton={
          closeButton
            ? closeButton === true
              ? {onClick: () => setShow(!show)}
              : {
                ...closeButton,
                onClick: () => {
                  handleCloseButtonClick();
                  closeButton.onClick?.();
                },
              }
            : undefined
        }
      >
        <Box show={!!userTitle}><b>{title}</b></Box>
        <Box show={!!body}>{body}</Box>
        <Box show={!!children}>{children}</Box>
        <Box>
          <Box
            show={!!errorCode}
            component="span"
            sx={{
              marginTop: theme => theme.spacing(0.5),
              fontSize: theme => theme.typography.fontSize * 0.7,
            }}
          >
            Error code: <code>{errorCode}</code>
            {" "}
          </Box>
          <ErrorLinkModalViewer
            error={error}
            show={showShowErrorLink && altKeyDown && hovering}
            color={ELinkColor.WHITE}
          />
        </Box>
      </Alert>
    </div>
  );
};
