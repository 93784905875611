export interface ITableFilter<TUserFilterValue = any> {
  filterName: string;
  hidden?: boolean;   // Hide it for any reason
  label: string;
  type: ETableFilterValueType;
  comparison: ETableFilterComparison;
  options?: ITableFilterOptions[];
  value: TUserFilterValue; // Initial filter value and later is the user's selected value
}

export enum ETableFilterValueType {
  TEXT = "TEXT",
  BOOLEAN = "BOOLEAN",
  NUMBER = "NUMBER",
  DATE = "DATE",
  OPTION = "OPTION",
  OPTIONS = "OPTIONS",
}

export enum ETableFilterComparison {
  EQUAL = "EQUAL",
  CONTAINS = "CONTAINS",
  BIGGER = "BIGGER",
  LESS = "LESS",
  EQUAL_BIGGER = "EQUAL_BIGGER",
  EQUAL_LESS = "EQUAL_LESS",
}

export interface ITableFilterOptions {
  label: string;
  value: string;
}
