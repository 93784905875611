import {ILocation} from "../../interfaces/ILocation";
import {IDevice} from "../../interfaces/IDevice";

export const API_PATH_ApiDeviceMgmtLocationsDevicesGet = '/services/devices-mgtm/apiDeviceMgmtLocationsDevicesGet';

export interface IApiDeviceMgmtLocationsDevicesGetQueryRequest {
  regionId: string;
  search: string;
  archived: "true" | "false";
  deleted: "true" | "false";
}

export interface IApiDeviceMgmtLocationsDevicesGetResponse {
  locations: ILocation[];
  devices: IDevice[];
}
