import {EWorkZonesManagementRights} from "mhc-server";

import {buildUrlByRoutePath} from "utils-library/dist/commonJs/utils";

import {IAppRoute} from "../../application/config/IAppRoute";
import {
  ECIMTabRoot,
  ECIMTabMap,
} from "../components/WorkzoneForm";

export const routeWorkZonesEditPaths: IAppRoute<
  {
    id: string;
    tabRoot?: ECIMTabRoot;
    tabMap?: ECIMTabMap;
  },
  {
    performAction?:
      | 'validate'
      | 'broadcast';
  }
> = {
  title: 'Edit CIM',
  routePath: '/cim/edit/:id/:tabRoot?/:tabMap?',
  getRoutePath: (
    {
      id,
      tabRoot = "",
      tabMap = "",
      performAction,
    } = {id: ''},
  ) =>
    buildUrlByRoutePath({
      routePath: routeWorkZonesEditPaths.routePath,
      pathParams: {
        id,
        tabRoot,
        tabMap,
      },
      queryParams: {performAction},
    }),
  menuId: 'edit-CIM',
  exact: true,
  userHasAllRights: [],
  userHasAnyOfRights: [
    EWorkZonesManagementRights.WORKZONES_VIEW,
    EWorkZonesManagementRights.WORKZONES_EDIT,
  ],
  render: () => null,
};
