import * as React from "react";

import {
  TDataComponentName,
  getDataComponentName,
} from "../ui-interfaces";
import {Box} from "../Box";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";

export interface IContainerRelativeProps {
  dataComponentName?: TDataComponentName;
  sx?: SxProps<Theme>;
  show?: boolean;
  fullHeight?: boolean;
  children?: any;
}

export const ContainerRelative: React.FC<IContainerRelativeProps> = (
  {
    dataComponentName,
    sx,
    show = true,
    fullHeight = false,
    children = null,
  },
) => {
  if (!show) return null;
  return (
    <Box
      dataComponentName={getDataComponentName(dataComponentName, "ContainerRelative")}
      sx={{
        ...sx,
        position: "relative",
      }}
      fullHeight={fullHeight}
    >
      {children}
    </Box>
  )
  ;
};
