import * as React from "react";

import MuiTooltip from "@mui/material/Tooltip";
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';

export interface ITooltipProps {
  title?: string | JSX.Element;

  arrow?: boolean;

  showDelay?: number;                   // Default is 1000ms
  showNextDelay?: number;               // Default is 500ms

  position?: ETooltipPosition;

  transitionType?: ETooltipTransition;  // Default is ETooltipTransition.NONE
  transitionDuration?: number;          // For all NONE transitions. Default is 150ms

  children: any;
}

export enum ETooltipPosition {
  BOTTOM_END = 'bottom-end',
  BOTTOM_START = 'bottom-start',
  BOTTOM = 'bottom',
  LEFT_END = 'left-end',
  LEFT_START = 'left-start',
  LEFT = 'left',
  RIGHT_END = 'right-end',
  RIGHT_START = 'right-start',
  RIGHT = 'right',
  TOP_END = 'top-end',
  TOP_START = 'top-start',
  TOP = 'top',
}

export enum ETooltipTransition {
  NONE = "NONE",
  FADE = "FADE",
  ZOOM = "ZOOM",
}

const transitionComponents: Record<ETooltipTransition, any> = {
  NONE: undefined,
  FADE: Fade,
  ZOOM: Zoom,
};

export const Tooltip: React.FC<ITooltipProps> = (
  {
    title,
    showDelay = 1000,
    showNextDelay = 500,
    position,
    arrow = true,
    transitionType = ETooltipTransition.NONE,
    transitionDuration = 150,
    children,
  },
) => {
  if (!title) return children;

  return (
    <MuiTooltip
      title={title}
      enterDelay={showDelay}
      enterNextDelay={showNextDelay}
      placement={position}
      arrow={arrow}
      TransitionComponent={transitionComponents[transitionType]}
      TransitionProps={{timeout: transitionDuration}}
      PopperProps={{style: {zIndex: 100000}}}
    >
      <span> {/* This ugly span solves the MUI's bug https://github.com/mui/material-ui/issues/33476 */}
        {children}
      </span>
    </MuiTooltip>
  );
};
