import {TDBEntityDataOnly} from "utils-library/dist/commonJs/db-entity-interfaces";

import {IAppOptions} from "../../db/options/AppOptionsEntity.interfaces";

export const API_PATH_ApiAppOptionsPut = '/services/app/apiAppOptionsPut';

export type IApiAppOptionsPutBodyRequest = Partial<TDBEntityDataOnly<IAppOptions>>;

export interface IApiAppOptionsPutResponse {
  ok: true;
}
