import {
  useState,
  useEffect,
} from "react";

import {areValuesEqual} from "utils-library/dist/commonJs/utils";

/**
 * UseOnChange is like useEffect but triggers only when something really changed in data and not on reference change.
 */
export const useOnChange = <TDep>(
  {
    dep,
    callOnStartup = false,
    onChange,
    onUnmount,
  }: {
    dep: TDep;
    callOnStartup?: boolean;
    onChange: (dep: TDep, oldDep: TDep | null) => void;
    onUnmount?: () => void;
  },
): void => {
  const [lastDep, setLastDep] = useState<TDep>(dep);

  useEffect(() => {
    if (callOnStartup) onChange(dep, null);
  }, []);

  useEffect(() => {
    if (!areValuesEqual(dep, lastDep)) {
      onChange(dep, lastDep);
      setLastDep(dep);
    }
    return onUnmount;
  }, [dep]);
};
