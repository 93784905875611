import {
  IDBEntityBase,
  getDefaultDBEntityDocument,
} from "utils-library/dist/commonJs/db-entity-interfaces";

export interface IPreset<TPreset> extends IDBEntityBase {
  groupId: string;
  title: string;
  description: string;
  preset: TPreset;
  usedTimes: number;
  lastUsedAt: number;
  deleteAfterDaysOfInactivity: number;  // 0 means live for ever (or till delete it manually)
}

export type IPresetHeader<TPreset> = Omit<IPreset<TPreset>, 'preset'>;

export const getDefaultPreset = <TPreset>(
  {
    groupId,
    preset,
  }: {
    groupId: string;
    preset: TPreset;
  },
) => getDefaultDBEntityDocument<IPreset<TPreset>>({
  groupId,
  title: '',
  description: '',
  usedTimes: 0,
  preset,
  lastUsedAt: 0,
  deleteAfterDaysOfInactivity: 0,
});

export const convertPresetToPresetHeader = <TPreset>(preset: IPreset<TPreset>): IPresetHeader<TPreset> => {
  const output: any = {...preset};
  delete output.preset;
  return output;
};
