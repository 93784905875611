import {
  IDBEntityBase,
  getDefaultDBEntityDocument,
} from "utils-library/dist/commonJs/db-entity-interfaces";

export interface IAppOptions extends IDBEntityBase{
  deleteForeverAfterDays: number;
}

export const getDefaultAppOptions = () => getDefaultDBEntityDocument<IAppOptions>({deleteForeverAfterDays: 30});
