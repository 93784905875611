import {Theme} from "../ThemeProvider";
// See the fault value at: https://mui.com/customization/default-theme/

export type TCSSAttr =
  | "opacity"
  | "color"
  | "border-color"
  | "background"
  | "background-color"
  | "padding"
  | "padding-left"
  | "padding-right"
  | "padding-top"
  | "padding-bottom"
  | "margin"
  | "margin-left"
  | "margin-right"
  | "margin-top"
  | "margin-bottom"
  | "width"
  | "height"
  | "top"
  | "bottom"
  | "left"
  | "right"
  | "transform"
  ;

export enum ECSSDuration {
  SHORTEST = 'shortest',        // MUI's Default: 150ms
  SHORTER = 'shorter',          // MUI's Default: 200ms
  SHORT = 'short',              // MUI's Default: 250ms
  STANDARD = 'standard',        // MUI's Default: 300ms
  COMPLEX = 'complex',          // MUI's Default: 375ms
  ENTERING = 'enteringScreen',  // MUI's Default: 225ms
  LEAVING = 'leavingScreen',    // MUI's Default: 195ms
}

export enum ECSSEasing {
  IN = "easeIn",
  OUT = "easeOut",
  IN_OUT = "easeInOut",
  SHARP = "sharp",
}

export const sxTransition = (
  theme: Theme,
  cssAttrs: TCSSAttr | TCSSAttr[],
  duration: ECSSDuration | number,
  easing: ECSSEasing = ECSSEasing.IN_OUT,
): string => {
  const applyDuration: number =
    typeof duration === "string"
      ? theme.transitions.duration[duration]
      : duration;
  return (
    (Array.isArray(cssAttrs)
      ? cssAttrs
      : [cssAttrs]
    )
      .map((cssAttr: string) =>
        theme.transitions.create(
          cssAttr,
          {
            easing: theme.transitions.easing[easing],
            duration: applyDuration,
          },
        ),
      )
      .join(', ')
  );
};

export const sxTransitionShowHide = (
  theme: Theme,
  attr: TCSSAttr | TCSSAttr[],
  currentShowValue: boolean,
): string =>
  currentShowValue
    ? sxTransition(theme, attr, ECSSDuration.LEAVING, ECSSEasing.IN_OUT)
    : sxTransition(theme, attr, ECSSDuration.ENTERING, ECSSEasing.SHARP);
