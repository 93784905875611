import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  createRef,
  CSSProperties,
  ReactElement,
} from "react";

import {watchElementRemoval} from "./utils/watchElementRemoval";
import {watchElementVisibility} from "./utils/watchElementVisibility";

export interface IContainerPortalProps {
  parentElement?: Element;  // Default is document.body
  nodeType?:                // Default is "div". The node type of the portal container
    | "div"
    | "span"
    | "section";
  show?: boolean;
  style?: CSSProperties;
  /**
   * The id of the empty `<div>` element rendered within this component that is used to monitor visibility from non-React parent components.
   *
   * The purpose of this div element is to monitor the visibility of ported content.
   * By setting properties on this div, non-React parents can control the visibility of the ported content.
   */
  detectVisibilityElementId?: string;
  children: any;
}

interface IWdPortalContainerState {
  isParentInTheDom: boolean;
  isParentVisible: boolean;
}

/**
 * This container renders the children within a React portal, incorporating additional features
 * such as direct styling of the portal container and monitoring the visibility of the parent.
 */
export class ContainerPortal extends React.Component<IContainerPortalProps, IWdPortalContainerState> {
  private readonly refContainer = createRef<HTMLDivElement>();
  private readonly refFloatContainer: HTMLElement;

  public state: IWdPortalContainerState = {
    isParentInTheDom: true,
    isParentVisible: true,
  };

  constructor(props: IContainerPortalProps) {
    super(props);
    const {nodeType = "div"} = props;
    this.refFloatContainer = document.createElement(nodeType);
  }

  public componentDidMount(): void {
    const {parentElement = document.body} = this.props;
    parentElement.appendChild(this.refFloatContainer);
    if (this.refContainer.current) {
      watchElementRemoval({
        element: this.refContainer.current,
        onRemove: () => this.setState({isParentInTheDom: false}),
      });
      watchElementVisibility({
        element: this.refContainer.current,
        onVisibilityChange: isVisible => {
          if (this.state.isParentVisible !== isVisible) this.setState({isParentVisible: isVisible});
        },
      });
    }
    else {
      // Dev info: this.refContainer.current null???
      // This is happening only when the component is not on the DOM
      // There is nothing to do, just ignore it.
    }
  }

  public componentDidUpdate() {
    this.applyStyle();
  }

  private applyStyle(): void {
    const {style} = this.props;
    if (!style) return;
    Object.keys(style)
      .forEach(styleProp => this.refFloatContainer.style[styleProp] = style[styleProp]);
  }

  public get innerRef(): HTMLElement {
    return this.refFloatContainer;
  }

  public componentWillUnmount(): void {
    document.body.removeChild(this.refFloatContainer);
  }

  public render(): ReactElement | null {
    const {
      show = true,
      detectVisibilityElementId,
      children,
    } = this.props;
    const {
      isParentInTheDom,
      isParentVisible,
    } = this.state;

    if (
      !show
      || !isParentInTheDom
    ) return null;

    return (
      <>
        <div
          id={detectVisibilityElementId}
          ref={this.refContainer}
        />
        {isParentVisible && (
          ReactDOM.createPortal(
            children,
            this.refFloatContainer,
          )
        )}
      </>
    );
  }
}
