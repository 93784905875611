import * as React from "react";
import {Popup as _Popup} from 'react-leaflet';  // Todo: MHC-00783 react-leaflet v4 has some typescript errors https://trello.com/c/U7DFYEIA

import {IGeoPosition} from "utils-library/dist/commonJs/geo";

import {Box} from "../../../../../../Box";

export interface IMapPopUpProps {
  children: any;
  position?: IGeoPosition;
  onOpen?: () => void;
  onClose?: () => void;
}

const Popup: any = _Popup;

export const MapPopUp = (props: IMapPopUpProps): JSX.Element | null => {
  const {
    children,
    position,
    onOpen,
    onClose,
  } = props;

  if (!children) return null;

  return (
    <Popup
      position={position}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Box sx={{color: theme => theme.palette.common.black}}>
        {children}
      </Box>
    </Popup>
  );
};
