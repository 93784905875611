import {serverAppConfig} from "mhc-server";
import {appConfig} from "./sections/application/config/appConfig";

import {templateString} from "utils-library/dist/commonJs/template-string";
import {getHost} from "utils-library/dist/commonJs/utils";

const updateElement = (
  {
    selector,
    attributeName,
    variableName,
    value,
  }: {
    selector: string;
    attributeName: string;
    variableName?: string;
    value: string;
  },
): void => {
  const element = document.querySelector(selector);
  if (!element) {
    console.error(
      `Internal error 202208311053: updateAppHtml: Cannot find element with [${selector}]. Probably the public/index.html is changed and the updateAppHtml is not updated.`,
      {
        selector,
        attributeName,
        value,
      },
    );
    return;
  }

  element.setAttribute(
    attributeName,
    variableName
      ? templateString(element.getAttribute(attributeName) || '', {[variableName]: value})
      : value,
  );
};

updateElement({
  // Help: https://content-security-policy.com/examples/blocked-csp/
  selector: 'html > head > meta[http-equiv="Content-Security-Policy"]',
  attributeName: 'content',
  value: [
    'default-src',
    appConfig.apiBaseUrl,
    appConfig.webBaseUrl,
    ...serverAppConfig.contentSecurityPolicyDirectives.defaultSrc,
    ';',

    'script-src',
    ...serverAppConfig.contentSecurityPolicyDirectives.scriptSrc,
    ';',

    'style-src',
    ...serverAppConfig.contentSecurityPolicyDirectives.styleSrc,
    ';',

    'style-src-elem',
    ...serverAppConfig.contentSecurityPolicyDirectives.styleSrcElem,
    ';',

    'font-src',
    ...serverAppConfig.contentSecurityPolicyDirectives.fontSrc,
    ';',

    'img-src',
    ...serverAppConfig.contentSecurityPolicyDirectives.imgSrc,
    ';',

    'media-src',
    ...serverAppConfig.contentSecurityPolicyDirectives.mediaSrc,
    ';',

    'connect-src',
    getHost(appConfig.apiBaseUrl),
    `${window.location.protocol.replace('http', 'ws')}//${getHost(appConfig.apiBaseUrl)}`,
    ...serverAppConfig.contentSecurityPolicyDirectives.connectSrc,
    ';',

    // Skip frame-ancestors, since these should be delivered only from the server as headers

    'form-action',
    ...serverAppConfig.contentSecurityPolicyDirectives.formAction,
    ';',
  ]
    .join(' ')
    .replace(/ ;/g, ';')
  ,
});
