import * as React from "react";

import {dynaSwitchEnum} from "dyna-switch";

import {IIconComponent} from "../IconComponent";

import {
  useTheme,
  SxProps,
  Theme,
} from "../ThemeProvider";

import {IconText} from "../IconText";

import {createIcon} from "../IconComponent";
import DefaultIcon from '@mui/icons-material/Circle';
import InfoIcon from '@mui/icons-material/Info';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import WarnIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import DebugIcon from '@mui/icons-material/BugReport';

export interface IHelperTextProps {
  sx?: SxProps<Theme>;
  show?: boolean;
  dataComponentName?: string;
  type?: EHelperTextType;
  /**
   * A default icon is selected by the type, but you can override it with this prop
   */
  Icon?: IIconComponent;
  line?: string;
  lines?: (string | undefined)[];
  children?: any;
}

export enum EHelperTextType {
  DEFAULT = "DEFAULT",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  INFO = "INFO",
  WARNING = "WARNING",
  DEBUG = "DEBUG",
}

// Like MUI's Helper Text but doesn't complain for nested divs
export const HelperText = (props: IHelperTextProps): JSX.Element | null => {
  const {
    sx = {},
    Icon,
    show = true,
    dataComponentName,
    type = EHelperTextType.DEFAULT,
    line,
    lines,
    children,
  } = props;
  const theme = useTheme();

  if (!children && !line && !lines?.length) return null;
  if (!show) return null;

  return (
    <IconText
      sx={{
        fontSize: theme.typography.fontSize * 0.8,
        color: dynaSwitchEnum<EHelperTextType, string>(
          type,
          {
            [EHelperTextType.DEFAULT]: "",
            [EHelperTextType.INFO]: theme.palette.info.main,
            [EHelperTextType.SUCCESS]: theme.palette.success.main,
            [EHelperTextType.ERROR]: theme.palette.error.main,
            [EHelperTextType.WARNING]: theme.palette.warning.main,
            [EHelperTextType.DEBUG]: theme.palette.secondary.main,
          },
        ),
        opacity: 0.8,
        ...sx,
      }}
      dataComponentName={dataComponentName}
      Icon={(() => {
        if (Icon) return Icon;
        return dynaSwitchEnum<EHelperTextType, IIconComponent>(
          type,
          {
            [EHelperTextType.DEFAULT]: createIcon.byMuiIcon(DefaultIcon),
            [EHelperTextType.INFO]: createIcon.byMuiIcon(InfoIcon),
            [EHelperTextType.SUCCESS]: createIcon.byMuiIcon(SuccessIcon),
            [EHelperTextType.ERROR]: createIcon.byMuiIcon(ErrorIcon),
            [EHelperTextType.WARNING]: createIcon.byMuiIcon(WarnIcon),
            [EHelperTextType.DEBUG]: createIcon.byMuiIcon(DebugIcon),
          },
        );
      })()}
    >
      {new Array<any>()
        .concat(
          line,
          lines,
        )
        .filter(Boolean)
        .map((line, index) => (
          <div key={index}>{line}</div>
        ))
      }
      {children}
    </IconText>
  );
};
