import {
  IValidationDataResults,
  IValidationResult,
} from "./interfaces";

const successValidationResult: IValidationResult<any> = {
  isValid: true,
  dataValidation: {},
  messages: [],
};

export const validationMergeResults = <TData, >(
  resultA: IValidationResult<TData> = successValidationResult as any,
  resultB: IValidationResult<TData> = successValidationResult as any,
): IValidationResult<TData, any> => {
  return {
    isValid: resultA.isValid && resultB.isValid,
    dataValidation:
      [
        ...Object.keys(resultA.dataValidation),
        ...Object.keys(resultB.dataValidation),
      ]
        .reduce((acc: string[], key) => {
          if (!acc.includes(key)) acc.push(key);
          return acc;
        }, [])
        .reduce((acc: IValidationDataResults<TData>, key) => {
          const r1 = resultA.dataValidation[key];
          const r2 = resultB.dataValidation[key];
          (acc as any)[key] = [r1, r2].filter(Boolean).join(' ');
          return acc;
        }, {} as any),
    messages: [
      ...resultA.messages,
      ...resultB.messages,
    ],
    customValidation: {
      ...resultA.customValidation || {},
      ...resultB.customValidation || {},
    },
  };
};
