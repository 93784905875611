import * as React from "react";

import {isMac} from "utils-library/dist/commonJs/web";

import {
  Button,
  EButtonVariant,
  EButtonColor,
  EButtonSize,
} from "../Button";
import {IIconComponent} from "../IconComponent";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {Box} from "../Box";

import {
  useTheme,
  useMediaQuery,
} from "../ThemeProvider";
import {createIcon} from "../IconComponent";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

interface IConfirmProps {
  // Dev tip: The `useConfirm` hook simplifies the usage of this component.
  show: boolean;        // We turn it to true when we want to ask the user something
  title: string;
  message?: string;     // Same as the children
  children?: any;       // Same as message
  helperMessage?: string;

  labelConfirmButton?: string;
  labelConfirmIcon?: IIconComponent;
  labelCancelButton?: string | null;  // Null to hide it
  labelCancelIcon?: IIconComponent;

  forceUserConfirmation?: boolean; // Set it true to force users to click a button to confirm/cancel

  onOk?: () => void;
  onCancel?: () => void;
  onClose?: (confirm: boolean) => void;
}

export const Confirm = (props: IConfirmProps) => {
  const {
    show,
    title,
    message = "",
    children,
    helperMessage = "",
    forceUserConfirmation = false,
    labelConfirmButton = "Confirm",
    labelConfirmIcon = createIcon.byMuiIcon(CheckIcon),
    labelCancelButton = "Cancel",
    labelCancelIcon = createIcon.byMuiIcon(CancelIcon),
    onOk,
    onCancel,
    onClose,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOkClick = (): void => {
    onOk && onOk();
    onClose && onClose(true);
  };

  const handleCancelClick = (): void => {
    onCancel && onCancel();
    onClose && onClose(false);
  };

  const primaryButton = (
    <Button
      Icon={labelConfirmIcon}
      variant={EButtonVariant.CONTAINED}
      color={EButtonColor.PRIMARY}
      size={EButtonSize.MEDIUM}
      onClick={handleOkClick}
    >
      {labelConfirmButton}
    </Button>
  );

  return (
    <Dialog
      sx={{zIndex: 3200}}
      open={show}
      onClose={forceUserConfirmation ? handleCancelClick : undefined}
      aria-labelledby={`confirmation-dialog-${title}`}
      fullScreen={fullScreen}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          <strong style={{fontSize: theme.typography.fontSize + 2}}>
            {message}
          </strong>
        </DialogContentText>
        {children}
        <br/>
        <br/>
        <Box
          component="span"
          sx={{
            fontStyle: 'italic',
            fontWeight: 'bold',
            whiteSpace: 'pre',
            marginTop: theme => theme.spacing(1),
          }}
        >
          {helperMessage}
        </Box>
      </DialogContent>
      <DialogActions>
        {!isMac && primaryButton}
        <Button
          Icon={labelCancelIcon}
          show={labelCancelButton !== null}
          variant={EButtonVariant.CONTAINED}
          color={EButtonColor.ERROR}
          size={EButtonSize.MEDIUM}
          onClick={handleCancelClick}
        >
          {labelCancelButton}
        </Button>
        {isMac && primaryButton}
      </DialogActions>
    </Dialog>
  );
};
