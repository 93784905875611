import {apiFetch} from "../../../api/apiFetch";
import {
  API_PATH_ApiCreateCompanyPost,
  IApiCreateCompanyPostBodyRequest,
  IApiCreateCompanyPostResponse,
} from "mhc-server";

export const apiCreateCompanyPost = (data: IApiCreateCompanyPostBodyRequest): Promise<IApiCreateCompanyPostResponse> => {
  return apiFetch.request<null, IApiCreateCompanyPostBodyRequest, IApiCreateCompanyPostResponse>({
    path: API_PATH_ApiCreateCompanyPost,
    method: 'POST',
    body: data,
  });
};
