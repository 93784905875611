export const monitorOnline = (onOnlineChange: (online: boolean) => void) => {
  const onlineStatusHandler = () => {
    onOnlineChange(window.navigator.onLine);
  };

  return {
    get online(): boolean {
      return window.navigator.onLine;
    },
    get offline(): boolean {
      return !window.navigator.onLine;
    },
    start: (): void => {
      window.addEventListener('online', onlineStatusHandler);
      window.addEventListener('offline', onlineStatusHandler);

    },
    stop: () => {
      window.removeEventListener('online', onlineStatusHandler);
      window.removeEventListener('offline', onlineStatusHandler);
    },
  };
};
