import * as React from "react";

import {
  useState,
  useEffect,
} from "react";

import {Box} from "../Box";

export const Ellipsis = (): JSX.Element => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const intervalId =
      setInterval(() => {
        setDots(prevDots =>
          prevDots === '...'
            ? ''
            : prevDots + '.',
        );
      }, 250);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box
      sx={{
        display: 'inline-block',
        textAlign: 'left',
        width: theme => theme.typography.fontSize * 3 / 2,
      }}
    >
      {dots}
    </Box>
  );
};
