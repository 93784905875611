import {irregularPlurals} from "./irregularPlurals";

export const pluralize = (word: string, items: any[] | number): string => {
  if (!word) return word;

  const count =
    Array.isArray(items)
      ? items.length
      : items;

  // Singular form for a single element
  if (count > 0 && count <= 1) return word;

  // Handle irregular plurals
  const irregularPlural = irregularPlurals[word];
  if (irregularPlural) return irregularPlural;

  // Convert words ending in "y" with a consonant before it (e.g., city) to plurals by replacing "y" with "ies"
  if (word.endsWith('y') && !['a', 'e', 'i', 'o', 'u'].includes(word[word.length - 2])) {
    return word.slice(0, -1) + 'ies';
  }

  // Convert words ending in "s", "ch", "sh", "x", or "z" to plurals by adding "es"
  if (word.endsWith('s') || word.endsWith('ch') || word.endsWith('sh') || word.endsWith('x') || word.endsWith('z')) {
    return word + 'es';
  }

  // For all other cases, simply add "s" to the word
  return word + 's';
};
