export const API_PATH_ApiCreateCompanyDelete = '/services/auth-create-company';

export interface IApiCreateCompanyDeleteBodyRequest {
  companyId: string;
  email: string;
}

export interface IApiCreateCompanyDeleteResponse {
  ok: true;
}
