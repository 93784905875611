import {convertEnumKeyToHumanReadable} from "./convertEnumKeyToHumanReadable";

export const convertEnumToValueLabel = (
  anEnum: any,
  args?: {
    capitalize?: boolean;
    everyWord?: boolean;
  },
): {
  value: string;
  label: string;
}[] => {
  const {
    capitalize = true,
    everyWord = false,
  } = args || {
    capitalize: true,
    everyWord: false,
  };
  return Object.values(anEnum)
    .map((v: string) => {
      return {
        label: capitalize ? convertEnumKeyToHumanReadable(v, everyWord) : v,
        value: v,
      };
    });
};
