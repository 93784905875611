import * as React from "react";

import {Collapse} from "../Collapse";
import {Box} from "../Box";
import {ProgressIcon} from "../ProgressIcon";

import {
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "../FlexContainer";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";
import {
  sxTransition,
  ECSSDuration,
} from "../sxTransition";
import {TBoxSpacing} from "../ui-interfaces";

export interface IIsLoadingBoxProps {
  sx?: SxProps<Theme>;
  spacing?: TBoxSpacing;
  iconSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  collapsible?: boolean;      // Default is true
  animation?: boolean;        // Default is true
  isLoading?: boolean;        // Default is true
  inline?: boolean;           // Default is false
  children?: any;
}

export const IsLoadingBox = (props: IIsLoadingBoxProps): JSX.Element => {
  const {
    sx = {},
    isLoading = true,
    collapsible = true,
    animation = true,
    spacing = 1,
    iconSize = 6,
    children,
    inline = false,
  } = props;

  return (
    <Box
      dataComponentName="IsLoadingBox"
      sx={{
        margin: inline ? undefined : "auto",
        opacity: isLoading ? 1 : 0,
        transition: theme => sxTransition(theme, 'opacity', ECSSDuration.SHORT),
        ...sx,
      }}
      inline={inline}
    >
      <Collapse
        expanded={collapsible ? isLoading : true}
        animation={animation}
      >
        <FlexContainerHorizontal
          spacing={spacing}
          alignVertical="middle"
        >
          <FlexItemMin>
            <ProgressIcon size={iconSize}/>
          </FlexItemMin>
          <FlexItemMax show={!!children}>
            <Box sx={{opacity: 0.7}}>
              {children}
            </Box>
          </FlexItemMax>
        </FlexContainerHorizontal>
      </Collapse>
    </Box>
  );
};
