export const scrollToElement = (
  {
    selector = 'html',
    animation = true,
    position = 'center',
  }: {
    selector?: string;
    animation?: boolean;
    position?:
      | 'start'
      | 'center'
      | 'end';
  },
): void => {
  const element = document.querySelector(selector);
  if (!element) {
    console.error(`scrollToElement: element with selector [${selector}] not found`);
    return;
  }
  element.scrollIntoView({
    block: position,
    inline: position,
    behavior: animation ? 'smooth' : undefined,
  });
};

