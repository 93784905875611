import * as React from "react";

import * as styles from "./Ghost.module.less";

export interface IGhostProps {
  show?: boolean;
  duration?: number;
}

export const Ghost = (props: IGhostProps): JSX.Element | null => {
  const {
    show = true,
    duration = 1000,
  } = props;

  if (!show) return null;

  return (
    <div
      className={styles.ghost}
      style={{animationDuration: `${duration}ms`}}
    />
  );
};
