import {EWorkZonesManagementRights} from "mhc-server";
import {IAppRoute} from "../../application/config/IAppRoute";
import WorkzonesIcon from "@mui/icons-material/CloudQueue";

export const routeWorkZonesManagementPaths: IAppRoute = {
  title: 'CIMs Management',
  icon: <WorkzonesIcon/>,
  description: 'Connected Infrastructure Messages Tool',
  routePath: '/cims-management',
  getRoutePath: () => routeWorkZonesManagementPaths.routePath,
  menuId: 'cims-management',
  userHasAllRights: [],
  userHasAnyOfRights: [
    EWorkZonesManagementRights.WORKZONES_VIEW,
    EWorkZonesManagementRights.WORKZONES_EDIT,
  ],
  render: () => null,
};
