import {
  dynaError,
  IDynaError,
} from "dyna-error";
import {
  encrypt,
  decrypt,
} from "dyna-crypt";

import {TObject} from "../typescript";

interface IWrapper<TData> {
  serializerId: 20240618084844;
  data: TData;
  dataVersion: string;
}

export interface IObjectSerializerConfig<TData extends TObject> {
  data: TData;
  /**
   * On deserializing, if the given payload is of a different version it will fail.
   */
  dataVersion: string;
  /**
   * If provided, the serialized string is encrypted.
   */
  encryptionKey?: string;
}

export class ObjectSerializer<TData extends TObject> {
  public data: TData;

  constructor(private config: IObjectSerializerConfig<TData>) {
    this.data = this.config.data;
  }

  public serialize(): string {
    const wrapped: IWrapper<TData> = {
      serializerId: 20240618084844,
      data: this.data,
      dataVersion: this.config.dataVersion,
    };
    const stringified = JSON.stringify(wrapped);
    return this.config.encryptionKey
      ? enCodeText(encrypt(stringified, this.config.encryptionKey))
      : stringified;
  }

  public deserialize(rawPayload: string): { data: TData; error: null | IDynaError } {
    if (!rawPayload) {
      return {
        data: this.data,
        error: dynaError({
          code: 20240618084349,
          message: 'No payload provided',
        }),
      };
    }

    const payload: string = (() => {
      if (rawPayload.startsWith('{')) return rawPayload;
      const decrypted = decrypt(deCodeText(rawPayload), this.config.encryptionKey) as string;
      if (decrypted === undefined) return "@@WRONG_KEY";
      return decrypted;
    })();

    if (payload === "@@WRONG_KEY") {
      return {
        data: this.data,
        error: dynaError({
          code: 20240618090645,
          message: 'The encrypted payload couldn’t be decrypted because of the wrong key',
        }),
      };
    }

    let parsed: IWrapper<TData> = {} as any;
    try {
      parsed = JSON.parse(payload);
    }
    catch (e) {
      return {
        data: this.data,
        error: dynaError({
          code: 20240618084350,
          message: 'Cannot parse the payload',
          parentError: e,
        }),
      };
    }
    if (typeof parsed !== "object") {
      return {
        data: this.data,
        error: dynaError({
          code: 20240618084351,
          message: 'Parsed result is not an object',
          data: {parsed},
        }),
      };
    }
    if (parsed.serializerId !== 20240618084844) {
      return {
        data: this.data,
        error: dynaError({
          code: 20240618084352,
          message: 'Parsed object is not recognized by the ObjectSerializer, the origin is unknown',
          data: {parsed},
        }),
      };
    }
    if (parsed.dataVersion !== this.config.dataVersion) {
      return {
        data: this.data,
        error: dynaError({
          code: 20240618084353,
          message: 'The data version of the payload is different, so the schema is not supported by the current implementation',
          data: {parsed},
        }),
      };
    }
    this.data = parsed.data;
    return {
      data: parsed.data,
      error: null,
    };
  }
}

const splitter = 'anenel';
const enCodeText = (sourceText: string): string => encodeURIComponent(sourceText).replace(/%/g, splitter);
const deCodeText = (encodedText: string): string => decodeURIComponent(encodedText.replace(new RegExp(splitter, 'g'), '%'));
