import {
  IPreset,
  IPresetHeader,
} from "../../db/presets/PresetEntity.interfaces";

export const API_PATH_ApiAppPresetSearchGet = '/services/app/presets/apiAppPresetSearchGet';

export interface IApiAppPresetSearchGetRequest {
  groupId: string;
  searchText?: string;
  archived?: boolean | "both";
  deleted?: boolean | "both";
  sortByFieldName?: keyof IPreset<any>;
  sortDirection?: 0 | 1 | -1;
  skip: number;
  limit: number;
}

export interface IApiAppPresetSearchGetResponse {
  presetHeaders: IPresetHeader<any>[];
}
