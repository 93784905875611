import {IDevice} from "../../interfaces/IDevice";

export const API_PATH_ApiDeviceMgmtDeviceGet = '/services/devices-mgtm/apiDeviceMgmtDeviceGet';

export interface IApiDeviceMgmtDeviceGetQueryRequest {
  deviceId: string;
}

export interface IApiDeviceMgmtDeviceGetResponse {
  device: IDevice;
  isMaster: boolean;
}
