import {IGeoPosition} from "utils-library/dist/commonJs/geo";

/**
 * Calculate the direction using the Bearing formula
 * @param startPosition
 * @param endPosition
 */
export const getDirectionDegreesByGeoPositions = (startPosition: IGeoPosition, endPosition: IGeoPosition): number => {
  const toRadians = (degrees: number) => degrees * Math.PI / 180;
  const toDegrees = (radians: number) => radians * 180 / Math.PI;

  // Convert latitude and longitude from degrees to radians
  const lat1 = toRadians(startPosition.lat);
  const lat2 = toRadians(endPosition.lat);
  const deltaLng = toRadians(endPosition.lng - startPosition.lng);

  // Calculate the bearing
  const y = Math.sin(deltaLng) * Math.cos(lat2);
  const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(deltaLng);
  let bearing = toDegrees(Math.atan2(y, x));

  // Normalize the bearing to be between 0 and 360 degrees
  bearing = (bearing + 360) % 360;

  return bearing;
};
