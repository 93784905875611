import {IInputMultiSelectOption} from "mhc-ui-components/dist/InputMultiSelect";

import {
  EUserAuthenticationRights,
  EUserAuthenticationRightsArray,
  EWorkZonesManagementRightsArray,
  EDeviceManagementRightsArray,
  EWrongWayResolutionPluginUserRightsArray,
  EAlertsManagementRightsArray,
  ELanePanningRightsArray,
  ETrafficStudiesRightsArray,
} from "mhc-server";

export const getUserRightsOptions = (
  {isSysAdmin = false}: {
    isSysAdmin?: boolean;
  } = {},
): IInputMultiSelectOption[] => {
  return [
    ...EUserAuthenticationRightsArray
      .filter(right => {
        if (isSysAdmin) return true;
        return right.value !== EUserAuthenticationRights.SYSTEM_ADMINISTRATOR;
      }),
    ...EWorkZonesManagementRightsArray,
    ...EDeviceManagementRightsArray,
    ...EWrongWayResolutionPluginUserRightsArray,
    ...EAlertsManagementRightsArray,
    ...ELanePanningRightsArray,
    ...ETrafficStudiesRightsArray,
  ];
};
