import * as React from "react";
import {MapButton} from "../MapButton";

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

export interface IFullScreenButtonProps {
  fullScreen: boolean;
  onChange: (fullScreen: boolean) => void;
}

export const FullScreenButton = (props: IFullScreenButtonProps): JSX.Element => {
  const {
    fullScreen,
    onChange,
  } = props;

  const handleClick = () => onChange(!fullScreen);

  return (
    <MapButton
      icon={fullScreen ? <FullscreenExitIcon/> : <FullscreenIcon/>}
      title="Fullscreen"
      onClick={handleClick}
    />
  );
};
