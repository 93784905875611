import * as React from "react";
import {
  ReactElement,
  forwardRef,
} from "react";

import {dynaSwitchEnum} from "dyna-switch";

import MuiButton from '@mui/material/Button';

import {
  TDataComponentName,
  getDataComponentName,
} from "../ui-interfaces";
import {
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "../FlexContainer";
import {Box} from "../Box";
import {Ghost} from "../Ghost";
import {
  Link,
  ELinkUnderline,
} from "../Link";
import {IIconComponent} from "../IconComponent";
import {IconViewer} from "../IconViewer";

import {useBreakpointDevice} from "../useBreakpointDevice";

import {
  ECSSDuration,
  sxTransition,
} from "../sxTransition";
import {
  useTheme,
  SxProps,
  Theme,
} from "../ThemeProvider";
import {convertSizeToPx} from "../utils";

export interface IButtonProps {
  dataComponentName?: TDataComponentName;

  show?: boolean;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;

  display?: EButtonDisplay;           // Default is EButtonDisplay.INLINE
  variant?: EButtonVariant;
  ghost?: boolean;
  color?: EButtonColor;
  size?: EButtonSize;                 // Default is EButtonSize.MEDIUM
  minWidth?: number;
  padding?: 0 | 0.5 | 1 | 2 | 3 | 4;  // Apply padding, set to zero for custom children
  noFontSize?: boolean;               // Ideal for Icon Buttons without paddings created by font siez
  type?: "button" | "submit" | "reset";
  title?: string;
  description?: string | ReactElement;

  /**
   * @deprecated Use the Icon (starting with capital) instead
   */
  icon?: ReactElement;                // The icon of the button OR
  Icon?: IIconComponent;              // Material UI icon, Only one of two can be used
  iconPosition?: EButtonIconPosition; // Default is EButtonIconPosition.LEFT

  fontFamilyInherit?: boolean;        // Default is false, this is for title and description
  fontWeightBold?: boolean;           // Default is true, this is for Title only (not for description)
  labelFontSize?: number | string;          // In spacing/px. Applied to the children (the label)
  descriptionFontSize?: number | string;    // In spacing/px. Applied on the description
  textAlign?: EButtonTextAlign;
  upperCase?: boolean;

  disabled?: boolean;
  hidden?: boolean;
  autoFocus?: boolean;

  ariaLabel?: string;                 // Todo: this should be required

  children?: any;                     // The text label of the button
  wrap?: boolean;                     // Default is false

  hideLabelOnMobile?: boolean;
  hideLabelOnTablet?: boolean;

  href?: string;              // For Link Button for React Router link, use the ButtonRouterLink instead
  hrefNewWindow?: boolean;    // Open href on new window/tab

  onClick?: (event: React.MouseEvent) => void;
}

export enum EButtonVariant {
  OUTLINED = "outlined",
  CONTAINED = "contained",
  TRANSPARENT = "TRANSPARENT",
}

export enum EButtonDisplay {
  INLINE = "INLINE",
  BLOCK = "BLOCK",
}

export enum EButtonIconPosition {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum EButtonTextAlign {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export enum EButtonSize {
  XXXSMALL = "XXXSMALL",
  XXSMALL = "XXSMALL",
  XSMALL = "XSMALL",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  XLARGE = "XLARGE",
  XXLARGE = "XXLARGE",
}

export enum EButtonColor {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  INFO = "INFO",
  WARNING = "WARNING",
  WHITE = "WHITE",
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>((props, ref): ReactElement | null => {
  const {
    dataComponentName,
    show = true,
    sx: userSx,
    fullWidth = false,
    display = EButtonDisplay.INLINE,
    variant = EButtonVariant.CONTAINED,
    ghost = false,
    color = EButtonColor.PRIMARY,
    fontFamilyInherit = false,
    fontWeightBold = true,
    labelFontSize,
    descriptionFontSize,
    textAlign = EButtonTextAlign.CENTER,
    size = EButtonSize.MEDIUM,
    minWidth,
    padding,
    noFontSize = false,
    upperCase = false,
    disabled = false,
    icon,
    Icon,
    iconPosition = EButtonIconPosition.LEFT,
    type,
    title,
    description,
    hidden = false,
    autoFocus,
    ariaLabel,
    children,
    wrap = false,
    hideLabelOnMobile = false,
    hideLabelOnTablet = false,
    href,
    hrefNewWindow = false,
    onClick,
  } = props;

  const theme = useTheme();
  const {
    isMobile,
    isTablet,
  } = useBreakpointDevice();

  if (!show || hidden) return null;

  const showChildrenDescription: boolean =
    (
      Array.isArray(children)
        ? children.filter(Boolean).length > 0
        : !!children
    )
    && (!isMobile || !hideLabelOnMobile)
    && (!isTablet || !hideLabelOnTablet)
  ;

  const actualSize: number = parseFloat(dynaSwitchEnum<EButtonSize, string>(
    size,
    {
      [EButtonSize.XXXSMALL]: theme.spacing(1),
      [EButtonSize.XXSMALL]: theme.spacing(1.25),
      [EButtonSize.XSMALL]: theme.spacing(1.5),
      [EButtonSize.SMALL]: theme.spacing(1.75),
      [EButtonSize.MEDIUM]: theme.spacing(1.9),
      [EButtonSize.LARGE]: theme.spacing(2.5),
      [EButtonSize.XLARGE]: theme.spacing(3),
      [EButtonSize.XXLARGE]: theme.spacing(4),
    },
  ));

  const sx: SxProps<Theme> = {
    display:
      dynaSwitchEnum<EButtonDisplay, 'block' | undefined>(
        display,
        {
          [EButtonDisplay.INLINE]: undefined,
          [EButtonDisplay.BLOCK]: 'block',
        },
      ),

    position: 'relative',

    width: fullWidth ? '100%' : undefined,

    color: (() => {
      if (disabled) return theme.palette.grayShades.gray8;
      if (color === EButtonColor.WHITE) {
        return dynaSwitchEnum<EButtonVariant, string>(
          variant,
          {
            [EButtonVariant.OUTLINED]: theme.palette.primary.main,
            [EButtonVariant.CONTAINED]: theme.palette.grey["900"],
            [EButtonVariant.TRANSPARENT]: theme.palette.primary.dark,
          },
        );
      }
      return undefined;
    })(),

    backgroundColor:
      (() => {
        if (disabled) return theme.palette.grayShades.gray4;
        if (color === EButtonColor.WHITE && variant === EButtonVariant.CONTAINED) return theme.palette.grey["400"];
        return undefined;
      })(),

    '&: hover': {
      backgroundColor:
        (() => {
          if (color === EButtonColor.WHITE && variant === EButtonVariant.CONTAINED) return theme.palette.grey["200"];
          return undefined;
        })(),
      borderColor:
        (() => {
          if (color === EButtonColor.WHITE && variant === EButtonVariant.OUTLINED) return theme.palette.grey["200"];
          return undefined;
        })(),
    },

    borderRadius:
      variant === EButtonVariant.TRANSPARENT
        ? 0
        : undefined,

    whiteSpace:
      wrap
        ? 'normal'
        : 'nowrap',

    px:
      (() => {
        if (padding !== undefined) return padding;
        return actualSize + 'px';
      })(),
    py:
      (() => {
        if (padding !== undefined) return padding;
        return (actualSize / 2) + 'px';
      })(),

    fontFamily:
      fontFamilyInherit
        ? 'inherit !important'
        : undefined,

    fontWeight:
      fontWeightBold
        ? 'bold !important'
        : 'normal !important',

    justifyContent:
      dynaSwitchEnum<EButtonTextAlign, string>(
        textAlign,
        {
          [EButtonTextAlign.LEFT]: 'flex-start',
          [EButtonTextAlign.CENTER]: 'center',
          [EButtonTextAlign.RIGHT]: 'flex-end',
        },
      ),

    minWidth:
      showChildrenDescription
        ? minWidth
        : 0,

    textTransform:
      upperCase
        ? 'uppercase'
        : 'none',

    opacity: disabled ? 0.75 : undefined,

    transition: theme => sxTransition(
      theme,
      [
        'color',
        "background",
        "background-color",
        "opacity",
      ],
      ECSSDuration.SHORT,
    ),

    '& > div': {
      textAlign:
        dynaSwitchEnum<EButtonTextAlign, 'left' | 'right' | 'center'>(
          textAlign,
          {
            [EButtonTextAlign.LEFT]: 'left',
            [EButtonTextAlign.CENTER]: 'center',
            [EButtonTextAlign.RIGHT]: 'right',
          },
        ),
    },

    ...userSx,
  };

  const style: React.CSSProperties = {
    // Style overrides MUI's base attrs easily, while sx not
    fontSize:
      noFontSize || !showChildrenDescription
        ? 0
        : undefined,
    textTransform:
      upperCase
        ? 'uppercase'
        : 'none',
    lineHeight: "initial",
    pointerEvents: disabled ? 'none' : undefined,
  };

  const applyButtonVariant: 'text' | 'outlined' | 'contained' = (() => {
    if (variant === EButtonVariant.TRANSPARENT) return "text";
    return variant;
  })();

  const applyButtonColor = dynaSwitchEnum<EButtonColor, 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined>(
    color,
    {
      [EButtonColor.PRIMARY]: 'primary',
      [EButtonColor.SECONDARY]: 'secondary',
      [EButtonColor.SUCCESS]: 'success',
      [EButtonColor.ERROR]: 'error',
      [EButtonColor.INFO]: 'info',
      [EButtonColor.WARNING]: 'warning',
      [EButtonColor.WHITE]: undefined,
    },
  );

  const iconContent = (
    <>
      <Box
        // Old image support (JSX.Element/ReactElements)
        show={!!icon}
        dataComponentName="OLDIconContainer"
        component={showChildrenDescription ? "span" : "div"}
        sx={{
          'svg': {
            width: (actualSize * 2) + 'px',
            height: (actualSize * 2) + 'px',
          },
        }}
        style={{lineHeight: 0}}
      >
        {icon}
      </Box>
      <IconViewer
        Icon={Icon}
        height={actualSize * 2}
      />
    </>
  );

  const handleClick = (event: React.MouseEvent): void => {
    if (disabled) return;
    onClick?.(event);
  };

  const button = (
    <MuiButton
      data-component-name={getDataComponentName(dataComponentName, "Button")}
      sx={sx}
      ref={ref}
      style={style}
      variant={applyButtonVariant}
      color={applyButtonColor}
      size={dynaSwitchEnum<EButtonSize, 'small' | 'medium' | 'large'>(
        size,
        {
          [EButtonSize.XXXSMALL]: 'small',
          [EButtonSize.XXSMALL]: 'small',
          [EButtonSize.XSMALL]: 'small',
          [EButtonSize.SMALL]: 'small',
          [EButtonSize.MEDIUM]: 'medium',
          [EButtonSize.LARGE]: 'large',
          [EButtonSize.XLARGE]: 'large',
          [EButtonSize.XXLARGE]: 'large',
        },
      )}
      autoFocus={autoFocus}
      type={type}
      title={title}
      aria-label={ariaLabel}
      onClick={handleClick}
    >
      <Ghost show={ghost}/>
      <FlexContainerHorizontal
        dataComponentName="ButtonContent"
        alignVertical={description ? "top" : "middle"}
        sx={{width: fullWidth ? '100%' : undefined}}
      >
        <FlexItemMin
          sx={{
            display: 'inherit',
            marginRight:
              showChildrenDescription
                ? (actualSize / 2) + 'px'
                : undefined,
          }}
          show={(!!icon || !!Icon) && iconPosition === EButtonIconPosition.LEFT}
        >
          {iconContent}
        </FlexItemMin>
        <FlexItemMax
          sx={{textAlign: fullWidth || description ? 'left' : 'undefined'}}
          show={showChildrenDescription}
        >
          <Box
            lowLevelClassName="button-label"
            sx={{
              fontSize:
              theme =>
                labelFontSize
                  ? convertSizeToPx(theme, labelFontSize)
                  : actualSize + 'px',
            }}
          >
            {children}
          </Box>
          <Box
            show={!!description}
            lowLevelClassName="button-description"
            sx={{
              fontSize: theme => convertSizeToPx(theme, descriptionFontSize || theme.typography.fontSize),
              fontWeight: 'normal',
              whiteSpace: 'pre-wrap',
              lineHeight: 1,
              marginBottom: theme => theme.spacing(0.5),
            }}
          >
            {description}
          </Box>
        </FlexItemMax>
        <FlexItemMin
          sx={{display: 'inherit'}}
          show={(!!icon || !!Icon) && iconPosition === EButtonIconPosition.RIGHT}
        >
          {iconContent}
        </FlexItemMin>
      </FlexContainerHorizontal>
    </MuiButton>
  );

  return href
    ? (
      <Link
        href={href}
        underline={ELinkUnderline.NONE}
        target={hrefNewWindow ? '_blank' : undefined} rel="noreferrer"
        sx={{textDecoration: 'none'}}
      >
        {button}
      </Link>
    )
    : button;
});
