import {EAlertHandlingUserFlag} from "../../interfaces/IAlertHandling";

export const API_PATH_ApiAlertMgtmAlertHandlingUserDataPut = '/services/alerts-mgtm/apiAlertMgtmAlertHandlingUserDataPut';

export interface IApiAlertMgtmAlertHandlingUserDataPutRequest {
  handlingId: string;
  userTitle?: string;
  userLabelText?: string;
  userLabelColor?: string;
  userFlag?: EAlertHandlingUserFlag;
}

export interface IApiAlertMgtmAlertHandlingUserDataPutResponse {
  ok: true;
}
