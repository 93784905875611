import * as React from "react";
import {dynaSwitch} from "dyna-switch";

import {
  EOpenMode,
  IMenuItemDivider,
} from "../../interfaces";

import {
  FlexContainerHorizontal,
  FlexContainerVertical,
  FlexItemMin,
  FlexItemMax,
} from "../../../FlexContainer";

import {CenterContainer} from "../../../CenterContainer";
import {Box} from "../../../Box";

import {BreakpointDeviceContainer} from "../../../BreakpointDeviceContainer";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import {
  Button,
  EButtonVariant,
  EButtonColor,
  EButtonTextAlign,
} from "../../../Button";
import {IconButton} from "../../../IconButton";
import {AppLogo} from "../AppLogo/AppLogo";

import {useBreakpointDevice} from "../../../useBreakpointDevice";

import {sxTransitionShowHide} from "../../../sxTransition";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from '@mui/icons-material/Close';

import {useTheme} from "../../../ThemeProvider";

const DEFAULT_EXPANDED_WIDTH = 256 + 42;
const MINIMIZED_WIDTH = 64;

export interface IAsideMenuProps {
  logoSquareImage?: string;
  logoLandscapeImage?: string;          // Ratio max width: 200px height: 56px

  selectedMenuId?: string;

  openMode: EOpenMode;

  menuItemsTop?: IMenuItemDivider[];
  menuItems: IMenuItemDivider[];
  menuItemsBottom?: IMenuItemDivider[];

  menuFullScreenOnMobile: boolean;
  menuFullScreenOnTablet: boolean;

  bottomAppInfo?: React.ReactNode;

  showMinimizedOption?: boolean;

  onAsideMenuOpenChange: (openMode: EOpenMode) => void;
  onDrawerClose: () => void;
  onLogoClick?: () => void;
  onToggleClick: () => void;
  onClick: () => void;
}

export const AsideMenu = (props: IAsideMenuProps): JSX.Element => {
  const theme = useTheme();
  const {
    logoSquareImage,
    logoLandscapeImage,
    openMode,
    menuItemsTop = [],
    menuItems,
    menuItemsBottom = [],
    menuFullScreenOnMobile,
    menuFullScreenOnTablet,
    showMinimizedOption,
    bottomAppInfo,
    onLogoClick,
    onToggleClick,
    onDrawerClose,
    onClick,
  } = props;

  const isOpen = openMode !== EOpenMode.COLLAPSED;
  const {
    isMobile,
    isTablet,
  } = useBreakpointDevice();

  const EXPANDED_WIDTH =
    isMobile || isTablet
      ? window.innerWidth
      : DEFAULT_EXPANDED_WIDTH;

  const renderMenuItems = (menuItems: IMenuItemDivider[]): JSX.Element | null => {
    if (!menuItems.length) return null;
    const output: (JSX.Element | null)[] = [];
    let index = 0;
    let level = 0;

    const processMenuItem = (menuItem: IMenuItemDivider): void => {
      output.push(renderMenuItem(menuItem, index++, level));
      if (menuItem !== 'DIVIDER' && menuItem.children) {
        level++;
        menuItem.children.forEach(processMenuItem);
        level--;
      }
    };

    menuItems
      .forEach(processMenuItem);

    return (
      <List>
        {output}
      </List>
    );
  };

  const renderMenuItem = (menuItem: IMenuItemDivider, index: number, level: number): JSX.Element | null => {
    if (menuItem === 'DIVIDER') {
      const leftRightMargin = dynaSwitch<number, EOpenMode>(
        openMode,
        0,
        {
          [EOpenMode.EXPANDED]: level * 2,
          [EOpenMode.MINIMIZED]: 0,
          [EOpenMode.COLLAPSED]: 0,
        },
      );
      return (
        <Divider
          key={index}
          sx={{
            marginLeft: leftRightMargin,
            marginRight: leftRightMargin,
            transition: sxTransitionShowHide(theme, ['margin-left', 'margin-right'], isOpen),
          }}
        />
      );
    }

    const {selectedMenuId} = props;
    const selected = !!menuItem.menuId && !!selectedMenuId && menuItem.menuId === selectedMenuId;

    if (menuItem.hidden) return null;

    const showDescription =
      !!menuItem.description
      && openMode === EOpenMode.EXPANDED;

    return (
      <ListItem
        key={index}
        button
        disabled={menuItem.disabled}
        selected={selected}
        sx={{
          overflow: 'hidden',
          paddingLeft: dynaSwitch<number, EOpenMode>(
            openMode,
            0,
            {
              [EOpenMode.EXPANDED]: 2 + (level * 2),
              [EOpenMode.MINIMIZED]: 2,
              [EOpenMode.COLLAPSED]: 2,
            },
          ),
          transition: sxTransitionShowHide(theme, 'padding-left', isOpen),
        }}
        onClick={() => menuItem.onClick && menuItem.onClick()}
      >
        <ListItemIcon
          title={menuItem.title}
          sx={{
            '& > svg': {
              width: theme => theme.typography.fontSize * 2,
              height: theme => theme.typography.fontSize * 2,
            },
          }}
        >
          {menuItem.icon || <FiberManualRecordIcon/>}
        </ListItemIcon>
        <ListItemText
          sx={{whiteSpace: 'break-spaces'}}
          primary={menuItem.title}
          secondary={showDescription ? menuItem.description : undefined}
        />
      </ListItem>
    );
  };

  return (
    <FlexContainerVertical
      dataComponentName="AsideMenu"
      fullHeight
      sx={{
        backgroundColor: theme.palette.background.paper,
        width: dynaSwitch<number, EOpenMode>(
          openMode,
          0,
          {
            [EOpenMode.EXPANDED]: EXPANDED_WIDTH,
            [EOpenMode.MINIMIZED]: MINIMIZED_WIDTH,
            [EOpenMode.COLLAPSED]: 0,

          },
        ),
        overflow: 'hidden',
        transition: sxTransitionShowHide(theme, 'width', isOpen),
      }}
      onClick={onClick}
    >
      <FlexItemMin>
        <FlexContainerHorizontal>
          <FlexItemMax>
            <Button
              padding={0}
              noFontSize
              textAlign={EButtonTextAlign.LEFT}
              variant={EButtonVariant.TRANSPARENT}
              onClick={onLogoClick}
            >
              <AppLogo
                squareLogoImage={logoSquareImage}
                landscapeLogoImage={logoLandscapeImage}
              />
            </Button>
          </FlexItemMax>
          <FlexItemMin fullHeight>
            <CenterContainer>
              <IconButton
                ariaLabel="Close menu"
                icon={theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                onClick={onDrawerClose}
              />
            </CenterContainer>
          </FlexItemMin>
        </FlexContainerHorizontal>
      </FlexItemMin>

      <FlexItemMin>
        {renderMenuItems(menuItemsTop)}
      </FlexItemMin>

      <FlexItemMax overFlowY>
        {renderMenuItems(menuItems)}
      </FlexItemMax>

      <FlexItemMin>
        {renderMenuItems(menuItemsBottom)}
      </FlexItemMin>

      <FlexItemMin show={showMinimizedOption}>
        <BreakpointDeviceContainer
          allExcept
          mobile={menuFullScreenOnMobile}
          tablet={menuFullScreenOnTablet}
        >
          {renderMenuItems([{
            icon: dynaSwitch<JSX.Element | null, EOpenMode>(
              openMode,
              null,
              {
                [EOpenMode.EXPANDED]: <ChevronLeftIcon/>,
                [EOpenMode.MINIMIZED]: <ChevronRightIcon/>,
                [EOpenMode.COLLAPSED]: <ChevronLeftIcon/>,
              },
            ),
            title: dynaSwitch<string, EOpenMode>(
              openMode,
              '',
              {
                [EOpenMode.EXPANDED]: 'Minimize',
                [EOpenMode.MINIMIZED]: 'Expand',
                [EOpenMode.COLLAPSED]: 'Minimize',
              },
            ),
            onClick: onToggleClick,
          }])}
        </BreakpointDeviceContainer>
      </FlexItemMin>

      <FlexItemMin>
        <BreakpointDeviceContainer
          mobile={menuFullScreenOnMobile}
          tablet={menuFullScreenOnTablet}
        >
          <Button
            variant={EButtonVariant.OUTLINED}
            icon={<CloseIcon/>}
            color={EButtonColor.ERROR}
            sx={{
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(1),
              width: `calc(100% - ${theme.spacing(2)})`,
            }}
          >
            Close
          </Button>
        </BreakpointDeviceContainer>
      </FlexItemMin>

      <FlexItemMin
        show={!!bottomAppInfo}
        sx={{
          width: EXPANDED_WIDTH,
          opacity: openMode === EOpenMode.EXPANDED ? 1 : 0,
          transition: sxTransitionShowHide(theme, "opacity", isOpen),
        }}
      >
        <Box sx={{margin: 1}}>
          {bottomAppInfo}
        </Box>
      </FlexItemMin>
    </FlexContainerVertical>
  );
};
