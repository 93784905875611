import * as React from "react";

import {
  TDataComponentName,
  getDataComponentName,
} from "../ui-interfaces";
import {
  FlexContainer,
  EFlexContainerOrientation,
} from "./FlexContainer";
import {TBoxSpacing} from "../ui-interfaces";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";

export interface IFlexContainerHorizontalProps {
  sx?: SxProps<Theme>;
  show?: boolean;
  inline?: boolean;
  dataComponentName?: TDataComponentName;

  spacing?: TBoxSpacing;

  alignHorizontal?: 'left' | 'center' | 'right';
  alignHorizontalSpaceBetween?: boolean;
  alignVertical?: 'top' | 'middle' | 'bottom';

  reverseOrder?: boolean;

  fullHeight?: boolean;

  title?: string;
  children: any;

  onClick?: (e: React.MouseEvent) => void;
  onMouseMove?: (event: React.MouseEvent) => void;
  onMouseDown?: (event: React.MouseEvent) => void;
  onMouseUp?: (event: React.MouseEvent) => void;
  onMouseEnter?: (event: React.MouseEvent) => void;
  onMouseLeave?: (event: React.MouseEvent) => void;
  onScroll?: (event: React.UIEvent) => void;
  onWheel?: (event: React.WheelEvent<HTMLDivElement>) => void;
}

export const FlexContainerHorizontal = (props: IFlexContainerHorizontalProps): JSX.Element => {
  const {
    sx = {},
    show = true,
    inline = false,
    dataComponentName,
    spacing,

    alignHorizontal,
    alignHorizontalSpaceBetween,
    alignVertical,

    reverseOrder,
    fullHeight,

    title,
    children,

    onClick,
    onMouseMove,
    onMouseDown,
    onMouseUp,
    onMouseEnter,
    onMouseLeave,
    onScroll,
    onWheel,
  } = props;
  return (
    <FlexContainer
      sx={sx}
      show={show}
      inline={inline}
      dataComponentName={getDataComponentName(dataComponentName, 'FlexContainerHorizontal')}
      orientation={EFlexContainerOrientation.HORIZONTAL}
      title={title}

      spacing={spacing}

      justifyStart={alignHorizontal === 'left'}
      justifyCenter={alignHorizontal === 'center'}
      justifyEnd={alignHorizontal === 'right'}
      justifySpaceBetween={alignHorizontalSpaceBetween}

      alignStart={alignVertical === 'top'}
      alignCenter={alignVertical === 'middle'}
      alignEnd={alignVertical === 'bottom'}

      fullHeight={fullHeight}
      reverseOrder={reverseOrder}

      children={children}

      onClick={onClick}
      onMouseMove={onMouseMove}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onScroll={onScroll}
      onWheel={onWheel}
    />
  );
};
