import {EAlertsManagementRights} from "mhc-server";

import {IAppRoute} from "../../application/config/IAppRoute";

import {AlertMgtmTestPage} from "../pages/AlertMgtmTestPage";

export const routeAlertMgtmTestPage: IAppRoute = {
  menuId: '202301031744-894624',
  title: 'Alerts Mgtm - Test page',
  routePath: '/alerts-management/test-page',
  getRoutePath: () => routeAlertMgtmTestPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [EAlertsManagementRights.ALERTS_TEST_PAGE],
  render: ()=> <AlertMgtmTestPage/>,
};
