import * as moment from "moment";

import {EDeviceType} from "../../device-management/interfaces/EDeviceType";

import {
  IDBEntityBase,
  getDefaultDBEntityDocument,
} from "utils-library/dist/commonJs/db-entity-interfaces";

export interface ITrafficStudy extends IDBEntityBase {
  deviceId: string;
  deviceType: EDeviceType;

  label: string;

  start: number;    // This is timestamp
  end: number;      // This is Timestamp

  binningPeriodInMinutes: 5 | 10 | 15 | 30 | 60;
  timezone: string;

  location: string;
  stateId: string;
  city: string;
  street: string;
  county: string;
  lane: string;

  speedLimitInMph: number;
  operator: string;

  locked: boolean;
  lockedReason: string;

  deviceConfig: any;

  copiedFromStudyId: string;
  trafficDataCollectedAt: number;
  lastPublishedAt: number;

  notes: string;
}

export const getDefaultTrafficStudy = () => getDefaultDBEntityDocument<ITrafficStudy>({
  deviceId: '',
  deviceType: EDeviceType.UNKNOWN,
  label: '',
  start: Date.now(),
  end: moment().add(1, 'week')
    .valueOf(),
  binningPeriodInMinutes: 5,
  timezone: '',
  location: '',
  stateId: '',
  city: '',
  street: '',
  county: '',
  lane: '',
  speedLimitInMph: 0,
  operator: '',
  locked: false,
  lockedReason: '',
  deviceConfig: {},
  copiedFromStudyId: '',
  trafficDataCollectedAt: 0,
  lastPublishedAt: 0,
  notes: '',
});
