import * as React from "react";
import {
  useState,
  useEffect,
} from "react";

import {IAppContainerDashboardProps} from "./interfaces";
import {EOpenMode} from "./interfaces";

import Box from "@mui/material/Box/Box";

import {
  FlexContainerVertical,
  FlexContainerHorizontal,
  FlexItemMax,
  FlexItemMin,
} from "../FlexContainer";

import {BreakpointDeviceContainer} from "../BreakpointDeviceContainer";
import {useBreakpointDevice} from "../useBreakpointDevice";

import {AppHeader} from "./components/AppHeader/AppHeader";
import {AsideMenu} from "./components/AsideMenu/AsideMenu";

import {useTheme} from "../ThemeProvider";

const LS_KEY = 'AppContainerDashboard-open-status';

export const AppContainerDashboard = (props: IAppContainerDashboardProps): JSX.Element => {
  const {
    logoSquareImage,
    logoLandscapeImage,

    appTitle,
    appTitleHelper,
    appTitleContent,

    notificationIcons,
    profileUserName,
    profileIcon,
    profileOptions,

    selectedMenuId,
    showMinimizedOption,

    menuItemsTop,
    menuItems,
    menuItemsBottom,

    menuFullScreenOnMobile = true,
    menuFullScreenOnTablet = false,

    bottomAppInfo,

    children,

    onLogoClick,
    onExpand,
    onMinimize,
    onCollapse,
  } = props;

  const theme = useTheme();
  const {
    isMobile,
    isTablet,
  } = useBreakpointDevice();
  const isSmallDevixw = isMobile || isTablet;

  const [openMode, _setOpenMode] =
    useState<EOpenMode>(() => {
      if (isSmallDevixw) return EOpenMode.COLLAPSED;
      return (
          localStorage.getItem(LS_KEY) as any
          || EOpenMode.COLLAPSED
      );
    },
    );

  useEffect(() => {
    if (!isSmallDevixw) return;
    if (openMode === EOpenMode.EXPANDED) setOpenMode(EOpenMode.COLLAPSED);
    if (openMode === EOpenMode.MINIMIZED) _setOpenMode(EOpenMode.COLLAPSED);
  }, [isSmallDevixw]);

  const setOpenMode = (openMode: EOpenMode): void => {
    _setOpenMode(openMode);
    if (isSmallDevixw) return;
    localStorage.setItem(LS_KEY, openMode);
  };

  const handleAsideMenuOpenModeChange = (openMode: EOpenMode): void => {
    setOpenMode(openMode);
  };

  const handleDrawerOpen = (): void => {
    setOpenMode(EOpenMode.EXPANDED);
    onExpand && onExpand();
  };

  const handleDrawerMinimize = (): void => {
    setOpenMode(EOpenMode.MINIMIZED);
    onMinimize && onMinimize();
  };

  const handleDrawerClose = (): void => {
    setOpenMode(EOpenMode.COLLAPSED);
    onCollapse && onCollapse();
  };

  const handleToggleClick = (): void => {
    switch (openMode) {
      case EOpenMode.EXPANDED:
        isSmallDevixw
          ? handleDrawerClose()
          : handleDrawerMinimize();
        break;
      case EOpenMode.MINIMIZED:
        handleDrawerOpen();
    }
  };

  const handleMobileClose = (): void => {
    if (!isSmallDevixw) return;
    handleDrawerClose();
  };

  const asideMenu = (
    <AsideMenu
      logoSquareImage={logoSquareImage}
      logoLandscapeImage={logoLandscapeImage}

      selectedMenuId={selectedMenuId}

      openMode={openMode}

      menuItemsTop={menuItemsTop}
      menuItems={menuItems}
      menuItemsBottom={menuItemsBottom}

      menuFullScreenOnMobile={menuFullScreenOnMobile}
      menuFullScreenOnTablet={menuFullScreenOnTablet}

      bottomAppInfo={bottomAppInfo}

      showMinimizedOption={showMinimizedOption}

      onLogoClick={onLogoClick}
      onDrawerClose={handleDrawerClose}
      onAsideMenuOpenChange={handleAsideMenuOpenModeChange}
      onToggleClick={handleToggleClick}
      onClick={handleMobileClose}
    />
  );

  return (
    <FlexContainerHorizontal
      dataComponentName="AppContainerDashboard"
      fullHeight
      sx={{position: 'relative'}}
    >
      <BreakpointDeviceContainer
        mobile={menuFullScreenOnMobile}
        tablet={menuFullScreenOnTablet}
      >
        <Box
          sx={{
            position: 'absolute',
            zIndex: 3000,
            top:0,
            left: 0,
            bottom: 0,
            background: theme.palette.background.default,
          }}
        >
          {asideMenu}
        </Box>
      </BreakpointDeviceContainer>
      <BreakpointDeviceContainer
        allExcept
        mobile={menuFullScreenOnMobile}
        tablet={menuFullScreenOnTablet}
      >
        <FlexItemMin>
          {asideMenu}
        </FlexItemMin>
      </BreakpointDeviceContainer>
      <FlexItemMax>
        <FlexContainerVertical fullHeight>
          <FlexItemMin>
            <AppHeader
              appTitle={appTitle}
              appTitleHelper={appTitleHelper}
              appTitleContent={appTitleContent}

              openMode={openMode}

              notificationIcons={notificationIcons}
              profileUserName={profileUserName}
              profileIcon={profileIcon}
              profileOptions={profileOptions}

              onAsideMenuOpenChange={handleAsideMenuOpenModeChange}
            />
          </FlexItemMin>
          <FlexItemMax overFlowY>
            {children}
          </FlexItemMax>
        </FlexContainerVertical>

      </FlexItemMax>
    </FlexContainerHorizontal>
  );
};
