import {ILocation} from "../../interfaces/ILocation";

export const API_PATH_ApiDeviceMgmtLocationGet = '/services/devices-mgtm/apiDeviceMgmtLocationGet';

export interface IApiDeviceMgmtLocationGetQueryRequest {
  locationId: string;
}

export interface IApiDeviceMgmtLocationGetResponse {
  location: ILocation;
}
