import {ILocation} from "../../interfaces/ILocation";

export const API_PATH_ApiDeviceMgmtLocationsGet = '/services/devices-mgtm/apiDeviceMgmtLocationsGet';

export interface IApiDeviceMgmtLocationsGetQueryRequest {
  regionId: string;
  search: string;
  archived: "true" | "false";
  deleted: "true" | "false";
}

export interface IApiDeviceMgmtLocationsGetResponse {
  locations: ILocation[];
}
