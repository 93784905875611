export const API_PATH_ApiAppPresetDelete = '/services/app/presets/apiAppPresetDelete';

export interface IApiAppPresetDeleteRequest {
  id: string;
  deleteIt: boolean;
}

export interface IApiAppPresetDeleteResponse {
  ok: true;
}
