import * as React from 'react';
import {
  useRef,
  useMemo,
  useContext,
  RefObject,
  forwardRef,
  useImperativeHandle,
} from "react";

import {Rnd} from 'react-rnd';
import {
  DraggableData,
  DraggableEvent,
} from "react-draggable";

import {guid} from 'dyna-guid';

import {value} from "utils-library/dist/commonJs/utils";

import {Box} from "../Box";
import {IconViewer} from "../IconViewer";
import {Paper} from "../Paper";
import {
  FlexContainerHorizontal,
  FlexItemMax,
  FlexItemMin,
} from "../FlexContainer";
import {IIconComponent} from '../IconComponent';
import {
  IconButton,
  EIconButtonSize,
} from "../IconButton";
import {ContainerEllipsis} from "../ContainerEllipsis";

import {WindowManagerContext} from "./WindowManager";

import {useTheme} from "../ThemeProvider";
import {createIcon} from "../IconComponent";
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/CloseFullscreen';
import RestoreIcon from '@mui/icons-material/OpenInFull';


export interface IWindowProps {
  ref?: RefObject<IWindowRef>;

  show?: boolean;
  Icon?: IIconComponent;
  title: string;

  // Default postion and size
  initial?: {
    top?: number;
    left?: number;
    width?: number;     // If there is no width or height specified, the size will be determined by the content of the children.
    height?: number;
  };

  // Resize options
  resizable?: boolean;
  minWidth?: number;    // If there is no width or height specified, the size will be determined by the content of the children.
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;

  // Move options
  movable?: boolean;

  // Bounds (for move and resize)
  bounds?:
    | "parent" // Should have `position: relative/absolute/fixed/sticky`
    | "window"
    | "body"
    | string    // Selector for example: #my-container
    | Element;

  // Minimize
  minimizable?: boolean;

  buttons?: {
    Icon: IIconComponent;
    disabled?: boolean;
    hidden?: boolean;
    title?: string;
    ariaLabel: string;
    onClick: () => void;
  } [];

  children: any;

  onDrag?: (event: DraggableEvent, draggableData: DraggableData) => void;
  onDragStart?: (event: DraggableEvent, draggableData: DraggableData) => void;
  onDragEnd?: (event: DraggableEvent, draggableData: DraggableData) => void;
  onClose?: () => void;
}

export interface IWindowRef {
  handleResizeHeightToContent: () => void;
}

export const Window = forwardRef<IWindowRef, IWindowProps>((props, ref): JSX.Element | null => {
  const {
    show = true,
    Icon,
    title,

    initial,

    resizable = false,
    minWidth = 128,
    minHeight = 104,
    maxWidth,
    maxHeight,

    movable = false,

    minimizable = false,

    bounds = "window",

    buttons = [],

    children,

    onDrag,
    onDragStart,
    onDragEnd,
    onClose,
  } = props;
  const theme = useTheme();

  const headerHeight = 40;
  const windowId = useMemo(() => 'window-id--' + guid(1).replace(/-/g, ''), []);

  const refRnd = useRef<Rnd>(null);
  const {
    activeWindowId, setActiveWindowId,
  } = useContext(WindowManagerContext);
  const refChildren = useRef<HTMLDivElement>(null);
  const [isMinimized, setIsMinimized] = React.useState(false);

  useImperativeHandle(ref, () => ({
    handleResizeHeightToContent: () => {
      if (!refRnd.current) return;
      if (!refChildren.current) return;
      refRnd.current.updateSize({
        width: refChildren.current.offsetWidth,
        height: refChildren.current.offsetHeight + headerHeight,
      });
    },
  }));

  if (!show) return null;

  const handleClick = () => setActiveWindowId(windowId);
  const handleMinimize = () => setIsMinimized(!isMinimized);

  const titleFontSize = theme.typography.fontSize * 1.1;

  return (
    <Rnd
      ref={refRnd}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: "hidden",
        zIndex: activeWindowId === windowId ? 1 : 0,
      }}
      minWidth={minWidth}
      minHeight={isMinimized ? headerHeight + 2 : minHeight}
      maxWidth={maxWidth}
      maxHeight={isMinimized ? headerHeight + 2 : maxHeight}
      default={{
        x: value<number>(initial?.left, 0),
        y: value<number>(initial?.top, 0),
        width: value<string | number>(initial?.width, 'auto'),
        height: value<string | number>(initial?.height, 'auto'),
      }}
      enableResizing={{
        bottom: resizable && !isMinimized,
        bottomLeft: resizable && !isMinimized,
        bottomRight: resizable && !isMinimized,
        left: resizable,
        right: resizable,
        top: resizable && !isMinimized,
        topLeft: resizable && !isMinimized,
        topRight: resizable && !isMinimized,
      }}
      bounds={bounds}
      onDrag={onDrag}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      dragHandleClassName={windowId}
      disableDragging={!movable}
    >
      <Paper
        sx={{
          padding: 0,
          border: theme => `1px solid ${theme.palette.grayShades.gray7}`,
          overflow: 'hidden',
          boxShadow: '8px 8px 8px -5px rgba(0,0,0,0.2)',
        }}
        fullWidth
        fullHeight
      >
        <Box onMouseDown={handleClick}>

          <div
            className={movable ? windowId : undefined}
            style={{cursor: movable ? 'grab' : undefined}}
          >
            <FlexContainerHorizontal
              dataComponentName="Window-Header"
              spacing={1}
              alignVertical="middle"
              sx={{
                height: headerHeight,
                backgroundColor: theme => theme.palette.grayShades.gray1,
                userSelect: 'none',
                cursor: movable ? 'grab' : undefined,
                ':active': {cursor: movable ? 'grabbing' : undefined},
              }}
            >
              <FlexItemMin show={!!Icon}>
                <IconViewer
                  Icon={Icon}
                  sx={{
                    fontSize: titleFontSize * 1.30,
                    position: 'relative',
                    top: (titleFontSize / 8) + 'px',
                  }}
                />
              </FlexItemMin>
              <FlexItemMax
                dataComponentName="Window-Header-Title"
                sx={{
                  fontSize: titleFontSize,
                  fontWeight: 'bold',
                }}
                title={title}
                overFlowX
              >
                <ContainerEllipsis>
                  {title}
                </ContainerEllipsis>
              </FlexItemMax>
              {buttons
                .map(
                  (
                    {
                      Icon,
                      hidden = false,
                      disabled = false,
                      ariaLabel,
                      title,
                      onClick,
                    },
                    index,
                  ) => (
                    <FlexItemMin
                      key={index}
                      show={!hidden}
                    >
                      <IconButton
                        ariaLabel={ariaLabel}
                        size={EIconButtonSize.SMALL}
                        Icon={Icon}
                        disabled={disabled}
                        title={title}
                        onClick={onClick}
                      />
                    </FlexItemMin>
                  ),
                )
              }
              <FlexItemMin show={minimizable}>
                <IconButton
                  ariaLabel={isMinimized ? "Restore window" : "Minimize window"}
                  size={EIconButtonSize.SMALL}
                  Icon={createIcon.byMuiIcon(isMinimized ? RestoreIcon : MinimizeIcon)}
                  onClick={handleMinimize}
                />
              </FlexItemMin>
              <FlexItemMin show={!!onClose}>
                <IconButton
                  ariaLabel="Close window"
                  size={EIconButtonSize.SMALL}
                  noPadding
                  Icon={createIcon.byMuiIcon(CloseIcon)}
                  onClick={onClose}
                />
              </FlexItemMin>
            </FlexContainerHorizontal>
          </div>

          <Box
            show={!isMinimized}
            ref={refChildren}
          >
            {children}
          </Box>

        </Box>
      </Paper>
    </Rnd>
  );
});
