import * as L from "leaflet";
import {Icon} from "leaflet";

export enum ELeafletMarkerColor {
  BLUE = "BLUE",
  GOLD = "GOLD",
  GREEN = "GREEN",
  RED = "RED",
  ORANGE = "ORANGE",
  YELLOW = "YELLOW",
  VIOLET = "VIOLET",
  GREY = "GREY",
  BLACK = "BLACK",

}
export const colorMarker = (color: ELeafletMarkerColor): Icon => {
  const baseUrl = `${window.location.origin}/assets/leaflet-map/markers`;
  return L.icon({
    iconUrl: `${baseUrl}/marker-icon-${color.toLowerCase()}.png`,
    iconRetinaUrl: `${baseUrl}/marker-icon-2x-${color.toLowerCase()}.png`,
    shadowUrl: `${baseUrl}/marker-shadow.png`,
    iconAnchor:  [13, 40], // Position is calibration
    iconSize:    [25, 41],
    popupAnchor: [1, -34],
    shadowSize:  [41, 41],
  } as any);
};
