import {EDeviceType} from "../../interfaces/EDeviceType";

export const API_PATH_ApiDeviceMgmtDevicesConfigPut = '/services/devices-mgtm/apiDeviceMgmtDevicesConfigPut';

export interface IApiDeviceMgmtDevicesConfigPutBodyRequest {
  deviceId: string;
  // Dev note:
  //    DeviceBrokerId and deviceType are required because the Device Item in the database might not have been updated yet.
  //    This occurs when there is a change in the device type or broker ID.  deviceBrokerId: string;
  deviceBrokerId: string;
  deviceType: EDeviceType;
  config: any;
  restartDevice: boolean;
}

export interface IApiDeviceMgmtDevicesConfigPutResponse {
}
