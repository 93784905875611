import * as React from "react";
import {useState} from "react";
import {dynaSwitch} from "dyna-switch";

import {THandleDeleteMapsArgs} from "../../GeoMapLeafletCore";

import {FlexContainerVertical} from "../../FlexContainer";
import {Box} from "../../Box";
import {
  ButtonBar,
  EButtonBarAlign,
} from "../../ButtonBar";
import {
  Button,
  EButtonColor,
  EButtonSize,
} from "../../Button";

import {
  SxProps,
  useTheme,
  Theme,
} from "../../ThemeProvider";
import {alpha} from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import FailedIcon from "@mui/icons-material/Error";
import RetryIcon from "@mui/icons-material/Replay";

export interface IDeleteOfflineMapsDialogProps {
  dataComponentName?: string;
  sx?: SxProps<Theme>;
  deleteMapsArgs: THandleDeleteMapsArgs;
  onClose: () => void;
}

export const DeleteOfflineMapsDialog = (props: IDeleteOfflineMapsDialogProps): JSX.Element => {
  const {
    sx = {},
    dataComponentName,
    deleteMapsArgs: {
      savedMapsLabel,
      savedMapsCount,
      deleteMaps,
    },
    onClose,
  } = props;
  const theme = useTheme();

  const [step, setStep] = useState<'CONFIRM_DELETE' | 'DELETING' | 'COMPLETED' | 'NOTHING_TO_DELETE'>(
    savedMapsCount
      ? 'CONFIRM_DELETE'
      : 'NOTHING_TO_DELETE',
  );
  const [failed, setFailed] = useState(false);

  const handleDeleteClick = async () => {
    try {
      setFailed(false);
      await deleteMaps();
    }
    catch (e) {
      setFailed(true);
    }
    finally {
      setStep('COMPLETED');
    }
  };

  const content = dynaSwitch<JSX.Element>(
    step,
    <span>Internal error</span>, // 4TS this is not possible
    {
      NOTHING_TO_DELETE: (
        <>
          <h3>There is nothing saved</h3>
          <h5>Nothing to delete</h5>
          <Button
            size={EButtonSize.LARGE}
            onClick={onClose}
          >
            Done
          </Button>
        </>
      ),
      CONFIRM_DELETE: (
        <>
          <DeleteIcon
            sx={{
              color: theme.palette.error.main,
              width: 128,
              height: 128,
            }}
          />
          <h3>Delete {savedMapsLabel} ?</h3>
          <ButtonBar align={EButtonBarAlign.CENTER}>
            <Button
              size={EButtonSize.LARGE}
              icon={<DeleteIcon/>}
              onClick={handleDeleteClick}
            >
              Delete
            </Button>
            <Button
              size={EButtonSize.LARGE}
              icon={<CancelIcon/>}
              color={EButtonColor.SECONDARY}
              onClick={onClose}
            >
              Cancel
            </Button>
          </ButtonBar>
        </>
      ),
      DELETING: (
        <>
          <h1>Deleting</h1>
          <h5>Please wait...</h5>
        </>
      ),
      COMPLETED: (
        <>
          {failed
            ? (
              <FailedIcon
                sx={{
                  color: theme.palette.error.main,
                  width: 128,
                  height: 128,
                }}
              />
            )
            : (
              <SuccessIcon
                sx={{
                  color: theme.palette.success.main,
                  width: 128,
                  height: 128,
                }}
              />
            )
          }
          {failed
            ? <h3>Complete failed</h3>
            : <h3>Completed</h3>
          }
          <ButtonBar align={EButtonBarAlign.CENTER}>
            <Button
              size={EButtonSize.LARGE}
              onClick={onClose}
            >
              Done
            </Button>
            {failed && (
              <Button
                size={EButtonSize.LARGE}
                icon={<RetryIcon/>}
                color={EButtonColor.SECONDARY}
                onClick={handleDeleteClick}
              >
                Retry
              </Button>
            )}
          </ButtonBar>
        </>
      ),
    },
  );

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2500,
        color: theme.palette.text.primary,
        backgroundColor: alpha(theme.palette.background.default, 0.8),
        ...sx,
      }}
      dataComponentName={dataComponentName}
    >
      <FlexContainerVertical
        fullHeight
        alignHorizontal="center"
        alignVertical="middle"
        spacing={3}
        sx={{textAlign: 'center'}}
      >
        {content}
      </FlexContainerVertical>
    </Box>
  );
};
