import * as React from "react";
import {
  ReactElement,
  forwardRef,
} from "react";

import {Box} from "../Box";
import {
  SxProps,
  Theme,
} from "../ThemeProvider";

export interface ICenterContainerProps {
  sx?: SxProps<Theme>;
  show?: boolean;
  dataComponentName?: string;
  textAlign?: "left" | "center" | "right";      // Default is "left"
  horizontally?: "left" | "center" | "right";   // Default is "center"
  vertically?: "top" | "middle" | "bottom";     // Default is "middle"
  fullHeight?: boolean;                         // Default is true
  children: any;
}

export const CenterContainer = forwardRef<HTMLDivElement, ICenterContainerProps>((props, ref): ReactElement | null => {
  const {
    sx = {},
    show = true,
    dataComponentName,
    textAlign = "left",
    horizontally,
    vertically,
    fullHeight = true,
    children,
  } = props;

  if (!show) return null;

  return (
    <Box
      dataComponentName={[dataComponentName, "CenterContainer"]}
      ref={ref}
      sx={{
        height: fullHeight ? '100%' : undefined,
        display: 'flex',
        flexDirection: 'column',
        textAlign,
        justifyContent: (() => {
          if (vertically === "top") return "flex-start";
          if (vertically === "bottom") return "flex-end";
          return "center";
        })(),
        alignContent: (() => {
          if (horizontally === "left") return "flex-start";
          if (horizontally === "right") return "flex-end";
          return "center";
        })(),
        flexWrap: 'wrap', // Needed for the flexDirection: 'column'
        ...sx,
      }}
    >
      {children}
    </Box>
  );
});
