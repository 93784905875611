export const API_PATH_ApiDeviceMgmtRegionArchive = '/services/devices-mgtm/apiDeviceMgmtRegionArchive';

export interface IApiDeviceMgmtRegionArchiveBodyRequest {
  regionId: string;
  archiveIt: boolean;
}

export interface IApiDeviceMgmtRegionArchiveResponse {
  ok: true;
}
