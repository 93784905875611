import {
  ILoadPostsPagination,
  IDTOPost,
} from "../../../../../features/db-posts";
import {ELogPostType} from "../../../../../features/db-logs";

export const API_PATH_ApiTrafficStudyPostsLoadGet = '/services/traffic-studies/apiTrafficStudyPostsLoadGet';

export interface IApiTrafficStudyPostsLoadGetRequest {
  trafficStudyId: string;
  postId: string;
  /**
   * Pagination for root and nested children
   */
  paginations: ILoadPostsPagination[];
}

export interface IApiTrafficStudyPostsLoadGetResponse {
  post: IDTOPost<ELogPostType, any>;
}
