// The config of the app is ALSO the `env_variables.sh`!
// Here we have some standards only configs.

import {getHost} from "utils-library/dist/commonJs/utils";
import {arrayUnique} from "utils-library/dist/commonJs/array";

export enum EAppMode {
  PRODUCTION = "PRODUCTION",
  DEVELOPMENT = "DEVELOPMENT",
}

export interface IAppConfig {
  companies: IAppConfigCompany[];
  devClientAppAddressPort: number;
  mode: EAppMode;
  allowCORSForAddresses: string[];
  contentSecurityPolicyDirectives: {
    defaultSrc: string[];
    scriptSrc: string[];
    styleSrc: string[];
    styleSrcElem: string[];
    fontSrc: string[];
    imgSrc: string[];
    mediaSrc: string[];
    connectSrc: string[];
    frameAncestors: string[];
    formAction: string[];
  };
}

export interface IAppConfigCompany {
  serverAddress: string;
  companyId: string;
}

// Note: The main source of the server's config, are the env_variables.sh, not this const.
const processEnv =
  typeof process === "undefined"
    ? {}
    : process.env;

const companies: IAppConfigCompany[] =
  (processEnv.COMPANIES || '')
    .split(' ')
    .filter(Boolean)
    .map(companyServerAddress => ({
      serverAddress: (() => {
        const address = companyServerAddress.split('|')[1] || '';
        if (address && !address.endsWith('/')) return address + '/';
        return address;
      })(),
      companyId: companyServerAddress.split('|')[0] || '',
    }))
    .filter(company => !!company.companyId);

export const appConfig: IAppConfig = {
  companies,
  devClientAppAddressPort: 3600,    // Should be the same with /packages/web-app/.env PORT var
  mode:
    processEnv.PRODUCTION === "true"
      ? EAppMode.PRODUCTION
      : EAppMode.DEVELOPMENT,
  allowCORSForAddresses: [
    // Whitelist server address and domains that use the services of the server.
    "http://localhost:9238/",           // Dev - Storybook
    "http://3.13.153.156:3000/",        // For Grafana staging instance
    "http://3.13.153.156:4000/",        // For Grafana staging instance
    "https://metiring.mhcorbin.com",    // Production MetiriNG Grafana app
    // Company domains are added automatically later in this file.
  ],
  // # This is the only place to set up the CORS of the application.
  //
  // The same contentSecurityPolicyDirectives are applied on the client side on app's startup by the updateAppHtml.ts
  //
  // These are applied on
  // - all server's responses as headers
  // - web-app updated <meta http-equiv="Content-Security-Policy"> with updateAppHtml.ts
  contentSecurityPolicyDirectives: {
    // For default-src
    defaultSrc: [
      "'self'",
      'blob:',
      'localhost',
      // Apps 3rd party deps
      "*.sentry.io",
      // General 3rd party dependencies
      '*.google.com',
      '*.google.co.in',
      '*.google-analytics.com',
      '*.googlesyndication.com',
      '*.googleadservices.com',
      '*.googletagservices.com',
      '*.googleusercontent.com',
      '*.ampproject.org',
      '*.fontawesome.com',
      '*.googleapis.com',
      '*.gstatic.com',
      'youtu.be',
      '*.youtu.be',
      '*.youtube.com',
      'https://cdnjs.cloudflare.com/polyfill/v3/',
      // For leaflet maps
      '*.openstreetmap.org',
      '*.tiles.stadiamaps.com',
    ],
    // For script-src
    scriptSrc: [
      // Google ads
      "'self'",
      "blob:",
      '*.gstatic.com',
      'https://cdnjs.cloudflare.com/polyfill/v3/',
      '*.googleapis.com',
    ],
    styleSrc: [
      "'self'",
      "'unsafe-inline'",
    ],
    // For style-src-elem
    styleSrcElem: [
      "'self'",
      "'unsafe-inline'",
      "https://use.fontawesome.com/releases/v5.0.13/",
      "fonts.googleapis.com",
    ],
    // For font-src
    fontSrc: [
      "'self'",
      '*.fontawesome.com',
      'fonts.gstatic.com',
    ],
    // For img-src
    imgSrc: [
      "'self'",

      // General
      'data:',
      'blob:',

      // Core App
      'https://dyna-cms.s3.amazonaws.com',
      'https://dyna-cms-dev.s3.amazonaws.com',

      // Google ads (and not only)
      '*.google.com',

      // Google oAuth
      '*.googleusercontent.com',
      '*.googlesyndication.com',

      // Google Analytics
      '*.google-analytics.com',

      // Google AdSense
      '*.googletagmanager.com',

      // Youtube images
      '*.ytimg.com',

      // Maps
      '*.googleapis.com',
      "maps.gstatic.com",
      '*.githubusercontent.com',
      '*.openstreetmap.org',
      '*.tile.openstreetmap.org',
    ],
    // For media-src
    mediaSrc: [
      "'self'",
    ],
    // For connect-src
    connectSrc: [
      "'self'",
      // Note the company's related should be applied on web-app's side manually!
      // This is because at the time of the evaluation of this object, the companies are empty array since companies are coming from env vars.
      ...companies.map(c => getHost(c.serverAddress)),
      ...companies.map(c => '*.' + getHost(c.serverAddress)),
      ...companies.map(c => {
        if (c.serverAddress.startsWith('http')) return c.serverAddress.replace('http', 'ws');
        return 'ws://' + c.serverAddress;
      }),
      '*.googleapis.com',
      '*.fontawesome.com',
      '*.sentry.io',
      '*.openstreetmap.org',
      '*.tile.openstreetmap.org',
    ].filter(Boolean),
    // For frame-ancestors
    frameAncestors: [
      "'self'",
      "*.linkwi.se",
    ],
    // For form-action
    formAction: [
      "'self'",
    ],
  },
};

appConfig.allowCORSForAddresses.push(`http://localhost:${appConfig.devClientAppAddressPort}/`);
appConfig.companies.forEach(({serverAddress}) => appConfig.allowCORSForAddresses.push(serverAddress));
appConfig.allowCORSForAddresses = arrayUnique(appConfig.allowCORSForAddresses);

// Validate the content of the COMPANIES
appConfig.companies.forEach(
  (
    {
      companyId,
      serverAddress,
    },
  ) => {
    if (companyId && !serverAddress) console.error(`COMPANIES var has error, the companyId [${companyId}] has no serverAddress`);
    if (!companyId && serverAddress) console.error(`COMPANIES var has error, the serverAddress [${serverAddress}] has no companyId`);
  });
