import * as React from "react";
import {ReactElement} from "react";

import {Box} from "../Box";

import {
  FlexContainerHorizontal,
  FlexItemMax,
  FlexItemMin,
} from "../FlexContainer";

import {
  SxProps,
  Theme,
  useTheme,
} from "../ThemeProvider";
import {FlexContainerVertical} from "../FlexContainer";
import {IIconComponent} from "../IconComponent";
import {IconViewer} from "../IconViewer";

export interface ILabelContainerProps {
  dataComponentName?: string;

  sx?: SxProps<Theme>;
  show?: boolean;
  variant?: ELabelContainerVariant;

  fullHeight?: boolean;
  overFlowY?: boolean;    // Needs fullHeight or sx={{height: something}}

  inputId?: string;
  Icon?: IIconComponent;
  label?: string | React.ReactNode;
  labelRightContent?: JSX.Element;
  ariaLabel?: string;
  error?: boolean;

  children: any;

  helperContent?: ReactElement;

  onLabelClick?: () => void;
}

export enum ELabelContainerVariant {
  NONE = "NONE",
  FILLED = "FILLED",
  OUTLINED = "OUTLINED",
  STANDARD = "STANDARD",
}

export const LabelContainer = (props: ILabelContainerProps): JSX.Element | null => {
  const {
    sx = {},
    show = true,
    variant = ELabelContainerVariant.STANDARD,
    fullHeight = false,
    overFlowY = false,
    dataComponentName,
    Icon,
    label,
    labelRightContent,
    ariaLabel,
    inputId,
    error,
    children,
    helperContent,
    onLabelClick,
  } = props;
  const theme = useTheme();

  if (!show) return null;

  return (
    <Box
      sx={{
        color: error ? theme.palette.error.main : undefined,
        ...(() => {
          switch (variant) {
            case ELabelContainerVariant.FILLED:
              return {
                backgroundColor: theme.palette.action.disabledBackground,
                padding: theme.spacing(1),
              };
            case ELabelContainerVariant.OUTLINED:
              return {
                border: '1px solid',
                borderRadius: '6px',
                borderColor: theme.palette.grayShades.gray5,
                backgroundColor: 'transparent',
                padding: theme.spacing(1),
              };
            case ELabelContainerVariant.STANDARD:
              return {borderBottom: '1px solid'};
            default:
              return {};
          }
        })(),
        ...sx,
      }}
      fullHeight={fullHeight}
      dataComponentName={[dataComponentName, 'LabelContainer']}
    >
      <FlexContainerVertical fullHeight={fullHeight}>
        <FlexItemMin>
          <FlexContainerHorizontal
            sx={{cursor: onLabelClick ? "pointer" : undefined}}
            onClick={onLabelClick}
          >
            <FlexItemMax>
              {!!label && (
                <label
                  style={{
                    display: 'block',
                    fontSize: (theme.typography.fontSize * 1.2) + 'px',
                    fontWeight: 'bold',
                    cursor: 'inherit',
                    ...(() => {
                      if (variant === ELabelContainerVariant.OUTLINED) {
                        return {marginTop: 4};
                      }
                      return {};
                    })(),
                  }}
                  aria-label={ariaLabel}
                  htmlFor={inputId}
                >
                  <IconViewer
                    sx={{
                      position: 'relative',
                      top: '3px',
                      paddingRight: 1,
                    }}
                    Icon={Icon}
                  />
                  {label}
                </label>
              )}
            </FlexItemMax>
            <FlexItemMin>
              {labelRightContent}
            </FlexItemMin>
          </FlexContainerHorizontal>
          <Box
            sx={{marginBottom: theme => theme.spacing(2)}}
            show={variant === ELabelContainerVariant.OUTLINED}
          />
        </FlexItemMin>
        <FlexItemMax
          overFlowY={overFlowY}
          fullHeight={overFlowY}
        >
          {children}
        </FlexItemMax>
        <FlexItemMin>
          {helperContent}
        </FlexItemMin>
      </FlexContainerVertical>
    </Box>
  );
};
