export enum ECommentPostType {
  COMMENTS_CONNECTION = "COMMENTS_CONNECTION",  // Content is ILogsConnection
  COMMENT = "COMMENT",                          // Content is ICommentPost
}

export interface ICommentsConnection {
}

export interface ICommentContent {
  body: string;
}

export const getDefaultCommentContent = (): ICommentContent => ({body: ''});
