export const API_PATH_apiUsersInvitationsPost = '/services/users-invitations';

export interface IApiUsersInvitationsPostBodyRequest {
  title: string;
  fullCompanyName: string;
  firstName: string;
  lastName: string;
  email: string;      // PK for the invitations
  rights: string[];
}

export interface IApiUsersInvitationsPostResponse {
  ok: true;
}

