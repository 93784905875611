import * as React from "react";

import {formatTimezoneField} from "utils-library/dist/commonJs/format-field/formatField";

import {
  EDateFormatter,
  ETimeFormatter,
  EDateTimeOrder,
  formatDateTimeField,
} from "utils-library/dist/commonJs/format-field";
import {getSystemTimezone} from "utils-library/dist/commonJs/time";

import {Box} from "../Box";
import {IconViewer} from "../IconViewer";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";
import {createIcon} from "../IconComponent";
import GlobeIcon from '@mui/icons-material/Public';

export interface IFormatDateProps {
  sx?: SxProps<Theme>;

  /**
   * The date time value
   *
   * @description Should be timestamp (number) or ISO-8601 ending with Z (UTC) or JS Date
   */
  value?:
    | number
    | string
    | Date;

  /**
   * Show the local timezone regardless if the `timezone` property is used or not
   *
   * When the `timezone` is used, the local timezone is always shown.
   */
  showLocalTimezone?: boolean;

  /**
   * Show the time in different timezone than the terminal's
   *
   * @description Prints together the local time but also the source time in parenthesis
   *
   * @default Terminal's timezone
   */
  sourceTimezone?: string;

  /**
   * To show date
   *
   * @default EDateFormatter.STANDARD
   */
  dateFormatter?: EDateFormatter;
  /**
   * To show time
   *
   * @default ETimeFormatter.HOUR_MINUTE
   */
  timeFormatter?: ETimeFormatter;
  /**
   * Date time order
   *
   * @default EDateTimeOrder.TIME_DATE
   */
  dateTimeOrder?: EDateTimeOrder;

  leadingSpace?: boolean;
  trailingSpace?: boolean;
}

export const FormatDateTime = (props: IFormatDateProps): JSX.Element => {
  const {
    sx,
    value = new Date(),
    showLocalTimezone = false,
    sourceTimezone,
    dateFormatter = EDateFormatter.STANDARD,
    timeFormatter = ETimeFormatter.HOUR_MINUTE,
    dateTimeOrder = EDateTimeOrder.DATE_TIME,
    leadingSpace,
    trailingSpace,
  } = props;

  const terminalTimezone = getSystemTimezone();

  const localDateTimeLabel =
    formatDateTimeField({
      value: value,
      dateFormatter,
      timeFormatter,
      dateTimeOrder,
    }) + " ";

  const localTimezoneLabel =
    showLocalTimezone
    || (sourceTimezone && sourceTimezone !== terminalTimezone)
      ? formatTimezoneField({timezone: getSystemTimezone()}) + " "
      : "";

  const sourceDateTimeLabel =
    sourceTimezone
    && sourceTimezone !== terminalTimezone
      ? formatDateTimeField({
        value: value,
        timezone: sourceTimezone,
        dateFormatter,
        timeFormatter,
        dateTimeOrder,
      })
      : "";

  const sourceTimezoneLabel =
    sourceTimezone
      ? formatTimezoneField({timezone: sourceTimezone})
      : "";

  return (
    <Box
      sx={sx}
      component="span"
    >
      {leadingSpace && " "}

      {localDateTimeLabel}

      <i>{localTimezoneLabel}</i>

      {sourceDateTimeLabel && (
        <>
          (
          <IconViewer
            Icon={createIcon.byMuiIcon(GlobeIcon)}
            sx={{
              position: "relative",
              top: 3,
            }}
          />
          {" "}
          {sourceDateTimeLabel}
          {" "}
          <i>{sourceTimezoneLabel}</i>
          )
        </>
      )}

      {trailingSpace && " "}
    </Box>
  );
};
