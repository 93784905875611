import {EAlertType} from "../../interfaces/EAlertType";
import {EAlertHandlingUserFlag} from "../../interfaces/IAlertHandling";
import {EAlertHandlingSortByField} from "../../interfaces/EAlertHandlingSortByField";
import {IAlertHandling} from "../../interfaces/IAlertHandling";
import {EDeviceType} from "../../../device-management/interfaces/EDeviceType";

export const API_PATH_ApiAlertMgtmAlertHandlingSearchGet = '/services/alerts-mgtm/apiAlertMgtmAlertHandlingSearchGet';

export interface IApiAlertMgtmAlertHandlingSearchGetRequest {
  // Basics
  searchText: string;
  searchInResolved: boolean;
  searchInUnresolved: boolean;
  // Filters
  regionIds: string[];
  locationIds: string[];
  deviceTypes: EDeviceType[];
  alertType: EAlertType | "*";
  assignedToUserId: string;
  userFlags?: EAlertHandlingUserFlag[];
  // Sort
  sortBy: EAlertHandlingSortByField;
  sortDirection: 0 | -1 | 1;
  // Pagination
  skip: number;
  limit: number;
}

export interface IApiAlertMgtmAlertHandlingSearchGetResponse {
  alertHandlings: Array<IAlertHandling<any, any>>;
}
