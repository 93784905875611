import * as React from "react";
import {
  useRef,
  RefObject,
  forwardRef,
  useImperativeHandle,
} from "react";

import {Marker} from "react-leaflet";
// Help: https://react-leaflet.js.org/docs/api-components/#marker

import {IGeoPosition} from "utils-library/dist/commonJs/geo";
import {
  ELeafletMarkerColor,
  colorMarker,
  customIconMarker,
} from "../../../markers";
import {
  IMapPopUpProps,
  MapPopUp,
} from "./components/MapPopUp/MapPopUp";

export interface IMapMarkerProps {
  ref?: RefObject<IMapMarkerRef>;
  markerId: string;
  position: IGeoPosition;
  color?: ELeafletMarkerColor;  // Default is ELeafletMarkerColor.RED
  customIcon?: JSX.Element;
  opacity?: number;
  popUp?: IMapPopUpProps;
  draggable?: boolean;          // Default is false
  onDragStart?: (position: IGeoPosition) => void;
  onDrag?: (position: IGeoPosition) => void;
  onDragEnd?: (position: IGeoPosition) => void;
}

export interface IMapMarkerRef {
  isPopUpOpen: boolean;
  openPopUp: () => void;
  closePopUp: () => void;
}

export const MapMarker = forwardRef<IMapMarkerRef, IMapMarkerProps>((props, ref): JSX.Element => {
  const {
    position,
    customIcon,
    color = ELeafletMarkerColor.RED,
    opacity,
    popUp,
    draggable,
    onDragStart,
    onDrag,
    onDragEnd,
  } = props;

  const refMarker = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    get isPopUpOpen(): boolean {
      return refMarker.current?.isPopupOpen();
    },
    openPopUp: () => {
      refMarker.current?.openPopup();
    },
    closePopUp: () => {
      refMarker.current?.closePopup();
    },
  }));

  const triggerDragEvent = (handler?: typeof onDragStart | typeof onDrag | typeof onDragEnd): void => {
    if (!handler) return;
    if (!refMarker.current) return;
    handler(refMarker.current.getLatLng());
  };

  const handleDragStart = () => triggerDragEvent(onDragStart);
  const handleDrag = () => triggerDragEvent(onDrag);
  const handleDragEnd = () => triggerDragEvent(onDragEnd);

  const icon =
    customIcon
      ? customIconMarker(customIcon)
      : colorMarker(color);

  return (
    <Marker
      ref={refMarker}
      position={position}
      icon={icon}
      opacity={opacity}
      draggable={draggable}
      eventHandlers={{
        dragstart: handleDragStart,
        drag: handleDrag,
        dragend: handleDragEnd,
      }}
    >
      {!!popUp && <MapPopUp {...popUp}/>}
    </Marker>
  );
});
