import * as React from "react";
import {ReactElement} from "react";

import {Icon as Iconify} from "@iconify/react";

import {IMuiIcon} from "utils-library/dist/commonJs/typescript";

import {IconViewer} from "../IconViewer";

/**
 * React component that renders an Icon with simple props to style it.
 * The color of the icon is the current css `color` of the owner.
 */
export type IIconComponent = (
  props: {
    width?: number;
    height?: number;
  },
) =>
  | INonMuiIconReactElement;

// This is just a dummy private interface designed to prevent variables of type IIconComponent
// From accepting references of the IMuiIcon and, in general, Material UI Icons.
interface INonMuiIconReactElement extends ReactElement {
  iconComponent: true;
}

/**
 * The createIcon method helps to create responsive icons based on SVG elements/images and MUI icons.
 * The result is a React component with the IIconComponent interface, which only has a few props like width and height.
 * The color is inherited.
 */
export const createIcon = {
  /**
   * Create an icon by an URL
   */
  byUrl: (url: string): IIconComponent => createIcon.byImage(url),
  /**
   * Create an icon by an imported from webpack image (jpg, gif, etc.)
   */
  byImage: (image: string): IIconComponent =>
    (
      {
        width,
        height,
      }: {
        width?: number;
        height?: number;
      },
    ): INonMuiIconReactElement => {
      return (
        <IconViewer
          image={image}
          width={width}
          height={height}
        />
      ) as any;
    },
  /**
   * Create an icon by an SVG (as JSX) element image
   */
  bySVGElement: (svg: ReactElement<SVGElement>): IIconComponent =>
    (
      {
        width,
        height,
      }: {
        width?: number;
        height?: number;
      },
    ): INonMuiIconReactElement => {
      return (
        <IconViewer
          svgElement={svg}
          width={width}
          height={height}
        />
      ) as any;
    },
  /**
   * Create an icon by an imported from webpack SVG image or URL of it
   */
  bySVGImage: (image: string): IIconComponent =>
    (
      {
        width,
        height,
      }: {
        width?: number;
        height?: number;
      },
    ): INonMuiIconReactElement => {
      return (
        <IconViewer
          svgScript={image}
          width={width}
          height={height}
        />
      ) as any;
    },
  /**
   * Create icon by MUI's package icons
   *
   * Browser icons: https://mui.com/material-ui/material-icons
   */
  byMuiIcon: (MuiIcon: IMuiIcon): IIconComponent =>
    (
      {
        width,
        height,
      }: {
        width?: number;
        height?: number;
      },
    ): INonMuiIconReactElement => {
      return (
        <IconViewer
          MuiIcon={MuiIcon}
          width={width}
          height={height}
        />
      ) as any;
    },
  /**
   * Create icon by Iconify icons
   *
   * Iconify icons are a larger open-source collection than MUI's repository.
   *
   * Website: https://iconify.design
   *
   * Instructions:
   * - Explore icons: https://icon-sets.iconify.design
   * - Choose an icon
   * - In the components section, select the "Unplugin icons" option
   * - Copy the import script, for example: `import MdiFlight from '~icons/mdi/flight';`
   * - Replace the `~icons` in the import with `@iconify-icons`
   * - If the icons package not installed, you may need to run `yarn add @iconify-icons/mdi` for the above import example.
   */
  byIconifyIcon: (iconifyIcon: any): IIconComponent =>
    (
      {
        width,
        height,
      }: {
        width?: number;
        height?: number;
      },
    ): INonMuiIconReactElement => {
      return (
        <Iconify
          icon={iconifyIcon}
          width={width}
          height={height}
        />
      ) as any;
    },

  /**
   * Emoji icon
   *
   * Directly the UTF-8 sequence of the icon and not as a human string.
   *
   * You can pick emoji's from https://emojipedia.org
   *
   * @example Apply it like `🍔` NOT like `:hamburger:`
   */
  byEmoji: (emoji: string): IIconComponent =>
    (
      {width}: {
        width?: number;
      },
    ): INonMuiIconReactElement => {
      return (
        <IconViewer
          emoji={emoji}
          width={width}
        />
      ) as any;
    },
};
