import {
  TObject,
  TEnum,
} from "utils-library/dist/commonJs/typescript";

import {
  IDBEntityBase,
  TDBEntityDataOnly,
  getDefaultDBEntityDocument,
} from "utils-library/dist/commonJs/db-entity-interfaces";

import {ILoadPostsPagination} from "./ILoadPostsPagination";

/**
 * Represents a post.
 */
export interface IPost<
  TEType extends TEnum,
  TContent extends TObject
> extends IDBEntityBase {
  /**
   * The unique identifier for a post.
   *   - For regular posts, it's the post's ID.
   *   - For posts connected to an app element, it's the ID of the owner's element.
   */
  id: string;

  /**
   * The owner of the post.
   *
   * @description Can be any kind of item or entity like another Post (in case or reply), CIM, Traffic Study, or a chat room.
   */
  parentId: string;

  /**
   * The type of the post.
   *
   * @description Examples: comment, post, reaction event, vote event, call event.
   */
  type: TEType;

  /**
   * The content of the post.
   */
  content: TContent;

  /**
   * If the content (only) has been edited
   */
  edited: number;

  /**
   * The id of the profile that posted the content.
   *
   * @description Similar to a user, a profile would handle a post.
   */
  profileId: string;

  /**
   * Decorative elements for the post.
   */
  reactions: Record<
    string, // The reactType (value of TEReactType enum) of the reaction
    number  // The counter of the reaction
  >;
  votes: number;
  claps: number;

  /**
   * Helper flag for the app to load replies or not.
   */
  childrenCounters: Record<
    string, // Type of posts
    number  // Counter
  >;
  /**
   * User regular tags.
   *
   * @description This array helps the application find matching posts by the user's tags.
   * @example ["metoo", "catlovers", "pizza"]
   */
  userTags: string[];
  /**
   * Search tags are keys used to search a post.
   * These tags are not shown to the user but help the application in searching since it is not possible to have indexers for the content of the post.
   *
   * @example ["info-log", "warn-log"]
   */
  searchTags: string[];

  /**
   * Specifies the entities (e.g., userId, profileId) permitted to view this post.
   * @type {string[]}
   */
  accessViewIds: string[];

  /**
   * Specifies the entities (e.g., userId, profileId) permitted to reply to this post.
   * @type {string[]}
   */
  accessReplyIds: string[];

  /**
   * Specifies the entities (e.g., userId, profileId) permitted to interact (e.g., vote, react, clap) with this post.
   * @type {string[]}
   */
  accessInteractIds: string[];
}

export const getDefaultPost = () =>
  getDefaultDBEntityDocument<IPost<any, any>>(getDefaultPostDataOnly());

export const getDefaultPostDataOnly = (): TDBEntityDataOnly<IPost<any, any>> => ({
  parentId: '',
  type: '',
  content: {},
  edited: 0,
  profileId: '',
  reactions: {},
  votes: 0,
  claps: 0,
  childrenCounters: {},
  userTags: [],
  searchTags: [],
  accessViewIds: [],
  accessReplyIds: [],
  accessInteractIds: [],
});

export interface IDTOPost<TEType extends TEnum, TContent extends TObject> extends IPost<TEType, TContent> {
  level: number;
  /**
   * Details of the author (creator) of this post
   */
  author: IPostAuthor;
  /**
   * Children posts and payload to load more
   */
  children: {
    posts: IDTOPost<TEType, any>[];
    hasMore: boolean;
    loadMorePayload:
      | IPostsLoadMorePayload<TEType>
      | null;
  };
  /**
   * Info on who is viewing the post.
   *
   * @description This is just for clarity and debugging, since the requester always knows who the viewer is.
   */
  viewer: {
    profileId: string;
    userId: string;
  };
  /**
   * Stats of how the profile or user has reacted on this post
   *
   * Note: This is updated only when viewer (userId or profileId) is provided,
   */
  viewerInteractions: {
    reactions: string[];
    vote: 1 | 0 | -1;
    claps: number;
  };
  /**
   * Viewer rights: operations are allowed to perform
   */
  viewerCan: {
    edit: boolean;
    reply: boolean;
    delete: boolean;
    interact: boolean;
  };
}

export interface IPostAuthor {
  id: string;
  displayName: string;
  profileImageUrl: string;
}

export interface IPostsLoadMorePayload<TEType extends TEnum> {
  companyId: string;
  container: string;
  parentId: string;

  /**
   * Load post types for all children levels
   *
   * Undefined or empty array, to return them all
   */
  postTypes?: TEType[];
  searchTags?: string[];

  archived?: boolean | "both";
  deleted?: boolean | "both";

  paginations: ILoadPostsPagination[];
}

export const getDefaultDTOPost = (): IDTOPost<any, any> => ({
  ...getDefaultPost(),
  level: 0,
  author: {
    id: '',
    displayName: '',
    profileImageUrl: '',
  },
  children: {
    posts: [],
    hasMore: false,
    loadMorePayload: null,
  },
  viewer: {
    profileId: '',
    userId: '',
  },
  viewerInteractions: {
    reactions: [],
    vote: 0,
    claps: 0,
  },
  viewerCan: {
    edit: false,
    reply: false,
    delete: false,
    interact: false,
  },
});
