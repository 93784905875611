import {getOpenIdEndpoint} from "../openIdAuthCreator/getOpenIdEndpoint";
import {EAuthStrategy} from "../../interfaces/EAuthStrategy";

export const API_PATH_apiAuthGoogleOpenIdMethodRedirect =
  (
    {
      host,
      companyId,
    }: {
      host: string;
      companyId: string;
    },
  ): string =>
    getOpenIdEndpoint({
      host,
      strategy: EAuthStrategy.OAUTH_GOOGLE,
      endpoint: "client",
      companyId,
    });
