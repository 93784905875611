// Dev note: Do not use this component in production
// Instead, use the GeoMapLeaflet since is has simpler interface.

export {
  IGeoPosition,
} from "utils-library/dist/commonJs/geo";

export {
  // Basics
  GeoMapLeafletCore,
  IGeoMapLeafletCoreProps,
  IGeoMapLeafletCoreRef,
  IMapFullScreenEvent,
  IMapViewState,
  // Geo Shapes
  IPopUp,
  IGeoMapLeafletMarker,
  IGeoMapLeafletPolyline,
  IGeoMapDirectionLine,
  IGeoMapLeafletCircle,
  IGeoMapComponent,
  IGeoMapToolWindow,
} from "./GeoMapLeafletCore";

export {
  IMapMarkerRef,
} from "./components/map-components/MapMarker";

export {
  MapButton,
} from "./components/ui-components/MapButton";

export {
  ELeafletMarkerColor,
} from "./markers";

export {
  IHeadingSlice,
} from "./components/map-components/MapHeadingSelector";

export {
  THandleSaveMaps,
  THandleSaveMapsArgs,
  THandleDeleteMaps,
  THandleDeleteMapsArgs,
} from "./offline";

export {
  getPointByDistance,
  getDistanceInMeters,
  getMiddlePosition,
  getDirectionDegreesByGeoPositions,
} from "./utils";
