import * as React from "react";

import {
  TDataComponentName,
  getDataComponentName,
} from "../ui-interfaces";
import {Box} from "../Box";
import {TBoxSpacing} from "../ui-interfaces";

import {
  SxProps,
  Theme,
  useTheme,
} from "../ThemeProvider";

export interface IFlexContainerProps {
  show?: boolean;
  inline?: boolean;
  orientation?: EFlexContainerOrientation;

  spacing?: TBoxSpacing;

  justifyStart?: boolean;
  justifyCenter?: boolean;
  justifyEnd?: boolean;
  justifySpaceBetween?: boolean;

  alignStart?: boolean;
  alignCenter?: boolean;
  alignEnd?: boolean;

  reverseOrder?: boolean;

  fullHeight?: boolean;

  sx?: SxProps<Theme>;
  dataComponentName?: TDataComponentName;
  title?: string;

  children: any;

  onClick?: (e: React.MouseEvent) => void;
  onMouseMove?: (event: React.MouseEvent) => void;
  onMouseDown?: (event: React.MouseEvent) => void;
  onMouseUp?: (event: React.MouseEvent) => void;
  onMouseEnter?: (event: React.MouseEvent) => void;
  onMouseLeave?: (event: React.MouseEvent) => void;
  onScroll?: (event: React.UIEvent) => void;
  onWheel?: (event: React.WheelEvent<HTMLDivElement>) => void;
}

export enum EFlexContainerOrientation {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
}

export const FlexContainer = (props: IFlexContainerProps): JSX.Element => {
  const {
    show = true,
    inline = false,
    orientation = EFlexContainerOrientation.HORIZONTAL,
    spacing,
    justifyStart = false,
    justifyCenter = false,
    justifyEnd = false,
    justifySpaceBetween = false,
    alignStart = false,
    alignCenter = false,
    alignEnd = false,
    fullHeight = false,
    reverseOrder = false,
    sx: userSx = {},
    dataComponentName,
    title,
    children,
    onClick,
    onMouseMove,
    onMouseDown,
    onMouseUp,
    onMouseEnter,
    onMouseLeave,
    onScroll,
    onWheel,
  } = props;
  const theme = useTheme();

  const innerBorderSpace =
    spacing
      ? theme.spacing(spacing)
      : undefined;

  const sx: SxProps<Theme> = {
    display: inline ? 'inline-flex' : 'flex',
    flexDirection: (() => {
      if (!reverseOrder) {
        return orientation === EFlexContainerOrientation.VERTICAL
          ? 'column'
          : undefined;
      }
      return orientation === EFlexContainerOrientation.HORIZONTAL
        ? 'row-reverse'
        : 'column-reverse';
    })(),

    justifyContent: (() => {
      if (justifySpaceBetween) return "space-between";
      if (justifyStart) return "flex-start";
      if (justifyCenter) return "center";
      if (justifyEnd) return "flex-end";
      return undefined;
    })(),

    alignItems: (() => {
      if (alignStart) return "flex-start";
      if (alignCenter) return "center";
      if (alignEnd) return "flex-end";
      return undefined;
    })(),

    height: fullHeight ? '100%' : undefined,

    paddingRight: innerBorderSpace,
    paddingBottom: innerBorderSpace,
    "& > *": {
      marginTop: innerBorderSpace,
      marginLeft: innerBorderSpace,
    },
    ...userSx,
  };

  return (
    <Box
      show={show}
      sx={sx}
      dataComponentName={getDataComponentName(dataComponentName, "FlexContainer")}
      title={title}
      onClick={onClick}
      onMouseMove={onMouseMove}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onScroll={onScroll}
      onWheel={onWheel}
    >
      {children}
    </Box>
  );
};
