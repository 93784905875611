import {
  useState,
  useEffect,
} from "react";
import {IDynaError} from "dyna-error";
import {guid} from "dyna-guid";

import {useIsMounted} from "../useIsMounted";
import {useOnChange} from "../useOnChange";

export interface IUseLoadMoreDataArgs<TItem> {
  loadOnStartup?: boolean;    // Default is true
  debounceTimeout?: number;   // Default is 300 (ms)
  loadSize: number;           // Default is 20, needed to calculate better the "hasMore"
  loadAll?: boolean;          // Dangerous! Will load them all, partially, no matter how it will take!
  reloadDep?: any;            // Will restart the load operation, if this is changed (it uses the useOnChange)

  errorHandling?: {
    consoleIt?: boolean;      // Default is true
    consoleMessage?: string;  // Default is 'useLoadData: Load error'
    userMessage?: string;     // Default is undefined, the userMessage of the Error will be used
  };

  onLoad: (args: IUseLoadMoreArgs) => Promise<TItem[]>;
}

export interface IUseLoadMoreArgs {
  skip: number;
  limit: number;
}

export interface IUseLoadMoreDataApi<TItem> {
  items: TItem[];
  hasMore: boolean;
  isLoading: boolean;
  changeId: string;
  error: IDynaError | null;
  setItems: (items: TItem[]) => void;
  reload: () => void;     // Start/restart a load
  refresh: () => void;    // Refresh asking the onLoad
  loadMore: () => void;   // Load more items
}

interface ILoadArgs {
  reloadId: string;
  loadType:
    | "reload"
    | "load-more"
    | "refresh";
}

export const useLoadMoreData = <TItem>(
  {
    loadOnStartup = true,
    debounceTimeout = 300,
    loadSize = 20,
    loadAll = false,
    reloadDep,
    errorHandling = {},
    onLoad,
  }: IUseLoadMoreDataArgs<TItem>,
): IUseLoadMoreDataApi<TItem> => {
  const {
    consoleIt = true,
    consoleMessage = 'useLoadMoreData: Load error',
    userMessage,
  } = errorHandling;

  const getIsMounted = useIsMounted();

  const [loadArgs, setLoadArgs] = useState<ILoadArgs>({
    reloadId: '',
    loadType: "reload",
  });

  const [isLoading, setIsLoading] = useState<boolean>(loadOnStartup);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [items, _setItems] = useState<Array<TItem>>([]);
  const [error, setError] = useState<IDynaError | null>(null);
  const [changeId, setChangeId] = useState<string>("");

  const setItems = (items: Array<TItem>): void => {
    _setItems(items);
    setChangeId(guid());
  };

  useOnChange({
    dep: reloadDep,
    onChange: () => reload(),
  });

  useEffect(() => {
    if (!loadArgs.reloadId) return;
    let ignore = false;
    (async () => {
      try {
        await new Promise(r => setTimeout(r, debounceTimeout));
        if (ignore) return;
        setIsLoading(true);
        const loadedItems = await (() => {
          switch (loadArgs.loadType) {
            case "reload":
              return onLoad({
                skip: 0,
                limit: loadSize,
              });
            case "refresh":
              return onLoad({
                skip: 0,
                limit: items.length,
              });
            case "load-more":
              return onLoad({
                skip: items.length,
                limit: loadSize,
              });
            default:
              throw new Error(`Internal error 20231011165448: Unknown loadType [${loadArgs.loadType}]`); // 4TS, not possible to happen this
          }
        })();
        if (!getIsMounted()) return;
        if (ignore) return;
        if (loadArgs.loadType === "reload") {
          setHasMore(loadedItems.length === loadSize);
          setItems(loadedItems);
        }
        if (loadArgs.loadType === "refresh") {
          setItems(loadedItems);
        }
        if (loadArgs.loadType === "load-more") {
          setHasMore(loadedItems.length === loadSize);
          setItems(items.concat(loadedItems));
        }
        if (error) setError(null);
        if (loadAll && loadedItems.length === loadSize) {
          loadMore();
        }
        else {
          setIsLoading(false);
        }
      }
      catch (e) {
        if (!getIsMounted()) return;
        if (ignore) return;
        if (userMessage) e.userMessage = userMessage;
        if (consoleIt) console.error(consoleMessage, e);
        setError(e);
        setIsLoading(false);
      }
    })();

    return () => {
      ignore = true;
    };
  }, [loadArgs.reloadId]);

  useEffect(() => {
    if (loadOnStartup) reload();
  }, []);

  const reload = (): void => {
    setLoadArgs({
      reloadId: guid(),
      loadType: "reload",
    });
  };

  const refresh = (): void => {
    setLoadArgs({
      reloadId: guid(),
      loadType: "refresh",
    });
  };

  const loadMore = (): void => {
    setLoadArgs({
      reloadId: guid(),
      loadType: "load-more",
    });
  };

  return {
    isLoading,
    hasMore,
    changeId,
    items,
    error,
    setItems,
    reload,
    refresh,
    loadMore,
  };
};
