import {IGeoPosition} from "../geo";

export interface IGeolocationPosition {  // Copy from browser's types
  readonly coords: IGeolocationCoordinates;
  readonly timestamp: number;
}

export interface IGeolocationCoordinates {
  readonly accuracy: number;
  readonly altitude: number | null;
  readonly altitudeAccuracy: number | null;
  readonly heading: number | null;
  readonly latitude: number;
  readonly longitude: number;
  readonly speed: number | null;
}

export interface IBrowserGeolocationConfig {
  enableHighAccuracy?: boolean; // Default is false,  https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions/enableHighAccuracy
  onPosition?: (position: IGeolocationPosition) => void;
  onError?: (error: any) => void;
}

export class BrowserGeolocation {
  constructor(private readonly config: IBrowserGeolocationConfig = {}) {
    if (config.onPosition) {
      navigator.geolocation.watchPosition(
        config.onPosition,
        config.onError,
        {enableHighAccuracy: config.enableHighAccuracy},
      );
    }
  }

  private getFullGeolocationPosition(): Promise<IGeolocationPosition> {
    return new Promise<IGeolocationPosition>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        resolve,
        reject,
        {enableHighAccuracy: this.config.enableHighAccuracy},
      );
    });
  }

  public async getGeoPosition(): Promise<IGeoPosition> {
    const {
      coords: {
        latitude: lat,
        longitude: lng,
        altitude: alt,
      },
    } = await this.getFullGeolocationPosition();
    return {
      lat,
      lng,
      alt: alt === null ? undefined : alt,
    };
  }

}
