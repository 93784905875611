export const API_PATH_ApiTrafficStudyPostReact = '/services/traffic-studies/apiTrafficStudyPostReact';

export interface IApiTrafficStudyPostReactRequest {
  trafficStudyId: string;
  postId: string;
  reactType: string;
}

export interface IApiTrafficStudyPostReactResponse {
  ok: true;
}
