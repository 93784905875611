import {IUser} from "../../user-authentication/interfaces/IUser";

export const API_PATH_apiUserMngmtUserSearch = '/services/users-management/items/search';

export interface IUserMngmtUserItemSearchGetQueryRequest {
  search: string;
  archived?: boolean | "both";
  deleted?: boolean | "both";
  skip: number;
  limit: number;
  sortByFieldName?: keyof IUser;
  sortDirection?: 0 | 1 | -1;
}

export interface IApiUserMngmtUserItemSearchGetResponse {
  users: IUser[];
}
