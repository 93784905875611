import {EBreakpointDevice} from "../ui-interfaces";

import {
  Theme,
  SxProps,
} from "../ThemeProvider";

export interface IColumn<TData> {
  fieldName: keyof TData | string;
  align?: EColumnAlign;           // Default is EAlign.RIGHT

  headerSx?: SxProps<Theme>;
  headerLabel: string | JSX.Element;
  headerTooltip?: string;

  breakpoints?: ('allExclude' | EBreakpointDevice)[];

  cellSx?: SxProps<Theme>;
  cellRender?: (cellValue: any, rowData: TData) => JSX.Element | string | number | null;

  sortable?: boolean;
  hidden?: boolean;
}

export enum EColumnAlign {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
}

export interface ITableSort<TData> {
  fieldName?: keyof TData;
  direction: 0 | 1 | -1;
}

export enum ETableSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

