// @ts-ignore
import * as GeometryUtil from "leaflet-geometryutil";
import {IGeoPosition} from "utils-library/dist/commonJs/geo";

export const getPointByDistance = (point: IGeoPosition, distanceInMeters: number, directionInDegrees: number): IGeoPosition => {
  const result = GeometryUtil.destination(point, directionInDegrees, distanceInMeters);
  return {
    lat: result.lat,
    lng: result.lng,
  };
};
