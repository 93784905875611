import {
  IValidateDataEngineRules,
  IValidationResult,
  IValidationDataResults,
} from "./interfaces";

export const validationEngineCleanResults = <TData>(
  validationRules: IValidateDataEngineRules<TData>,
): IValidationResult<TData> => {
  return {
    isValid: true,
    dataValidation: createValidationCleanResults<TData>(validationRules),
    messages: [],
  };
};

const createValidationCleanResults = <TData>(
  validationRules: IValidateDataEngineRules<TData>,
  _output: IValidationDataResults<any> = {},
): IValidationDataResults<TData> => {
  Object.keys(validationRules)
    .forEach(property => {
      if (!property.includes('.')) _output[property] = "";
    });

  return _output as any;
};
