import {IRegion} from "../../interfaces/IRegion";

export const API_PATH_ApiDeviceMgmtRegionsGet = '/services/devices-mgtm/apiDeviceMgmtRegionsGet';

export interface IApiDeviceMgmtRegionsGetQueryRequest {
  // Nothing at the moment, in future lazy load props would be added.
  // For now, it returns all regions.
  deleted: "true" | "false";
  archived: "true" | "false";
}

export interface IApiDeviceMgmtRegionsGetResponse {
  regions: IRegion[];
}
