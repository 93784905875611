import {IRegion} from "../../interfaces/IRegion";

export const API_PATH_ApiDeviceMgmtRegionGet = '/services/devices-mgtm/apiDeviceMgmtRegionGet';

export interface IApiDeviceMgmtRegionGetQueryRequest {
  regionId: string;
}

export interface IApiDeviceMgmtRegionGetResponse {
  region: IRegion;
}
