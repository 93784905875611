import {IAlertHandling} from "../../interfaces/IAlertHandling";

export const API_PATH_ApiAlertMgtmAlertHandlingGet = '/services/alerts-mgtm/apiAlertMgtmAlertHandlingGet';

export interface IApiAlertMgtmAlertHandlingGetRequest {
  handlingId: string;
  handlingIsResolved: boolean;
}

export interface IApiAlertMgtmAlertHandlingGetResponse {
  alertHandling: IAlertHandling<any, any> | null;
}
