export const API_PATH_ApiTrafficStudyDelete = '/services/traffic-studies/apiTrafficStudyDelete';

export interface IApiTrafficStudyDeleteRequest {
  trafficStudyId: string;
  deleteIt: boolean;
}

export interface IApiTrafficStudyDeleteResponse {
  ok: true;
}
