import {ETrafficStudiesRights} from "mhc-server";

import {buildUrlByRoutePath} from "utils-library/dist/commonJs/utils";

import {IAppRoute} from "../../application/config/IAppRoute";

import {TrafficStudiesIcon} from "../icons";

export const routeTrafficStudyListPaths: IAppRoute<{ searchObjectText: string }> = {
  menuId: "menu--trafficStudiesSearch",
  title: 'Traffic Studies',
  icon: <TrafficStudiesIcon/>,
  routePath: '/traffic-studies/list/:searchObjectText?',
  getRoutePath: ({searchObjectText} = {searchObjectText: ''}) =>
    buildUrlByRoutePath({
      routePath: routeTrafficStudyListPaths.routePath,
      pathParams: {searchObjectText},
    }),
  userHasAllRights: [],
  userHasAnyOfRights: [
    ETrafficStudiesRights.READ,
    ETrafficStudiesRights.WRITE,
  ],
  exact: true,
  render: () => null,
};
