import * as React from "react";

import {
  useTheme,
  useMediaQuery,
} from "../ThemeProvider";

export interface IBreakpointContainerProps {
  breakpoint?: EBreakpoint;   // From start of breakpoint
  toBreakpoint?: EBreakpoint; // To start of an breakpoint
  children: any;
}

export enum EBreakpoint {
  // Source: https://mui.com/customization/breakpoints/
  // Breakpoints can be changed by https://material-ui.com/customization/breakpoints/#custom-breakpoints
  XSMALL = "xs",    //     0px
  SMALL = "sm",     //   600px
  MEDIUM = "md",    //   900px
  LARGE = "lg",     //  1200px
  XLARGE = "xl",    //  1536px
}

export const BreakpointContainer = (props: IBreakpointContainerProps): JSX.Element => {
  const {
    breakpoint,
    toBreakpoint,
    children,
  } = props;
  const theme = useTheme();

  const before = useMediaQuery(theme.breakpoints.between(breakpoint as any, 9999999));
  const after = useMediaQuery(theme.breakpoints.up(toBreakpoint as any));
  const middle = useMediaQuery(theme.breakpoints.between(breakpoint as any, toBreakpoint as any));

  const matches = (()=>{
    if (breakpoint && !toBreakpoint) {
      return before;
    }
    if (!breakpoint && toBreakpoint)  {
      return after;
    }
    return middle;
  })();

  return <>{matches ? children : null}</>;
};
