import {useHistory} from "react-router-dom";

import {AppStore} from "./AppStore";

import {INavigateTo} from "../interfaces/INavigateTo";

import {isCurrentAddressSame} from "utils-library/dist/commonJs/web";

export const AppRouterHistory = (): JSX.Element => {
  const history = useHistory();

  const handleNavigateTo = (navigateTo: INavigateTo): void => {
    const {
      url: rawUrl,
      delay = 0,
      replace = false,
      external = false,
    } = navigateTo;

    const url = rawUrl
      .split('/')
      .filter(slug => {
        // Remove the optional React router params and complain if some left in the url.
        const isParam = slug.startsWith(':');
        if (!isParam) return true;
        const isRequired = !slug.endsWith('?');
        if (isRequired) console.error(`developer error: navigateTo(): the url [${rawUrl}] contains a forgotten react router variable [${slug}], you have to replace it with the value`);
        return isRequired;
      })
      .join('/');

    if (isCurrentAddressSame(url)) return;

    setTimeout(() => {
      if (external) {
        window.location.href = url;
      }
      else if (replace) {
        history.replace({pathname: url});
      }
      else {
        if (url === '<') {
          history.goBack();
        }
        else if (url === '>') {
          history.goForward();
        }
        else {
          history.push(url);
        }
      }
    }, delay);
  };

  return <AppStore onNavigateTo={handleNavigateTo}/>;
};
