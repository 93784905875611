export const dispatchElementEvent = (
  element: Element,
  eventName: string,
  options: EventInit = {
    bubbles: true,
    cancelable: true,
  },
): void => {
  const event = new Event(eventName, options);
  element.dispatchEvent(event);
};
