/**
 * Generates an array of numeric indexes from 0 to length-1.
 *
 * Useful for creating things in react.
 *
 * @example render arrayIndices(12).map(index => <h1 key={index}>No ${index+1}</h1>)
 */
export const arrayIndices = (length: number): number[] => {
  const result = [];
  for (let i = 0; i < length; i++) result.push(i);
  return result;
};

