import {
  useTheme,
  useMediaQuery,
} from "../ThemeProvider";

import {EBreakpoint} from "../BreakpointContainer";
import {EBreakpointDevice} from "../ui-interfaces";

export const useBreakpointDevice = (): {
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isDesktop: boolean;
  isWide: boolean;
  device: EBreakpointDevice;
} => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down(EBreakpoint.SMALL));
  const isTablet = useMediaQuery(theme.breakpoints.down(EBreakpoint.MEDIUM));
  const isLaptop = useMediaQuery(theme.breakpoints.down(EBreakpoint.LARGE));
  const isDesktop = useMediaQuery(theme.breakpoints.down(EBreakpoint.XLARGE));

  return {
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
    isWide: !isMobile && !isTablet && !isLaptop && !isDesktop,
    device: (()=>{
      if (isMobile) return EBreakpointDevice.MOBILE;
      if (isTablet) return EBreakpointDevice.TABLET;
      if (isLaptop) return EBreakpointDevice.LAPTOP;
      if (isDesktop) return EBreakpointDevice.DESKTOP;
      return EBreakpointDevice.WIDE;
    })(),
  };
};
