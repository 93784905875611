import {dynaObjectScan} from "dyna-object-scan";

export const clearDynamicValuesForSnapshots = (
  obj: any,
  customProperties:
    | string[]
    | ((propertyName: string, value: string) => string)
  = [],
): any => {
  if (obj === null) return null;
  const obj_ = JSON.parse(JSON.stringify(obj));
  const defaultProperties: string[] = [
    'id',
    'docId',
    'date',
    'createdAt',
    'createdBy',
    'updatedAt',
    'updatedBy',
    'archivedAt',
    'archivedBy',
    'deletedAt',
    'deletedBy',
  ];
  const properties: string[] = [
    ...defaultProperties,
    ...(Array.isArray(customProperties) ? customProperties : []),
  ];
  const replaceFunction:
    | ((propertyName: string, value: string) => string)
    | null
    =
    Array.isArray(customProperties)
      ? null
      : customProperties;
  dynaObjectScan(obj_, (
    {
      propertyName,
      parent,
    },
  ) => {
    if (!propertyName) return;

    if (replaceFunction) parent[propertyName] = replaceFunction(propertyName, parent[propertyName]);

    if (!properties.includes(propertyName)) return;

    if (parent[propertyName] instanceof Date) {
      parent[propertyName] =
        parent[propertyName] > 0
          ? `---NUMBER>0---`
          : `---NUMBER=0---`;
    }
    else if (parent[propertyName].valueOf() === undefined) {
      parent[propertyName] = "---UNDEFINED---";
    }
    else if (parent[propertyName].valueOf() === null) {
      parent[propertyName] = "---NULL---";
    }
    else if (typeof parent[propertyName].valueOf() === "number") {
      parent[propertyName] =
        parent[propertyName] > 0
          ? `---NUMBER>0---`
          : `---NUMBER=0---`;
    }
    else if (typeof parent[propertyName].valueOf() === "string") {
      parent[propertyName] =
        parent[propertyName] === ""
          ? `---STRING_EMPTY---`
          : `---STRING_NON_EMPTY---`;
    }
    else {
      parent[propertyName] = `---VALUE---`;
    }
  });
  return obj_;
};
export const clearArrayFromDynamicValuesForTestSnapshots = (
  array: any[],
  customProperties:
    | string[]
    | ((propertyName: string, value: string) => string)
  = [],
): any => {
  return array.map(v => clearDynamicValuesForSnapshots(v, customProperties));
};

clearArrayFromDynamicValuesForTestSnapshots([], (p, v) => {
  p;
  return v;
});
