import {ILocation} from "../../interfaces/ILocation";

export const API_PATH_ApiDeviceMgmtLocationCreate = '/services/devices-mgtm/apiDeviceMgmtLocationCreate';

export interface IApiDeviceMgmtLocationCreateBodyRequest {
  locationId: string;
  showOnMap: boolean;
  maintenanceMode: boolean;
  regionId: string;
  beforeLocationId: string;
  label: string;
  description: string;
  geoPosition: {
    lat: number;
    lng: number;
    alt?: number;
  };
  notes: string;
}

export interface IApiDeviceMgmtLocationCreateResponse {
  location: ILocation;
}
