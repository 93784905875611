import {
  useRef,
  useEffect,
} from 'react';

export interface IUseSetTimeoutArgs {
  cb: () => void;
}

export interface IUseSetTimeoutApi {
  start: (ms: number) => void;
  clear: () => void;
}

export const useSetTimeout = ({cb}: IUseSetTimeoutArgs): IUseSetTimeoutApi => {
  const refTimeoutId = useRef<number | null>(null);

  const start = (ms: number) => {
    clear();
    refTimeoutId.current = window.setTimeout(cb, ms);
  };

  const clear = () => {
    if (refTimeoutId.current === null) return;
    window.clearTimeout(refTimeoutId.current);
    refTimeoutId.current = null;
  };

  useEffect(() => {
    return clear;
  }, []);

  return {
    start,
    clear,
  };
};
