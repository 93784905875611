import {
  useEffect,
  useState,
} from "react";

import {
  dynaError,
  IDynaError,
} from "dyna-error";
import {dynaSwitchEnum} from "dyna-switch";

export interface IDependency {
  type: EDependencyType;
  src: string;
  crossOrigin?:
    | "anonymous"
    | "use-credentials";
  integrity?: string;
  /**
   * Used only for EDependencyType.SCRIPT. Otherwise, the Dev Error 20240807123826 is consoled
   *
   * @default false
   */
  defer?: boolean;
  /**
   * Used only for EDependencyType.SCRIPT. Otherwise, the Dev Error 20240807123827 is consoled
   *
   * @default true
   */
  async?: boolean;
}

export enum EDependencyType {
  STYLE = "STYLE",
  SCRIPT = "SCRIPT",
}

/**
 * Load external dependencies like `<style type="text/css" href="...">` & `<script src="...">`
 * only when they are rendered.
 *
 * Once loaded, they stay in the DOM, are not removed on unmount.
 *
 * Dependencies can't be re-imported in the DOM, so this component can safely be used with the same
 * dependencies from multiple other components that are used on the same time or not.
 */
export const useLoadExternalDependencies = (dependencies: IDependency[]): {
  isLoading: boolean;
  errors: IDynaError[];
} => {
  const [loadedCount, setLoadedCount] = useState(0);
  const [loadErrors, setLoadErrors] = useState<IDynaError[]>([]);

  useEffect(() => {
    dependencies.forEach(dependency => {
      const {
        type,
        src,
        crossOrigin = "anonymous",
        integrity = "",
        defer = false,
        async: _async = true,
      } = dependency;
      dynaSwitchEnum<EDependencyType, () => undefined>(
        type,
      {
        [EDependencyType.STYLE]: () => {
          if (document.querySelector(`link[href="${src}"]`)) {
            setLoadedCount(loadedCount => loadedCount + 1);
            return; // Exit, it is already loaded by a parent
          }
          const styleLink = document.createElement("link");
          styleLink.rel = "stylesheet";
          styleLink.href = src;
          styleLink.crossOrigin = crossOrigin;
          styleLink.integrity = integrity;
          if (defer !== undefined) console.error('Dev error EDependencyType: `defer` cannot be used to STYLE dependency', dependency);
          if (_async !== undefined) console.error('Dev error EDependencyType: `async` cannot be used to STYLE dependency', dependency);
          styleLink.addEventListener('load', () => {
            setLoadedCount(loadedCount => loadedCount + 1);
          });
          styleLink.addEventListener('error', error => {
            setLoadErrors(
              loadErrors => loadErrors.concat(dynaError({
                code: 20240722154819,
                message: `Cannot load style dependency due to error: ${error.message || "Unknown, check the console"}`,
                parentError: error,
                data: {dependency},
              })),
            );
          });
          document.head.appendChild(styleLink);
          return undefined;
        },
        [EDependencyType.SCRIPT]: () => {
          if (document.querySelector(`script[src="${src}"]`)) {
            setLoadedCount(loadedCount => loadedCount + 1);
            return; // Exit, it is already loaded by a parent
          }
          const scriptLink = document.createElement("script");
          scriptLink.async = _async;
          scriptLink.defer = defer;
          scriptLink.src = src;
          scriptLink.crossOrigin = crossOrigin;
          scriptLink.integrity = integrity;
          scriptLink.addEventListener('load', () => {
            setLoadedCount(loadedCount => loadedCount + 1);
          });
          scriptLink.addEventListener('error', error => {
            setLoadErrors(
              loadErrors => loadErrors.concat(dynaError({
                code: 20240722154820,
                message: `Cannot load script dependency due to error: ${error.message || "Unknown, check the console"}`,
                parentError: error,
                data: {dependency},
              })),
            );
          });
          document.head.appendChild(scriptLink);
          return undefined;
        },
      },
      )();
    });
  }, []);

  const isLoading = loadedCount + loadErrors.length < dependencies.length;

  return {
    isLoading,
    errors: loadErrors,
  };
};
