import {EDeviceType} from "../../../../device-management/interfaces/EDeviceType";
import {ITrafficStudy} from "../../../interfaces/ITrafficStudy";

export const API_PATH_ApiTrafficStudyPost = '/services/traffic-studies/apiTrafficStudyPost';

export interface IApiTrafficStudyPostRequest {
  deviceId: string;
  deviceType: EDeviceType;
  label: string;
}

export type IApiTrafficStudyPostResponse = ITrafficStudy;
