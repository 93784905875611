/**
 * Interface representing the configuration for a field or column.
 */


export interface IFieldConfiguration {
  //#region "Data"

  /**
   * The field name for the column.
   *
   * It is used as a key of the dataset.
   *
   * By this value, the client will resolve the data from the data object/row.
   *
   * Supports deep definition for nested data.
   */
  fieldName: string;

  /**
   * Used when the key in the data differs from `fieldName` to specify a different key to use for resolving the value.
   *
   * Supports deep definition for nested data.
   */
  sourceFieldName?: string;

  /**
   * Test ID for the column.
   */
  testId?: string;

  //#endregion "Data"

  //#region "UI settings"

  /**
   * The label content for the column.
   *
   * Will be used for header input or label of input control.
   */
  label: ITranslatedContent;

  /**
   * The alignment option for the value.
   */
  align?: EAlign;

  /**
   * Tooltip content for the column.
   */
  tooltip?: ITranslatedContent;

  /**
   * Description content for the column.
   */
  description?: ITranslatedContent;

  /**
   * Specifies, where this value will be placed
   */
  placeId?: string;

  /**
   * Desired component setup to render this field
   */
  component?: IFieldComponentSetup<any>;

  /**
   * Directions to show this data as link
   */
  link?: ILink;

  //#endregion "UI settings"

  //#region "Value format"

  /**
   * The value type for the column.
   *
   * According to this type
   * - some properties will be used for formatting
   * - the format should be from a specific enum, check out the `format` for more
   *
   * For all other types of object data, assign a string value that the client supports.
   * Example: "groupware" the data may be a complex object but the client who supports this valueType knows how to read the data and render it.
   */
  valueType:
    | EValueType
    | string;       // For all other kinds of object data, the

  /**
   * The currency symbol for CURRENCY valueType, required for CURRENCY!
   *
   * Used for CURRENCY only valueType
   *
   * Format: ISO 4217 https://en.wikipedia.org/wiki/ISO_4217
   *
   * Examples (usd, eur)
   */
  currency?: string;

  /**
   * The currency symbol position.
   *
   * Undefined for auto.
   *
   * Used for CURRENCY only valueType
   *
   * Examples (usd, eur)
   */
  currencySymbolPosition?:
    | "auto"
    | "front"
    | "end";

  /**
   * The precision of a number.
   *
   * Used for NUMBER, CURRENCY valueTypes
   *
   * Positive value is for rounding decimals while negative is for rounding integers.
   */
  precision?: number;

  /**
   * For the leading zeros of a number, specify the length of integer portion of the number
   *
   * Used for INTEGER, NUMBER, CURRENCY, DURATION (INDUSTRIAL_MINUTES & INDUSTRIAL_HOURS) valueTypes
   */
  leadingZeros?: number;

  /**
   * For the trailing zeros of a number, specify the length decimal portion of the number
   *
   * Used for NUMBER, CURRENCY, DURATION (INDUSTRIAL_MINUTES & INDUSTRIAL_HOURS) valueTypes
   */
  trailingZeros?: number;

  /**
   * Which symbol will be used as decimal point. Undefined for the default of the locale.
   *
   * Used for NUMBER, CURRENCY, DURATION (INDUSTRIAL_MINUTES & INDUSTRIAL_HOURS) valueTypes
   */
  decimalPoint?: string;

  /**
   * Which symbol will be used as thousands seperator.
   *
   * Undefined for none, "auto" for the default of the locale on any other char
   *
   * Used for INTEGER, NUMBER, CURRENCY, DURATION (INDUSTRIAL_MINUTES & INDUSTRIAL_HOURS) valueTypes
   *
   * The symbols will be resolved according to the locale.
   */
  thousandsSeparator?: "auto" | string;

  /**
   * The format Date required for DATE & DATE_TIME valueTypes.
   */
  dateFormatter?: EDateFormatter;

  /**
   * The format Time required for TIME & DATE_TIME valueTypes.
   */
  timeFormatter?: ETimeFormatter;

  /**
   * Order for date time format
   *
   * Used for DATE_TIME valueType only
   * */
  dateTimeOrder?: EDateTimeOrder;

  /**
   * The format Duration required for DURATION valueType.
   * The value should be in ms (milliseconds)
   */
  durationFormatter?: EDurationFormatter;

  pdfWidth?: number;

  /**
   * An optional locale override to replace the user's locale (not recommended!).
   *
   * Format: ISO 3166-1 alpha-2 https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
   *
   * Examples: en-US, de-AT, de-DE
   */
  locale?: string;

  //#endregion "Value format"
}

export type TInputValueType =
  | string
  | number
  | Date
  ;

export interface ITranslatedContent {
  tk?: string;
  default?: string;
}

export interface ILink {
  href: string;           // Supports {{variables}} of the given dataset
  newWindow?: boolean;
}

export interface IFieldComponentSetup<TProps> {
  component: EComponentName;
  props?: TProps;
}

export enum EComponentName {
  PROFILE_ICON = "PROFILE_ICON",
}

export enum EAlign {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  CENTER = "CENTER",
}

export enum EValueType {
  /**
   * Simple text, as sting
   *
   * Configuration: value
   */
  TEXT = "FC__TEXT",
  /**
   * Integer (number without decimal points)
   *
   * Configuration: value, leadingZeros, thousandsSeparator
   */
  INTEGER = "FC__INTEGER",
  /**
   * As number (the precision will be used)
   *
   * Configuration: value, ?precision, ?leadingZeros, ?trailingZeros, ?thousandsSeparator, ?decimalPoint
   */
  NUMBER = "FC__NUMBER",
  /**
   * As number (the precision will be used)
   *
   * Configuration: value, currency, ?currencySymbolPosition, ?precision, ?leadingZeros, ?trailingZeros, ?thousandsSeparator, ?decimalPoint
   */
  CURRENCY = "FC__CURRENCY",              // Examples (usd, eur), (precision will be used if procided)
  /**
   * Date
   *
   * Configuration: value, formatDate, ?timezone, ?overrideUserLocale
   */
  DATE = "FC__DATE",
  /**
   * Time
   *
   * Configuration: value, formatTime, ?timezone, ?overrideUserLocale
   */
  TIME = "FC__TIME",
  /**
   * Date time
   *
   * Configuration: value, formatDate, formatTime, ?dateTimeOrder, ?timezone, ?overrideUserLocale
   */
  DATE_TIME = "FC__DATE_TIME",
  /**
   * Date time
   *
   * Configuration: value (in ms only), ?formatDuration, ?precision, ?leadingZeros, ?trailingZeros, ?decimalPoint, ?thousandsSeparator, ?overrideUserLocale
   */
  DURATION = "FC__DURATION",
}

export enum EDateFormatter {
  HIDDEN = "HIDDEN",
  // Combinations
  STANDARD = 'STANDARD',                  // Classic DD/MM/YYYY according to the current locale
  LONG_DATE = 'LONG_DATE',
  FULL_DATE = 'FULL_DATE',
  YEAR_MONTH = 'YEAR_MONTH',
  MONTH_YEAR = 'MONTH_YEAR',
  // Week
  DAY_OF_WEEK = 'DAY_OF_WEEK',
  // Month
  SHORT_MONTH = 'SHORT_MONTH',
  FULL_MONTH = 'FULL_MONTH',
  // Year
  SHORT_YEAR = 'SHORT_YEAR',
  FULL_YEAR = 'FULL_YEAR',
  // ISO
  ISO = 'ISO',
}

export enum ETimeFormatter {
  HIDDEN = "HIDDEN",
  HOUR = "HOUR",
  HOUR_MINUTE = 'HOUR_MINUTE',
  HOUR_MINUTE_SECONDS = 'HOUR_MINUTE_SECONDS',
  HOUR_MINUTE_SECONDS_MILLISECONDS = 'HOUR_MINUTE_SECONDS_MILLISECONDS',
}

export enum EDateTimeOrder {
  DATE_TIME = "DATE_TIME",
  TIME_DATE = "TIME_DATE",
}

export enum EDurationFormatter {
  INDUSTRIAL_MINUTES = "INDUSTRIAL_MINUTES",
  INDUSTRIAL_HOURS = "INDUSTRIAL_HOURS",
  HOURS_MINUTES = 'HOURS_MINUTES',
  HOURS_MINUTES_SECONDS = 'HOURS_MINUTES_SECONDS',
  DAYS_HOURS_MINUTES = 'DAYS_HOURS_MINUTES',
  MINUTES = 'MINUTES',
  SECONDS = 'SECONDS',
  HOURS_ONLY = 'HOURS_ONLY',
  DAYS_ONLY = 'DAYS_ONLY',
  FULL_DURATION = 'FULL_DURATION',
  SHORT_DURATION = 'SHORT_DURATION',
  LONG_DURATION = 'LONG_DURATION',
  SHORT_HOURS_MINUTES = 'SHORT_HOURS_MINUTES',
  SHORT_HOURS_MINUTES_SECONDS = 'SHORT_HOURS_MINUTES_SECONDS',
}

