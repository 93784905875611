import {
  SxProps,
  Theme,
} from "../ThemeProvider";
import {
  sxTransition,
  ECSSDuration,
} from "../sxTransition";
import {TCSSAttr} from "../sxTransition";

export const sxHover = (
  {
    hover = true,
    pointer = false,
    additionalTransformCssAttrs = [],
  }: {
    hover?: boolean;
    pointer?: boolean;
    additionalTransformCssAttrs?: TCSSAttr[];
  } = {},
): SxProps<Theme> => ({
  transition: theme =>
    sxTransition(
      theme,
      [
        'background-color',
        ...additionalTransformCssAttrs,
      ],
      ECSSDuration.SHORT,
    ),
  ':hover': {
    backgroundColor: theme => hover ? theme.palette.grayShades.gray2 : undefined,
    cursor: pointer ? 'pointer' : undefined,
  },
  ':active': {backgroundColor: theme => hover ? theme.palette.grayShades.gray3 : undefined},
});
