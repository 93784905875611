import * as React from "react";
import {ReactElement} from "react";

import {useTheme} from "../ThemeProvider";

import {CircularProgressIcon} from "./components/CircularProgressIcon";
import {DoublePulseProgressIcon} from "./components/DoublePulseProgressIcon";
import {convertSizeToPx} from "../utils";

export interface IProgressIconProps {
  show?: boolean;
  iconType?: EProgressIconType;
  size?: number | string; // Spacing value, number px or string with px
}

export enum EProgressIconType {
  MUI_CIRCULAR_PROGRESS = "MUI_CIRCULAR_PROGRESS",
  DOUBLE_PULSE = "DOUBLE_PULSE",
}

export const ProgressIcon = (props: IProgressIconProps): ReactElement | null => {
  const {
    show = true,
    iconType = localStorage.getItem(LS_KEY) || EProgressIconType.MUI_CIRCULAR_PROGRESS,
    size: userSize = 16,
  } = props;
  const theme = useTheme();
  const sizePx = convertSizeToPx(theme, userSize);
  const width = parseFloat(sizePx);

  if (!show) return null;

  switch (iconType) {
    case EProgressIconType.MUI_CIRCULAR_PROGRESS:
      return <CircularProgressIcon width={width}/>;
    case EProgressIconType.DOUBLE_PULSE:
      return <DoublePulseProgressIcon sizePx={sizePx}/>;
    default:
      console.error(`ProgressIcon 20230618165628 error: iconType: [${iconType}] is unknown`);
      return <CircularProgressIcon width={width}/>;
  }
};

const LS_KEY = 'Dyna-ProgressIcon--default';
export const setDefaultProgressIcon = (iconType: EProgressIconType): void => localStorage.setItem(LS_KEY, iconType);
