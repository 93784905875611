export const API_PATH_ApiDeviceMgmtRegionChangeOrder = '/services/devices-mgtm/apiDeviceMgmtRegionChangeOrder';

export interface IApiDeviceMgmtRegionChangeOrderBodyRequest {
  regionId: string;
  beforeRegionId: string;
}

export interface IApiDeviceMgmtRegionChangeOrderResponse {
  ok: true;
}
