export const API_PATH_ApiTrafficStudyPostVote = '/services/traffic-studies/apiTrafficStudyPostVote';

export interface IApiTrafficStudyPostVoteRequest {
  trafficStudyId: string;
  postId: string;
  vote: 1 | -1;
}

export interface IApiTrafficStudyPostVoteResponse {
  ok: true;
}
