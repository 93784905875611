import {EWorkZonesManagementRights} from "mhc-server";

import {IAppRoute} from "../../application/config/IAppRoute";
import OfflineIcon from "@mui/icons-material/CloudCircle";

export const routeWorkzoneOfflinePaths: IAppRoute = {
  title: 'Offline',
  description: 'Prepare CIMs for offline use',
  icon: <OfflineIcon/>,
  routePath: '/cimt/offline',
  getRoutePath: () => routeWorkzoneOfflinePaths.routePath,
  menuId: 'CIMs-offline',
  userHasAllRights: [EWorkZonesManagementRights.WORKZONES_EDIT],
  userHasAnyOfRights: [],
  render: () => null,
};
