import moment, {unitOfTime} from "moment";
import {Moment} from "moment";

export type TUnitOfTime = unitOfTime.Diff;

export const getDateTimeDiff = (
  a: Date | number | string | Moment,
  b: Date | number | string | Moment,
  unitOfTime: TUnitOfTime,
): number => {
  return Math.abs(
    moment(a)
      .diff(moment(b), unitOfTime),
  );
};
