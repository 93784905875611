import * as React from "react";

import {Marker} from "react-leaflet";

import {IGeoPosition} from "utils-library/dist/commonJs/geo";
import {
  headingCircle,
  IHeadingSlice,
} from "./headingCircle";

export interface IHeadingSelectorProps {
  position: IGeoPosition;
  headings: IHeadingSlice[];
  circleRadius?: number;
  readOnly?: boolean;
  onSliceClick?: (x: any) => void;
  onSelectDeselectClick?: () => void;
}

export const HeadingSelector = (props: IHeadingSelectorProps): JSX.Element => {
  const {
    position,
    circleRadius = 30,
    headings = [],
    readOnly = true,
    onSliceClick,
    onSelectDeselectClick,
  } = props;

  return (
    <Marker
      position={position}
      icon={headingCircle({
        radius: circleRadius,
        slices: headings,
        readOnly,
        onSliceClick,
        onSelectDeselectClick,
      })}
    />
  );
};
