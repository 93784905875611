import axios from "axios";
import * as qs from "qs";
import {TObject} from "../typescript";

export const apiFetch = async <
  TQueryRequest extends TObject | null | void,
  TBodyRequest extends TObject | null | void,
  TResponse extends TObject | null | void,
>(
  {
    path,
    method = "GET",
    body,
    query,
    headers = {},
    jsonHeader = true,
    withCredentials,
    auth,
  }: {
    path: string;
    method?: 'GET' | 'POST' | 'PUT' | 'HEAD' | 'DELETE' | 'PATCH' | 'OPTIONS';
    body?: TBodyRequest;
    query?: TQueryRequest;
    headers?: any;
    jsonHeader?: boolean;       // Default is true
    withCredentials?: boolean;  // Default is false
    auth?: {
      username: string;
      password: string;
    };
  },
): Promise<TResponse> => {
  const applyHeaders = {...headers};
  if (jsonHeader) applyHeaders['content-type'] = 'application/json; charset=utf-8';

  try {
    const response = await axios.request({
      url: [
        path,
        query ? `?${qs.stringify(query)}` : '',
      ].join(''),
      method,
      data: body,
      headers: applyHeaders,
      withCredentials,
      auth,
    });
    return response.data;
  }
  catch (error) {
    if (error?.response?.data) throw error?.response?.data;
    throw error;
  }
};
