import * as React from "react";

import {isMac} from "utils-library/dist/commonJs/web";

import {Box} from "../Box";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";

import {useBreakpointDevice} from "../useBreakpointDevice";
import {TBoxSpacing} from "../ui-interfaces";

export interface IButtonBarProps {
  sx?: SxProps<Theme>;
  inline?: boolean;               // Default is false
  show?: boolean;
  align?: EButtonBarAlign;        // Default is LEFT
  spacing?: TBoxSpacing;          // Default is 1
  noHorizontalSpace?: boolean;    // Default is false
  noVerticalSpace?: boolean;      // Default is false
  nowrap?: boolean;               // Default is false
  wrapOnMobile?: boolean;         // Default is false
  reverseOnMac?: boolean;         // Default is false
  children: any;                  // Button components
}

export enum EButtonBarAlign {
  LEFT = "flex-start",
  CENTER = "center",
  RIGHT = "flex-end",
}

export const ButtonBar = (props: IButtonBarProps): JSX.Element | null => {
  const {
    sx = {},
    inline = false,
    show = true,
    align = EButtonBarAlign.LEFT,
    spacing = 1,
    noHorizontalSpace = false,
    noVerticalSpace = false,
    nowrap = false,
    wrapOnMobile = false,
    reverseOnMac = false,
    children,
  } = props;
  const {isMobile} = useBreakpointDevice();

  if (!show) return null;

  return (
    <Box
      sx={{
        display: inline ? 'inline-flex' : 'flex',
        flexWrap: (() => {
          if (isMobile && wrapOnMobile) return "wrap";
          return nowrap ? 'nowrap' : 'wrap';
        })(),
        flexDirection: isMac && reverseOnMac ? 'row-reverse' : undefined,
        justifyContent: isMac && reverseOnMac ? undefined : align,
        paddingTop: noVerticalSpace ? undefined : spacing,
        paddingLeft: noHorizontalSpace ? undefined : spacing,
        '& .MuiButton-root': {
          verticalAlign: 'bottom',
          marginBottom: noVerticalSpace ? undefined : spacing,
          marginRight: spacing,
          [`&:${isMac && reverseOnMac ? 'first' : 'last'}-child`]: {marginRight: noHorizontalSpace ? 0 : undefined},
        },
        overflowX: isMobile ? "auto" : undefined,
        ...sx,
      }}
      dataComponentName="ButtonBar"
    >
      {children}
    </Box>
  );
};
