import * as React from "react";
import {ReactElement} from "react";

import MuiSnackbar from "@mui/material/Snackbar";

export interface ISnackbarProps {
  show?: boolean;
  position?: {
    horizontal:
      | 'center'
      |'left'
      |'right';
    vertical:
      |'bottom'
      |'top';
  };
  children: ReactElement;
}

export const Snackbar: React.FC<ISnackbarProps> = (
  {
    show,
    position,
    children,
  },
) => {
  return (
    <MuiSnackbar
      open={show}
      anchorOrigin={position}
    >
      {children}
    </MuiSnackbar>
  );
};
