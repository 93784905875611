import * as React from "react";
import {
  ReactElement,
  useState,
  useEffect,
} from "react";

import {dynaSwitchEnum} from "dyna-switch";

import {IMuiIcon} from "utils-library/dist/commonJs/typescript";

import {Box} from "../Box";
import {IconViewer} from "../IconViewer";

import {createIcon} from "../IconComponent";
import SuccessIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Report';
import InfoIcon from '@mui/icons-material/Info';
import DebugIcon from '@mui/icons-material/BugReport';
import HelpIcon from '@mui/icons-material/Help';

export interface IIconStatusProps {
  status: EIconStatus;
  sizePx?: number;
  color?: boolean;                // Default is true
  animation?: boolean;            // Default is true
  delayAnimationStartMs?: number; // This is needed when the parent is shown with animation, like Modals
  inline?: boolean;               // Default is false
  center?: boolean;               // Default is true, but is effective when not inline!
  message?: string | ReactElement;
}

export enum EIconStatus {
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  ERROR = "ERROR",
  INFO = "INFO",
  DEBUG = "DEBUG",
  HELP = "HELP",
}

export const IconStatus: React.FC<IIconStatusProps> = (
  {
    status,
    sizePx = 64,
    color = true,
    animation = true,
    delayAnimationStartMs = 0,
    inline = false,
    center = true,
    message,
  },
) => {
  const [startAnimation, setStartAnimation] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => setStartAnimation(true), delayAnimationStartMs);
  }, []);

  return (
    <Box
      dataComponentName="IconStatus"
      inline={inline}
      sx={{textAlign: center ? 'center' : undefined}}
    >
      <IconViewer
        sx={{
          color: theme => (() => {
            if (!color) return undefined;
            return dynaSwitchEnum<EIconStatus, string>(
              status,
              {
                [EIconStatus.SUCCESS]: theme.palette.success.main,
                [EIconStatus.WARNING]: theme.palette.warning.main,
                [EIconStatus.ERROR]: theme.palette.error.main,
                [EIconStatus.INFO]: theme.palette.info.main,
                [EIconStatus.DEBUG]: theme.palette.error.dark,
                [EIconStatus.HELP]: theme.palette.info.light,
              },
            );
          })(),
          transform:
            animation
              ? startAnimation
                ? undefined
                : "rotateY(90deg)"
              : undefined,
          transition:
            animation
              ? "transform 250ms ease"
              : undefined,
        }}
        width={sizePx}
        height={sizePx}
        Icon={
          createIcon.byMuiIcon(
            dynaSwitchEnum<EIconStatus, IMuiIcon>(
              status,
              {
                [EIconStatus.SUCCESS]: SuccessIcon,
                [EIconStatus.WARNING]: WarningIcon,
                [EIconStatus.ERROR]: ErrorIcon,
                [EIconStatus.INFO]: InfoIcon,
                [EIconStatus.DEBUG]: DebugIcon,
                [EIconStatus.HELP]: HelpIcon,
              },
            ),
          )
        }
      />
      <Box
        show={!!message}
        sx={{
          marginTop: theme => theme.spacing(0.5),
          fontSize: theme => theme.typography.fontSize * 0.9,
          opacity: 0.8,
        }}
      >
        {message}
      </Box>
    </Box>
  );
};
