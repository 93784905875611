import {IGeoPosition} from "utils-library/dist/commonJs/geo";

export const API_PATH_ApiLanePlanningPut = '/services/lane-planning/apiLanePlanningPut';

export interface IApiLanePlanningPutRequest<TConfig, TData> {
  id: string;
  groupIds?: string[];
  position?: IGeoPosition;
  config?: Partial<TConfig>;      // Partial config object, deep values also supported
  data?: Partial<TData>;          // Partial data object, deep values also supported
}

export interface IApiLanePlanningPutResponse {
  ok: true;
}
