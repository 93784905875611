export {
  Table,
  ITableProps,
} from "./Table";

export {
  IColumn,
  EColumnAlign,
  ITableSort,
  ETableSize,
} from "./interfaces";
