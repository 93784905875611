import Dexie from 'dexie';
import {guid} from "dyna-guid";
import {syncPromises} from "dyna-sync";
import {dynaError} from "dyna-error";

import {searchTextEngine} from "utils-library/dist/commonJs/utils";

import {ICRUDDoc} from "./ICRUDDoc";

const SEARCH_TEXT_FIELD_NAME = '__browserDb_dbSearchText';

export interface IBrowserDbCollectionConfig<TSchema extends ICRUDDoc> {
  dbName: string;
  version?: number;
  collectionName: string;
  keyFieldName: string;
  indexFieldNames?: string[];
  buildSearchContent?: (data: TSchema) => string[];

  onCreate?: (doc: TSchema) => Promise<void>;
  onUpdate?: (key: string, doc: Partial<TSchema>) => Promise<void>;
  onDelete?: (key: string, doc: Partial<TSchema>) => Promise<void>;
  onUndelete?: (key: string, doc: Partial<TSchema>) => Promise<void>;
  onPermanentDelete?: (key: string) => Promise<void>;
}

export class BrowserDbCollection<TSchema extends ICRUDDoc> {
  private readonly db: Dexie;

  constructor(private readonly config: IBrowserDbCollectionConfig<TSchema>) {
    const {
      dbName,
      version = 0,
      collectionName,
      indexFieldNames = [],
      keyFieldName,
    } = config;
    // Create db per collection since we don't know the number of collections in advance.
    this.db = new Dexie(`${dbName}--${collectionName}--v${version}`);
    this.db
      .version(1)
      .stores({
        [collectionName]: [
          keyFieldName,
          SEARCH_TEXT_FIELD_NAME,
          ...indexFieldNames,
        ].join(', '),
      });
  }

  public get collectionName(): string {
    return this.config.collectionName;
  }

  /**
   * Creates or updates (merging) a document by the keyFieldName of the collection
   * If the fields with name keyFieldName has not value, this methods assigns a guid and creates the document
   * @param doc
   * @param [params] - Parameters for the update
   * @param [params.newerOnly] - If the document doesn't exist, create it. If exists, update it if the updateAt is newer this the existed one.
   * @param [params.externalImport] - When the item doesn't exist locally, preserve the timestamps as this update is an import from an external DB resource.
   */
  public async update(
    doc: Partial<TSchema>,
    params?: {
      newerOnly?: boolean;        // Default is false
      merge?: boolean;            // Default is true
      externalImport?: boolean;   // Default is false
    },
  ): Promise<string> {
    const _doc: TSchema = {...doc} as any;
    const {
      newerOnly = false,
      merge = true,
      externalImport = false,
    } = params || {};
    if (localStorage.getItem('_debug_offlineMessages') === 'true') {
      console.log('DEBUG-OFFLINE: DB_BROWSER_COLLECTION__UPDATE', this.config.collectionName, {
        doc,
        params,
      });
    }

    if (!this.db.isOpen()) await this.db.open();
    const now = Date.now();

    // Has no key, it is new, create key and create doc
    if (!_doc[this.config.keyFieldName]) {
      const newKey = _doc[this.config.keyFieldName] = guid();
      _doc.createdAt = now;
      _doc.updatedAt = now;
      _doc.deletedAt = 0;
      await this.db.table(this.collectionName).add(this.convertDocToDbDoc(_doc));
      if (!externalImport && this.config.onCreate) await this.config.onCreate(_doc);
      return newKey;
    }

    // It has key, find it and update it
    const docKey = _doc[this.config.keyFieldName];
    if (_doc.createdAt === undefined) _doc.createdAt = now;
    if (_doc.updatedAt === undefined) _doc.updatedAt = now;
    if (_doc.deletedAt === undefined) _doc.deletedAt = 0;
    const currentDoc = await this.get(docKey);
    if (!externalImport) _doc.updatedAt = now;
    if (newerOnly && currentDoc && currentDoc.updatedAt > _doc.updatedAt) {
      return docKey; // Exit, do not update, the existed doc is newer
    }
    if (currentDoc) {
      const updatedFullDoc =
        merge
          ? {
            ...currentDoc,
            ..._doc,
          }
          : {
            [this.config.keyFieldName]: currentDoc[this.config.keyFieldName],
            ..._doc,
          };
      await this.db.table(this.collectionName).update(docKey, this.convertDocToDbDoc(updatedFullDoc));
      if (!externalImport && this.config.onUpdate) await this.config.onUpdate(docKey, _doc);
      return docKey;
    }

    // Cannot be found to update it, so create it locally
    // This is also the same importing items in this db from anither db
    if (!externalImport) {
      _doc.createdAt = now;
      _doc.updatedAt = now;
      _doc.deletedAt = 0;
    }
    try {
      await this.db.table(this.collectionName).add(this.convertDocToDbDoc(_doc));
    }
    catch (e) {
      if (e.message.startsWith("Failed to execute 'add' on 'IDBObjectStore'")) {
        throw dynaError({
          code: 202104090858,
          message: `The keyFieldName of the [${this.config.collectionName}] collection is currently [${this.config.keyFieldName}] but the indexedDB has a different one. IndexedDB cannot save this document. You have to drop and re-create the collection.`,
          parentError: e,
          data: {
            collectionConfig: this.config,
            doc: _doc,
          },
        });
      }
      throw e;
    }

    if (!externalImport && this.config.onCreate) await this.config.onCreate(_doc).catch();
    return docKey;
  }

  /**
   * Create or update bulk docs.
   * @param docs
   * @param [params] - Parameters for the update
   * @param [params.newerOnly] - If the document doesn't exist, create it. If exists, update it if the updateAt is newer this the existed one.
   * @param [params.externalImport] - When the item doesn't exist locally, preserve the timestamps as this update is an import from an external DB resource.
   */
  public async updateMany(
    docs: TSchema[],
    params?: {
      newerOnly?: boolean;
      merge?: boolean;
      externalImport?: boolean;
    },
  ): Promise<string[]> {
    const _docs = docs.concat();
    return syncPromises<string>(..._docs.map(doc => () => this.update(doc, params)));
  }

  public get = async (key: string): Promise<TSchema | null> => {
    if (!this.db.isOpen()) await this.db.open();
    const searchResults =
      await this.db.table(this.collectionName)
        .where(this.config.keyFieldName)
        .equals(key)
        .toArray()
        .then(this.convertDbDocsToDocs);
    const doc = searchResults[0];
    if (!doc) return null;
    return {...doc};
  };


  public getMany(keys: string[]): Promise<Array<TSchema | null>> {
    return Promise.all(keys.map(this.get));
  }

  public async getAll(): Promise<TSchema[]> {
    if (!this.db.isOpen()) await this.db.open();
    return this.db.table(this.collectionName).toArray();
  }

  public async exists(key: string): Promise<boolean> {
    if (!this.db.isOpen()) await this.db.open();
    const doc = await this.get(key);
    return !!doc;
  }

  public async search(
    {
      searchText,
      sort,
      deleted = false,
      pagination,
    }: {
      searchText: string;
      deleted?: boolean;
      sort?: {
        fieldName: keyof TSchema;
        direction?: 0 | 1 | -1;
      };
      pagination: {
        skip: number;
        limit: number;
      };
    },
  ): Promise<TSchema[]> {
    const {
      collectionName,
      buildSearchContent,
    } = this.config;

    if (!buildSearchContent) console.error(`BrowserDbCollection: collection [${collectionName}] has no the "buildSearchContent" defined and the .search() method called. You have to implemented the "buildSearchContent" on the collection in order to use the search().`);

    const search = searchText.toLocaleLowerCase();

    return this.find({
      filter: (doc: TSchema) => {
        const hasSearchContent = searchTextEngine({
          searchText: search,
          sourceText: (doc[SEARCH_TEXT_FIELD_NAME] || '').toLocaleLowerCase(),
        });
        const isValidByDeleteFlag =
          deleted
            ? doc.deletedAt !== 0
            : doc.deletedAt === 0;
        return hasSearchContent && isValidByDeleteFlag;
      },
      sort,
      pagination,
    });
  }

  public async find(
    {
      filter = () => true,
      sort,
      pagination,
    }: {
      filter?: (doc: TSchema) => boolean;
      sort?: {
        fieldName: keyof TSchema;
        direction?: 0 | 1 | -1;
      };
      pagination: {
        skip: number;
        limit: number;
      };
    },
  ): Promise<TSchema[]> {
    if (!this.db.isOpen()) await this.db.open();
    if (sort && sort.direction === -1) {
      return this.db.table(this.collectionName)
        .orderBy(sort.fieldName as any)
        .filter(filter)
        .reverse()
        .offset(pagination.skip)
        .limit(pagination.limit)
        .toArray()
        .then(this.convertDbDocsToDocs);
    }

    if (sort && sort.direction === 1) {
      return this.db.table(this.collectionName)
        .orderBy(sort.fieldName as any)
        .filter(filter)
        .offset(pagination.skip)
        .limit(pagination.limit)
        .toArray()
        .then(this.convertDbDocsToDocs);
    }

    return this.db.table(this.collectionName)
      .filter(filter)
      .offset(pagination.skip)
      .limit(pagination.limit)
      .toArray()
      .then(this.convertDbDocsToDocs);
  }

  public async delete(key: string): Promise<boolean> {
    const now = Date.now();

    const doc = await this.get(key);
    if (!doc) return false;

    if (doc.deletedAt) return false;

    doc.updatedAt = now;
    doc.deletedAt = now;
    const updatedDocKey = await this.update(doc, {externalImport: true});

    if (!updatedDocKey) console.error('internal error: BrowserDbCollection cannot delete this doc because cannot be found', {key});

    if (!!updatedDocKey && this.config.onDelete) this.config.onDelete(key, doc);

    return !!updatedDocKey;
  }

  public async undelete(key: string): Promise<boolean> {
    const now = Date.now();

    const doc = await this.get(key);
    if (!doc) return false;

    if (doc.deletedAt === 0) return false;

    doc.updatedAt = now;
    doc.deletedAt = 0;
    const updatedDocKey = await this.update(doc, {externalImport: true});

    if (!updatedDocKey) console.error('internal error: BrowserDbCollection cannot delete this doc because cannot be found', {key});

    if (!!updatedDocKey && this.config.onUndelete) this.config.onUndelete(key, doc);

    return !!updatedDocKey;
  }

  public async deletePermanent(key: string, externalImport: boolean = false): Promise<boolean> {
    if (!this.db.isOpen()) await this.db.open();
    const deletedCount = await this.db.table(this.collectionName)
      .where(this.config.keyFieldName)
      .equals(key)
      .delete();
    if (deletedCount > 1) {
      console.error(
        'internal error: BrowserDbCollection delete(key:string): More that one deleted!',
        {
          dbName: this.config.dbName,
          collection: this.config.collectionName,
          key,
        },
      );
    }
    if (!externalImport && !!deletedCount && this.config.onPermanentDelete) this.config.onPermanentDelete(key);
    return !!deletedCount;
  }

  public async drop(): Promise<void> {
    if (!this.db.isOpen()) await this.db.open();
    return this.db.delete();
  }

  public async deleteAll(): Promise<void> {
    if (!this.db.isOpen()) await this.db.open();
    return this.db.table(this.collectionName).clear();
  }

  public async count(): Promise<number> {
    if (!this.db.isOpen()) await this.db.open();
    return this.db.table(this.collectionName).count();
  }

  private convertDocToDbDoc = (doc: TSchema): TSchema => {
    const {buildSearchContent} = this.config;
    const _doc = {...doc};
    _doc[SEARCH_TEXT_FIELD_NAME] =
      buildSearchContent
        ? buildSearchContent(doc).join(' ')
        : '';
    return _doc;
  };

  private convertDbDocsToDocs = (doc: TSchema[]): TSchema[] => {
    return doc.map(this.convertDbDocToDoc);
  };

  private convertDbDocToDoc = (doc: TSchema): TSchema => {
    const _doc = {...doc};
    delete _doc[SEARCH_TEXT_FIELD_NAME];
    return _doc;
  };

}
