import * as React from "react";
import {
  useState,
  useEffect,
  useRef,
} from "react";

import cloneDeep from 'lodash.clonedeep';

import {dynaObjectScan} from "dyna-object-scan";
import {dynaSwitch} from "dyna-switch";
import {
  dynaError,
  IDynaError,
} from "dyna-error";
import {guid} from "dyna-guid";

import {
  validationMergeResults,
  IValidateDataEngineRules,
  IValidationResult,
  validationEngine,
  validationEngineEmptyRules,
  validationEngineCleanResults,
} from "utils-library/dist/commonJs/validation-engine";

import {
  convertStringToNumber,
  getDeepValue,
  setDeepValue,
} from "utils-library/dist/commonJs/utils";

import {
  EAlertType,
  Alert,
} from "../Alert";
import {useConfirm} from "../useConfirm";

export enum EFormType {     // How the form behaves
  VIEW = "VIEW",            // The form is always in view mode, considered read-only.
  VIEW_EDIT = "VIEW_EDIT",  // Use the Edit button (the pencil) to toggle the View/Edit mode. On Save, it turns to View mode.
  EDIT = "EDIT",            // It always stays on Edit mode, like the standard forms. The Edit button is not shown.
}

export enum EFormMode {         // Current status of the form.
  CREATE = "CREATE",            // Create mode is always when the form is creating an item. This is when the id of the item is an empty string. On save, it turns to EDIT_MANAGE mode.
  EDIT_MANAGE = "EDIT_MANAGE",  // This is the Edit mode of the form. This is for existing items. Options un/archive & un/delete are enabled.
}

export enum EViewMode {
  VIEW = "VIEW",
  EDIT = "EDIT",
}

export enum EUpdateMode {
  ON_CHANGE = "ON_CHANGE",
  ON_BLUR = "ON_BLUR",
}

export interface IUseFormArgs<TData, TDataId = string> {
  formType: EFormType;
  updateMode?: EUpdateMode;     // Default is EUpdateMode.ON_BLUR, this makes the form to be rendered on input's blur events instead of on change event, this has significant performance impact.

  emptyFormData: TData;         // Needed for the first render, showing the fields empty
  trimStringValues?: boolean;   // Default is true

  loadDataId: TDataId | null;   // Load form data by id. Null to create data by this form.

  userCanCreate?: boolean;      // Default is true
  userCanEdit?: boolean;        // Default is true
  userCanArchive?: boolean;     // Default is true
  userCanUnarchive?: boolean;   // Default is true
  userCanDelete?: boolean;      // Default is true
  userCanUnDelete?: boolean;    // Default is true

  disabledEditOnArchived?: boolean;             // Default is false
  disabledEditOnDeleted?: boolean;              // Default is false
  hideCancelButtonOnCreateFormMore?: boolean;   // Default is false

  /**
   * Disable automatic mappings:
   * - input type=checkbox  mapped to boolean
   * - input type=number    mapped to number
   */
  disableAutoMapping?: boolean;

  /**
   * Map a value from UI to the state on change event.
   * Triggered on any property change, ability to map the value.
   * You should return the final value of the property, ideally the `newValue`.
   * You can compare the old and new or even the whole form data.
   * The `newValue` is already the result of default mapping (checkboxes, numbers)
   * Return `null` will save the null value to the property
   * Return `undefined` to exit and do not change anything.
   * @param args
   */
  onChangeMapValue?: (args: {
    input: HTMLInputElement;
    propName: string;
    oldValue: any;
    newValue: any;
    formData: TData;
  }) => any | undefined;

  validationRules?: IValidateDataEngineRules<TData>;

  // Api methods
  onApiPost?: (data: TData) => Promise<{ dataId: TDataId; data?: TData }>;
  onApiGet?: (dataId: TDataId, currentData: TData) => Promise<TData>;
  onApiPut?: (data: TData, currentData: TData) => Promise<void | TData>;
  onApiArchive?: (dataId: TDataId, currentData: TData) => Promise<void | TData>;
  onApiUnarchive?: (dataId: TDataId, currentData: TData) => Promise<void | TData>;
  onApiDelete?: (dataId: TDataId, currentData: TData) => Promise<void | TData>;
  onApiUndelete?: (dataId: TDataId, currentData: TData) => Promise<void | TData>;

  // Form events, triggered when the operation is completed
  onAlert?: () => void;                             // Triggered when a new alert is shown
  onBeforeFormSave?: (data: TData) => TData | void; // Optionally change the data before save them (post or put)
  onFormSave?: (data: TData) => void;               // The passed data are the most up to date (from onFormSaveBefore or from server's return)
  onFormCancel?: () => void;
  onFormArchive?: (data: TData) => void;
  onFormUnarchive?: (data: TData) => void;
  onFormDelete?: (data: TData) => void;
  onFormUndelete?: (data: TData) => void;

  _debug_consoleFormChange?: string;
}

export interface IUseFormApi<TData> {
  useFormApi: IUseFormApi<TData>;                   // Exports itself for easier destruction

  formMode: EFormMode;
  viewMode: EViewMode;
  alertViewer: JSX.Element;                         // WARNING: You have to always render this viewer to show the hook's alerts!
  confirmViewer: JSX.Element;                       // WARNING: You have to always render this viewer to show the hook's confirmation dialogs!

  data: TData;

  isNew: boolean;           // True when the Form is on EFormMode.CREATE, and it is still not saved.
  isChanged: boolean;
  changeId: string;         // Changes when something is really changed
  isLoading: boolean;       // True when a network operation is in progress
  isLoadingLabel: string;   // Label for the isLoading operation
  isCanceled: boolean;

  validationStatus: EValidationStatus;
  validationResult: IValidationResult<TData>;   // Validation errors (server or client by validation & validateForm() call)
  networkError: IDynaError | null | unknown;    // Network error, cannot perform a network operation
  loadFatalError: IDynaError | null | unknown;  // If this is not null, do not render the form. The server responded with error 404, 401 or whatever else making imporssible to continue.

  change: (
    data: Partial<TData>,       // Update the data with Partial TData
    silentUpdate?: boolean,     // Update bypassing the viewMode (if it is VIEW) and without mark the form as changed. (Ideal for backend updates).
    changeOrigin?: string,      // A string the indicated who triggered this change. This is used only for debugging reason and is shown only using the _debug_consoleFormChange.
  ) => void;

  changeByInput: (
    value: any,
    name?: keyof TData,         // The `name of the input`. Required: in case f no value consoles.error
    silentUpdate?: boolean,     // Update bypassing the viewMode (if it is VIEW) and without mark the form as changed. (Ideal for backend updates).
    changeOrigin?: string,      // A string the indicated who triggered this change. This is used only for debugging reason and is shown only using the _debug_consoleFormChange.
  ) => void;

  formProps: {
    onReset: (event: any) => void;
    onChange: (event: any) => void;
    onBlur: (event: any) => void;
    onSubmit: (event: any) => void;
  };

  validateForm: (_data?: TData) => boolean;
  setValidationResult: (validationResult: IValidationResult<TData> | null) => void; // Set validation errors.

  save: () => Promise<TData>;
  cancel: () => Promise<void>;
  archive: () => Promise<void>;
  unarchive: () => Promise<void>;
  delete: () => Promise<void>;
  undelete: () => Promise<void>;

  reloadData: () => Promise<void>;
  resetForm: (defaultData?: Partial<TData>) => void;    // Optionally, you can pass default values that will be applied on emptyData.

  addEventListener: (
    eventName:
      | 'load'
      | 'save'
      | 'cancel'
      | 'archive'
      | 'unarchive'
      | 'delete'
      | 'undelete'
    ,
    handler: (args?: any) => void,
  ) => void;

  removeEventListener: (
    eventName:
      | 'load'
      | 'save'
      | 'cancel'
      | 'archive'
      | 'unarchive'
      | 'delete'
      | 'undelete'
    ,
    handler: (args?: any) => void,
  ) => void;

  buttons: {
    edit: {
      show: boolean;
      active: boolean;
      disabled: boolean;
      onClick: () => void;
    };
    save: {
      show: boolean;
      disabled: boolean;
      onClick: () => Promise<TData>;
    };
    cancel: {
      show: boolean;
      disabled: boolean;
      onClick: () => Promise<void>;
    };
    archive: {
      show: boolean;
      disabled: boolean;
      onClick: () => Promise<void>;
    };
    unarchive: {
      show: boolean;
      disabled: boolean;
      onClick: () => Promise<void>;
    };
    delete: {
      show: boolean;
      disabled: boolean;
      onClick: () => Promise<void>;
    };
    undelete: {
      show: boolean;
      disabled: boolean;
      onClick: () => Promise<void>;
    };
  };
}

export enum EValidationStatus {
  NONE = "NONE",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export interface IUseFormAlert {
  show: boolean;
  type: EAlertType;
  title: string;
  children?: string;
}

const ALLOW_CHANGES_ON_BLUR = [
  {
    nodeName: 'INPUT',
    type: 'checkbox',
  },
  {
    nodeName: 'SELECT',
    type: 'select-one',
  },
];

/**
 * Determinate if the input control has only change and not blur event
 * @param event
 */
const isOnlyChangeControl = (event: any): boolean =>
  !!ALLOW_CHANGES_ON_BLUR.find(
    scan =>
      scan.nodeName === event.target.nodeName &&
      scan.type === event.target.type,
  );

export const useForm = <TData, TDataId = string>(
  {
    formType,
    updateMode = EUpdateMode.ON_BLUR,
    emptyFormData,
    trimStringValues = true,
    loadDataId,
    userCanCreate = true,
    userCanEdit = true,
    userCanArchive = true,
    userCanUnarchive = true,
    userCanDelete = true,
    userCanUnDelete = true,

    disabledEditOnArchived = false,
    disabledEditOnDeleted = false,
    hideCancelButtonOnCreateFormMore = false,

    disableAutoMapping = false,

    validationRules = validationEngineEmptyRules<TData>(emptyFormData),

    onApiPost,
    onApiGet,
    onApiPut,
    onApiArchive,
    onApiUnarchive,
    onApiDelete,
    onApiUndelete,

    onAlert,
    onChangeMapValue,
    onBeforeFormSave,
    onFormSave,
    onFormCancel,
    onFormArchive,
    onFormUnarchive,
    onFormDelete,
    onFormUndelete,

    _debug_consoleFormChange,
  }: IUseFormArgs<TData, TDataId>,
): IUseFormApi<TData> => {

  const [formMode, setFormMode] = useState<EFormMode>(
    loadDataId
      ? EFormMode.EDIT_MANAGE
      : EFormMode.CREATE,
  );
  const [viewMode, setViewMode] = useState<EViewMode>(((): EViewMode => {
    if (formType === EFormType.VIEW) return EViewMode.VIEW;
    if (formType === EFormType.EDIT) return EViewMode.EDIT;
    return loadDataId === null ? EViewMode.EDIT : EViewMode.VIEW;
  })());

  const [alert, setAlert] = useState<IUseFormAlert>({
    show: false,
    type: EAlertType.INFO,
    title: '',
    children: undefined,
  });
  const setAlertHide = (): void => {
    setAlert({
      ...alert,
      show: false,
    });
  };

  useEffect(() => {
    if (alert.show) onAlert && onAlert();
  }, [alert.show]);

  const [data, _setData] = useState<TData>(emptyFormData);
  const setData = (
    partialData: Partial<TData>,
    infoSilentUpdate: boolean = false,
    changeOrigin: string = 'useForm user',
  ): void => {
    _setData(data => {
      const newData = {
        ...data,
        ...partialData,
      };
      if (_debug_consoleFormChange) {
        console.log(
          'useForm: formChange',
          _debug_consoleFormChange,
          {
            currentData: {...data},
            updateData: {...partialData},
            newData: {...newData},
            silentUpdate: infoSilentUpdate,
            changeOrigin,
          },
        );
      }
      return newData;
    });
  };
  const [dataId, setDataId] = useState<TDataId | null>(loadDataId);
  const [originalData, setOriginalData] = useState<TData>(emptyFormData);

  const [isNew, setIsNew] = useState<boolean>(formMode === EFormMode.CREATE);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [changeId, setChangeId] = useState<string>(guid());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingLabel, setIsLoadingLabel] = useState<string>('');
  const [isCanceled, setIsCanceled] = useState<boolean>(false);
  const isArchived = (data as any).archivedAt > 0;
  const isDeleted = (data as any).deletedAt > 0;

  const [availablePropPaths, setAvailablePropPaths] = useState<string[]>([]);

  const [validationStatus, _setValidationStatus] = useState<EValidationStatus>(EValidationStatus.NONE);
  const [validationResult, _setValidationResult] = useState<IValidationResult<TData>>(validationEngineCleanResults<TData>(validationRules));
  const setValidationResult = (validationResult: IValidationResult<TData> | null): void => {
    if (validationResult === null) {
      _setValidationResult(validationEngineCleanResults(validationRules));
      _setValidationStatus(EValidationStatus.NONE);
      return;
    }

    _setValidationResult(validationResult);
    _setValidationStatus(
      validationResult.isValid
        ? EValidationStatus.SUCCESS
        : EValidationStatus.FAILED,
    );

    if (!validationResult.isValid) {
      onAlert && onAlert();
    }
  };

  const [networkError, setNetworkError] = useState<any | null>(null);
  const [loadFatalError, setLoadFatalError] = useState<any | null>(null);

  const {
    confirm,
    confirmViewer,
  } = useConfirm();

  const eventListeners = useRef<{
    load: Array<(data: TData) => void>;
    save: Array<() => void>;
    cancel: Array<() => void>;
    archive: Array<() => void>;
    unarchive: Array<() => void>;
    delete: Array<() => void>;
    undelete: Array<() => void>;
      }>({
        load: [],
        save: [],
        cancel: [],
        archive: [],
        unarchive: [],
        delete: [],
        undelete: [],
      });

  const loadFormData = async (): Promise<void | never> => {
    try {
      if (formMode === EFormMode.CREATE) return;  // The form will create the data.
      if (loadDataId === null) return;            // 4TS, It is the same as the above line

      setIsLoading(true);
      setIsLoadingLabel('Loading...');
      setChangeId(guid());

      if (!onApiGet) {
        throw dynaError({
          code: 202102030732,
          message: 'Dev error: onApiGet is not implemented',
          userMessage: 'Dev error: onApiGet is not implemented',
        });
      }

      const loadedData_ = await onApiGet(loadDataId, data);
      const loadedData = cloneDeep(loadedData_);
      setData(loadedData, false, 'useForm: loadFormData');
      setOriginalData(loadedData);
      eventListeners.current.load.forEach(handler => handler(loadedData));
    }
    catch (e) {
      const error: IDynaError = e;
      setNetworkError(error);
      if (error.status === 401) {
        setLoadFatalError(dynaError({
          message: '401 - Access denied',
          userMessage: 'Access denied\nYou don\'t have access to this item.',
        }));
        return;
      }
      if (error.status === 404) {
        setLoadFatalError(dynaError({
          message: error.message || '404 - Not found',
          userMessage: error.userMessage || 'Not found\nCannot find this item.',
        }));
        return;
      }
      setLoadFatalError(error);
    }
    finally {
      setIsLoading(false);
      setChangeId(guid());
      setIsLoadingLabel('');
    }
  };

  // Find the available update paths
  useEffect(() => {
    const availablePaths: string[] = [];
    dynaObjectScan(emptyFormData, ({path}) => {
      if (!path) return;
      availablePaths.push(path.slice(1));
    });
    setAvailablePropPaths(availablePaths);
  }, []);

  // Initial data form load
  useEffect(() => {
    loadFormData();
  }, []);

  const changeProp = <TValue, >(
    {
      propPathName,
      value,
      silentUpdate = false,
      changeOrigin,
      forceRender,
    }: {
      propPathName: string;
      value: TValue;
      silentUpdate: boolean;
      changeOrigin?: string;
      forceRender?: boolean;
    },
  ): void => {
    if (!silentUpdate && viewMode === EViewMode.VIEW) {
      console.error('useForm: `changeProp()` called, while the form is in view mode (and not in edit mode). Most likely, an input control is not read-only.', {
        fieldName: propPathName,
        value,
      });
      return;
    }

    if (!availablePropPaths.includes(propPathName)) {
      console.error(
        `Dev error: useForm.changeProp(): Property name/path [${propPathName}] doesn't not exist on the loaded data and it is not added. Checkout if this name/path is correct.`,
        {availablePropPaths},
      );
      return;
    }

    const currentValue = getDeepValue(data, propPathName);
    if (!forceRender && value === currentValue) return; // Exit, the value is the same.

    setData(
      setDeepValue<TData>(
        {
          data,
          propName: propPathName,
          value,
        })
        .data,
      silentUpdate,
      changeOrigin || 'useForm: changeProp()',
    );

    if (!silentUpdate) {
      setIsChanged(true);
      setChangeId(guid());
    }
  };

  const getFieldNameFromInput = (event: any): string => {
    return event.target.name;
  };

  const getValueFromInput = (event: any): string => {
    return disableAutoMapping
      ? event.target.value
      : dynaSwitch(
        event.target.type,
        event.target.value,
        {
          checkbox: event.target.checked,
          // eslint-disable-next-line id-blacklist
          number: convertStringToNumber(event.target.value, -1),
        },
      );
  };

  const getMappedValueFromInput = (event: any): any => {
    const propPathName = getFieldNameFromInput(event);
    const inputValue = getValueFromInput(event);

    return (
      onChangeMapValue
        ? onChangeMapValue({
          input: event.target,
          propName: propPathName,
          oldValue: getDeepValue(data, propPathName),
          newValue: inputValue,
          formData: data,
        })
        : inputValue
    );
  };

  const trimValueIfNeededOnBlur = (event: any): void => {
    if (!trimStringValues) return;

    const propPathName = getFieldNameFromInput(event);
    if (!propPathName) return;

    const value = getMappedValueFromInput(event);
    if (typeof value !== "string") return;

    const textValue = value.trim();

    const currentValue = getDeepValue(data, propPathName);
    if (currentValue === textValue) return;

    changeProp({
      propPathName,
      value: textValue,
      silentUpdate: true,
      changeOrigin: 'trimValueIfNeededOnBlur',
      forceRender: false,
    });
  };

  const handleFormChange = (event: any): void => {
    const propPathName = getFieldNameFromInput(event);
    if (!propPathName) return;

    if (viewMode === EViewMode.VIEW) {
      console.error('useForm: Form\'s `change` triggered, while the form is in view mode (and not in edit mode). Most likely an input control is not in readonly.', {
        fieldName: propPathName,
        value: getValueFromInput(event),
      });
      return;
    }

    const value = getMappedValueFromInput(event);

    changeProp({
      propPathName,
      value: value,
      silentUpdate: false,
      changeOrigin: 'useForm: <form> change event',
    });
  };

  const useFormApi: IUseFormApi<TData> = {
    useFormApi: null as any, // Updated later with the actual value

    formMode,
    viewMode,

    alertViewer: <Alert {...alert}/>,
    confirmViewer,

    data,

    isNew,
    isChanged,
    changeId,
    isLoading,
    isLoadingLabel,
    isCanceled,

    validationStatus,
    validationResult,
    networkError,
    loadFatalError,

    change: (
      updateData: Partial<TData>,
      silentUpdate: boolean = false,
      changeOrigin: string = 'useForm: change() called',
    ): void => {
      if (!silentUpdate && viewMode === EViewMode.VIEW) {
        console.error('useForm: `change()` called, while the form is in view mode (and not in edit mode). Most likely, an input control is not read-only.', {formData: updateData});
        return;
      }

      if (Object.keys(updateData).length === 0) return; // Nothing to change

      setData(
        updateData,
        silentUpdate,
        changeOrigin,
      );

      if (!silentUpdate) {
        setIsChanged(true);
        setChangeId(guid());
      }
    },

    changeByInput: (
      value,
      name,
      silentUpdate: boolean = false,
      changeOrigin: string = 'useForm: changeByInput() called',
    ): void => {
      if (!name) {
        console.error(
          'useForm: changeByInput() the `name` is not provided',
          {
            data,
            name,
          });
        return;
      }

      setData(
        {[name]: value} as any,
        silentUpdate,
        changeOrigin,
      );

      if (!silentUpdate) {
        setIsChanged(true);
        setChangeId(guid());
      }
    },

    formProps: {
      // It is necessary to stop the propagation for all the below events because this form would be a nested form of another form.
      onReset: (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        useFormApi.resetForm();
      },
      onChange: (event: any) => {
        event.stopPropagation();
        let changeApplied = false;
        if (updateMode === EUpdateMode.ON_CHANGE) {
          handleFormChange(event);
          changeApplied = true;
        }
        if (updateMode === EUpdateMode.ON_BLUR) {
          if (isOnlyChangeControl(event)) {
            // Change it immediately since this control has not a blur event
            handleFormChange(event);
            changeApplied = true;
          }
        }
        if (!changeApplied && !isChanged) {
          setIsChanged(true);
          setChangeId(guid());
        }
      },
      onBlur: (event: any) => {
        event.stopPropagation();
        if (viewMode === EViewMode.VIEW) return;
        if (event.target?.readOnly === true) return;
        if (event.target?.disabled === true) return;

        if (updateMode === EUpdateMode.ON_BLUR) {
          handleFormChange(event);
          trimValueIfNeededOnBlur(event);
        }

      },
      onSubmit: (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        useFormApi.buttons.save.onClick();
      },
    },

    validateForm: (_data?: TData) => {
      setValidationResult(null);
      const validateData = _data || data;
      const validationResult = validationEngine({
        data: validateData,
        validationRules: validationRules,
      });
      setValidationResult(validationResult);
      return validationResult.isValid;
    },

    setValidationResult: setValidationResult,

    save: () => {
      return useFormApi.buttons.save.onClick();
    },
    cancel: () => {
      return useFormApi.buttons.cancel.onClick();
    },
    archive: () => {
      return useFormApi.buttons.archive.onClick();
    },
    unarchive: () => {
      return useFormApi.buttons.unarchive.onClick();
    },
    delete: () => {
      return useFormApi.buttons.delete.onClick();
    },
    undelete: () => {
      return useFormApi.buttons.undelete.onClick();
    },

    reloadData: async () => {
      if (viewMode === EViewMode.EDIT) {
        console.error('useForm: reloadFormData() called while the form is in EDIT mode, only in VIEW can be called');
        return;
      }
      return loadFormData();
    },

    resetForm: (emptyData: Partial<TData> = {}) => {
      setData(
        {
          ...emptyFormData,
          ...emptyData,
        },
        true,
        'useForm: resetForm() called',
      );
      setIsChanged(false);
      setIsCanceled(false);
      setValidationResult(null);
      setNetworkError(null);
      setLoadFatalError(null);
      setChangeId(guid());
    },

    addEventListener: (eventName, handler) => {
      eventListeners.current[eventName].push(handler);
    },

    removeEventListener: (eventName, handler) => {
      eventListeners.current[eventName] =
        eventListeners.current[eventName]
          .filter(scanHandler => scanHandler !== handler) as any;
    },

    buttons: {
      edit: {
        show:
          userCanEdit
          && formType === EFormType.VIEW_EDIT,
        active: viewMode === EViewMode.EDIT,
        disabled:
          isLoading
          || (disabledEditOnArchived && isArchived)
          || (disabledEditOnDeleted && isDeleted),
        onClick: (): void => {
          if (formType === EFormType.VIEW || formType === EFormType.EDIT) {
            console.error('Edit button clicked while the formType === EFormType.EDIT, the button should be hidden!');
            return;
          }
          const newViewMode = viewMode === EViewMode.VIEW ? EViewMode.EDIT : EViewMode.VIEW;
          setViewMode(newViewMode);
          setChangeId(guid());
          if (newViewMode === EViewMode.EDIT && isCanceled) setIsCanceled(false);
          setAlertHide();
        },
      },

      save: {
        show:
          formType !== EFormType.VIEW
          && (
            (userCanCreate && formMode === EFormMode.CREATE) ||
            (userCanEdit && formMode === EFormMode.EDIT_MANAGE)
          ),
        disabled:
          !isChanged
          || isLoading
          || (disabledEditOnArchived && isArchived)
          || (disabledEditOnDeleted && isDeleted),
        onClick: async (): Promise<TData> => {
          const dataToSave: TData = (() => {
            if (!onBeforeFormSave) return data;
            const changedBySaveBeforeData = onBeforeFormSave(data);
            if (changedBySaveBeforeData !== undefined) return changedBySaveBeforeData;
            return data;
          })();

          let updatedData: void | TData = undefined;
          try {
            setValidationResult(null);

            const validationResult = validationEngine({
              data: dataToSave,
              checkForUnexpectedProperties: false, // Do not check, because DB might hold deprecated properties
              validationRules: validationRules,
            });
            if (!validationResult.isValid) {
              // Throw error with the client side validation error as validationResult.
              // These errors will be consumed as server side errors, but this doesn't matter.
              // We have to throw an error because the save() required returning the saved data.
              throw dynaError({
                message: 'Client validation errors',
                validationErrors: {body: validationResult},
              });
            }
            setIsLoading(true);
            setIsLoadingLabel('Saving...');
            setNetworkError(null);
            setAlertHide();
            setChangeId(guid());
            if (formMode === EFormMode.CREATE) {
              if (!onApiPost) {
                throw dynaError({
                  code: 202102030728,
                  message: 'Dev error: onApiPost is not implemented',
                  userMessage: 'Dev error: onApiPost is not implemented',
                });
              }
              const response = await onApiPost(dataToSave);
              updatedData = response.data;
              if (!updatedData) setDataId(response.dataId);
              setIsNew(false);
              setFormMode(EFormMode.EDIT_MANAGE);
            }
            else if (formMode === EFormMode.EDIT_MANAGE) {
              if (!onApiPut) {
                throw dynaError({
                  code: 202102030729,
                  message: 'Dev error: onApiPut is not implemented',
                  userMessage: 'Dev error: onApiPut is not implemented',
                });
              }
              updatedData = await onApiPut(dataToSave, originalData);
            }
            if (updatedData) {
              setData(updatedData, false, 'useForm: update data from onApiPost()');
              setOriginalData(cloneDeep(updatedData));
            }
            else {
              setOriginalData(cloneDeep(dataToSave));
            }
            setIsChanged(false);
            setChangeId(guid());
            if (formType === EFormType.VIEW_EDIT) setViewMode(EViewMode.VIEW);
            setAlert({
              show: true,
              type: EAlertType.INFO,
              title: 'Successfully saved',
            });
            onFormSave && onFormSave(updatedData || dataToSave);
            eventListeners.current.save.forEach(handler => handler());
          }
          catch (e) {
            const error: IDynaError = e;
            setNetworkError(error);
            if (error.validationErrors) {
              // Merge body / query validation errors
              const validationResult = validationMergeResults<TData>(
                error.validationErrors.query
                || {
                  isValid: true,
                  dataValidation: {},
                  messages: [],
                },
                error.validationErrors.body
                || {
                  isValid: true,
                  dataValidation: {},
                  messages: [],
                },
              );
              if (!validationResult.isValid) {
                setValidationResult(validationResult);
                setAlert({
                  show: true,
                  type: EAlertType.WARNING,
                  title: 'Validation error',
                  children: validationResult.messages.join('\n'),
                });
              }
            }
            else {
              setAlert({
                show: true,
                type: EAlertType.ERROR,
                title: '',
                children: [
                  (() => {
                    if (error.status === 400) return 'Invalid request';
                    if (error.status === 500) return 'Backend error';
                    return 'General error';
                  })(),
                  error.userMessage || '',
                  error.code ? `Error code: ${error.code}` : undefined,
                ]
                  .filter(Boolean).join('\n'),
              });
              console.error('useForm: Unexpected error on save', e);
            }
          }
          finally {
            setIsLoading(false);
            setIsLoadingLabel('');
            setChangeId(guid());
          }
          return updatedData || dataToSave;
        },
      },
      cancel: {
        show:
          formType !== EFormType.VIEW
          && (
            (userCanCreate && formMode === EFormMode.CREATE) ||
            (userCanEdit && formMode === EFormMode.EDIT_MANAGE)
          )
          && !(
            formMode === EFormMode.CREATE
            && hideCancelButtonOnCreateFormMore
          ),
        disabled:
          isLoading
          || viewMode === EViewMode.VIEW,
        onClick: async (): Promise<void> => {
          if (isChanged) {
            const userDiscardChangesConfirmation = await confirm({
              title: 'Discard changes?',
              message: 'You have unsaved changes, are you sure you want to discard them?',
              labelConfirmButton: 'Continue editing',
              labelCancelButton: 'Discard changes',
            });
            if (userDiscardChangesConfirmation) return;
          }
          setIsCanceled(true);
          setIsChanged(false);
          setData(originalData, false, 'useForm: restore original data by cancel operation');
          if (formType === EFormType.VIEW_EDIT) setViewMode(EViewMode.VIEW);
          setValidationResult(null);
          setAlertHide();
          setChangeId(guid());
          onFormCancel && onFormCancel();
          eventListeners.current.cancel.forEach(handler => handler());
        },
      },

      archive: {
        show:
          !!onApiArchive
          && userCanArchive
          && !isArchived
          && formMode === EFormMode.EDIT_MANAGE,
        disabled:
          isLoading
          || isChanged
          || (formType === EFormType.VIEW_EDIT && viewMode === EViewMode.EDIT),
        onClick: async (): Promise<void> => {
          try {
            if (dataId === null) return; // 4TS
            const userArchiveConfirmation = await confirm({
              title: 'Archive',
              message: 'Are you sure you want to archive?',
              labelConfirmButton: 'Archive',
              labelCancelButton: 'Cancel',
            });
            if (!userArchiveConfirmation) return;
            setIsLoading(true);
            setIsLoadingLabel('Archiving...');
            setNetworkError(null);
            setAlertHide();
            setChangeId(guid());
            if (!onApiArchive) {
              throw dynaError({
                code: 202102230730,
                message: 'Dev error: onApiArchive is not implemented',
                userMessage: 'Dev error: onApiArchive is not implemented',
              });
            }
            const updateData = await onApiArchive(dataId, data);
            const newData: TData =
              updateData
                ? updateData
                : {archivedAt: Date.now()} as any;
            setData(
              newData,
              false,
              updateData
                ? 'useForm: update data from onApiArchive()'
                : 'useForm: update archivedAt by archive operation',
            );
            setOriginalData(cloneDeep(newData));
            if (formType === EFormType.VIEW_EDIT) setViewMode(EViewMode.VIEW);
            setChangeId(guid());
            onFormArchive && onFormArchive(newData);
            eventListeners.current.archive.forEach(handler => handler());
          }
          catch (e) {
            setNetworkError(e);
            setAlert({
              show: true,
              type: EAlertType.ERROR,
              title: getTitleError(e.status),
              children: e.userMessage,
            });
          }
          finally {
            setIsLoading(false);
            setIsLoadingLabel('');
            setChangeId(guid());
          }
        },
      },
      unarchive: {
        show:
          !!onApiUnarchive
          && userCanUnarchive
          && (data as any).archivedAt !== 0
          && formMode === EFormMode.EDIT_MANAGE,
        disabled:
          isLoading
          || isChanged
          || (formType === EFormType.VIEW_EDIT && viewMode === EViewMode.EDIT),
        onClick: async (): Promise<void> => {
          try {
            if (dataId === null) return; // 4TS
            const userUnarchiveConfirmation = await confirm({
              title: 'Unarchive',
              message: 'Are you sure you want to unarchive?',
              labelConfirmButton: 'Unarchive',
              labelCancelButton: 'Cancel',
            });
            if (!userUnarchiveConfirmation) return;
            setIsLoading(true);
            setIsLoadingLabel('Undeleting...');
            setNetworkError(null);
            setAlertHide();
            setChangeId(guid());
            if (!onApiUnarchive) {
              throw dynaError({
                code: 202102230731,
                message: 'Dev error: onApiUnarchive is not implemented',
                userMessage: 'Dev error: onApiUnarchive is not implemented',
              });
            }
            const updatedData = await onApiUnarchive(dataId, data);
            const newData: TData = updatedData
              ? updatedData
              : {archivedAt: 0} as any;
            setData(
              newData,
              false,
              updatedData
                ? 'useForm: update data from onApiUnarchive()'
                : 'useForm: update archivedAt by unarchive operation',
            );
            setOriginalData(cloneDeep(newData));
            if (formType === EFormType.VIEW_EDIT) setViewMode(EViewMode.VIEW);
            setChangeId(guid());
            onFormUnarchive && onFormUnarchive(newData);
            eventListeners.current.unarchive.forEach(handler => handler());
          }
          catch (e) {
            setNetworkError(e);
            setAlert({
              show: true,
              type: EAlertType.ERROR,
              title: getTitleError(e.status),
              children: e.userMessage,
            });
          }
          finally {
            setIsLoading(false);
            setIsLoadingLabel('');
            setChangeId(guid());
          }
        },
      },
      delete: {
        show:
          !!onApiDelete
          && userCanDelete
          && !isDeleted
          && formMode === EFormMode.EDIT_MANAGE,
        disabled:
          isLoading
          || isChanged
          || (formType === EFormType.VIEW_EDIT && viewMode === EViewMode.EDIT),
        onClick: async (): Promise<void> => {
          try {
            if (dataId === null) return; // 4TS
            const userDeleteConfirmation = await confirm({
              title: 'Delete',
              message: 'Are you sure you want to delete?',
              labelConfirmButton: 'Delete',
              labelCancelButton: 'Cancel',
            });
            if (!userDeleteConfirmation) return;
            setIsLoading(true);
            setIsLoadingLabel('Deleting...');
            setNetworkError(null);
            setAlertHide();
            setChangeId(guid());
            if (!onApiDelete) {
              throw dynaError({
                code: 202102030730,
                message: 'Dev error: onApiDelete is not implemented',
                userMessage: 'Dev error: onApiDelete is not implemented',
              });
            }
            const updateData = await onApiDelete(dataId, data);
            const newData: TData =
              updateData
                ? updateData
                : {deletedAt: Date.now()} as any;
            setData(
              newData,
              false,
              updateData
                ? 'useForm: update data from onApiDelete()'
                : 'useForm: update deletedAt by delete operation',
            );
            setOriginalData(cloneDeep(newData));
            if (formType === EFormType.VIEW_EDIT) setViewMode(EViewMode.VIEW);
            setChangeId(guid());
            onFormDelete && onFormDelete(newData);
            eventListeners.current.delete.forEach(handler => handler());
          }
          catch (e) {
            setNetworkError(e);
            setAlert({
              show: true,
              type: EAlertType.ERROR,
              title: getTitleError(e.status),
              children: e.userMessage,
            });
          }
          finally {
            setIsLoading(false);
            setIsLoadingLabel('');
            setChangeId(guid());
          }
        },
      },
      undelete: {
        show:
          !!onApiUndelete
          && userCanUnDelete
          && (data as any).deletedAt !== 0
          && formMode === EFormMode.EDIT_MANAGE,
        disabled:
          isLoading
          || isChanged
          || (formType === EFormType.VIEW_EDIT && viewMode === EViewMode.EDIT),
        onClick: async (): Promise<void> => {
          try {
            if (dataId === null) return; // 4TS
            const userUndeleteConfirmation = await confirm({
              title: 'Undelete',
              message: 'Are you sure you want to undelete?',
              labelConfirmButton: 'Undelete',
              labelCancelButton: 'Cancel',
            });
            if (!userUndeleteConfirmation) return;
            setIsLoading(true);
            setIsLoadingLabel('Undeleting...');
            setNetworkError(null);
            setAlertHide();
            setChangeId(guid());
            if (!onApiUndelete) {
              throw dynaError({
                code: 202102030731,
                message: 'Dev error: onApiUndelete is not implemented',
                userMessage: 'Dev error: onApiUndelete is not implemented',
              });
            }
            const updatedData = await onApiUndelete(dataId, data);
            const newData: TData = updatedData
              ? updatedData
              : {deletedAt: 0} as any;
            setData(
              newData,
              false,
              updatedData
                ? 'useForm: update data from onApiUndelete()'
                : 'useForm: update deletedAt by undelete operation',
            );
            setOriginalData(cloneDeep(newData));
            if (formType === EFormType.VIEW_EDIT) setViewMode(EViewMode.VIEW);
            setChangeId(guid());
            onFormUndelete && onFormUndelete(newData);
            eventListeners.current.undelete.forEach(handler => handler());
          }
          catch (e) {
            setNetworkError(e);
            setAlert({
              show: true,
              type: EAlertType.ERROR,
              title: getTitleError(e.status),
              children: e.userMessage,
            });
          }
          finally {
            setIsLoading(false);
            setIsLoadingLabel('');
            setChangeId(guid());
          }
        },
      },
    },
  };

  useFormApi.useFormApi = useFormApi;

  return useFormApi;
};

const getTitleError = (status?: number): string => {
  switch (status) {
    case undefined:
      return "Unknown error";
    case 400:
      return "Invalid request";
    case 404:
      return "Not found";
    case 503:
      return "Unavailable service";
    case 401:
      return "You are Unauthorized";
    case 403:
      return "Access denied";
    case 500:
      return "Backend error";
    default:
      return "Network error";
  }
};
