import {
  useState,
  useRef,
  useCallback,
} from 'react';

import {areValuesEqual} from "utils-library/dist/commonJs/utils";

export type TSetMethod<TState> = (
  partialStateOrCb:
    | Partial<TState>
    | ((state: TState) => Partial<TState>),
) => void;

/**
 * An optimized useState version that set the state if really needed.
 *
 * The set methods supports partial update of the state when it is used for objects.
 */
export const usePerformanceState = <TState>(
  initialState:
    | TState
    | (() => TState),
): [
  TState,
  TSetMethod<TState>,
] => {
  const initial: TState =
    typeof initialState === 'function'
      ? (initialState as () => TState)()
      : initialState;

  const prevStateRef = useRef<TState>(initial);
  const [state, setState] = useState<TState>(initial);

  const handleSetState: TSetMethod<TState> = (partialStateOrCb) => {
    if (typeof state === "object" && !Array.isArray(partialStateOrCb)) {
      // State is an object, support partial updates
      const prevState = prevStateRef.current;
      const partialState =
        typeof partialStateOrCb === 'function'
          ? (partialStateOrCb as (prevState: TState) => TState)(prevStateRef.current)
          : partialStateOrCb;
      const newState = {
        ...prevStateRef.current,
        ...partialState,
      };
      if (!areValuesEqual(prevState, newState)) {
        prevStateRef.current = newState;
        setState(newState);
      }
    }
    else {
      // State is not an object, just overwrite the state
      const newState: TState =
        typeof partialStateOrCb === 'function'
          ? (partialStateOrCb as (prevState: TState) => TState)(prevStateRef.current) as any
          : partialStateOrCb as any;
      const prevState = prevStateRef.current;
      if (prevState !== newState) {
        prevStateRef.current = newState;
        setState(newState);
      }
    }
    return undefined;
  };
  const setStatePerformance = useCallback(
    handleSetState,
    [state],
  );

  return [state, setStatePerformance];
};
