import {ILocation} from "../../interfaces/ILocation";
import {IDevice} from "../../interfaces/IDevice";

export const API_PATH_ApiDeviceMgmtDeviceChangeLocationId = '/services/devices-mgtm/apiDeviceMgmtDeviceChangeLocationId';

export interface IApiDeviceMgmtDeviceChangeLocationIdRequest {
  deviceId: string;
  locationId: string;
}

export interface IApiDeviceMgmtDeviceChangeLocationIdResponse {
  deviceWasMasterDevice: boolean;
  oldDeviceLocation: ILocation;
  newDeviceLocation: ILocation;
  device: IDevice;
}
