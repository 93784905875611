import {IDBEntityHistoryDocument} from "utils-library/dist/commonJs/db-entity";
import {ITrafficStudy} from "../../../interfaces/ITrafficStudy";

export const API_PATH_ApiTrafficStudyHistoryLoadGet = '/services/traffic-studies/apiTrafficStudyHistoryLoadGet';

export interface IApiTrafficStudyHistoryLoadGetRequest {
  historyDocId: string | "actual";
}

export type IApiTrafficStudyHistoryLoadGetResponse = IDBEntityHistoryDocument<ITrafficStudy> | null;
