import {EUserAuthenticationRights} from "mhc-server";
import {IAppRoute} from "../config/IAppRoute";
import AdminIcon from "@mui/icons-material/Dns";

export const routeAdministratorPagePaths: IAppRoute = {
  menuId: '',
  title: 'Administrator',
  icon: <AdminIcon/>,
  routePath: '/admin',
  getRoutePath: () => routeAdministratorPagePaths.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [
    EUserAuthenticationRights.ADMINISTRATOR,
    EUserAuthenticationRights.SYSTEM_ADMINISTRATOR,
  ],
  exact: true,
  render: () => null,
};
