import {PaletteOptions} from "@mui/material/styles/createPalette";
import {MUIV4_PALETTE_LIGHT} from "./MUIV4_PALETTE_LIGHT";
import {grayShades} from "./grayShades";

export const MUIV4_PALETTE_DARK: PaletteOptions = {
  ...MUIV4_PALETTE_LIGHT,
  mode: 'dark',
  appMainColor: {
    light: '#64b5f6',
    main: '#2196f3',
    dark: '#303f9f',
    contrastText: '#fff',
  },
  primary: {
    light: '#96cdf6',
    main: '#6fbbf8',
    dark: '#42A5F5',
    contrastText: '#1C1C1C',
  },
  secondary: {
    light: '#ecc8f1',
    main: '#CE93D8',
    dark: '#AB47BC',
    contrastText: '#1C1C1C',
  },
  error: {
    light: '#E57373',
    main: '#F44337',
    dark: '#D32F2F',
    contrastText: '#1C1C1C',
  },
  warning: {
    light: '#ffb74d',
    main: '#ff9800',
    dark: '#f57c00',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  info: {
    light: '#64b5f6',
    main: '#2196f3',
    dark: '#303f9f',
    contrastText: '#1C1C1C',
  },
  success: {
    light: '#81c784',
    main: '#4caf50',
    dark: '#388e3c',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#fff',
    secondary: 'rgba(255, 255, 255, 0.7)',
    disabled: 'rgba(255, 255, 255, 0.5)',
  },
  background: {
    default: '#424242',
    paper: '#2c2c2c',
  },
  grayShades: {
    gray0: grayShades[9],
    gray1: grayShades[8],
    gray2: grayShades[7],
    gray3: grayShades[6],
    gray4: grayShades[5],
    gray5: grayShades[4],
    gray6: grayShades[3],
    gray7: grayShades[2],
    gray8: grayShades[1],
    gray9: grayShades[0],
  },
};
