import {dynaError} from "dyna-error";

export const getImageDimensionByUrl = (url: string): Promise<{ width: number; height: number } | never> => {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => {
      const width = image.width;
      const height = image.height;

      resolve({
        width,
        height,
      });
    };

    image.onerror = event => {
      reject(dynaError({
        code: 20231105102352,
        message: 'Error loading the image',
        userMessage: 'Error loading the image',
        data: {event},
      }));
    };

    image.src = url;
  });
};

