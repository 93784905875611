import {dynaError} from "dyna-error";

import {TObject} from "../typescript";

export const parseJSONDefault = <TData extends TObject>(
  {
    serialized,
    default: defaultValue,
    errorMessage = "parseJSONDefault cannot parse",
    errorCode,
  }: {
    serialized: string;
    default: TData;
    errorMessage?: string;
    errorCode?: number;
  },
): TData => {
  if (!serialized) return defaultValue;
  try {
    return {
      ...defaultValue,
      ...JSON.parse(serialized),
    };
  }
  catch (e: any) {
    const error = dynaError({
      code: errorCode,
      message: `${errorMessage}, error: ${e.message}, default value returned`,
      data: {
        serialized,
        default: defaultValue,
      },
      parentError: e,
    });
    console.error(error.message, error);
    return defaultValue;
  }
};
