import {ICompressUrlFile} from "../../zip-creator/endpoints/apiZipCreatorStartProcess/CreateCompressedFileByURLs.interfaces";

export const API_PATH_ApiWWRStartZipFileCreationV2 = '/services/wrong-way-resolution/apiWWRStartZipFileCreation-v2';

export interface IApiWWRStartZipFileCreationV2Request {
  wsConnectionId: string;
  files: ICompressUrlFile[];
}

export interface IApiWWRStartZipFileCreationV2Response {
  maxSizeInMb: number;
  compressJobId: string;
  wsEventName: string;
}
