import * as React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import {IIconComponent} from "../../IconComponent";

export const CircularProgressIcon: IIconComponent = (props = {}) => {
  const {
    width,
    height,
  } = props;

  const output: any = (
    <CircularProgress
      variant="indeterminate"
      color="primary"
      size={width || height}
    />
  );
  return output;
};
