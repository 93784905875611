import * as React from "react";
import {useState} from "react";
import {dynaSwitch} from "dyna-switch";

import {THandleSaveMapsArgs} from "../../GeoMapLeafletCore";

import LinearProgress from "@mui/material/LinearProgress";

import {Box} from "../../Box";
import {FlexContainerVertical} from "../../FlexContainer";
import {
  ButtonBar,
  EButtonBarAlign,
} from "../../ButtonBar";
import {
  Button,
  EButtonColor,
  EButtonSize,
} from "../../Button";

import {
  SxProps,
  useTheme,
  Theme,
} from "../../ThemeProvider";
import {alpha} from "@mui/material/styles";
import SaveIcon from "@mui/icons-material/Save";
import BackIcon from "@mui/icons-material/ArrowBackIos";
import CancelIcon from "@mui/icons-material/Cancel";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import FailedIcon from "@mui/icons-material/Error";
import RetryIcon from "@mui/icons-material/Replay";
import DownloadedIcon from "@mui/icons-material/CloudDownload";
import SavedIcon from "@mui/icons-material/SaveAlt";

export interface ISaveOfflineMapsDialogProps {
  sx?: SxProps<Theme>;
  dataComponentName?: string;
  saveMapsArgs: THandleSaveMapsArgs;
  onClose: () => void;
}

export const SaveOfflineMapsDialog = (props: ISaveOfflineMapsDialogProps): JSX.Element => {
  const {
    sx = {},
    dataComponentName,
    saveMapsArgs: {
      savedMapsLabel,
      savedMapsCount,
      saveMapsLabel,
      saveMapsCount,
      saveMaps,
    },
    onClose,
  } = props;
  const theme = useTheme();

  const [step, setStep] = useState<'NO_INTERNET' | 'CONFIRM_SAVE' | 'SAVING' | 'COMPLETED'>(
    navigator.onLine
      ? 'CONFIRM_SAVE'
      : 'NO_INTERNET',
  );
  const [progress, setProgress] = useState(0);
  const [loadFailed, setLoadFailed] = useState(0);
  const [saveFailed, setSaveFailed] = useState(0);
  const [saveHandler, setSaveHandler] = useState<{ cancel: () => void } | undefined>();

  const handleNoInternetRetry = () => {
    setStep(
      navigator.onLine
        ? "CONFIRM_SAVE"
        : "NO_INTERNET",
    );
  };

  const handleSaveClick = () => {
    const saveHandler = saveMaps({
      onProgress: ({
        progress, failedLoadCount, failedSaveCount,
      }) => {
        setProgress(progress);
        setLoadFailed(failedLoadCount);
        setSaveFailed(failedSaveCount);
      },
      onComplete: () => {
        setStep('COMPLETED');
      },
    });
    setSaveHandler(saveHandler);
    setStep('SAVING');
  };

  const handleCancelSaving = (): void => {
    if (saveHandler) saveHandler.cancel();
    onClose();
  };

  const handleRetryClick = (): void => {
    setLoadFailed(0);
    setSaveHandler(undefined);
    setProgress(0);
    handleSaveClick();
  };

  const sxSuccessIcon: SxProps = {
    color: theme.palette.success.main,
    width: 128,
    height: 128,
  };
  const sxFailedIcon: SxProps = {
    color: theme.palette.error.main,
    width: 128,
    height: 128,
  };
  const sxProgressSmallIcon: SxProps = {
    width: 16,
    height: 16,
    marginRight: 6,
    position: 'relative',
    top: 2,
  };

  const content = dynaSwitch<JSX.Element>(
    step,
    <span>Internal error</span>, // 4TS this is not possible
    {
      NO_INTERNET: (
        <>
          <FailedIcon sx={sxFailedIcon}/>
          <h2>No internet connection</h2>
          <ButtonBar align={EButtonBarAlign.CENTER}>
            <Button
              size={EButtonSize.LARGE}
              icon={<RetryIcon/>}
              color={EButtonColor.PRIMARY}
              onClick={handleNoInternetRetry}
            >
              Retry
            </Button>
            <Button
              size={EButtonSize.LARGE}
              icon={<CancelIcon/>}
              color={EButtonColor.SECONDARY}
              onClick={onClose}
            >
              Cancel
            </Button>
          </ButtonBar>
        </>
      ),
      CONFIRM_SAVE: (
        <>
          <SaveIcon
            sx={{
              width: 128,
              height: 128,
            }}
          />
          <h3>Save {saveMapsLabel} ?</h3>
          {savedMapsCount
            ? <h5>You have already saved {savedMapsLabel}</h5>
            : <h5>Nothing has been saved so far</h5>
          }
          <ButtonBar align={EButtonBarAlign.CENTER}>
            <Button
              size={EButtonSize.LARGE}
              icon={<SaveIcon/>}
              onClick={handleSaveClick}
            >
              Save
            </Button>
            <Button
              size={EButtonSize.LARGE}
              icon={<CancelIcon/>}
              color={EButtonColor.SECONDARY}
              onClick={onClose}
            >
              Cancel
            </Button>
          </ButtonBar>
        </>
      ),
      SAVING: (
        <>
          <h1>Saving...</h1>
          <LinearProgress variant="determinate" value={progress}/>
          <h3>{progress}%</h3>
          <h5>
            <DownloadedIcon sx={sxProgressSmallIcon}/>
            Download failed: {loadFailed}
          </h5>
          <h5>
            <SavedIcon sx={sxProgressSmallIcon}/>
            Save failed: {saveFailed}
          </h5>
          <br/>
          <Button
            size={EButtonSize.LARGE}
            icon={<CancelIcon/>}
            color={EButtonColor.ERROR}
            onClick={handleCancelSaving}
          >
            Cancel
          </Button>
        </>
      ),
      COMPLETED: (
        <>
          {!!loadFailed || !!saveFailed
            ? <FailedIcon sx={sxFailedIcon}/>
            : <SuccessIcon sx={sxSuccessIcon}/>
          }
          <h3>Completed</h3>
          {!!loadFailed && <h5>...with {loadFailed} load failed of {saveMapsCount} total</h5>}
          {!!saveFailed && (
            <>
              <h3>Couldn't save {saveFailed} map{saveFailed > 1 ? 's' : ''}.</h3>
              <h5>The device's disk is probably full, or the allocated disk space for the app is not enough. Try to delete the maps and retry.</h5>
            </>
          )}
          <ButtonBar
            align={EButtonBarAlign.CENTER}
            spacing={loadFailed ? 1 : 0}
          >
            <Button
              size={EButtonSize.LARGE}
              icon={loadFailed ? <BackIcon/> : undefined}
              onClick={onClose}
            >
              Done
            </Button>
            {!!loadFailed && (
              <Button
                size={EButtonSize.LARGE}
                icon={<RetryIcon/>}
                color={EButtonColor.SECONDARY}
                onClick={handleRetryClick}
              >
                Retry
              </Button>
            )}
          </ButtonBar>
        </>
      ),
    },
  );

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2500,
        color: theme.palette.text.primary,
        backgroundColor: alpha(theme.palette.background.default, 0.8),
        ...sx,
      }}
      dataComponentName={dataComponentName}
    >
      <FlexContainerVertical
        fullHeight
        alignHorizontal="center"
        alignVertical="middle"
        spacing={3}
        sx={{textAlign: 'center'}}
      >
        {content}
      </FlexContainerVertical>
    </Box>
  );
};
