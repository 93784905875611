import * as React from "react";
import {useMemo} from "react";

import {guid} from "dyna-guid";

import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";

import {Box} from "../Box";
import {
  FlexContainerHorizontal,
  FlexItemMax,
  FlexItemMin,
} from "../FlexContainer";
import {
  HelperText,
  EHelperTextType,
} from "../HelperText";
import {
  TDataComponentName,
  getDataComponentName,
} from "../ui-interfaces";

import {
  SxProps,
  useTheme,
  Theme,
} from "../ThemeProvider";
import {
  ECSSDuration,
  sxTransition,
} from "../sxTransition";

export interface IInputSliderProps<TData> {
  sx?: SxProps<Theme>;
  dataComponentName?: TDataComponentName;
  name?: keyof TData;
  label?: string;
  ariaLabel?: string;
  disabled?: boolean;
  readonly?: boolean;
  required?: boolean;
  step?: number | null;
  min?: number;
  max?: number;
  marks?: true | ISliderMark[];
  value: number;
  getAriaValueLabel: (value: number) => string;
  getValueLabel: (value: number) => string | React.ReactNode;
  labelWidth?: number;
  valueLabelDisplay?: "on" | "auto" | "off";
  valueLabelFormat?: string | ((value: number, index: number) => React.ReactNode);
  helperLabel?: string;
  validationError?: string;
  onChange: (value: number, name: keyof TData) => void;
}

export interface ISliderMark{
  value: number;
  label?: React.ReactNode;
}

export const InputSlider = <TData, >(props: IInputSliderProps<TData>): JSX.Element => {
  const {
    sx = {},
    dataComponentName,
    name = "",
    label,
    ariaLabel = label || "Slider",
    disabled = false,
    readonly = false,
    required = false,
    step = 1,
    min,
    max,
    marks,
    value,
    getAriaValueLabel,
    getValueLabel,
    labelWidth,
    valueLabelDisplay,
    valueLabelFormat,
    helperLabel,
    validationError,
    onChange,
  } = props;

  const id = useMemo(guid, []);
  const theme = useTheme();

  const handleChange = (event: Event, value: number): void => {
    event; // 4TS
    if (readonly) return;
    if (disabled) return;
    onChange(value, name as any);
  };

  return (
    <Box
      sx={{
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        ...sx,
      }}
      dataComponentName={getDataComponentName(dataComponentName,"InputSlider")}
    >
      <Typography
        id={id}
        aria-label={ariaLabel}
        gutterBottom
      >
        {label}
        <Box
          sx={{
            color: theme.palette.error.main,
            position: "relative",
            top: -3,
          }}
          show={required}
          component="span"
        >*</Box>
      </Typography>
      <FlexContainerHorizontal>
        <FlexItemMax>
          <Slider
            sx={{
              transition: sxTransition(theme, 'opacity', ECSSDuration.SHORTEST),
              opacity: readonly ? 0.6 : undefined,
            }}
            aria-labelledby={id}
            getAriaValueText={getAriaValueLabel}
            disabled={disabled}
            step={step}
            min={min}
            max={max}
            marks={marks}
            value={value}
            valueLabelDisplay={valueLabelDisplay}
            valueLabelFormat={valueLabelFormat}
            onChange={handleChange}
          />
        </FlexItemMax>
        <FlexItemMin
          sx={{
            whiteSpace: "nowrap",
            paddingLeft: theme.spacing(2),
            minWidth: labelWidth,
            textAlign: 'right',
          }}
        >
          {getValueLabel(value)}
        </FlexItemMin>
      </FlexContainerHorizontal>
      <HelperText>{helperLabel}</HelperText>
      <HelperText type={EHelperTextType.ERROR}>{validationError}</HelperText>
    </Box>
  );
};
