import {useEffect} from 'react';
import {usePerformanceState} from "../usePerformanceState";
import {useIsMounted} from "../useIsMounted";

export interface IUseClipboardPasteApi {
  hasClipboard: boolean;
  clipboardText: string;
}

export const useClipboardPaste = (): IUseClipboardPasteApi => {
  const getIsMounted = useIsMounted();
  const [clipboardData, _setClipboardData] = usePerformanceState<IUseClipboardPasteApi>({
    hasClipboard: false,
    clipboardText: '',
  });
  const setClipboardData = (date: IUseClipboardPasteApi): void => {
    if (!getIsMounted()) return;
    _setClipboardData(date);
  };

  // Initialize the state
  useEffect(() => {
    (async () => {
      try {
        await new Promise(r => setTimeout(r, 500));
        const pasteText = await window.navigator.clipboard.readText();
        setClipboardData({
          hasClipboard: !!pasteText,
          clipboardText: pasteText,
        });
      }
      catch (e) {
        if (e.message?.includes('Document is not focused')) return; // Ignore it, will be updated by the handleFocusIn.
        console.error('useClipboardPaste.init: Cannot read the clipboard', e);
      }
    })();
  }, []);

  // Listen the global events
  useEffect(() => {
    const handleFocusIn = async () => {
      try {
        const clipboardText = await window.navigator.clipboard.readText();
        setClipboardData({
          hasClipboard: clipboardText !== '',
          clipboardText: clipboardText,
        });
      }
      catch (error) {
        console.error("useClipboardPaste.handleFocusIn(global): Error reading the clipboard:", error);
      }
    };

    const handleCopy = async () => {
      try {
        const clipboardText = await window.navigator.clipboard.readText();
        setClipboardData({
          hasClipboard: clipboardText !== '',
          clipboardText: clipboardText,
        });
      }
      catch (error) {
        console.error("useClipboardPaste.handleCopy: Error copying to clipboard:", error);
      }
    };

    const handlePaste = (event: any) => {
      const clipboardText = event.clipboardData.getData('text');
      setClipboardData({
        hasClipboard: !!clipboardText,
        clipboardText: clipboardText,
      });
    };

    window.addEventListener('focus', handleFocusIn);
    document.addEventListener('copy', handleCopy);
    document.addEventListener('paste', handlePaste);

    return () => {
      window.addEventListener('focus', handleFocusIn);
      document.removeEventListener('copy', handleCopy);
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  return clipboardData;
};
