// PathOptions api: https://react-leaflet.js.org/docs/api-components/#polyline

import * as React from "react";
import {ReactElement} from "react";

import {PathOptions} from "leaflet";
import {Polyline as LeafletPolyline} from "react-leaflet";

import {IGeoPosition} from "utils-library/dist/commonJs/geo";

import {
  MapPopUp,
  IMapPopUpProps,
} from "../MapMarker/components/MapPopUp";
import {makeStyles} from "@mui/styles";

export interface IMapPolylineProps {
  id?: string;
  points: IGeoPosition[];
  pathOptions?: PathOptions;
  popUp?: IMapPopUpProps;
  cursor?: 'pointer';
  onClick?: () => void;
}

export const MapPolyline = (props: IMapPolylineProps): ReactElement | null => {
  const {
    points,
    pathOptions = {},
    popUp,
    // Cursor, Todo: fix me! Currently, this is intentionally ignored as changes to the className of the LeafletPolyline are not taking effect. In other words, the LeafletPolyline applies the className only once and not on subsequent renders.
    onClick,
  } = props;
  const classes = useStyles();

  if (!points.length) return null;

  return (
    <LeafletPolyline
      className={classes.cursorPointer}
      positions={points}
      pathOptions={pathOptions}
      eventHandlers={{click: onClick}}
    >
      {!!popUp && <MapPopUp {...popUp}/>}
    </LeafletPolyline>
  );
};

const useStyles = makeStyles({cursorPointer: {cursor: "pointer !important"}});
