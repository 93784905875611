import * as React from "react";

import {
  ETableSize,
  IColumn,
  ITableSort,
} from "./interfaces";

import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {getDeepValue} from "utils-library/dist/commonJs/utils";

import {Box} from "../Box";

import {EBreakpointDevice} from "../ui-interfaces";
import {SortButton} from "./components/SortButton";
import {BreakpointDeviceRender} from "../BreakpointDeviceRender";

import {
  SxProps,
  Theme,
  useTheme,
} from "../ThemeProvider";
import {sxHover} from "../sxHover";

export interface ITableProps<TData> {
  sx?: SxProps<Theme>;
  show?: boolean;
  ariaLabel?: string;
  columns: IColumn<TData>[];
  sort?: ITableSort<TData>;
  rowHover?: boolean;
  rows: any[];
  size?: ETableSize; // Default is ETableSize.LARGE
  onRowClick?: (rowData: TData) => void;
  onChangeSort?: (sort: ITableSort<TData>) => void;
}

export const Table = <TData, >(props: ITableProps<TData>): React.ReactElement | null => {
  const {
    sx,
    show = true,
    ariaLabel,
    columns,
    sort = {
      fieldName: undefined,
      direction: 0,
    },
    rowHover = true,
    size = ETableSize.LARGE,
    rows,
    onRowClick,
    onChangeSort,
  } = props;
  const theme = useTheme();

  const handleChangeSort = (fieldName: keyof TData, direction: 0 | 1 | -1): void => {
    if (!onChangeSort) return;
    onChangeSort({
      fieldName,
      direction,
    });
  };

  const handleRowClick = (event: any, rowData: any): void => {
    if (['BUTTON', 'A'].includes(event.target.nodeName)) return;
    onRowClick && onRowClick(rowData);
  };

  const renderValue = (column: IColumn<TData>, row: any): any => {
    const value = getDeepValue(row, column.fieldName as any);
    if (column.cellRender) return column.cellRender(value, row);
    return value;
  };

  const cellPadding = (() => {
    switch (size) {
      case ETableSize.SMALL:
        return theme.spacing(0.5);
      case ETableSize.MEDIUM:
        return theme.spacing(1);
      case ETableSize.LARGE:
        return theme.spacing(2);
    }
  })();

  const mapColumnHeader = (column: IColumn<TData>, index: number) => (
    <TableCell
      key={index}
      style={{
        whiteSpace: "nowrap",
        padding: cellPadding,
      }}
      align={column.align}
    >
      <Box
        sx={column.headerSx}
        inline
        title={column.headerTooltip}
      >
        {column.headerLabel}
      </Box>
      {!!column.sortable && (
        <SortButton
          fieldName={column.fieldName}
          sort={sort.fieldName === column.fieldName ? sort.direction : 0}
          onChange={handleChangeSort}
        />
      )}
    </TableCell>
  );

  const mapColumnBody = (row: any, column: IColumn<TData>, index: number): JSX.Element => (
    <TableCell
      sx={column.cellSx}
      key={index}
      align={column.align}
      style={{padding: cellPadding}}
      component="th"
      scope="row"
    >
      {renderValue(column, row)}
    </TableCell>
  );

  const showColumnByBreakpoint = (column: IColumn<TData>, breakpoint: EBreakpointDevice): boolean => {
    if (!column.breakpoints) return true; // 4TS
    return column.breakpoints.includes("allExclude")
      ? !column.breakpoints.includes(breakpoint)
      : column.breakpoints.includes(breakpoint);
  };

  if (!show) return null;

  return (
    <TableContainer
      sx={sx}
      component={Paper}
    >

      <MuiTable aria-label={ariaLabel}>

        <TableHead>
          <TableRow>
            <BreakpointDeviceRender
              render={breakpoint => (
                columns
                  .filter(column => !column.hidden)
                  .filter(column => showColumnByBreakpoint(column, breakpoint))
                  .map(mapColumnHeader)
              )}
            />
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={
                sxHover({
                  hover: rowHover,
                  pointer: !!onRowClick,
                })
              }
              onClick={(e) => handleRowClick(e, row)}
            >
              <BreakpointDeviceRender
                render={breakpoint => (
                  columns
                    .filter(column => !column.hidden)
                    .filter(column => showColumnByBreakpoint(column, breakpoint))
                    .map((column, index) => mapColumnBody(row, column, index))
                )}
              />
            </TableRow>
          ))}
        </TableBody>

      </MuiTable>
    </TableContainer>
  );
};
